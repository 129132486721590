import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { SendSmsComponent } from './components/send-sms/send-sms.component';
import { SMSCampingsRoutingModule } from './sms-campings-routing.module';
import { SmsCampingsComponent } from './sms-campings.component';

@NgModule({
  declarations: [SmsCampingsComponent, SendSmsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SMSCampingsRoutingModule,
  ],
})
export class SmsCampingsModule {}
