<div class="dialog-container">
  <div mat-dialog-content>
    <div class="close-icon" (click)="close()" data-dismiss="modal">
      <img src="assets/images/svg/closeModal.svg" alt="">
    </div>
    <div class="avatar-sec">
      <img [src]="data.src" alt="">
    </div>
  </div>
</div>
