import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "src/app/shared/shared.module";
import { ProjectsComponent } from "./projects.component";
import { ProjectsListComponent } from './pages/projects-list/projects-list.component';
import { ProjectsRoutingModule } from "./projects-routing.module";
import { NgxPaginationModule } from "ngx-pagination";
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
  } from '@angular/material/core';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ProjectsOrdersComponent } from './pages/projects-orders/projects-orders.component';

class CustomDateAdapter extends MomentDateAdapter {

    override getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
        return [
            'الأحد',
            'الإثنين',
            'الثلاثاء',
            'الأربعاء',
            'الخميس',
            'الجمعة',
            'السبت',
        ];
    }
}

export const MATERIAL_DATEPICKER_FORMATS = {
    parse: {
        dateInput: 'YYYY/MM/DD',
    },
    display: {
        dateInput: 'YYYY/MM/DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD/MMM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
@NgModule({
    declarations: [
        ProjectsComponent,
        ProjectsListComponent,
        ProjectDetailsComponent,
        ProjectsOrdersComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        NgSelectModule,
        MatInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatIconModule,
        ProjectsRoutingModule
    ],
    providers: [
        DatePipe,
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: 'en-eg' },
    ]
})
export class ProjectsModule { }
