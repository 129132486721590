import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { WhatsNewService } from '../../services/whats-new/whats-new.service';
import { Images } from 'src/app/shared/global/image-constants';
import { SelectUtil } from 'src/app/core/services/uitls/selectUtil';

@Component({
  selector: 'app-whats-new-grid',
  templateUrl: './whats-new-grid.component.html',
  styleUrls: ['./whats-new-grid.component.scss'],
})
export class WhatsNewGridComponent implements OnInit {

  disabledDelete: boolean = true;
  selectedRowsPK: number[] = [];
  selectedRowsIndex: number[] = [];
  deletedWhatsNewIndex;
  deletedWhatsNewID;
  whatsNewMsg = 'هل أنت متأكد من حذف التحديث؟';
  whatsNewList = [];
  totalRecords: number = 0;
  page: number = 1;
  config: any;
  imgDefault: string = Images.DEFAULT_SORT;
  imgASC: string = Images.ASC_SORT;
  imgDesc: string = Images.DESC_SORT;
  ordering = {
    publish_date: true,
  };
  modelImg: boolean;
  confirmMsg: string;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  totalRowsSelected: boolean = false;
  pageTitle: string = 'تحديثات النظام';
  @ViewChildren('checkBoxList') checkBoxList: ElementRef<HTMLInputElement>[];
  @ViewChild('selectAllElm') selectAllElm: ElementRef<HTMLInputElement>;
  currentItemsPerPage: number;

  constructor(
    private router: Router,
    private whatsNewService: WhatsNewService,
    private dialog: MatDialog
  ) {
    this.config = {
      id: 'myPagination',
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
    };
  }

  ngOnInit(): void {
    this.getWhatsNewList();
  }

  getWhatsNewList(ordering?) {
    this.totalRowsSelected = false;
    let params = `page=${this.config.currentPage}`;
    params += ordering ? `&ordering=${ordering}` : '';
    params +=`&page_size=${this.config.itemsPerPage}`;
    this.whatsNewService.getWhatsNewList(params).subscribe((data) => {
      this.whatsNewList = data.results;
      this.config['totalItems'] = data.count;
      this.currentItemsPerPage = data.results.length;
    });
  }

  toggleSrc(orderCol, img) {
    img.src = this.imgASC;
    let col = orderCol;
    if (!this.ordering[orderCol]) {
      col = '-' + orderCol;
      img.src = this.imgDesc;
    }
    this.ordering[orderCol] = !this.ordering[orderCol];
    this.getWhatsNewList(col);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  deleteSelectedRows() {
    this.deletedWhatsNewID = this.selectedRowsPK;
    this.deletedWhatsNewIndex = this.selectedRowsIndex;
    this.showDeleteConfirmationBox();
  }


  deleteSelectRow(event, rowPk, index) {
    this.disabledDelete = true;
    this.totalRowsSelected = false;
    SelectUtil.selectsSingleRow(event, rowPk, index, this);
    if (this.selectedRowsPK.length > 0) {
      this.disabledDelete = false;
    }
  }

  selectAll(event) {
    this.disabledDelete = true;
    SelectUtil.selectAll(event, this);
  }

  addNewEvent() {
    this.router.navigateByUrl('/pages/whats-new/add-new-event/0');
  }

  openWhatsNew(id) {
    this.router.navigateByUrl('/pages/whats-new/add-new-event/' + id);
  }

  showDeleteConfirmationBox() {
    this.deleteDialogRef = this.dialog.open(ConfirmationBoxComponent, {
      data: {
        id: this.deletedWhatsNewID,
        index: this.deletedWhatsNewIndex,
        message: this.whatsNewMsg,
      },
    });
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete(result);
      }
    });
  }

  delete(event) {
    let index = event.index;
    let id = event.id;
    this.whatsNewService.deleteWhatsNew(id).subscribe(
      (data) => {
        for (let i = index.length - 1; i >= 0; i--) {
          let OrderInd = index[i];
          this.whatsNewList.splice(OrderInd, 1);
        }
        this.selectedRowsPK = [];
        this.selectedRowsIndex = [];
      },
      (error) => {
        try {
          this.modelImg = false;
          this.confirmMsg = '  عذراً لا يمكن حذف التحديث . ‎';
          this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
            data: {
              imgSuccess: this.modelImg,
              message: this.confirmMsg,
            },
          });
          this.selectedRowsPK = [];
          this.selectedRowsIndex = [];
        } catch {
          console.log(error);
        }
      }
    );
  }

  selectTotalRows(event) {
    this.totalRowsSelected = event;
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.totalRowsSelected = false;
    this.getWhatsNewList();
  }
  pageSizeChanged(event) {
    this.config.itemsPerPage = event;
    this.getWhatsNewList();
  }
}
