import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { PaymentService } from '../../../services/payment/payment.service';

@Component({
  selector: 'app-amount-summary',
  templateUrl: './amount-summary.component.html',
  styleUrls: ['./amount-summary.component.scss'],
})
export class AmountSummaryComponent implements OnInit {

  @Output() summary_view = new EventEmitter();
  @Input() summaryData;
  address_building_code: string;
  address_unit_code: string;
  address_neighborhood: string;
  address: string;
  imgSuccess: boolean;
  confirmMsg: string;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;

  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private paymentService: PaymentService
  ) {}
  
  ngOnInit(): void {
    this.address_building_code = this.summaryData.address_building_code
      ? this.summaryData.address_building_code + ' , '
      : '';
    this.address_unit_code = this.summaryData.address_unit_code
      ? this.summaryData.address_unit_code + ' , '
      : '';
    this.address_neighborhood = this.summaryData.address_neighborhood
      ? this.summaryData.address_neighborhood + ' , '
      : '';
    this.address = this.address_building_code;
    this.address += this.address_neighborhood;
    this.address += this.summaryData.google_address_city
      ? this.summaryData.google_address_city + ','
      : '';
    this.address += this.summaryData.google_address_area
      ? this.summaryData.google_address_area + ','
      : '';
    this.address += this.summaryData.google_address_country
      ? this.summaryData.google_address_country
      : '';
  }

  prevStep() {
    this.summary_view.emit({ viewName: 'details', data: '' });
  }
  
  goOrderList() {
    this.titleService.setTitle('الطلبات | عاين');
    let data = {
      reference_number: this.summaryData.parent_reference_number,
      reason_pk: this.summaryData.sub_order_reason,
      sub_order_value: this.summaryData.sub_order_value,
    };
    this.paymentService.creatAdditionalAmount(data).subscribe(() => {
      this.imgSuccess = true;
      this.confirmMsg =
        'تم إرسال طلب الخدمة الإضافية ' +
        this.summaryData.parent_reference_number +
        '#' +
        ' للعميل من أجل إتمام عملية الدفع';
      this.showAlertBox();
      localStorage.removeItem('additional_amount');
    });
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.imgSuccess,
        message: this.confirmMsg,
        button: 'تم',
        backUrl: '/pages/orders/orders-list',
      },
    });
  }
}
