<form [formGroup]="additionForm">
  <div class="row">
    @for (elem of additions; track elem.id; let i = $index) {
    <div class="col-6">
      <div class="form-group">
        @switch(elem.type) {
        @case('text') {
          <label class="form-label"> {{ elem.name }}</label>
          <input type="text" class="form-control" [formControlName]="elem.type+'_'+elem.id" />
        }
        @case('date') {
          <label class="form-label"> {{ elem.name }}</label>
          <mat-form-field (click)="picker.open()" appearance="outline">
            <input matInput [formControlName]="elem.type+'_'+elem.id" [matDatepicker]="picker" disabled />
            <mat-datepicker-toggle matSuffix [for]="picker"><mat-icon matDatepickerToggleIcon class="calender-icon">
                <img src="assets/images/svg/calendar-admin-icon.svg" alt="" />
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        } @case('select') {
          <label class="form-label"> {{ elem.name }}</label>
          <ng-select bindLabel="name" class="single-select" #selectStatus appendTo="body" [formControlName]="elem.type+'_'+elem.id"
            [searchable]="true" [clearable]="true">
            @for (item of elem.choices; track item) {
            <ng-option [value]="item.id">
              {{ item.name }}
            </ng-option>
            }
          </ng-select>
        }
        @case('file') {
        <file-input [file]="elem" [fileIndex]="i" [fileName]="'name'" [fileLabel]="'field_value'"
          (delete)="deleteFile($event)" (save)="saveFile($event)"></file-input>
        }
        }
      </div>
    </div>
    }
  </div>
</form>
<div  class="save-section">
  <div class="save-btn-wrapper">
    <button type="button" class="btn btn-green"  (click)="save()">
      @if (showSpinner) {
        <img class="spin-img" src="assets/images/svg/Spinner-s.svg" alt=""/>
      }
      حفظ
    </button>
  </div>
</div>
