<!-- START MAIN SECTION -->
<main class="login-layout reset-password">
  <div class="login-card">
    <h3 class="login-title">تعيين كلمة المرور الجديدة</h3>

    <form
      class="needs-validation"
      novalidate
      id="passwordReset-form"
      [formGroup]="resetFormGroup"
      >
      <div class="form-group">
        <label class="form-label">كلمة المرور الجديدة</label>
        <input
          type="password"
          required
          class="form-control"
          formControlName="new_password1"
          name="new_password1"
          id="new_password1"
          />
        </div>
        <div class="form-group">
          <label class="form-label">تأكيد كلمة المرور</label>
          <input
            type="password"
            required
            class="form-control"
            formControlName="new_password2"
            name="new_password2"
            id="new_password2"
            />
          </div>
          <div class="login-btn">
            <button
              class="btn btn-auth"
              type="submit"
              [disabled]="!resetFormGroup?.valid"
              (click)="save()"
              >
              تعيين كلمة المرور
            </button>
          </div>

          @if (alertmsg) {
            <div class="alert-box mt-4 mb-0">
              <img
                src="assets/images/svg/error-icon.svg"
                class="close-alert"
                alt=""
                (click)="closeError()"
                />
                <p class="alert-msg">{{errorMsg}}</p>
              </div>
            }
          </form>
        </div>
      </main>
