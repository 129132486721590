import { Component, OnInit, ViewChild } from '@angular/core';
import { InspectorGridService } from '../../services/inspectors-grid/inspectors-grid.service';
import { Images } from 'src/app/shared/global/image-constants';
import * as XLSX from 'xlsx';
import { Charts } from 'src/app/core/services/uitls/charts';
import { RegionMapComponent } from 'src/app/shared/components/statistics/region-map/region-map.component';
import { PieChartParams } from 'src/app/models/enums/charts.enum';
import { ColorPaletas } from 'src/app/common/colorPalates';

@Component({
  selector: 'app-inspector-statistics',
  templateUrl: './inspector-statistics.component.html',
  styleUrls: ['./inspector-statistics.component.scss'],
})
export class InspectorStatisticsComponent implements OnInit {
  inspectorsStats: any;
  regionData: any;
  params: any;
  yearsOfExperience: any;
  imagePath = Images;
  templateToExcel: string[][];
  xAxisData = [];
  yAxisData = [];
  acadmicMajorData = [];
  colorPalet80 = ColorPaletas.colorPalet80;
  inspectorCardsState: any[] = [];
  totalInspectors: any;
  acadmicMajorParams: PieChartParams;
  basicColumnParams: { xAxisData: any[]; yAxisData: any[]; xAxisName: string; yAxisName: string; id: string; };
  selectedRegionName: any;
  @ViewChild('regionChild')private regionChild: RegionMapComponent;
  selectedCardName: any;

  constructor(private InspectorGrid: InspectorGridService) {}

  ngOnInit(): void {
    this.getInspectorStats(this.params);
  }

  createBasicChartColumn(): void {
    this.setAxisData();
    this.basicColumnParams = {
      xAxisData: this.xAxisData,
      yAxisData: this.yAxisData,
      xAxisName: 'عدد الفاحصين',
      yAxisName: 'سنوات الخبرة',
      id: 'ExpertiseBasicColumn',
    };
    Charts.createBasicColumnChart(this.basicColumnParams);
  }

  setAxisData() {
    this.yearsOfExperience.forEach((item) => {
      this.xAxisData.push(item.years_of_experience_text ?? 'لا يوجد');
      this.yAxisData.push(item.count);
    });
  }

  getPrecentage(value, total) {
    if(total == 0) 
    {
      return 0+'%';
    }
    return Math.round((value / total) * 100) + '%';
  }

  mapRegionData(data) {
    return data.map((val) => {
      data[val.map_code] = val;
    });
  }

  getInspectorStats(params) {
    this.InspectorGrid.getInspectorsStats(params).subscribe((data) => {
      this.inspectorCardsState=[];
      this.acadmicMajorData=[];
      this.xAxisData = [];
      this.yAxisData = [];
      this.inspectorsStats = data;
      this.regionData = this.inspectorsStats.inspectors_by_area;
      this.mapRegionData(this.regionData)
      this.totalInspectors = data.all_inspectors_count;
      this.yearsOfExperience = this.inspectorsStats.inspectors_by_years_of_experience;
      this.statisitcsCards();
      this.createBasicChartColumn();
      this.createAcadmicMajorsChart();
    });
  }
  
  statisitcsCards() {
    this.inspectorCardsState.push(
      { key:null,name: 'المسجلين', value: this.inspectorsStats.all_inspectors_count,color: '#91809E',textColor:'purple-txt'},
      { key:'qualified', name: 'المؤهلين ', value: this.inspectorsStats.qualified, color: '#1B9DF1',textColor:'blue-txt' },
      { key:'not_qualified', name: 'الغير مؤهلين', value: this.inspectorsStats.not_qualified, color: '#FC3664',textColor:'red-txt' },
      { key:'under_examination', name: 'تحت التأهيل', value: this.inspectorsStats.under_examination, color: '#FF7547',textColor:'orange-txt' },
      { key:'available', name: 'المتاحيين', value: this.inspectorsStats.available, color: '#00CC92',textColor:'green-txt' },
      { key:'not_available', name: 'الغير متاحين', value: this.inspectorsStats.not_available, color: '#FFBE0B',textColor:'yellow-txt' }
    );
    this.inspectorCardsState.forEach((data, index) => {
      setTimeout(() => {
        Charts.createDonoutChart({
          data: data.value,
          color: data.color,
          id: `donutChart${index}`,
          total: this.totalInspectors,
          prcentage: this.getPrecentage(data.value, this.totalInspectors),
        });
      }, 0);
    });
  }

  createAcadmicMajorsChart() {
    this.inspectorsStats.inspectors_by_academic_major.forEach(
      (major, index) => {
        this.acadmicMajorData.push({
          name: major.academic_major_text ?? 'لا يوجد',
          y: major.count,
          color: this.colorPalet80[index],
        });
      }
    );
    this.acadmicMajorParams = {
      id: 'MajorsPie',
      series: this.acadmicMajorData,
      name: 'التخصصات',
      height: 400
    };
    Charts.createStylesPie(this.acadmicMajorParams);
  }

  resetFilter() {
      this.selectedCardName = null;
      this.regionChild.resetFilter();   
  }

  getSelectedRegion(event) {
    if (event) {
      this.params = { map_code: event.code };
      this.selectedRegionName = event.name;
      this.getInspectorStats(this.params);
    } else {
      this.selectedRegionName = null;
      this.getInspectorStats(null);
    }
  }
  exportDataXLSX(arr: any[]) {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, 'المدن');
    XLSX.writeFile(wb, `cities.xlsx`);
  }
  
  cardFilter(event) {
    if(event.key && this.selectedRegionName) {
      this.selectedCardName = event.name;
      this.params = { ...this.params, category: event.key };
      this.getInspectorStats(this.params);
    }
  }
}
