<!--header-->

<div class="inner-page-header">
  <h3>تحديثات النظام</h3>
</div>
@if (whatsNewList) {
<div class="request-control">
  <div class="delete-icon-grid">
    <button
      class="btn btn-custom-delete"
      (click)="deleteSelectedRows()"
      [disabled]="disabledDelete"
    >
      <img src="assets/images/svg/new-delete.svg" alt="" />
    </button>
  </div>
  <div class="new-element">
    <button class="btn btn-add" (click)="addNewEvent()">
      <img src="assets/images/svg/add-icon.svg" alt="" />
      إضافة تحديث
    </button>
  </div>
</div>
} @if (whatsNewList) {
<ayen-selected-count
  [itemsPerPage]="currentItemsPerPage"
  [selectedRows]="selectedRowsPK"
  [totalRowsSelected]="totalRowsSelected"
  [pageTitle]="pageTitle"
  [totalItems]="config.totalItems"
  (setTotalRowsSelected)="selectTotalRows($event)"
>
</ayen-selected-count>
}

<!-- coupon table -->
<div class="request-table">
  <div class="table-responsive">
    <table id="order-table" class="table table-striped" aria-label="">
      <thead>
        <tr>
          <th class="width-55" id="">
            <label class="checkbox-container">
              <input
                type="checkbox"
                id="custom_check_all"
                #selectAllElm
                (click)="selectAll($event.target)"
              />
              <span class="checkmark"></span>
            </label>
            <span class="checkbox-label">عنوان التحديث</span>
          </th>
          <th class="width-15" id="">نوع الحساب</th>
          <th class="width-15" id="" (click)="toggleSrc('publish_date', img1)">
            التاريخ
            <img #img1 [src]="imgDefault" alt="" />
          </th>
          <th class="width-15" id="">الحالة</th>
        </tr>
      </thead>

      <tbody>
        @if (whatsNewList) { @for (item of whatsNewList | paginate: config ;
        track item; let i = $index) {
        <tr (click)="openWhatsNew(item.id)">
          <td>
            <label class="checkbox-container" (click)="stopPropagation($event)">
              <input
                type="checkbox"
                #checkBoxList
                (click)="deleteSelectRow($event, item.id, i)"
                [id]="i + '_' + item.id"
              />
              <span class="checkmark"></span>
            </label>
            <span class="checkbox-label">
              <a href="/pages/whats-new/add-new-event/{{ item.id }}">{{
                item.title
              }}</a>
            </span>
          </td>
          <td>{{ item.user_type }}</td>
          <td>{{ item.publish_date }}</td>
          <td>
            <button
              class="btn-checked"
              [ngClass]="{
                'active-status': item.active === true,
                'inactive-status': item.active === false
              }"
            >
              {{ item?.active ? "منشور" : "غير منشور" }}
            </button>
          </td>
        </tr>
        } @if (whatsNewList.length === 0) {
        <tr>
          <td colspan="9" class="text-center">لا توجد بيانات</td>
        </tr>
        } }
      </tbody>
    </table>
  </div>
</div>

@if (whatsNewList) {
<ayen-pagination
  (pageData)="pageChanged($event)"
  (pageSize)="pageSizeChanged($event)"
  [totalCount]="config.totalItems"
  [pageTitle]="pageTitle"
>
</ayen-pagination>
}
