import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class RefundService extends AYENServiceBase {

  constructor(private http: HttpClient) {
    super();
  }


  changeRefundStatus(orderID, isPaid): Observable<any> {
    return this.http
      .put<any>(this.baseURL + `/refund-is-paid/${orderID}/`, isPaid);
  }

  createManualRefund(orderId, refundData): Observable<any> {
    const payload = {
      reason: refundData.refundReason,
      type: refundData.refundType,
      change_order_status_to_cancel: refundData.changeOrderStatusToCancel,
      received_on_date: refundData.refundDate,
      qoyod_customer_id: refundData.qoudClinetNumber,
      credit_note_id: refundData.qoudNotificationNumber,
      payment_voucher_id: refundData.bondNumber,
      payment_method: refundData.paymentMethod
    }
    if (refundData.value) {
      payload['value'] = refundData.value;
    }
    return this.http.post<any>(this.baseURL + `/admin/orders/${orderId}/refund`, payload);
  }

  getOrderCostRefund(orderID): Observable<any> {
    return this.http
      .get<any>(this.baseURL + `/order-cost-refunds/${orderID}/`);
  }

  createCostRefund(orderID, refundData): Observable<any> {
    return this.http
      .post<any>(this.baseURL + `/order-cost-refunds/${orderID}/`, refundData);
  }

  getRefundReasons() {
    return this.http.get<any>(this.baseURL + `/refund-reasons/`);
  }

  getRefundType(): Observable<any> {
    return this.http.get<any>(this.baseURL + `/refund-types/`);
  }

  calculateRefundValue(orderID, refundData): Observable<any> {
    return this.http.post<any>(
      this.baseURL + `/calculate-refund-value/${orderID}/`,
      refundData
    );
  }
}
