import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, expand, map, reduce } from 'rxjs/operators';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';
import { RegisterAsInspector } from 'src/app/models/enums/register-as.enum';

@Injectable({
  providedIn: 'root',
})
export class InspectorGridService extends AYENServiceBase {
  inspectorFilesList: any = [];
  inspectorFile = {};
  fileLabel = {
    membership_license_of_the_SCE_file: 'رخصة عضوية الهيئة السعودية للمهندسين',
    experience_certificate_file: 'شهادة خبرة',
    cv_file: 'ملف السيرة الذاتية',
    prefabricated_building_inspection_course_file:
      ' شهادة حضور دورة" فحص المباني الجاهزة"',
    building_quality_inspection_course_file:
      'شهادة حضور دورة" فحص جودة البناء"',
    career_insurance_file: 'صورة التأمين المهني الهندسي',
  };
  file = {};
  constructor(private http: HttpClient) {
    super();
  }

  createOrderFilesList(inspector) {
    this.inspectorFilesList = [];
    Object.entries(inspector).forEach(([key, value]: any[]) => {
      if (
        key === 'membership_license_of_the_SCE_file' ||
        key === 'experience_certificate_file' ||
        key === 'cv_file' ||
        key === 'prefabricated_building_inspection_course_file' ||
        key === 'building_quality_inspection_course_file' ||
        key === 'career_insurance_file'
      ) {
        this.inspectorFile = {
          label: this.fileLabel[key],
          inspectorlabel: key,
          name: value,
          exist: value ? true : false,
          progress: false,
          progressVal: 0,
          error: false,
        };
        this.inspectorFilesList.push(this.inspectorFile);
      }
    });
    return this.inspectorFilesList;
  }
  getExpertiseFocus(): Observable<any> {
    return this.http.get(this.baseURL + '/expertise-focus-areas/').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getExperinceYears(): Observable<any> {
    return this.http.get(this.baseURL + '/years-of-experience/').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  uploadInspectorFiles(data, certificateID): Observable<any> {
    return this.http
      .patch(this.baseURL + `/user-certificates/${certificateID}`, data, {
        reportProgress: true,
        observe: 'response',
      });
  }

  deleteUserCertificate(certificateID): Observable<any> {
    return this.http.delete(this.baseURL + `/user-certificates/${certificateID}`);
  }

  getInspectorsByPage(query) {
    return this.http.get(this.baseURL + '/inspectors', { params: query }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getAllInspectors(query) {
    return this.http.get(this.baseURL + '/inspectors', { params: query }).pipe(
      expand((response) =>
        response['links'].next
          ? this.http.get(
            this.baseURL +
            '/inspectors?' +
            response['links'].next.split('?')[1]
          )
          : EMPTY
      ),
      reduce((acc, current) => acc.concat(current['results']), [])
    );
  }

  getAccountInfo(): Observable<any> {
    return this.http.get(this.baseURL + '/account-info/').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getCertificates(): Observable<any> {
    return this.http.get(this.baseURL + `/certificates/`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  addCertificate(certificate): Observable<any> {
    return this.http.post(this.baseURL + `/certificates/`, certificate);
  }

  deleteCertificate(certificateID): Observable<any> {
    return this.http.delete(this.baseURL + `/certificates/${certificateID}`)
  }

  updateCertificate(certificateID, data): Observable<any> {
    return this.http.put(this.baseURL + `/certificates/${certificateID}`, data);
  }

  updateInspectorsData(id, inspector): Observable<any> {
    return this.http
      .put(this.baseURL + '/inspectors/' + id + '/', inspector)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }
  getRegistrationAs(): Observable<any> {
    return this.http.get(this.baseURL + '/registration-as/').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getInspectorsStats(params): Observable<any> {
    let url = this.baseURL + '/inspectors-stats/';
    return this.http.get(url,{params: params}).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  
  getFacilities(): Observable<any> {
    return this.http.get(this.baseURL + `/facilities/`);
  }

  isEditable(scope) {
    return scope.data.registration_in_ayen_as == RegisterAsInspector.facility ? true : false;
  }
  
  booleanCellStyle(params) {
    if (params.value) {
      return { backgroundColor: '#379B79' };
    }
    return { backgroundColor: '#D9727F' };
  }

  goOrders(params) {
    return `<a href="/pages/orders/orders-list?page=1&order_inspector=${params.data.full_name}"target="_blank">${params.value}</a>`;
  }

  handleError(error) {
    let errorMessage;
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
