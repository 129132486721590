<div class="inner-page-header">
  <h3>بروفايل الفاحص</h3>
</div>
@if (inspectorData) {
  <div class="main-content">
    <form class="needs-validation" [formGroup]="inspectorProfileForm">
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngClass]="{
          'closed-panel': panelOpenState == false,
          'open-panel': panelOpenState == true
          }" class="custom-panel">
          <mat-expansion-panel-header>
            <mat-panel-title> البيانات الشخصية</mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="panel-body">
            @if (inspectorData) {
              <user-main-info
                [subForm]="inspectorProfileForm"
                [userData]="inspectorData">
              </user-main-info>
            }
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label">رقم الهوية الوطنية</label>
                  <input formControlName="id_number" class="form-control" type="text">
                  <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="" class="form-label"> الجنسية </label>
                  <ng-select bindLabel="name" class="single-select" class="single-select"
                    #selectNationality
                    placeholder="يرجى تحديد الجنسية"
                    appendTo="body"
                    formControlName="nationality"
                    [searchable]="true"
                    [clearable]="true">
                    @for (country of countries; track country) {
                      <ng-option [value]="country.pk" >
                        {{country.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="" class="form-label"> المدينة </label>
                  <ng-select bindLabel="name" class="single-select" class="single-select"
                    #selectCity
                    placeholder="يرجى تحديد المدينة"
                    appendTo="body"
                    formControlName="city"
                    [searchable]="true"
                    [clearable]="true">
                    @for (city of cities; track city) {
                      <ng-option [value]="city.pk" >
                        {{city.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="" class="form-label"> المنطقة </label>
                  <ng-select bindLabel="name" class="single-select" class="single-select"
                    #selectRegion
                    placeholder="يرجى تحديد المنطقة"
                    appendTo="body"
                    formControlName="region"
                    [searchable]="true"
                    [clearable]="true">
                    @for (region of regions; track region) {
                      <ng-option [value]="region.pk" >
                        {{region.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group ">
                  <label for="" class="form-label">حالة الفاحص</label>
                  <ng-select bindLabel="name" class="single-select"
                    #select
                    placeholder="يرجى اختيار  حالة الفاحص  "
                    appendTo="body"
                    formControlName="inspector_is_available"
                    [searchable]="true"
                    [clearable]="true">
                    @for (vrification of inspectorStatus; track vrification) {
                      <ng-option [value]="vrification.id">
                        {{vrification.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group ">
                  <label for="" class="form-label"> حالة الحساب </label>
                  <ng-select bindLabel="name" class="single-select"
                    #select
                    placeholder="  يرجى اختيار حالة الحساب  "
                    appendTo="body"
                    formControlName="is_active"
                    [searchable]="true"
                    [clearable]="true">
                    @for (vrification of accountStatus; track vrification) {
                      <ng-option [value]="vrification.id">
                        {{vrification.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group custom-form-panel">
                  <label for="" class="form-label"> التأهيل  </label>
                  <ng-select bindLabel="name" class="single-select"
                    #select
                    placeholder="  يرجى اختيار التأهيل  "
                    appendTo="body"
                    formControlName="qualification_status"
                    [searchable]="true"
                    [clearable]="true">
                    @for (value of qualificationsValues; track value) {
                      <ng-option [value]="value.id">
                        {{value.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngClass]="{
          'closed-panel': panelOpenState == false,
          'open-panel': panelOpenState == true
          }" class="custom-panel">
          <mat-expansion-panel-header>
            <mat-panel-title> صفة العمل</mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <ng-select bindLabel="name" class="single-select" #selectRegisterAs placeholder="يرجي اختيار صفة العمل"
                    appendTo="body" formControlName="registration_in_ayen_as" [searchable]="true" [clearable]="true">
                    @for (register of registrationAs; track register) {
                      <ng-option [value]="register.id">
                        {{register.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
              @if (inspectorProfileForm.controls.registration_in_ayen_as.value == 2) {
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">اسم المنشأة</label>
                    <input formControlName="facilty_name" class="form-control" type="text">
                    <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
                  </div>
                </div>
              }
              @if (inspectorProfileForm.controls.registration_in_ayen_as.value == 2) {
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">رقم السجل التجاري</label>
                    <input formControlName="commercial_registration_no" class="form-control" type="text">
                    <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
                  </div>
                </div>
              }
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngClass]="{
          'closed-panel': panelOpenState == false,
          'open-panel': panelOpenState == true
          }" class="custom-panel">
          <mat-expansion-panel-header>
            <mat-panel-title> الخبرات المهنية</mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="" class="form-label"> التخصص الأكاديمي </label>
                  <ng-select bindLabel="name" class="single-select" #select placeholder="  يرجى اختيار التخصص الأكاديمي  "
                    appendTo="body" formControlName="academic_major" [searchable]="true" [clearable]="true">
                    @for (value of academicMajors; track value) {
                      <ng-option [value]="value.id">
                        {{value.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="" class="form-label"> عدد سنوات الخبرة</label>
                  <ng-select bindLabel="name" class="single-select" #select placeholder="  يرجى اختيار عدد سنوات الخبرة  "
                    appendTo="body" formControlName="years_of_experience" [searchable]="true" [clearable]="true">
                    @for (value of experinceYears; track value) {
                      <ng-option [value]="value.id">
                        {{value.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label">رقم عضوية الهيئة السعودية للمهندسين</label>
                  <input formControlName="membership_number_of_the_SCE" class="form-control" type="text">
                  <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="" class="form-label">تاريخ انتهاء صلاحيتها</label>
                  <mat-form-field (click)="picker.open()" appearance="outline">
                    <input matInput formControlName="expiration_date_of_membership_number_of_the_SCE"
                      [matDatepicker]="picker" disabled />
                      <mat-datepicker-toggle matSuffix [for]="picker">
                        <mat-icon matDatepickerToggleIcon class="calender-icon">
                          <img src="assets/images/svg/calendar-admin-icon.svg" alt="">
                        </mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker disabled="false"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              <div class="col-md-6 custom-control">
                <div class="form-group">
                  <label for="" class="form-label">مجال تركيز الخبرات </label>
                  <app-select [optionItems]="expertiseFocus" [placeholder]="'يرجى تحديد مجال تركيز الخبرات'"
                    [initValues]="selectedExpertiseFocus" [id]="'expertise-focus'" [bindValue]="'pk'"
                    (selectedOptions)="setExpertiseFocus($event)">
                  </app-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="notes-section">
                  <label for="" class="form-label">نبذة عن المنجزات المهنية</label>
                  <textarea class="form-control" formControlName="summary_of_professional_accomplishments"
                  name="summary_of_professional_accomplishments" maxlength="500"></textarea>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngClass]="{
          'closed-panel': panelOpenState == false,
          'open-panel': panelOpenState == true
          }" class="custom-panel">
          <mat-expansion-panel-header>
            <mat-panel-title> الملفات</mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="panel-body">
            <div>
              <div class="row">
                @for (file of inspectorCertificates ; track file; let i = $index) {
                  <div class="col-md-6">
                    <label class="form-label file-label">{{file.certificate}}</label>
                    @if (file.certificate_file) {
                      <div class="existance-wrapper">
                        <div class="existance">
                          <img src="assets/images/svg/file-name-icon.svg" alt="">
                          <a [href]="file.certificate_file" target="_blank">{{file.certificate}}</a>
                        </div>
                        <div>
                          <button class="btn delete-wrapper" (click)="deleteFile(file.id ,  i)">
                            <img src="assets/images/svg/DELETE1.svg" alt="">
                          </button>
                        </div>
                      </div>
                    }
                    @if (file.progress) {
                      <div class="file-progress">
                        <div class="file-progress-boxs col-md-12">
                          <div class="progress-item">
                            <img src="assets/images/svg/file-progress.svg" alt="">
                          </div>
                          <div class="progress-item upload-progress">
                            <span class="file-name">{{file.certificate}}</span>
                            <progress id="bar" value="{{file.progressVal}}" max="100">
                            </progress>
                          </div>
                        </div>
                      </div>
                    }
                    @if (!(file.certificate_file || file.progress)) {
                      <div class="drag-btn">
                        <button type="button" class="btn btn-outline-secondary add-new-file">
                          <img src="assets/images/svg/upload-img.svg" alt="" />
                          رفع ملف
                        </button>
                        <input type="file" name="" (change)="handleFileInput($event.target.files , file.id , i)"
                          accept="image/jpeg,image/png,application/pdf" class="file-new form-control" />
                          @if (file.error) {
                            <div class="file-error">
                              <span>عفوا لا يمكن رفع صور حجم اكبر من 5GB </span>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngClass]="{
            'closed-panel': panelOpenState == false,
            'open-panel': panelOpenState == true
            }" class="custom-panel">
            <mat-expansion-panel-header>
              <mat-panel-title> الاشعارات</mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="panel-body">
              <activat-notifications [is_SMS]="is_SMS" [is_Email]="is_Email" (switchEvent)="switchBtnChanged($event)">
              </activat-notifications>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngClass]="{
            'closed-panel': panelOpenState == false,
            'open-panel': panelOpenState == true
            }">
            <mat-expansion-panel-header>
              <mat-panel-title>سجل الاشعارات</mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="panel-body">
              <notifications-log userType="INS" [userID]="inspectorID"></notifications-log>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="row">
          <div class="col-md-12">
            @if (alertmsg) {
              <div class="alert-box">
                <img src="assets/images/svg/error-icon.svg" class="close-alert" (click)="closeError()" alt="" />
                <p class="alert-msg">{{errorMessage}}</p>
              </div>
            }
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-md-12 double-action">
          <button class="btn btn-cancel" type="button" (click)="back()">
            رجوع
          </button>
          <button class="btn btn-green" id='saveBtn' type="submit" (click)="save()">
            حفظ
          </button>
        </div>
      </div>
    </div>
  }
