import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { LocationsService } from 'src/app/core/services/locations/locations.service';
/**
declare country render component 
 *  
*/
@Component({
  selector: 'app-country-render',
  templateUrl: './country-render.component.html',
  styleUrls: ['./country-render.component.scss']
})
export class CountryRenderComponent implements ICellRendererAngularComp {
/**
declare params variable
 *  
*/
  public params: any;
/**
declare countries variable 
 *  
*/
  public countries :any;
/**
declare country variable
 *  
*/
  public country : any;
/**
declare constructor
 *  
*/
  constructor(private locations : LocationsService) { }
/**
declare aginit function 
 *  
*/
  agInit(params: any): void {
    this.params = params;
    if(params.colDef.field == "nationality" || params.colDef.field == "region"){
      this.countries = params.values;
      this.setCountry(params.value);
    }
    else if (params.colDef.field == "city"){
      this.getCities(params);
    }    
  }
/**
declare set country function 
 *  
*/
  setCountry(value){
    this.countries.forEach(country => {
      if (country.pk == value)
        this.country = country.name;
    });
  }
/**
declare refresh function 
 *  
*/
  refresh(params: any): boolean {
    if(params.colDef.field == 'city')
      this.getCities(params);
    else
      this.setCountry(params.value);
    return true;
  }
/**
declare get cities function 
 *  
*/
  getCities(params){
    let region_id = params.data.region ;
    this.locations.getCoveredCities(region_id).subscribe(data =>{
      this.countries = data ;
      this.setCountry(params.value);
    })
  }
}
