import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { InspectorRatingModalComponent } from '../inspector-rating-modal/inspector-rating-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-rating-star-editor',
  templateUrl: './rating-star-editor.component.html',
  styleUrls: ['./rating-star-editor.component.scss'],
})
export class RatingStarEditorComponent implements ICellEditorAngularComp {
  params: any;
  inspectorId: number = 0;
  overall_rating: number = 0;
  ratingDialogRef: MatDialogRef<InspectorRatingModalComponent>;

  constructor(private dialog: MatDialog) {}

  ngAfterViewInit() {
    window.setTimeout(() => {
      document.getElementsByClassName('ag-popup-editor')[0].classList.add(
        this.params.api.getFocusedCell().column.colId
      );
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.overall_rating = this.params.data.overall_rating;
    this.inspectorId = this.params.data.id;
  }

  getValue() {
    return this.params.value;
  }

  displayModal() {
    this.ratingDialogRef = this.dialog.open(InspectorRatingModalComponent, {
      data: { inspectorId: this.inspectorId },
      panelClass: 'rating-dialog-container'
    });
    this.ratingDialogRef.afterClosed().subscribe((result) => {
      this.params.api.stopEditing();
    });
  }

  isPopup(): boolean {
    return true;
  }

  onKeyDown(event): void {
    let key = event.which || event.keyCode;
    if (
      key == 37 || // left
      key == 39
    ) {
      // right
      event.stopPropagation();
    }
  }
}
