@if (spinner) {
<app-spinner></app-spinner>
}
<div class="inner-page-header">
  <h3>الطلبات</h3>
</div>
<div class="request-control">
  <!-- <div class="delete-icon-grid">
    <button
      class="btn btn-custom-delete"
      (click)="deleteSelectedRows()"
      [disabled]="disabledDelete"
    >
      <img src="assets/images/svg/new-delete.svg" alt="" />
    </button>
  </div> -->
  @if (filterData) {
  <app-filter
    [filterData]="filterData"
    [filterDataInit]="filterDataInit"
    [filterPage]="filterPage"
    (formValus)="filterFormValues($event)"
  >
  </app-filter>
  }
  <quick-search (searchResults)="quickSearch($event)"></quick-search>
  <orders-management
    [selectedRowsPK]="selectedRowsPK"
    [assignedInspectorId]="assignedInspectorId"
    [pageIdentifier]="'individual'"
    (updateOrders)="getOrdersByPage()"
    (sipnnerState)="sipnnerState($event)"
  >
  </orders-management>
  <add-order></add-order>
</div>
<div class="saved-filters">
  <saved-filters></saved-filters>
</div>
<!-- request table -->
@if (orders.length > 0) {
<div class="request-table">
  <ayen-selected-count
    [itemsPerPage]="currentItemsPerPage"
    [selectedRows]="selectedRowsPK"
    [totalRowsSelected]="totalRowsSelected"
    [pageTitle]="pageTitle"
    [totalItems]="config.totalItems"
    (setTotalRowsSelected)="selectTotalRows($event)"
  >
  </ayen-selected-count>
  <div class="table-responsive">
    <ayen-table
      [tableData]="orderData"
      [tableHeaderData]="tableHeaderData"
      [paginationConfig]="config"
      (disabledDelete)="disabledDeleteBtn($event)"
      (checkedRow)="getSelectedRow($event)"
      (sortingCol)="toggleSorting($event)"
      [pageUrl]="'/pages/orders/'"
    >
    </ayen-table>
  </div>
</div>
} @if (orders && orders.length > 0) {
<ayen-pagination
  (pageData)="pageChanged($event)"
  (pageSize)="pageSizeChanged($event)"
  [totalCount]="config.totalItems"
  [pageTitle]="pageTitle"
>
</ayen-pagination>
}
