import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
/**
declar select editor variable
 *  
*/
@Component({
  selector: 'app-select-editor',
  templateUrl: './select-editor.component.html',
  styleUrls: ['./select-editor.component.scss']
})
export class SelectEditorComponent implements ICellEditorAngularComp, AfterViewInit {
/**
declar params variable
 *  
*/
  private params: any;
/**
declar container variable
 *  
*/
  @ViewChild('container', { read: ViewContainerRef }) public container;
/**
declare values variable
 *  
*/
  public values :any[];
/**
declar valueSelected variable
 *  
*/
  valueSelected: any;
/**
declar ngAfterViewInit variable
 *  
*/
  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    window.setTimeout(() => {
      this.container.element.nativeElement.focus();
    });
  }
/**
declare aginit function
 *  
*/
  agInit(params: any): void {
    this.values = params.values
    this.params = params;
    this.setValue(params.value)
  }
/**
declare get values function
 *  
*/
  getValue(): any {
    return  this.valueSelected;
  }
/**
declare onkeyDown function
 *  
*/
  onKeyDown(event): void {
    this.toggle();
    event.stopPropagation();
  }
/**
declare toggle function
 *  
*/
  toggle() {
    return false;
  }
/**
declar setValue function
 *  
*/
  setValue(value) {
    this.valueSelected = value;
  }
}
