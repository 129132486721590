<div class="dialog-container">
  <div mat-dialog-content>
    <label for="" class="form-label">{{data.label}}</label>
    @for(item of data.list; track item.id) {
     <div class="input-box">
        <input #input [id]="item.id" type="text" [value]="item.name"   class="form-control"  />
     </div>
    }
  </div>
  <div mat-dialog-actions class="setup-footer actions-double">
    <button class="btn btn-cancel" type="button" mat-dialog-close>إلغاء</button>
    <button class="btn btn-green" [mat-dialog-close]="data" type="submit">
      موافق
    </button>
  </div>
</div>
