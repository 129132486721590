import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SummerNoteConfig } from 'src/app/common/summernote-config';
import { NotificationsService } from 'src/app/core/services/notifications/notifications.service';

@Component({
  selector: 'send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit {
  sentNotificationForm: UntypedFormGroup;
  alertmsg: boolean;
  message: string;
  @Output() closeModal = new EventEmitter();
  users = [];
  routeParams: any = {};
  summerNoteConfig = SummerNoteConfig.MAIN_CONFIG;

  constructor(private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<SendNotificationComponent>,
    private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.data.selectedRecipient.forEach(recipient => {
      this.users.push(recipient.id);
    });
    this.getRouteQueryParams();
    this.createForm();
    this.createFormControls();
  }

  getRouteQueryParams() {
    this.route.queryParams.subscribe((params) => {
      this.routeParams = params;
    });
  }

  createForm() {
    this.sentNotificationForm = this.formBuilder.group({
      users: [this.users, Validators.required],
      body: [null, Validators.required],
      url: [null],
      title: [null],
      is_browser: [this.data.isBrowser],
      select_all: [this.data.totalRowsSelected]
    });
  }

  createFormControls() {
    return this.sentNotificationForm.controls;
  }

  deleteRecipient(index) {
    this.data.selectedRecipient.splice(index, 1);
    this.users.splice(index, 1)
    this.sentNotificationForm.get('users').setValue(this.users);
  }

  closeError() {
    this.alertmsg = false;
  }

  send() {
    this.alertmsg = false;
    const data = this.sentNotificationForm.value;
    let params = { ...this.routeParams };
    params['query'] = this.data.query;
    this.notificationsService.sendPrivateNotification(data, params).subscribe(
      () => {
        this.dialogRef.close(this.data);
      }, error => {
        try {
          this.alertmsg = true;
          this.message = Object.values(error.error)[0].toString();
        } catch { }
      }
    )
  }

}
