import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';
import { Images } from 'src/app/shared/global/image-constants';

export interface DialogData {
  inspectorName: string;
  inspectorID: string;
  imgPath: string;
}
@Component({
  selector: 'avatar-model',
  templateUrl: './avatar-model.component.html',
  styleUrls: ['./avatar-model.component.scss'],
})
export class AvatarModelComponent {
  imagePath = Images;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private inspectorService: InspectorsService,
    public dialogRef: MatDialogRef<AvatarModelComponent>
  ) {}

  delete() {
    let ValueChanged = {};
    ValueChanged['document'] = 'avatar';
    this.inspectorService
      .deleteInspectorFile(this.data.inspectorID, ValueChanged)
      .subscribe(
        () => {
          this.dialogRef.close({
            document: 'avatar',
            inspectorID: this.data.inspectorID,
          });
        },
        (error) => {
          this.dialogRef.close();
        }
      );
  }
}
