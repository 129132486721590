import { Component } from '@angular/core';
/**
declare  spinner component
 *  
*/
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
}
