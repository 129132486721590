import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BuildingTypeService } from 'src/app/core/services/buildingType/building-type.service';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { OrderDetailsService } from '../../../services/order-details/order-details.service';
import { MetersService } from '../../../services/meters/meters-service.service';

@Component({
  selector: 'building-details',
  templateUrl: './building-details.component.html',
  styleUrls: ['./building-details.component.scss'],
})
export class BuildingDetailsComponent implements OnInit {
  buildingDetailsFormGroup: UntypedFormGroup;
  @Input() orderData: any;
  @Input() orderId;
  alertmsg: boolean = false;
  licenceTypes: any;
  selectedLicenceType;
  buildingTypes: any;
  selectedBuildingTypes;
  buildindStatus: any;
  selectedBuildindStatus;
  meters: any;
  errorMessage: string;
  disableAdd_water: boolean = false;
  disableAdd_electricity: boolean = false;
  timeout: any = null;
  updatedMeters: any;
  @Output() errorMsg = new EventEmitter();
  @Input() orderType: any;
  showSpinner: boolean;

  constructor(
    private buildingService: BuildingTypeService,
    private orderDetails: OrderDetailsService,
    private formBuilder: UntypedFormBuilder,
    private metersService: MetersService
  ) { }

  ngOnInit(): void {
    this.getLicenceTypes();
    this.getBuildingStatus();
    this.getBuildingTypes();
    this.getMeters();
    this.createForm();
    this.createFormControls();
    if (this.orderType != 5) {
      this.setRequiredValidatorVal('licence_type');
    }
    if (this.orderData) this.patchValues();
  }

  createForm() {
    this.buildingDetailsFormGroup = this.formBuilder.group({
      designer: [null],
      engineering_supervisor: [null],
      instrument_number: [null],
      licence_number: [null],
      licence_type: [null],
      main_contractor: [null],
      owner: [null],
      realty_developer: [null],
      custom_property_type: [null],
      status: [null],
      building_type: [null],
    });
  }

  changeBuildingType(event) {
    this.buildingService.changeBuildingType(event);
    if (this.orderType == 5 && event == 10) {
      this.setRequiredValidatorVal('custom_property_type');
      this.clearFormControlValidators('status');
    } else if (event != 7 && event != 10) {
      this.setRequiredValidatorVal('status');
      this.clearFormControlValidators('custom_property_type');
    } else if (event == 7) {
      this.clearFormControlValidators('status');
      this.clearFormControlValidators('realty_developer');
      this.clearFormControlValidators('designer');
      this.clearFormControlValidators('engineering_supervisor');
      this.clearFormControlValidators('main_contractor');
    }
  }

  createFormControls() {
    return this.buildingDetailsFormGroup.controls;
  }

  getMeters() {
    this.metersService.getOrderMeters(this.orderId).subscribe((data) => {
      this.meters = data;
      if (this.meters['electricity_meters'].length <= 0) {
        this.addMeter('electricity');
      }
      if (this.meters['water_meters'].length <= 0) {
        this.addMeter('water');
      }
    });
  }
  getLicenceTypes() {
    this.orderDetails.getLicenceTypes().subscribe((data) => {
      this.licenceTypes = data;
    });
  }

  getBuildingTypes() {
    this.buildingService.getBuildingTypes().subscribe((data) => {
      this.buildingTypes = data;
    });
  }

  getBuildingStatus() {
    this.buildingService.getBuildingStatus().subscribe((data) => {
      this.buildindStatus = data;
    });
  }

  preventDefault(e) {
    e.preventDefault();
  }

  updateMeter() {
    clearTimeout(this.timeout);
    var $this = this;
    $this.timeout = setTimeout(() => {
      this.metersService
        .updateMeter(this.orderId, this.meters)
        .subscribe((data) => {
          this.updatedMeters = data;
        });
    }, 600);
  }

  addMeter(type) {
    let data = JSON.stringify({ category: type });
    if (this.meters[type + '_meters'].length < 3) {
      this['disableAdd_' + type] = true;
      this.metersService.addMeter(this.orderId, data).subscribe((res) => {
        this['disableAdd_' + type] = false;
        this.meters[type + '_meters'].push({
          device_number: null,
          device_reading: null,
          id: res.id,
        });
      });
    }
  }

  deleteMeter(type, id, index) {
    this.metersService.deleteMeter(id).subscribe((data) => {
      this.meters[type + '_meters'].splice(index, 1);
    });
  }

  patchValues() {
    this.buildingDetailsFormGroup.patchValue({
      designer: this.orderData.designer,
      engineering_supervisor: this.orderData.engineering_supervisor,
      instrument_number: this.orderData.instrument_number,
      licence_number: this.orderData.licence_number,
      licence_type: this.orderData.licence_type,
      main_contractor: this.orderData.main_contractor,
      owner: this.orderData.owner,
      realty_developer: this.orderData.realty_developer,
      status: this.orderData.status,
      building_type: this.orderData.type_id,
      custom_property_type: this.orderData.custom_property_type,
    });
    this.buildingService.changeBuildingType(this.orderData.type_id);
  }

  save() {
    this.alertmsg = false;
    this.showSpinner = true;
    if (this.buildingDetailsFormGroup.valid) {
      this.orderDetails.updateBuildingDetails(
        this.orderId,
        this.buildingDetailsFormGroup.value
      )
        .subscribe(
          (data) => {
            this.showSpinner = false;
            this.showConfirmationModal();
          },
          (error) => {
            this.alertmsg = true;
            this.showSpinner = false;
            this.errorMessage = Object.values(error.error)[0].toString();
          }
        );
    } else {
      this.alertmsg = true;
      this.errorMessage = 'يوجد خطأ في بعض البيانات';
    }
  }
  /**
   *  implement setRequiredValidatorVal
   */
  setRequiredValidatorVal(formControl) {
    this.buildingDetailsFormGroup.controls[formControl].setValidators(
      Validators.required
    );
    this.buildingDetailsFormGroup.controls[
      formControl
    ].updateValueAndValidity();
  }

  clearFormControlValidators(formControl) {
    this.buildingDetailsFormGroup.controls[formControl].setValue(null);
    this.buildingDetailsFormGroup.controls[formControl].clearValidators();
    this.buildingDetailsFormGroup.controls[
      formControl
    ].updateValueAndValidity();
  }
  showConfirmationModal() {
    this.errorMsg.emit({
      message: 'تم تحديث البيانات بنجاح.',
      imgSuccess: true,
    });
  }

  hideErrorMSG() {
    this.alertmsg = false;
  }
}
