import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
/**
declare status editor
 *  
*/
@Component({
  selector: 'app-status-editor',
  templateUrl: './status-editor.component.html',
  styleUrls: ['./status-editor.component.scss']
})
export class StatusEditorComponent implements ICellEditorAngularComp, AfterViewInit {
/**
declare params variable
 *  
*/
  private params: any;
/**
declare status true variable
 *  
*/
  statusTrue:string;
/**
declare status false variable
 *  
*/
  statusFalse:string;
/**
declare viewContainerRef variable
 *  
*/
  @ViewChild('container', { read: ViewContainerRef }) public container;
/**
declar status variable
 *  
*/
  public status: boolean = false;

  public is_test : boolean = false;
/**
declare ngAfterViewInit function
 *  
*/
  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    window.setTimeout(() => {
      this.container.element.nativeElement.focus();
    });
  }
/**
declare agInint function
 *  
*/
  agInit(params: any): void {
    this.params = params;
    this.statusTrue= params.active ;
    this.statusFalse= params.notActive;
    this.is_test = params.isTest ? params.isTest : false ;
    this.setStatus(params.value);
  }
/**
declar getValue function
 *  
*/
  getValue(): any {
    return this.status ? true : false;
  }
/**
declar ispopup  function
 *  
*/
  isPopup(): boolean {
    return true;
  }
/**
declar setStatus function
 *  
*/
  setStatus(status: boolean): void {
    this.status = status;
  }
/**
declar toggleMood function
 *  
*/
  toggleMood(): void {
    this.setStatus(!this.status);
  }
/**
declar onclick function
 *  
*/
  onClick(status: boolean) {
    this.setStatus(status);
    this.params.api.stopEditing();
  }
/**
declar onkeyDown function
 *  
*/
  onKeyDown(event): void {
    let key = event.which || event.keyCode;
    if (
      key == 37 || // left
      key == 39
    ) {
      // right
      this.toggleMood();
      event.stopPropagation();
    }
  }
}
