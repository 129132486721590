import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Images } from 'src/app/shared/global/image-constants';
import { AlertBoxComponent } from '../../alert-box/alert-box.component';
import { ConfirmationBoxComponent } from '../../confirmation-box/confirmation-box.component';
import { FileModalComponent } from '../../file-modal/file-modal.component';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';

@Component({
  selector: 'app-file-upload-render',
  templateUrl: './file-upload-render.component.html',
  styleUrls: ['./file-upload-render.component.scss'],
})
export class FileUploadRenderComponent implements ICellRendererAngularComp {
  params: any;
  link: any;
  id: any;
  field: any;
  fileDialogRef: MatDialogRef<FileModalComponent>;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  deleteMsg = 'هل أنت متأكد من حذف هذا الملف؟';
  constantImages = Images;

  constructor(
    private dialog: MatDialog,
    private inspectorService: InspectorsService
  ) {}

  agInit(params: any): void {
    this.params = params;
    this.link = this.params.value;
    this.id = params?.data?.id;
    this.field = params?.column?.colId;
  }

  refresh(params: any) {
    return true;
  }

  deleteFile() {
    this.deleteDialogRef = this.dialog.open(ConfirmationBoxComponent, {
      data: {
        id: this.id,
        message: this.deleteMsg,
      },
    });
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let ValueChanged = {};
        ValueChanged['document'] = this.params.column.colId;
        this.inspectorService
          .deleteInspectorFile(this.id, ValueChanged)
          .subscribe(
            (data) => {
              this.params.value = null;
            },
            (error) => {
              this.showAlertBox();
            }
          );
      }
    });
  }

  uploadFile(event) {
    this.fileDialogRef = this.dialog.open(FileModalComponent, {
      data: {
        files: event.target['files'],
        orderlabel: this.field,
        url: '/inspectors/' + this.id + '/',
        exist: true,
      },
    });
    this.fileDialogRef.afterClosed().subscribe((result) => {
      if (result && result.exist) {
        this.params.value = result.value;
        this.link = result.value;
      } else {
        this.params.value = null;
      }
    });
  }

  showAlertBox() {
    this.deleteMsg = 'هل أنت متأكد من حذف هذا الملف؟';
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: false,
        message: this.deleteMsg,
        button: 'تم',
      },
    });
  }
}
