@if (Loader) {
  <app-spinner></app-spinner>
}
@if (!Loader) {
  <div class="main-card">
    <div class="req-row">
      <div class="req-box">
        <div class="req-title"> تفاصيل العقار </div>
      </div>
    </div>
    <div class="req-body">
      <div class="req-row last">
        <div class="req-box">
          <div class="req-title">رقم الطلب</div>
          <div class="req-data">{{order?.reference_number}}</div>
        </div>
        <div class="req-box">
          <div class="req-title">اسم العميل</div>
          <div class="req-data">{{order?.order_details?.order_client_object?.name}}</div>
        </div>
        <div class="req-box">
          <div class="req-title">الخدمة</div>
          <div class="req-data">
            {{orderTitle}}
          </div>
        </div>
        <div class="req-box">
          <div class="req-title">نوع الوحدة</div>
          @if (order.building_type === buildingType.Other) {
            <div class="req-data">
              {{order?.building_data?.custom_property_type}}
            </div>
          } @else {
            <div class="req-data">{{order?.building_type_object?.name}}</div>
          }
        </div>
        @if (order?.order_details?.order_type_object?.id == orderType.Visit) {
          @if (order.building_type != buildingType.Other && order.building_type != buildingType.Land) {
            <div class="req-box"
              >
              <div class="req-title">حالة المبنى</div>
              <div class="req-data">{{order?.building_status_object?.name}}</div>
            </div>
          }
        } @else {
          <div class="req-box">
            <div class="req-title">مساحة الوحدة</div>
            <div class="req-data">
              {{order?.building_specification?.surfaces_space}}
              <span>م<sup>2</sup></span>
            </div>
          </div>
        }
        @if (order?.contract?.pk) {
          <div class="req-box">
            <div class="req-title">اسم المشروع</div>
            <div class="req-data">{{order?.contract?.name}}</div>
          </div>
        }
        @if (order.building_type != buildingType.Land) {
          <div class="req-box">
            <div class="req-title">رقم المبنى</div>
            <div class="req-data">{{order?.address_building_code ? order?.address_building_code : '-'}}</div>
          </div>
        }
        @if (order.building_type != buildingType.Land) {
          <div class="req-box">
            <div class="req-title">رقم الوحدة</div>
            <div class="req-data">{{order?.address_unit_code ? order?.address_unit_code : '-'}}</div>
          </div>
        }
        @if (order?.order_details?.order_type_object?.id == orderType.Visit) {
          <div class="req-box">
            <div class="req-title">تخصص مزود الخدمة</div>
            <div class="req-data">{{order?.inspector_academic_major_object?.name}}</div>
          </div>
        }
        <div class="req-box">
          <div class="req-title">تاريخ الفحص</div>
          <div class="req-data">{{order?.order_date}} {{getPriods(order?.order_periods_object)}}</div>
        </div>
        <div class="req-box">
          <div class="req-title"> العنوان</div>
          <div class="req-data">{{address}}</div>
        </div>
        <button class="btn btn-copy" (click)="copyOrderLink()" >
          <img src="assets/images/svg/copy-report-link.svg" alt=""/>
          <span>نسخ رابط الدفع</span>
        </button>
      </div>
    </div>
  </div>
}
<!-- @if (!hideSummary) { -->
  <div class="row total-cost">
    <div class="col-md-6">
      <div class="main-card coupon-code">
        <label for="" class="form-label">كود الخصم</label>
        <div class="couponInput">
          <input class="form-control" [(ngModel)]="couponValue" type="text" value="">
          <button class="btn " [ngClass]="{'btn-confirm' : confirmBtn ==='تطبيق' ,'btn-delete' : confirmBtn==='حذف' }"
          (click)="applyCoupon(couponObject)">{{confirmBtn}}</button>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="main-card total-sec">
        <div class="req-row">
          <div class="req-box">
            <div class="req-title"> المجموع </div>
          </div>
        </div>
        <div class="req-body">
          @if (order?.order_details?.order_type_object?.id != orderType.Visit) {
            <div class="req-row">
              <div class="req-box">
                <div class="req-title">{{orderTitle}}</div>
                <div class="req-data">{{order?.cost?.cost}} <span>ر.س</span></div>
              </div>
              <div class="req-box">
                <div class="req-title light">
                  المساحة الأساسية
                  <span>{{order?.cost?.spaces_cost?.basic_space?.space}} </span>
                  <span>م<sup>2</sup></span>
                </div>
                <div class="req-data">{{order?.cost?.spaces_cost?.basic_space?.cost}} <span>ر.س</span></div>
              </div>
              <div class="req-box">
                <div class="req-title light"> المساحة الإضافية <span>
                  {{order?.cost?.spaces_cost?.additional_space?.space}}</span> <span>م<sup>2</sup></span></div>
                  <div class="req-data">{{order?.cost?.spaces_cost?.additional_space?.cost}} <span>ر.س</span></div>
                </div>
              </div>
            }
            <div class="req-row last">
              <div class="req-box">
                <div class="req-title"> <strong>مجموع الطلب</strong> </div>
                <div class="req-data">{{order?.cost?.cost_after_discount}} <span>ر.س</span> </div>
              </div>
              @if (valid) {
                <div class="req-box">
                  <div class="req-title">
                    <strong>
                      {{couponData?.cost_summary?.coupon_discount?.coupon_description}}
                      <span>
                        ({{couponData?.cost_summary?.coupon_discount.amount}}
                        {{couponData?.cost_summary?.coupon_discount?.display_sign}})
                      </span>
                    </strong>
                  </div>
                  <div class="req-data">{{couponData?.cost_summary?.coupon_discount?.value}} <span>ر.س</span></div>
                </div>
              }
              <div class="req-box">
                <div class="req-title"> <strong> ضريبة القيمة المضافة
                  <span>({{order?.cost?.tax_percentage}}%)</span></strong> </div>
                  <div class="req-data">{{order?.cost?.tax_cost}} <span>ر.س</span> </div>
                </div>
                <div class="req-box">
                  <div class="req-title"> <strong>المجموع </strong> </div>
                  <div class="req-data greenText">{{order?.cost?.final_cost}} <span>ر.س</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- } -->
    <div class="row">
      <div class="col-md-12 confirm-next-step double-action">
        <button class="btn btn-grey" type="button" (click)="prevStep()">
          السابق
        </button>
        <button class="btn btn-green" type="button" (click)="goOrderList()">
          إضافة طلب
        </button>
      </div>
    </div>
