import { Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingServicesService } from '../../services/setting-services/setting-services.service';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-recommendation-dialoge',
  templateUrl: './recommendation-dialoge.component.html',
  styleUrl: './recommendation-dialoge.component.scss',
})
export class RecommendationDialogeComponent {
  @ViewChildren('input') input: QueryList<any>;
  fn: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingServices: SettingServicesService
  ) {}

  ngAfterViewInit(): void {
    this.editInput();
  }

  editInput() {
    this.fn = this.data.fnName;
    this.input.toArray().forEach((element) => {
      const searchText$: Observable<string> = fromEvent<any>(
        element.nativeElement,
        'keyup'
      ).pipe(debounceTime(1000), distinctUntilChanged());
      searchText$.subscribe((data) => {
        let index = +element.nativeElement.getAttribute('data-index');
        let id = element.nativeElement.id;
        let name = element.nativeElement.value;
        let color = this.data.list[index].color;
        this.settingServices[this.fn](id, {
          name: name,
          color: color,
        }).subscribe((data) => {});
      });
    });
  }

  editColor(item) {
    this.settingServices[this.fn](item.id, item).subscribe((data) => {});
  }
}
