export class GridDefinitions {
  public static DEFAULT_GRID_COLS_DEFINITIONS = {
    flex: 1,
    minWidth: 110,
    editable: true,
    resizable: true,
    sortable: false,
    filter: false,
    rowSelection: 'multiple',
    context: {},
    pagination: true,
    onGridReady: (params) => {
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    }
  }
}
