import { Component, HostListener, OnInit } from '@angular/core';
import { WhatsNewService } from 'src/app/features/whats-new/services/whats-new/whats-new.service';

@Component({
  selector: 'whats-new-modal',
  templateUrl: './whats-new-modal.component.html',
  styleUrls: ['./whats-new-modal.component.scss'],
})
export class WhatsNewModalComponent implements OnInit {
  whatsNewList = [];
  page: number = 1;
  page_count: number = 0;
  params = '?page=';
  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight &&
      this.page < this.page_count
    ) {
      this.page++;
      this.params = this.params + this.page;
      this.getList(this.params, false);
      this.params = '?page=';
    }
  }

  constructor(private whatsNewService: WhatsNewService) {}

  ngOnInit(): void {
    this.params = this.params + this.page;
    this.whatsNewService.newWhatsNewList.subscribe((data) => {
      if (data) this.getList(this.params, true);
    });
  }

  getList(params, update) {
    this.whatsNewService.getWhatsNewModalList(params).subscribe(
      (data) => {
        this.page_count = data.pages_count;
        if (update) this.whatsNewList = data.results;
        else this.whatsNewList.push(...data.results);
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  toggleRead(id, index) {
    this.whatsNewService.toggleReadItem(id).subscribe(
      (data) => {
        this.whatsNewList[index].is_read = !this.whatsNewList[index].is_read;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  makeAllReaded() {
    this.whatsNewService.toggleReadAllItem().subscribe(
      (data) => {
        this.whatsNewList.forEach((item) => {
          item.is_read = true;
        });
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  mouseEnter(index) {
    this.whatsNewList[index].hover = true;
  }

  mouseLeave(index) {
    this.whatsNewList[index].hover = false;
  }

  handleBackendError(error) {
    try {
    } catch {}
  }
}
