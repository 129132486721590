import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { ProjectsService } from '../../services/projects.service';
import { SelectUtil } from 'src/app/core/services/uitls/selectUtil';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit {

  imgDefault: string = '/assets/images/svg/sort-default.svg'
  imgASC: string = '/assets/images/svg/sort-up.svg';
  imgDesc: string = '/assets/images/svg/sort-down.svg';
  projects: any;
  config: { id: string; itemsPerPage: number; currentPage: any; totalItems: any; };
  searchValue: any;
  disabledDelete: boolean = true;
  selectedRowsIndex: any[] = [];
  selectedRowsPK: any[] = [];
  deletedProjectPK: any[] = [];
  deletedProjectIndex: any[] = [];
  orderingState = {
    shortcode: null,
    name: null,
    active_date: null,
    expiry_date: null
  }
  ordering: any[] = [];
  modelImg: boolean;
  imgPath: string;
  confirmMsg: string;
  pageTitle: string = 'المشاريع';
  totalRowsSelected: boolean = false;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  @ViewChildren('checkBoxList') checkBoxList: ElementRef<HTMLInputElement>[];
  @ViewChild('selectAllElm') selectAllElm: ElementRef<HTMLInputElement>;
  currentItemsPerPage: number;

  constructor(private projectService: ProjectsService,
    private router: Router,
    private titleService: Title,
    private dialog: MatDialog,
    private route: ActivatedRoute) {
    this.config = {
      id: 'myPagination',
      itemsPerPage: 20,
      currentPage: 1,
      totalItems: 0,
    };
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.searchValue = params["query"] ? params["query"] : '';
      }
      this.getProjects();
    });
    this.titleService.setTitle(' المشاريع | عاين');
  }

  getProjects() {
    let params = {
      page: this.config.currentPage,
      ordering: this.ordering,
      page_size: this.config.itemsPerPage
    }
    this.totalRowsSelected = false;
    if(this.searchValue) {
      params['query'] = this.searchValue;
    }
    this.projectService.getProjects(params).subscribe(data => {
      this.projects = data.results;
      this.config['totalItems'] = data.count;
      this.currentItemsPerPage = data.results.length;
    }, error => {
      try {}
      catch {}
    });
  }

  addProject() {
    this.router.navigateByUrl('/pages/projects/add');
    this.titleService.setTitle('إضافة مشروع | عاين');
  }

  openProject(id) {
    this.router.navigateByUrl("/pages/projects/" + id);
    this.titleService.setTitle('تعديل المشروع #' + id + '  | عاين');
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  toggleSorting(colNam, img) {
    this.ordering = [];
    switch (this.orderingState[colNam]) {
      case null:
        this.orderingState[colNam] = false;
        img.src = this.imgASC;
        break;

      case false:
        this.orderingState[colNam] = true;
        img.src = this.imgDesc;
        break;

      case true:
        this.orderingState[colNam] = null;
        img.src = this.imgDefault
        break;
    }
    for (const [key, value] of Object.entries(this.orderingState)) {
      switch (value) {
        case false:
          this.ordering.push(key);
          break;
        case true:
          this.ordering.push("-" + key);
          break;
      }
    }
    this.getProjects();
  }

  selectAll(event) {
    this.disabledDelete = true;
    SelectUtil.selectAll(event, this);
  }

  quickSearch(event) {
    this.searchValue = event;
    this.config.currentPage = 1;
    this.getProjects();
  }

  deleteSelectedRows() {
    this.deletedProjectPK = this.selectedRowsPK;
    this.deletedProjectIndex = this.selectedRowsIndex;
    this.showDeleteConfirmationBox();
  }

  selectsSingleRow(event, rowPk, index) {
    this.disabledDelete = true;
    this.totalRowsSelected = false;
    SelectUtil.selectsSingleRow(event, rowPk, index, this);
    if (this.selectedRowsPK.length > 0) {
      this.disabledDelete = false;
    }
  }

  showDeleteConfirmationBox() {
    this.deleteDialogRef = this.dialog.open(ConfirmationBoxComponent, {
      data: {
        id: this.deletedProjectPK,
        index: this.deletedProjectIndex,
        message: 'هل أنت متأكد من حذف المشروع؟'
      }
    });
    this.deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.delete(result);
      }
    });
  }

  delete(event) {
    let index = event.index;
    let pks = event.id;
    this.projectService.deleteProject(pks).subscribe(data => {
      for (let i = index.length - 1; i >= 0; i--) {
        let OrderInd = index[i];
        this.projects.splice(OrderInd, 1)
      }
      this.selectedRowsPK = [];
      this.selectedRowsIndex = [];
    },
      (error) => {
        try {
          this.modelImg = false;
          this.imgPath = '../../../../assets/images/svg/refusal-icon-admin.svg';
          this.confirmMsg = '  عذراً لا يمكن حذف المشروع . ‎';
          this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
            data: {
              imgSuccess: this.modelImg,
              message: this.confirmMsg
            },
          });
          this.selectedRowsPK = [];
          this.selectedRowsIndex = [];
        }
        catch {}
      })
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.totalRowsSelected = false;
    this.getProjects();
  }

  pageSizeChanged(event) {
    this.config.itemsPerPage = event;
    this.getProjects();
  }
  selectTotalRows(event) {
    this.totalRowsSelected = event;
  }

}
