import { Component } from '@angular/core';
/**
 * inspector parent component
*/
@Component({
  selector: 'app-inspectors',
  templateUrl: './inspectors.component.html',
  styleUrls: []
})
export class InspectorsComponent {
}
