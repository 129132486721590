<div class="dialog-container">
  <h2 mat-dialog-title>إرسال إشعار</h2>
  <form class="needs-validation" [formGroup]="sentNotificationForm" (ngSubmit)="send()">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="" class="form-label"> المرسل إليهم</label>
            <div class="recipient-container">
              @if (data.totalRowsSelected) {
                <div>جميع الفاحصين</div>
              } @else {
                @for (recipient of data.selectedRecipient; track recipient; let i = $index) {
                  <div class="recipient-item">
                    {{recipient.full_name}}
                    <img src="/assets/images/svg/cancel-tag.svg" (click)="deleteRecipient(i)" alt="">
                  </div>
                }
              }
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group notes-section">
            <label for="" class="form-label">نص الإشعار</label>
            <div formControlName="body" [ngxSummernote]="summerNoteConfig"></div>
            <!-- <textarea class="form-control" formControlName="body" name="body" maxlength="500"></textarea> -->
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="form-label">الرابط </label>
            <input formControlName="url" class="form-control" type="url">
            <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
          </div>
        </div>
      </div>
      @if (alertmsg) {
        <div class="alert-box">
          <img src="assets/images/svg/error-icon.svg" class="close-alert" alt="" (click)="closeError()" />
          <p class="alert-msg">{{message}}</p>
        </div>
      }
    </div>
    <div mat-dialog-actions class="actions-double">
      <a class="btn btn-cancel center-btn" mat-dialog-close>إلغاء</a>
      <button class="btn btn-green center-btn" [disabled]="!sentNotificationForm.valid" type="submit">
        إرسال الإشعار
      </button>
    </div>
  </form>
</div>
