<!-- page header -->
<div class="inner-page-header">
  <h3>إنشاء طلب جديد</h3>
</div>
<!-- end page header -->
<div class="inner-content">
  <app-stepper  [selectedView]="stepper"></app-stepper>
  <div >
    @switch (selectedView) {
      @default {
        <app-build-details (building_view)="getView($event)"></app-build-details>
      }
      @case ('inspectoring') {
        <app-inspection-details [orderId]="orderId"    (inspector_view)="getView($event)"></app-inspection-details>
      }
      @case ('summary') {
        <app-order-summary  [orderId]="orderId"  (summary_view)="getView($event)"></app-order-summary>
      }
    }
  </div>
</div>
