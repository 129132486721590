import { Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-tag-element-render',
  templateUrl: './tag-element-render.component.html',
  styleUrls: ['./tag-element-render.component.scss']
})
export class TagElementRenderComponent implements ICellRendererAngularComp {
  params: any;

  constructor() { }
 
  agInit(params: any): void {
    this.params = params;
  }
  
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

}
