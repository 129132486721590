<div class="row" [formGroup]="otherFeaturesForm">
  @for (feature of orderData.building_other_features; track feature; let i = $index) {
    <div class="col-md-4" >
      <div class="checkBox-wrapper">
        <label class="checkbox-container" >
          <input type="checkbox" [attr.checked]="feature.value ? true : null" [id]="feature.id" (click)="featureValueChanged($event , i )">
          <span class="checkmark"> </span>
        </label>
        <span class="checkbox-label">{{feature.label_ar}}</span>
      </div>
    </div>
  }
</div>
<div  class="row">
  <div class="save-btn-wrapper col-md-12">
    <button class="btn btn-green" (click)="save()">
      @if (showSpinner) {
        <img class="spin-img" src="assets/images/svg/Spinner-s.svg" alt=""/>
      }
      حفظ
    </button>
  </div>
</div>
