import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceGlobalValueService } from 'src/app/core/services/service-engine/service-global-value/service-global-value.service';
import { ServiceQuatationsService } from 'src/app/core/services/service-engine/service-quatations/service-quatations.service';

@Component({
  selector: 'app-services-order-info',
  templateUrl: './services-order-info.component.html',
  styleUrls: ['./services-order-info.component.scss'],
})
export class ServicesOrderInfoComponent implements OnInit {
  @Input() basicInfo;
  @Input() ServiceProvider;
  @Input() financialInfo;
  @Input() orderStatus;
  @Input() servicePaymentProvider;
  openQuatation: boolean = false;
  orderId: any;
  quotationData: any;
  constructor(private activatedRoute: ActivatedRoute,
    private globalValueService : ServiceGlobalValueService,
    private serviceQuatations: ServiceQuatationsService) {}

  ngOnInit(): void {
    // get order id from router
    this.orderId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getServiceQuatations();
    this.globalValueService.currentServiceQuatation.subscribe(data =>{
      this.getServiceQuatations();
    })
    this.openQuatation = history.state?.openQuatation;
    if (this.openQuatation) {
      document.getElementById('quatationInfo-tab').classList.add('active');
      document.getElementById('quatationInfo').classList.add('show', 'active');
    } else {
      document.getElementById('basicInfo-tab').classList.add('active');
      document.getElementById('basicInfo').classList.add('show', 'active');
    }
  }

  getServiceQuatations(): void {
    this.serviceQuatations
      .getServiceQuatations(this.orderId)
      .subscribe((res) => {
        this.quotationData = res ;
      }, error =>{
        try{
          console.log(error);
        }catch{

        }
      });
  }

  updateQuotationData(event){
    this.getServiceQuatations();
  }
}
