import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AYENServiceBase } from '../base/base.service';
/**
  authentication service component
 *  
*/
@Injectable({
  providedIn: 'root',
})

export class AuthenticationService extends AYENServiceBase {
/**
  constructor function
 *  
*/
  constructor(private httpClient: HttpClient) {
    super();
  }
/**
  authlogin function
 *  
*/
  authLogin(data: any): Observable<any> {
    return this.httpClient.post(this.baseURL + '/auth/login/', data);
  }
/**
 setIsLoggedIn function
 *  
*/
  setIsLoggedIn(loggedIn){
    localStorage.setItem('isLoggedIn',loggedIn)
  }
/**
  get isLogged in function
 *  
*/
  isLoggedIn(){
    return localStorage.getItem("isLoggedIn");
  }
/**
  get recover password function
 *  
*/
  recoverPaswword(data: any): Observable<any> {
    return this.httpClient.post(
      this.baseURL + '/user/send_verification_code/',
      data
    );
  }
/**
  get reset password function
 *  
*/
  resetPassword(data: any): Observable<any> {
    return this.httpClient.post(this.baseURL + '/user/reset_password/', data);
  }
/**
  get update user data function
 *  
*/
  updateUserData(id : number , data:any):Observable<any>{
    return this.httpClient.put(this.baseURL + '/user/'+id+'/', data);
  }
/**
  get isAuthenticated function
 *  
*/
  public isAuthenticated(): boolean {
  /**
    current user from local storage
  *  
  */
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      return true;
    } else {
      return false;
    }
  }

  /**
 * Service for get new token by refrsh token
 */
  refreshToken(refreshToken):Observable<any>{
    return this.httpClient.post(
      this.baseURL + '/auth/refresh/',
      {refresh : refreshToken}
    );
  }
}
