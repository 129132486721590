import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AYENServiceBase } from './services/base/base.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { NgxsModule } from '@ngxs/store';
import { FilterState } from './state/filter-state/filter.state';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([FilterState]),
  ],
  providers: [
    AYENServiceBase,
    AuthenticationService
  ]
})
export class CoreModule { }
