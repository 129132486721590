import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  AddAdditions,
  AddNewCategory,
  AddService,
  ChangeCategoriesRatio,
  ChangeTermCategory,
  DeleteAddition,
  DeleteCategory,
  DeleteService,
  GetAdditionById,
  GetAdditionsFieldsChoices,
  GetAdditionsGeneralChoices,
  GetAdditionsList,
  GetNewServiceList,
  GetServiceById,
  HandleError,
  RedistrbuteRatios,
  UpdateAdditions,
  UpdateCategory,
  UpdateService,
  addServiceSetting,
} from './service-action';
import { DynamicServices } from '../services/dynamic-services/dynamic-services.service';
import { catchError, tap } from 'rxjs/operators';
import { CategoriesService } from '../services/categories/categories.service';

@State<any>({
  name: 'NewServicesState',
  defaults: {
    serviceList: [],
    serviceById: {},
    serverError: '',
  },
})
@Injectable()
export class NewServicesState {
  constructor(
    private dynamicServices: DynamicServices,
    private categoriesService: CategoriesService
  ) {}

  @Selector()
  static getNewServicesList(state: any): any {
    return state.serviceList;
  }

  @Selector()
  static getAdditionList(state: any): any {
    return state.additionList;
  }

  @Selector()
  static getAdditionChoices(state: any): any {
    return state.choices;
  }

  @Selector()
  static getAdditionFieldsChoices(state: any): any {
    return state.fieldChoices;
  }

  @Selector()
  static getAdditionById(state: any): any {
    return state.additionById;
  }
  @Selector()
  static getServiceById(state: any): any {
    return state.serviceById;
  }
  @Selector()
  static serverError(state: any): string {
    return state.serverError;
  }

  @Action(GetNewServiceList)
  getNewServiceList(ctx: StateContext<any>, action: GetNewServiceList) {
    return this.dynamicServices.getServices(action.payload).pipe(
      tap((response) => {
        ctx.patchState({
          serviceList: response,
        });
      })
    );
  }

  @Action(GetAdditionsList)
  getAdditionsList(ctx: StateContext<any>, action: GetAdditionsList) {
    return this.dynamicServices.getAdditions(action.payload).pipe(
      tap((response) => {
        ctx.patchState({
          additionList: response,
        });
      })
    );
  }

  @Action(GetAdditionsGeneralChoices)
  GetAdditionsGeneralChoices(
    ctx: StateContext<any>,
    action: GetAdditionsGeneralChoices
  ) {
    return this.dynamicServices.getDynamicAdditionChoices().pipe(
      tap((response) => {
        ctx.patchState({
          choices: response,
        });
      })
    );
  }

  @Action(GetAdditionsFieldsChoices)
  GetAdditionsFieldsChoices(
    ctx: StateContext<any>,
    action: GetAdditionsFieldsChoices
  ) {
    return this.dynamicServices.getDynamicAdditionFieldChoices().pipe(
      tap((response) => {
        ctx.patchState({
          fieldChoices: response,
        });
      })
    );
  }

  @Action(AddService)
  addService(ctx: StateContext<any>, action: AddService) {
    return this.dynamicServices.addNewService(action.payload).pipe(
      tap((response) => {
        ctx.patchState({
          serviceById: response,
        });
      }),
      catchError((err) => {
        return this.getServerError(ctx, err);
      })
    );
  }
  @Action(AddAdditions)
  addAddition(ctx: StateContext<any>, action: AddAdditions) {
    return this.dynamicServices.addDynamicAddition(action.payload).pipe(
      tap((response) => {
        ctx.patchState({
          additionById: response,
        });
      }),
      catchError((err) => {
        return this.getServerError(ctx, err);
      })
    );
  }

  @Action(UpdateService)
  updateService(ctx: StateContext<any>, action: UpdateService) {
    return this.dynamicServices
      .updateService(action.serviceId, action.payload)
      .pipe(
        tap((response) => {
          ctx.patchState({
            serviceById: response,
            serviceList: [],
          });
        }),
        catchError((err) => {
          return this.getServerError(ctx, err);
        })
      );
  }

  @Action(addServiceSetting)
  addServiceSetting(ctx: StateContext<any>, action: addServiceSetting) {
    return this.dynamicServices
      .updateService(action.serviceId, action.payload)
      .pipe(
        tap((response) => {
          ctx.patchState({
            serviceById: response,
            serviceList: [],
          });
        }),
        catchError((err) => {
          return this.getServerError(ctx, err);
        })
      );
  }
  @Action(UpdateAdditions)
  updateAddition(ctx: StateContext<any>, action: UpdateAdditions) {
    const state = ctx.getState();
    return this.dynamicServices
      .updateDynamicAddition(action.additionId, action.payload)
      .pipe(
        tap((response) => {
          ctx.patchState({
            additionById: response,
            additionList: [],
          });
        }),
        catchError((err) => {
          return this.getServerError(ctx, err);
        })
      );
  }

  @Action(DeleteService)
  deleteService(ctx: StateContext<any>, action: DeleteService) {
    return this.dynamicServices.deleteService(action.id).pipe(
      tap((response) => {
        ctx.patchState({
          serviceList: response,
        });
      })
    );
  }

  @Action(GetServiceById)
  getServiceById(ctx: StateContext<any>, action: GetServiceById) {
    return this.dynamicServices.getServiceById(action.id).pipe(
      tap((response) => {
        ctx.patchState({
          serviceById: response,
        });
      })
    );
  }

  @Action(GetAdditionById)
  getAdditionById(ctx: StateContext<any>, action: GetServiceById) {
    return this.dynamicServices.getDynamicAdditionById(action.id).pipe(
      tap((response) => {
        ctx.patchState({
          additionById: response,
        });
      })
    );
  }
  @Action(AddNewCategory)
  addNewCategory(ctx: StateContext<any>, action: AddNewCategory) {
    const state = ctx.getState();
    return this.categoriesService
      .addCategory(action.id, action.categoryData)
      .pipe(
        tap((response) => {
          state.serviceById.categories.push(response);
          ctx.patchState({
            serviceById: { ...state.serviceById },
          });
        })
      );
  }

  @Action(UpdateCategory)
  updateCategory(ctx: StateContext<any>, action: UpdateCategory) {
    const state = ctx.getState();
    return this.categoriesService
      .updateCategory(action.categoryId, action.categoryData)
      .pipe(
        tap((response: any) => {
          state.serviceById.categories[action.index].terms = response.terms;
          ctx.patchState({
            serviceById: { ...state.serviceById },
          });
        }),
        catchError((err) => {
          return this.getServerError(ctx, err);
        })
      );
  }
  @Action(ChangeTermCategory)
  ChangeTermCategory(ctx: StateContext<any>, action: ChangeTermCategory) {
    const state = ctx.getState();
    return this.categoriesService
      .changeTermCategory(action.termId, action.category)
      .pipe(
        tap((response) => {
          ctx.patchState({
            serviceById: { ...state.serviceById },
          });
        }),
        catchError((err) => {
          return this.getServerError(ctx, err);
        })
      );
  }
  @Action(DeleteCategory)
  deleteCategory(ctx: StateContext<any>, action: DeleteCategory) {
    const state = ctx.getState();
    return this.categoriesService.deleteCategory(action.id).pipe(
      tap((response) => {
        state.serviceById.categories = [
          ...state.serviceById.categories.filter(
            (category) => category.id !== action.id
          ),
        ];
        ctx.patchState({
          serviceById: { ...state.serviceById },
        });
      }),
      catchError((err) => {
        return this.getServerError(ctx, err);
      })
    );
  }

  @Action(ChangeCategoriesRatio)
  changeCategoriesRatio(ctx: StateContext<any>, action: ChangeCategoriesRatio) {
    return this.categoriesService
      .createCategoriesRatio(action.serviceId, action.categories)
      .pipe(
        tap((response) => {
          const state = ctx.getState();
          state.serviceById.categories = [...response];
          ctx.patchState({
            serviceById: { ...state.serviceById },
          });
        }),
        catchError((err) => {
          return this.getServerError(ctx, err);
        })
      );
  }

  @Action(RedistrbuteRatios)
  redistrbuteRatios(ctx: StateContext<any>, action: RedistrbuteRatios) {
    return this.dynamicServices.redistrebuteRatios(action.serviceId).pipe(
      tap((response) => {
        ctx.patchState({
          serviceById: response,
        });
      })
    );
  }

  @Action(DeleteAddition)
  deleteAddition(ctx: StateContext<any>, action: DeleteAddition) {
    return this.dynamicServices.deleteAddition(action.id).pipe(
      tap((response) => {
        ctx.patchState({
          additionList: response,
        });
      })
    );
  }

  @Action(HandleError)
  HandleError(ctx: StateContext<any>, action: HandleError) {
    return ctx.patchState({
      serverError: action.error,
    });
  }

  getServerError(ctx, err) {
    return ctx.patchState({
      serverError: err.error.non_field_errors[0],
    });
  }
}
