import { Component, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
/**
  datepicker component
 *  
*/
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements  ICellEditorAngularComp {
/**
declare params variable 
 *  
*/
  params: any;
/**
declare datepicker viewchild variable
 *  
*/
  @ViewChild('picker') datePicker;
/**
declare selected value variable
 *  
*/
  selectedValue: any;
/**
declare aginint function 
 *  
*/
  agInit(params: any): void {
    this.params = params;
   
  
  }
/**
declare ngAfterViewInit function 
 *  
*/
  ngAfterViewInit() {
    window.setTimeout(() => {
      this.datePicker.open();
    });
     
  }
/**
declare is popup function 
 *  
*/
  isPopup?(): boolean {
   return true
  }
/**
declare get Value function 
 *  
*/
  getValue  ()  {
    return  this.selectedValue;
  }
/**
declare ondatechange function 
 *  
*/
  onDateChanged(event) {
    let formatedDate=event.value;
    formatedDate= formatedDate.format('YYYY-MM-DD');
    this.selectedValue=formatedDate;
    this.params.stopEditing();
  }
 
}
