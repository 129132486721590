import { Component,
         ViewContainerRef, Input } from '@angular/core';
import { DynamicComp } from 'src/app/models/interfaces/serviceEngine/dynamic-comp.model';
@Component({
  selector: 'app-component-host',
  templateUrl: './component-host.component.html',
  styleUrls: []
})
export class ComponentHostComponent {
 @Input() inputs;
 @Input() subForm ;
 @Input() orderId ;
  constructor(
    private viewContainerRef: ViewContainerRef) { }

  @Input()
  set component(component: any) {
    this.viewContainerRef.clear();
    if (component) {
      const componentFactory = this.viewContainerRef.createComponent(component);
      (<DynamicComp>componentFactory.instance).inputs= this.inputs;
      (<DynamicComp>componentFactory.instance).subForm= this.subForm;
      (<DynamicComp>componentFactory.instance).orderId= this.orderId;
    }

  }
}
