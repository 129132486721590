<div class="dialog-container" id="addService">
  <h5 mat-dialog-title>إضافة خدمة للفاحصين</h5>
  <form
    class="needs-validation"
    [formGroup]="addServiceFormGroup"
    (ngSubmit)="addService()">
    <div mat-dialog-content class="text-center">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="" class="form-label"> الخدمة</label>
            <ng-select
              bindLabel="name"
              class="single-select"
              placeholder=" يرجى تحديد الخدمة التي تريد إضافتها "
              appendTo="body"
              formControlName="services"
              [searchable]="true"
              [clearable]="true">
              @for (service of services; track service) {
                <ng-option [value]="service.id">
                  {{ service.name }}
                </ng-option>
              }
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="actions-double">
      <a class="btn btn-cancel center-btn" mat-dialog-close>إلغاء</a>
      <button
        class="btn btn-confirm center-btn"
        [disabled]="disable"
        type="submit">
        إضافة
      </button>
    </div>
  </form>
</div>
