<!-- start page header-->
<div class="inner-page-header">
  <h3>{{ title }}</h3>
</div>
<!-- end page header -->
<form [formGroup]="addbankFormGroup" class="needs-validation">
  <div class="main-card">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-label">اسم البنك <span class="red">*</span></label>
          <input
            class="form-control"
            type="text"
            id="bank_name"
            formControlName="name"
            />
            <img
              class="form-control-feedback"
              src="assets/images/svg/error-icon1.svg"
              alt=""
              />
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label"> توضيح </label>
            <input class="form-control" formControlName="description" type="text" />
          </div>
          <div class="col-md-12">
            <label class="form-label"> أرقام البطاقات </label>
            @for (cardNum of cardNums; track cardNum; let i = $index) {
              <div class="bank-cards">
                <div class="input-field">
                  <div class="form-group">
                    <input
                      #bankInputs
                      class="form-control cardVal"
                      [id]="cardNum.code"
                      (keypress)="addSpace($event)"
                      [formControlName]="cardNum.code"
                      type="text"
                      maxlength="7"
                      />
                      <img
                        class="form-control-feedback"
                        src="assets/images/svg/error-icon1.svg"
                        alt=""
                        />
                      </div>
                    </div>
                    <div class="img-field">
                      <div class="delete" (click)="deleteCardNo(i)">
                        <img src="/assets/images/svg/grid-delete-icon.svg" alt="" /> حذف
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div class="col-md-12 addNumbtn">
                <button class="btn btn-green" id="addNum" (click)="addCardNo()">
                  إضافة رقم
                </button>
              </div>
            </div>
            @if (alertmsg) {
              <div class="alert-box">
                <img
                  src="assets/images/svg/error-icon.svg"
                  class="close-alert"
                  alt=""
                  (click)="closeError()"
                  />
                  <p class="alert-msg">{{ message }}</p>
                </div>
              }
            </div>
            <div class="row">
              <div class="col-md-12 double-action">
                <button class="btn btn-cancel" type="button" (click)="back()">
                  رجوع
                </button>
                <button class="btn btn-green" id="saveBtn" type="submit" (click)="save()">
                  {{ saveBtn }}
                </button>
              </div>
            </div>
          </form>
