<div class="dialog-container avatar-dialog" mat-dialog-content>
  <div class="avatar-data">
    <div class="closeBtn" mat-dialog-actions>
      <button type="button" class="close" mat-dialog-close>
        <span aria-hidden="true">
          <img
            [src]="imagePath.CANCEL_ICON"
            alt=""
          />
        </span>
      </button>
    </div>
    <div class="modal-sec">
      <p class="main-title">اسم الفاحص</p>
      <p class="sec-title">{{ data.inspectorName }}</p>
    </div>
    <a [href]="data.imgPath" class="downloadImg" download target="_blank">
      <img [src]="imagePath.DOWNLOAD_ICON" alt="" />
      تحميل الصورة
    </a>
    <button (click)="delete()" class="delete-icon">
      <img [src]="imagePath.DELETE_ICON" alt="" />
       حذف
    </button>
  </div>
  <div class="avatar-sec">
    <img [src]="data.imgPath" alt="" />
  </div>
</div>
