@if (ServiceProvider) {
  <div class="row">
    <div class="col-md-3 basic-data">
      <h4>مزود الخدمة</h4>
      <p> {{ServiceProvider?.service_provider ? ServiceProvider.service_provider : '-'}} </p>
    </div>
    <div class="col-md-3 basic-data">
      <h4> وقت تسليم التقرير</h4>
      <p> {{dateFormate(ServiceProvider?.inspection_end_date)}} </p>
    </div>
    <div class="col-md-3 basic-data">
      <h4>حصة مزود الخدمة </h4>
      <p>{{ServiceProvider?.service_provider_share ? ServiceProvider.service_provider_share : '-'}}</p>
    </div>
    <div class="col-md-3 basic-data">
      <h4> حالة الدفع (مزود الخدمة)</h4>
      <div class="double-action">
        <p> {{ServiceProvider?.service_provider_payment_status ? ServiceProvider.service_provider_payment_status : '-'}}</p>
        @if (servicePaymentProvider.status == 'CO' &&
          ServiceProvider.service_provider_payment_status == 'مستحقة') {
          <button class="btn btn-green" (click)="providerPay(orderId)">
            تم الدفع
          </button>
        }
      </div>
    </div>
    <div class="col-md-3 basic-data">
      <h4>  تاريخ الدفع (مزود الخدمة)</h4>
      <p>{{dateFormate(ServiceProvider?.service_provider_payment_date)}}</p>
    </div>
  </div>
}