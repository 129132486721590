@if (displayOrderConfirmation) {
  <div
    class="modal modal-xs fade"
    id="requestQuatation"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-title">
          <h5>إرسال التسعيرة</h5>
        </div>
        <div class="modal-body">
          @if (quatationType === 'single') {
            <div class="qutation-item">
              <p>التكلفة</p>
              <span>{{ facility.cost.text }} {{ facility.cost.sign }}</span>
            </div>
          }
          <form [formGroup]="quatationFormGroup">
            @if (quatationType === 'custom') {
              <div class="form-group">
                <label for="" class="form-label">مزود الخدمة </label>
                <ng-select class="single-select"
                  bindLabel="name"
                  #select
                  placeholder="يرجى اختيار  مزود الخدمة"
                  appendTo="body"
                  formControlName="facility"
                  [searchable]="true"
                  [clearable]="true"
                  >
                  @for (provider of quatations; track provider) {
                    <ng-option
                      [value]="provider.id"
                      >
                      {{ provider.name }}
                    </ng-option>
                  }
                </ng-select>
              </div>
            }
            @if (quatationType === 'custom') {
              <div class="form-group">
                <label for="client" class="form-label">
                  التكلفة
                  <span class="label-des">( بدون ضريبة القيمة المضافة )</span>
                </label>
                <input
                  type="number"
                  id="cost_exclude_vat"
                  formControlName="cost_exclude_vat"
                  name="cost_exclude_vat"
                  class="form-control"
                  />
                  <span class="desc">ر.س</span>
                </div>
              }
              <div class="form-group">
                <label for="client">سعر البيع</label>
                <input
                  type="number"
                  id="price_exclude_vat"
                  formControlName="price_exclude_vat"
                  name="price_exclude_vat"
                  class="form-control"
                  (keyup)="vatCostCalc($event)"
                  />
                  <span class="desc">ر.س</span>
                </div>
                <div class="form-group">
                  <label for="client">صلاحية العرض</label>
                  <input
                    type="number"
                    id="offer_validity_per_hour"
                    formControlName="offer_validity_per_hour"
                    name="offer_validity_per_hour"
                    class="form-control"
                    />
                    <span class="desc">ساعة</span>
                  </div>
                </form>
                @if (quatationType === 'single') {
                  <div class="qutation-item">
                    <p>ضريبة القيمة المضافة</p>
                    <span>{{ vat }} ر.س</span>
                  </div>
                }
                <div class="qutation-item">
                  <p>الإجمالي</p>
                  <span>{{ finalCost }} ر.س</span>
                </div>
              </div>
              <div class="actions-double">
                <a class="btn btn-cancel center-btn" data-dismiss="modal">إلغاء</a>
                <button
                  class="btn btn-green"
                  data-dismiss="modal"
                  [disabled]="quatationFormGroup.invalid"
                  (click)="submitQuatation()"
                  >
                  إرسال
                </button>
              </div>
            </div>
          </div>
        </div>
      }
