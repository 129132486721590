import {
  Component,
  Output,
  OnInit,
  EventEmitter,
  Input,
  Inject,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
})
export class ReportModalComponent implements OnInit {
  // @Output() notes = new EventEmitter();
  @Input() headerTxt: string = '';
  @Input() flag: boolean = false;
  reportFormGroup: UntypedFormGroup;
  @Input() inspector_status;
  placeholder: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.headerTxt = this.data.headerTxt;
    this.inspector_status = this.data.inspector_status;
    this.createForm();
    this.createFormControls();
    this.placeholder = !this.flag ? 'اكتب ملاحظاتك على التقرير هنا...' : '';
  }

  createFormControls() {
    return this.reportFormGroup.controls;
  }

  createForm() {
    this.reportFormGroup = this.formBuilder.group({
      notes: [null, Validators.required],
      receiver: [null],
    });
  }

  addnotes() {
    this.dialogRef.close({ data: this.reportFormGroup.value });
    this.reportFormGroup.reset();
  }
}
