@if (!projects) {
<app-spinner></app-spinner>
}
<div class="inner-page-header">
  <h3>المشاريع</h3>
</div>
@if (projects) {
<div class="request-control">
  <!-- <div class="delete-icon-grid">
    <button
      class="btn btn-custom-delete"
      (click)="deleteSelectedRows()"
      [disabled]="disabledDelete"
    >
      <img src="assets/images/svg/delete-icon-ins.svg" alt="" />
    </button>
  </div> -->
  <quick-search (searchResults)="quickSearch($event)"></quick-search>
  <div class="new-element">
    <button class="btn btn-add" (click)="addProject()">
      <img src="assets/images/svg/add-icon.svg" alt="" />
      إضافة مشروع
    </button>
  </div>
</div>
@if (projects && projects.length > 0) {
<ayen-selected-count
  [itemsPerPage]="currentItemsPerPage"
  [selectedRows]="selectedRowsPK"
  [totalRowsSelected]="totalRowsSelected"
  [pageTitle]="pageTitle"
  [totalItems]="config.totalItems"
  (setTotalRowsSelected)="selectTotalRows($event)"
>
</ayen-selected-count>
}
<div class="request-table">
  <div class="table-responsive">
    <table id="project-table" aria-label="" class="table table-striped">
      <thead>
        <tr>
          <th id="">
            <label class="checkbox-container">
              <input
                type="checkbox"
                #selectAllElm
                id="custom_check_all"
                (click)="selectAll($event.target)"
              />
              <span class="checkmark"></span>
            </label>
            <span
              class="checkbox-label"
              (click)="toggleSorting('shortcode', img1)"
            >
              كود المشروع
              <img #img1 [src]="imgDefault" alt="" />
            </span>
          </th>
          <th (click)="toggleSorting('name', img2)">
            اسم المشروع
            <img #img2 [src]="imgDefault" alt="" />
          </th>
          <th>حالة المشروع</th>
          <th>تابع لشركة</th>
          <th (click)="toggleSorting('active_date', img3)">
            تاريخ إعتماد المشروع
            <img #img3 [src]="imgDefault" alt="" />
          </th>
          <th (click)="toggleSorting('expiry_date', img4)">
            تاريخ تسليم العملاء
            <img #img4 [src]="imgDefault" alt="" />
          </th>
          <th>حالة الاستلام</th>
        </tr>
      </thead>
      <tbody>
        @for (project of projects | paginate: config; track project; let i =
        $index) {
        <tr
          (click)="openProject(project.pk)"
          class="table-row"
          data-href="requests-waitingForCompleted.html"
          role="row"
        >
          <td>
            <label class="checkbox-container" (click)="stopPropagation($event)">
              <input
                type="checkbox"
                #checkBoxList
                (click)="selectsSingleRow($event, project.pk, i)"
                [id]="i + '_' + project.pk"
              />
              <span class="checkmark"></span>
            </label>
            <span class="checkbox-label">
              <a href="/pages/projects/{{ project.pk }}">{{
                project.shortcode
              }}</a>
            </span>
          </td>
          <td>{{ project.name ? project.name : "-" }}</td>
          <td>{{ project.status ? project.status.name : "-" }}</td>
          <td>{{ project.company ? project.company.name_arabic : "-" }}</td>
          <td>{{ project.active_date ? project.active_date : "-" }}</td>
          <td>{{ project.expiry_date ? project.expiry_date : "-" }}</td>
          <td>
            <button
              class="btn"
              [ngClass]="{
                'not-ready': !project.is_acquirable,
                ready: project.is_acquirable
              }"
            >
              @if (!project.is_acquirable) {
              <span> غير </span>
              } جاهز للعملاء
            </button>
          </td>
        </tr>
        } @if (projects.length === 0) {
        <tr>
          <td colspan="5" class="text-center">لا توجد بيانات</td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>
@if (projects && projects.length > 0) {
<ayen-pagination
  (pageData)="pageChanged($event)"
  (pageSize)="pageSizeChanged($event)"
  [totalCount]="config.totalItems"
  [pageTitle]="pageTitle"
>
</ayen-pagination>
} }
