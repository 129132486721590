import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  currentUser: any;
  value: boolean = false;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.value = this.currentUser.user.profile.can_receive_email_notifications;
  }

  codeValueChanged(event) {
    let value = event.value;
    const userID = this.currentUser.user.pk;
    let data = {
      can_receive_email_notifications: value
    }
    this.authenticationService.updateUserData(userID, data).subscribe(
      res => {
        console.log(res);
        this.currentUser["user"] = res;
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
      }
    )
  }

}
