import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class OrderAdditionsService extends AYENServiceBase{

  constructor(private http: HttpClient) {
    super();
   }

  updateOrderAdditions(id, data): Observable<any> {
    return this.http.put(this.baseURL + `/admin-order-dynamic-service-additional-fields/${id}/`, data);
  }
}
