<!-- start page header-->
<div class="inner-page-header">
  <h3>البطاقات البنكية</h3>
</div>
<!-- end page header -->
<!-- start search bar -->
<div class="request-control">
  <div class="delete-icon-grid">
    <button
      class="btn btn-custom-delete"
      (click)="deleteSelectedRows()"
      [disabled]="disabledDelete"
    >
      <img src="assets/images/svg/new-delete.svg" alt="" />
    </button>
  </div>
  <quick-search (searchResults)="quickSearch($event)"></quick-search>
  <div class="new-element">
    <button class="btn btn-add" (click)="addBank()">
      <img src="assets/images/svg/add-icon.svg" alt="" />
      إضافة رقم بطاقة
    </button>
  </div>
</div>
@if (bankdata && bankdata.length > 0) {
<ayen-selected-count
  [itemsPerPage]="currentItemsPerPage"
  [selectedRows]="selectedRowsPK"
  [totalRowsSelected]="totalRowsSelected"
  [pageTitle]="pageTitle"
  [totalItems]="config.totalItems"
  (setTotalRowsSelected)="selectTotalRows($event)"
></ayen-selected-count>
}
<!-- coupon table -->
<div class="request-table">
  <div class="table-responsive">
    <table id="order-table" class="table table-striped" aria-label="">
      <thead>
        <tr>
          <th class="width-40" id="">
            <label class="checkbox-container">
              <input
                type="checkbox"
                #selectAllElm
                (click)="selectAll($event.target)"
              />
              <span class="checkmark"></span>
            </label>
            <span class="checkbox-label"> اسم البنك </span>
          </th>
          <th class="width-60" id="">توضيح</th>
        </tr>
      </thead>

      <tbody>
        @if (bankdata) { @for (bank of bankdata | paginate : config; track bank;
        let i = $index) {
        <tr (click)="openBank(bank.pk)">
          <td>
            <label class="checkbox-container" (click)="stopPropagation($event)">
              <input
                #checkBoxList
                type="checkbox"
                (click)="selectsSingleRow($event, bank.pk, i)"
                [id]="i + '_' + bank.pk"
              />
              <span class="checkmark"></span>
            </label>
            <span class="checkbox-label">
              <a href="/pages/coupons/add-bank/{{ bank.pk }}">{{
                bank?.name
              }}</a>
            </span>
          </td>
          <td>{{ bank?.description }}</td>
        </tr>
        } @if (bankdata.length === 0) {
        <tr>
          <td colspan="9" class="text-center">لا توجد بيانات</td>
        </tr>
        } }
      </tbody>
    </table>
  </div>
</div>

@if (bankdata && bankdata.length > 0) {
<ayen-pagination
  (pageData)="pageChanged($event)"
  (pageSize)="pageSizeChanged($event)"
  [totalCount]="config.totalItems"
  [pageTitle]="pageTitle"
>
</ayen-pagination>
}
