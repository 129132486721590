import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { OrderDetailsService } from '../../../services/order-details/order-details.service';

@Component({
  selector: 'other-features',
  templateUrl: './other-features.component.html',
  styleUrls: ['./other-features.component.scss']
})
export class OtherFeaturesComponent implements OnInit {

  otherFeaturesForm: UntypedFormGroup;
  @Input() orderData: any;
  @Input() orderID;
  @Output() errorMsg = new EventEmitter;
  @Output() enableSave = new EventEmitter;
  features: any = [] ;
  showSpinner: boolean;

  constructor (
    private formBuilder : UntypedFormBuilder,
    private orderDetails : OrderDetailsService){
  }

  ngOnInit(): void {
    this.features = this.orderData.building_other_features;
    this.createForm();
    this.createFormControls();
    this.patchValues();
  }

  createForm(){
    this.otherFeaturesForm = this.formBuilder.group({
      building_other_features : []
    })
  }

  createFormControls() {
    return this.otherFeaturesForm.controls;
  }

  featureValueChanged(event , index){
    this.features[index].value = event.target.checked  ;
    this.patchValues();
  }

  patchValues(){
    this.otherFeaturesForm.get('building_other_features').setValue(this.features);
  }

  save(){
    let data ={
      building_other_features : this.otherFeaturesForm.get('building_other_features').value
    }
    this.showSpinner = true ;
    this.orderDetails.updateOtherFeatures(this.orderID , data ).subscribe(
      res=>{
        this.showSpinner = false ;
        this.showConfirmationModal();
      }
    )
  }

  showConfirmationModal(){
    this.errorMsg.emit({
      message :"تم تحديث البيانات بنجاح.",
      imgSuccess : true
    });
  }
  
}
