<div class="row" [formGroup]="buildingSpecificationForm" >
  @if (!landFlag) {
    <div class="col-md-6">
      <div class="form-group">
        <label for="" class="form-label">عمر العقار</label>
        <input type="number" formControlName="age"  class="form-control" placeholder="عمر العقار"/>
      </div>
    </div>
  }
  <div class="col-md-6">
    <div class="form-group">
      <label for="" class="form-label"> مساحة الأرض </label>
      <span class="static-code">م<sup>2</sup></span>
      <input type="text" formControlName="land_area"
        class="form-control" placeholder="مساحة الأرض " readonly/>
      </div>
    </div>
    @if (buildingType != 7 && buildingType != 10) {
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">مسطحات البناء</label>
          <span class="static-code">م<sup>2</sup></span>
          <input type="text" formControlName="surfaces_space"
            class="form-control" placeholder="مسطحات البناء " readonly/>
          </div>
        </div>
      }
      @if (!landFlag) {
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label">عدد الأدوار</label>
            <input type="text" formControlName="floors_number"  class="form-control" placeholder="عدد الأدوار"/>
          </div>
        </div>
      }
      <div class="col-md-6 custom-control">
        <div class="form-group">
          <label for="" class="form-label">البناء المجاور </label>
          <app-select
            [optionItems]="directions"
            [placeholder]="'يرجى تحديد البناء المجاور'"
            [initValues] = "selectedNeighbour_buildings"
            [id]="'id'"
            (selectedOptions)="setNeighbour($event , 'neighbour_buildings')">
          </app-select>
        </div>
      </div>
      <div class="col-md-6 custom-control">
        <div class="form-group">
          <label for="" class="form-label">الشوارع المحيطة </label>
          <app-select
            [optionItems]="directions"
            [placeholder]="'يرجى تحديد الشوارع المحيطة'"
            [initValues] = "selectedNeighbour_streets"
            [id]="'id'"
            (selectedOptions)="setNeighbour($event , 'neighbour_streets')">
          </app-select>
        </div>
      </div>
    </div>
    <div  class="save-section">
      <div class="save-btn-wrapper">
        <button type="button" class="btn btn-green" (click)="save()">
          @if (showSpinner) {
            <img class="spin-img" src="assets/images/svg/Spinner-s.svg" alt=""/>
          }
          حفظ
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        @if (alertmsg) {
          <div class="alert-box">
            <img alt=""
              src="assets/images/svg/error-icon.svg"
              class="close-alert"
              (click)="hideErrorMSG()"
              alt=""
              />
              <p class="alert-msg">{{errorMessage}}</p>
            </div>
          }
        </div>
      </div>
