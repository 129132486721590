<div #container
  [ngClass]="{'qualify-mood': qualification === true,
  'register-mood':register === true  ,
  'status-editor' : statusEditor === true ,
  'status-editorT' : statusEditorTest === true ,
  'accountInfo-mood' : accountInfo ===true}">
  <ng-select
    [appendTo]="'div'"
    class="single-select"
    (change)="clickMenu($event)"
    #Selecter [(ngModel)]="valueSelected"
    (focus)="setStatus(status)"
    >
    @for (value of values; track value) {
      <ng-option  [value]="value.id" >
        {{value.name}}
      </ng-option>
    }
  </ng-select>
</div>
