import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Element } from 'src/app/models/interfaces/serviceEngine/element.model';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: []
})
export class DateComponent implements OnInit {
  @Input() inputs:Element;
  @Input() subForm: UntypedFormGroup;
  isRequired: boolean = false;

  ngOnInit(): void {
    this.isRequired = this.inputs.validation.required;
  }

}
