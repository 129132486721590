<div class="form-group">
  <label for="" class="form-label">{{ label }}</label>
  <ng-select
    #select
    class="paginantion-select"
    bindLabel="name"
    [(ngModel)]="selectedContract"
    (scrollToEnd)="onScrollPagination()"
    [placeholder]="placeholder"
    appendTo="body"
    [searchable]="true"
    (removed)="resetSearch()"
    (search)="searchtext()"
    (clear)="resetSearch()"
    (change)="changeItemData($event)"
    (open)="resetSearch()"
    [virtualScroll]="true"
    [readonly]="readonly"
    [clearable]="true"
    >
    @for (item of items; track item) {
      <ng-option [value]="item.pk || item.id">
        {{ item.name }}
      </ng-option>
    }
  </ng-select>
</div>
