<div class="row">
  <div class="col-3 basic-data">
    <h4> إجمالي المبلغ المستحق</h4>
    <p>
      {{orders?.cost?.final_cost?orders?.cost?.final_cost: '-'}}
      @if (orders?.cost?.final_cost!='-') {
        <span> ر.س </span>
      }
    </p>
  </div>
  <div class="col-3 basic-data">
    <h4> قيمة الضريبة المضافة </h4>
    <p>
      {{orders?.cost?.tax_cost ?orders?.cost?.tax_cost : '-'}}
      @if (orders?.cost?.tax_cost !='-') {
        <span> ر.س </span>
      }
    </p>
  </div>
  <div class="col-3 basic-data">
    <h4>  الإيراد بعد خصم الضريبة</h4>
    <p>
      {{orders?.cost?.cost_after_discount ? orders?.cost?.cost_after_discount : '-'}}
      @if (orders?.cost?.cost_after_discount !='-') {
        <span> ر.س </span>
      }
    </p>
  </div>
  <div class="col-3 basic-data">
    <h4>  حالة الدفع </h4>
    <div class="double-action">
      <p> {{orders?.payment_data.payment_status?orders?.payment_data.payment_status : '-'}}
        @if (orders?.payment_data?.payment_status === 'مستلم') {
          <img src="assets/images/svg/ellipse-green.svg" alt="">
        }
      </p>
      @if (orders?.payment_data?.payment_status === 'مدفوع') {
        <button (click)="deliveredOrder(orders.id, received)" class="btn btn-green">
          تم الإستلام
        </button>
      }
    </div>
  </div>
</div>
<div class="row">
  <div class="col-3 basic-data">
    <h4>تاريخ الدفع </h4>
    <p>{{orders?.payment_data?.payment_date?orders?.payment_data?.payment_date : '-'}}</p>
  </div>
  <div class="col-3 basic-data">
    <h4>طريقة الدفع </h4>
    <p>
      <span class="card-no">{{orders?.payment_data?.card_number}}</span>
      <!-- 0002 **** **** ****  -->
      {{orders?.payment_data?.payment_option}}
    </p>
  </div>
  <div class="col-3 basic-data">
    <h4> رقم المعاملة </h4>
    <p>{{orders?.payment_data?.fort_id?orders?.payment_data?.fort_id : '-'}}</p>
  </div>
  <div class="col-3 basic-data">
    <h4>كود الخصم </h4>
    <p>{{orders?.cost?.coupon_discount?.coupon_name?orders?.cost?.coupon_discount?.coupon_name : '-'}}</p>
  </div>
</div>
<div class="row">
  <div class="col-3 basic-data">
    <h4> قيمة الخصم </h4>
    <p>{{orders?.cost?.coupon_discount?.value?orders?.cost?.coupon_discount?.value : '-'}}
      @if (orders?.cost?.coupon_discount?.value !=0) {
        <span> ر.س </span>
      }
    </p>
  </div>
</div>
