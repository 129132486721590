import { environment } from '../../../../environments/environment';
/**
*  define and export AYENServiceBase class
*/
export class AYENServiceBase {
  /**
  *  declare baseURL   
  */
  baseURL: string;
  /**
  *  implement constructor
  */
  constructor() {
    this.baseURL = environment.AYEN_API_URL;
  }
}
