import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AYENServiceBase } from '../../../../core/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicServices extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getServices(params): Observable<any> {
    return this.http.get(`${this.baseURL}/admin/dynamic-services`, {
      params: params,
    });
  }

  getAdditions(params): Observable<any> {
    return this.http.get(`${this.baseURL}/admin/dynamic-services/dynamic-service-additions`, {
      params: params,
    });
  }

  addNewService(service): Observable<any> {
    return this.http.post(`${this.baseURL}/admin/dynamic-services`, service);
  }

  getServiceById(id): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/admin/dynamic-services/${id}`);
  }

  updateService(id, service): Observable<any> {
    return this.http.put<any>(
      `${this.baseURL}/admin/dynamic-services/${id}`,
      service
    );
  }

  deleteService(id): Observable<any> {
    return this.http.delete(`${this.baseURL}/admin/dynamic-services/${id}`);
  }

  getDynamicServicesChoices() {
    return this.http.get(`${this.baseURL}/admin/show-terms-choices/`);
  }

  getStatusCustomDisplay() {
    return this.http.get(`${this.baseURL}/admin/completion-statuses/`);
  }

  getBuildingData(): Observable<any> {
    return this.http.get(`${this.baseURL}/admin/dynamic-services/building-data-to-show`);
  }

  redistrebuteRatios(id): Observable<any> {
    return this.http.put(`${this.baseURL}/admin/dynamic-services/${id}/update-service-all-ratios/`, {});
  }

  getDynamicAdditionChoices() {
    return this.http.get(`${this.baseURL}/admin/dynamic-services/addition-status-choices/`);
  }

  getDynamicAdditionFieldChoices() {
    return this.http.get(`${this.baseURL}/admin/dynamic-services/addition-field-types/`);
  }

  addDynamicAddition(Addition): Observable<any> {
    return this.http.post<any>(`${this.baseURL}/admin/dynamic-services/dynamic-service-additions/`, Addition);
  }

  updateDynamicAddition(id, Addition): Observable<any> {
    return this.http.put<any>(`${this.baseURL}/admin/dynamic-services/dynamic-service-additions/${id}/`, Addition);
  }

  getDynamicAdditionById(id): Observable<any> {
    return this.http.get(`${this.baseURL}/admin/dynamic-services/dynamic-service-additions/${id}`);
  }

  deleteAddition(id): Observable<any> {
    return this.http.delete(`${this.baseURL}/admin/dynamic-services/dynamic-service-additions/${id}`);
  }
}
