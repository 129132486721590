import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'card-filter',
  templateUrl: './card-filter.component.html',
  styleUrl: './card-filter.component.scss',
})
export class CardFilterComponent {
  @Input() card;
  @Input() cardId;
  @Output() cardSelected = new EventEmitter();

  cardFilter() {
    this.cardSelected.emit(this.card);
  }
}
