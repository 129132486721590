<div class="inspector-modal">
  <h2 mat-dialog-title>تعيين فاحص للطلبات</h2>
  <div mat-dialog-content>
    <div class="form-group">
      <label for="" class="form-label"> اسم الفاحص </label>
      <ng-select bindLabel="name" (scrollToEnd)="onScrollPagination()" #selectInsp
        placeholder="يرجى تحديد اسم الفاحص " appendTo="body" [searchable]="true" (removed)="resetSearch()"
        (search)="searchtext()" (clear)="resetSearch()" (change)="inspectorData($event)" (open)="resetSearch()"
        [virtualScroll]="true" [clearable]="true">
        @for (inspector of inspectors; track inspector) {
          <ng-option [value]="inspector.id">
            {{inspector.full_name}}
          </ng-option>
        }
      </ng-select>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="actions-double">
      <button class="btn btn-cancel" type="button" mat-dialog-close>إلغاء</button>
      <button class="btn btn-green" type="submit" [mat-dialog-close]="data">تعيين</button>
    </div>
  </div>
</div>
