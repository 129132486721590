<div class="order-title inner-page-header"><h2>سجل الإشعارات</h2></div>
<!-- filter icons -->
<div class="filter-icons" >
  @for (item of noitify_Filter; track item) {
    <div class="item-filter custom-tooltip {{item.name}}" [ngClass]="{'active-item': checkActive(item.name)  }"
      (click)="ToggleImg(item?.name)">
      @if (!checkActive(item.name)) {
        <img [src]="item.icon" alt="">
      }
      @if (checkActive(item.name)) {
        <img [src]="item.icon_active" alt="">
      }
      <span class="tooltip-text" >{{item.tooltip}}</span>
    </div>
  }
</div>
<!-- end filter icons-->

<!-- filter result page-->
<div class="main-content" (scroll)="onScroll($event)">
  @for (item of allNotification; track item; let i = $index) {
    <div class="notify-item">
      <a class="notify-link" href="{{item.url}}">
        <div class="item-container">
          <div class="item-msg icon">
            <img [src]="item?.notification_type?.icon" alt="">
          </div>
          <div class="item-info">
            <div [innerHtml]="item.body"></div>
            <div class="sub-item"> {{item.date_added}} </div>
          </div>
        </div>
      </a>
      <div class="droppoints">
        <div class="radio-div">
          <input type="radio"
            id="btn_{{item.id}}"
            class="notifyBtn"
            [checked]="!item.is_read"
            (click)="radioBtnToggle(item?.is_read,item?.id,i)"
            >
            <label class="notify-label custom-tooltip" for="btn_{{item.id}}">
              <span class="tooltip-text" [ngClass]="{'read':item.is_read== false , 'not-read':item.is_read==true}">{{item.is_read ? "جعله غير مقروء" :   "جعله مقروء" }}</span>
            </label>
          </div>
        </div>
      </div>
    }

  </div>
  <!-- end filter result page-->
