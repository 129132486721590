import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
/**
  declare numbers directive
 *  
*/
@Directive({
  selector: 'input[numbersOnly]'
})
export class NumberDirective {
/**
  declare value change emitter 
 *  
*/
@Output() valueChange = new EventEmitter()
/**
  declare constructor function
 *  
*/
  constructor(private _el: ElementRef) { }
/**
  declare hostlistener onInputChange 
 *  
*/
  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    const newValue = initalValue.replace(/[^0-9]*/g, '');
       this._el.nativeElement.value = newValue;
       this.valueChange.emit(newValue);
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}