<section class="total-count">
  @if (((itemsPerPage == selectedRows.length || selectedRows.length == totalItems) && !totalRowsSelected)) {
    <div>
      <span>تم تحديد جميع {{pageTitle}} {{itemsPerPage}}  بالصفحة.</span>
      <span class="all" (click)="selectTotalRows()">حدد جميع {{pageTitle}} البالغ عددهم {{totalItems}}</span>
    </div>
  }
  @if (totalRowsSelected) {
    <div>
      <span>تم تحديد جميع {{pageTitle}} {{totalItems}}</span>
    </div>
  }
</section>
