import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { SecurityErrorComponent } from 'src/app/shared/components/security-error/security-error.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  securityErrorDialogRef: MatDialogRef<SecurityErrorComponent>;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const userData = JSON.parse(localStorage.getItem('currentUser'));
    if (
      localStorage.getItem('currentUser') != null &&
      localStorage.getItem('currentUser') != ''
    ) {
      const token = userData.access_token;
      if (token) {
        request = this.addTokenHeader(request, token);
      }
      if (request.body instanceof FormData) {
        if (!request.headers.has('Content-Type')) {
          request = request.clone({
            headers: request.headers.set['Content-Type'],
          });
        }

        request = request.clone({
          headers: request.headers.set['Accept'],
        });
      } else {
        if (!request.headers.has('Content-Type')) {
          request = request.clone({
            headers: request.headers.set('Content-Type', 'application/json'),
          });
        }

        request = request.clone({
          headers: request.headers.set('Accept', 'application/json'),
        });
      }
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleAuthError(error, request, userData, next);
      })
    );
  }

  private handleAuthError(
    err: HttpErrorResponse,
    request,
    userData,
    next
  ): Observable<any> {
    if (!request.url.includes('auth/refresh') && err.status == 401) {
      const refreshToken = userData.refresh_token;
      return this.authenticationService.refreshToken(refreshToken).pipe(
        switchMap((res) => {
          request = this.addTokenHeader(request, res.access);
          userData.access_token = res.access;
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(userData));
          return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
              return event;
            }),
            catchError(() => {
              return this.relogin(err);
            })
          );
        }),
        catchError(() => {
          return this.relogin(err);
        })
      );
    } else if (err.status === 429 || err.status == 403) {
      if (this.securityErrorDialogRef == null) {
        this.securityErrorDialogRef = this.dialog.open(SecurityErrorComponent, {
          data: {
            message: err.status == 403 ? 'غير مصرح لك بهذا الإجراء' : err.error.message,
          },
        });
        this.securityErrorDialogRef.afterClosed().subscribe((result) => {
          this.securityErrorDialogRef = null;
        });
      }
    } else if (err.status == 404) {
      this.router.navigate(['/pages/NotFound']);
    } else if (err.status == 500) {
      this.router.navigate(['/pages/ServerError']);
    }
    return throwError(err);
  }

  relogin(err) {
    localStorage.clear();
    this.router.navigateByUrl('/auth/login');
    return throwError(err.message);
  }

  addTokenHeader(request, token) {
    request = request.clone({
      headers: request.headers.set('Authorization', `JWT ${token}`),
    });
    return request;
  }
}
