import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.scss']
})
export class AddOrderComponent {

  constructor(private router: Router,
    private titleService: Title) {}

  newOrder() {
    this.router.navigateByUrl(`/pages/orders/new-order`);
    this.titleService.setTitle('إضافة طلب | عاين');
  }

  amount() {
    this.router.navigateByUrl('/pages/orders/additional-amount');
    this.titleService.setTitle(' خدمة إضافية | عاين');
  }
}
