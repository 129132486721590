import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RatingService } from 'src/app/core/services/rating/rating.service';

@Component({
  selector: 'app-inspector-rating-modal',
  templateUrl: './inspector-rating-modal.component.html',
  styleUrls: ['./inspector-rating-modal.component.scss'],
})
export class InspectorRatingModalComponent implements OnInit {
  @Input() inspectorId;
  public ratingArr = [];
  ratingData: any = {};
  rating: number = 0;
  starCount: number = 5;
  inspectorReviewData: any;
  CustomerhasRating: boolean = true;
  QChasRating: boolean = true;

  constructor(
    private InspRating: RatingService,
    public dialogRef: MatDialogRef<InspectorRatingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.data['inspectorId'] = this.data.inspectorId;

    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    this.inspectorReview();
  }

  inspectorReview() {
    this.InspRating.getInspectorReview(this.data.inspectorId).subscribe(
      (data) => {
        this.inspectorReviewData = data.results;
        if (this.inspectorReviewData?.client_service_avg_rating == 0) {
          this.CustomerhasRating = false;
        }
        if (this.inspectorReviewData?.qc_inspector_avg_rating == 0) {
          this.QChasRating = false;
        }
      }
    );
  }
}
