import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersComponent } from './orders.component';
import { OrdersListComponent } from './pages/orders-list/orders-list.component';
import { ServicesOrdersListComponent } from './pages/services-orders-list/services-orders-list.component';
import { AdditionalAmountComponent } from './pages/additional-amount/additional-amount.component';
import { NewOrderComponent } from './pages/new-order/new-order.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { RefundComponent } from './pages/refund/refund.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { ServiceOrderDetailsComponent } from './pages/service-order-details/service-order-details.component';

const routes: Routes = [
  {
    path: '',
    component: OrdersComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'orders-list' },
      { path: 'orders-list', component: OrdersListComponent },
      { path: 'services-orders', component: ServicesOrdersListComponent },
      { path: 'additional-amount', component: AdditionalAmountComponent },
      { path: 'new-order', component: NewOrderComponent },
      { path: ':id', component: OrderDetailsComponent },
      { path: ':id/refund', component: RefundComponent },
      { path: ':id/purchase', component: PurchaseComponent },
      { path: 'service-order/:id', component: ServiceOrderDetailsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class OrdersRoutingModule {}
