<div class="dialog-container">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <div mat-dialog-content>
    <form [formGroup]="surveyFormGroup">
      <div class="form-group">
        <label for="" class="form-label custom-lable">
          مستوى تقديم الخدمة
        </label>
        <div class="stars" dir="rtl">
          @for (ratingId of ratingArr; track ratingId; let i = $index) {
            <button mat-icon-button [id]="'star_'+i"
              (click)="onClick(i+1 , 'inspector_rating')">
              <img dir="rtl" [src]="showIcon(i , 'inspector_rating')" alt="" />
            </button>
          }
        </div>
      </div>
      <div class="form-group">
        <div class="notes-section">
          <label for="" class="form-label"> اكتب رأيك بالفاحص <span class="sub-title">( يمكن للفاحص الاطلاع على
          التقييم)</span></label>
          <textarea class="form-control" name="notes" maxlength="500" formControlName="qc_notes"></textarea>
        </div>
      </div>
      @if (msgError.length > 0) {
        <div class="alert-box">
          <img src="assets/images/svg/error-icon.svg" class="close-alert" alt=""
            onclick="this.parentElement.style.display='none';" />
            <p class="alert-msg">{{msgError}}</p>
          </div>
        }
      </form>
    </div>
    <div mat-dialog-actions class="actions-double">
      <a class="btn btn-cancel center-btn" mat-dialog-close>إلغاء</a>
      <button class="btn btn-green center-btn" type="submit" (click)="addSurvey()">{{data.btnSubmit}}</button>
    </div>
  </div>

