import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailsService extends AYENServiceBase {

  constructor(private http: HttpClient) {
    super();
  }

  getDirections(): Observable<any> {
    return this.http.get(this.baseURL + '/neighbours-direction/').pipe(
      map((data: any) => {
        return data;
      }), catchError(this.handleError)
    );
  }

  getLicenceTypes(): Observable<any> {
    return this.http.get(this.baseURL + '/licence-types/').pipe(
      map((data: any) => {
        return data;
      }), catchError(this.handleError)
    );
  }


  getPropertyLocation(id): Observable<any> {
    return this.http.get(this.baseURL + '/admin-order-building-location-update/' + id).pipe(
      map((data: any) => {
        return data;
      }), catchError(this.handleError)
    );
  }

  postPropertyLocation(id, data): Observable<any> {
    return this.http.post(this.baseURL + '/admin-order-building-location-update/' + id + '/', data)
  }

  updateBuildingSpecifications(orderId, data): Observable<any> {
    return this.http.put(this.baseURL + `/admin-building-specifications/${orderId}/`, data);
  }

  updateBuildingDetails(orderId, data): Observable<any> {
    return this.http.put(this.baseURL + `/admin-order-building-info-update/${orderId}/`, data);
  }

  updateOtherFeatures(orderId, data): Observable<any> {
    return this.http.put(this.baseURL + `/admin-building-other-features/${orderId}/`, data)
  }

  getInfrastrucutreAndServices(id): Observable<any> {
    return this.http.get(
      this.baseURL + `/admin-infrastructure-and-services/${id}`
    );
  }

  putInfrastrucutreAndServices(id, data): Observable<any> {
    return this.http.put(
      this.baseURL + `/admin-infrastructure-and-services/${id}/`,
      data
    );
  }

  handleError(error) {
    let errorMessage;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
