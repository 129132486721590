import { Component, ElementRef, Input, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox-btn',
  templateUrl: './checkbox-btn.component.html',
  styleUrls: ['./checkbox-btn.component.scss']
})
export class CheckboxBtnComponent implements OnInit {

  @Input() subForm: UntypedFormGroup;
  @Input() inputs;
  checkboxValues = [];
  isRequired: boolean = false;
  @ViewChildren('checkbox') checkbox: ElementRef<HTMLInputElement>[];

  ngOnInit(): void {
    this.isRequired = this.inputs.validation.required;
  }

  ngAfterViewInit() {
    let checbox_value = this.subForm.get(this.inputs.field_key_name).value;
    if (checbox_value?.length > 0) {
      checbox_value.forEach(check => {
        this.inputs.choices.forEach(element => {
          if (check == element.choice_value) {
            let checked = this.checkbox.find(item => item.nativeElement.name == element.choice_value);
            checked.nativeElement.checked = true;
          }
        });
      });
    }
  }

  change(event) {
    let form_value = [];
    form_value = this.subForm.get(this.inputs.field_key_name).value;
    if (form_value) {
      form_value.forEach(element => {
        if (!this.checkboxValues.includes(element))
          this.checkboxValues.push(element);
      });
    }

    if (event.target.checked) {
      this.checkboxValues.push(event.target.value)
    }
    else {
      this.checkboxValues.splice(this.checkboxValues.indexOf(event.target.value), 1);
    }
    this.subForm.get(this.inputs.field_key_name).setValue(this.checkboxValues)
  }
}
