<div class="sidenav">
  <p class="sidenav-title">القائمة</p>
  <ul class="nav flex-column">
    @for (menu of sidebarMenu; track menu) {
      @if (!menu.isSubMenu) {
        <li class="nav-item "
          [ngClass]="{'active' : sidebarstatus === menu.sidebarstatus}">
          <a class="nav-link"  (click)="clickItem(menu.sidebarstatus,menu.pageTile, menu.url, true)">
            <div class="nav-icon">
              <img [src]="menu?.imgIcon" alt="">
            </div>
            <span>{{menu.sideItem}}</span>
          </a>
        </li>
      } @else {
        <li class="sub-menu" >
          <a class="nav-link sub-link" (click)="toggleSubMenu(menu.sidebarstatus)">
            <div class="nav-icon">
              <img [src]="menu.imgIcon" alt="">
            </div>
            <span>{{menu.sideItem}}</span>
          </a>
          @if (dropdowns[menu.sidebarstatus]) {
            <ul>
              @for (subMenu of menu.subMenuItems; track subMenu) {
                <li class="nav-item  sub-items"
                  [ngClass]="{'active' : sidebarstatus === subMenu.sidebarstatus}">
                  <a class="nav-link" #elements (click)="clickItem(subMenu.sidebarstatus, subMenu.pageTile, subMenu.url, false)">
                    <span> {{subMenu.item}} </span>
                  </a>
                </li>
              }
            </ul>
          }
        </li>
      }
      <ng-template #subMenu>
        <li class="sub-menu" >
          <a class="nav-link sub-link" (click)="toggleSubMenu(menu.sidebarstatus)">
            <div class="nav-icon">
              <img [src]="menu.imgIcon" alt="">
            </div>
            <span>{{menu.sideItem}}</span>
          </a>
          @if (dropdowns[menu.sidebarstatus]) {
            <ul>
              @for (subMenu of menu.subMenuItems; track subMenu) {
                <li class="nav-item  sub-items"
                  [ngClass]="{'active' : sidebarstatus === subMenu.sidebarstatus}">
                  <a class="nav-link" #elements (click)="clickItem(subMenu.sidebarstatus, subMenu.pageTile, subMenu.url, false)">
                    <span> {{subMenu.item}} </span>
                  </a>
                </li>
              }
            </ul>
          }
        </li>
      </ng-template>
    }
  </ul>
</div>
