export class ExportExcel {
  public static processCells(params: any) {
    let values = params.value;
    let cellRenderer = params.api.getColumnDef(
      params.column.colId
    ).cellRenderer;
    if (values !== null && cellRenderer) {
      let data = params.column.userProvidedColDef.cellEditorParams?.values;
      if (data && data.length > 0) {
        if (
          params?.value &&
          typeof params?.value != 'string' &&
          params?.value.length > 0
        ) {
          values = data.filter((elem) => params.value.includes(elem.pk));
          let mappedData = values.map(function (item) {
            return item['name'];
          });
          values = mappedData;
        } else {
          data.forEach((item) => {
            if (item?.pk == params.value || item?.id == params.value) {
              values = item.name;
            }
          });
        }
      }
    }
    return values;
  }
  public static exportExcel(scope) {
    let params = { ...scope.filterParamsUrl };
    params['page'] = scope.config.currentPage;
    if (scope.searchValue) {
      params['query'] = scope.searchValue;
    }
    scope.download = true;
    scope.getAllData(params);
  }
  public static exportGridCSV(scope, fileName) {
    let params = {
      fileName: fileName,
      processCellCallback: this.processCells,
    };
    scope.gridExcelApi.exportDataAsCsv(params);
  }
}
