@if (params.value) {
  <img
    alt=""
    (click)="showAvatarModal()"
    [id]="imgId"
    src="assets/images/svg/image-icon.svg"
    />
} @else {
  <div class="drag-btn">
    <button
      type="button"
      class="btn choose-file"
      style="justify-content: start">
      <img src="assets/images/svg/upload-icon-admin.svg" alt="" />
      اختيار صورة
    </button>
    <input
      type="file"
      [id]="inputId"
      name=""
      accept="image/*"
      (change)="uploadFile($event)"
      class="file-new form-control"/>
    </div>
  }
