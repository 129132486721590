import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { Order } from 'src/app/models/interfaces/order';
import { Select } from '@ngxs/store';
import { OrderState } from '../../state/order.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss'],
})
export class OrderInfoComponent implements OnInit {
  orderId: string;
  @Output() errorMsg = new EventEmitter();
  address: string = '';
  public orderType: any;
  public orderStatus: any = OrderStatus;
  @Output() isTest = new EventEmitter();
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;
  orderData: Order;

  constructor() {}

  ngOnInit(): void {
    this.getAdminOrder();
  }

  getAdminOrder() {
    this.orderDetails$.subscribe(
      (data) => {
        this.orderData = data;
        this.address = data.address_unit_code
          ? this.orderData.address_unit_code + ' , '
          : '';
        this.address += this.orderData.address_building_code
          ? this.orderData.address_building_code + ' , '
          : '';
        this.address += this.orderData.address_street
          ? this.orderData.address_street + ' , '
          : '';
        this.address += this.orderData.address_neighborhood
          ? this.orderData.address_neighborhood + ' , '
          : '';
        this.address += this.orderData.google_address_city
          ? this.orderData.google_address_city + ' , '
          : '';
        this.address += this.orderData.google_address_area
          ? this.orderData.google_address_area + ' , '
          : '';
        this.address += this.orderData.google_address_country
          ? this.orderData.google_address_country
          : '';
      },
      (error) => {
        try {
          console.log('order not found' + error);
        } catch (err) {
          console.log('order not found' + error);
        }
      }
    );
  }

  copyAddress() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.orderData.location;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  showModal(event) {
    this.errorMsg.emit({
      message: event.message,
      imgSuccess: event.imgSuccess,
    });
  }
}
