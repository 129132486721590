import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  private currentApiStatus: BehaviorSubject<Boolean>;
  obsCurrentApiStatus: Observable<Boolean>;

  constructor(private httpClient: HttpClient) {
    this.currentApiStatus =  new BehaviorSubject(new Boolean(false));
    this.obsCurrentApiStatus = this.currentApiStatus.asObservable();

  }
  loadMapScript() {
    return this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.Google_API_KEY}&libraries=places&language=ar&region=SA`, 'callback')
    .pipe(
      map(() => true),
      catchError(() => of(false)),
    )
  }
  
  checkScriptLoaded() {
    this.loadMapScript().subscribe( loaded => {
      this.currentApiStatus.next(loaded);
    });
  }
}
