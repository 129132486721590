import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltersService } from 'src/app/core/services/filters/filters.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { FilterUtilService } from 'src/app/core/services/uitls/filter-utilizer/filter-util.service';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { GridDefinitions } from 'src/app/shared/global/grid-definition';
import { frameworkComponents } from '../../framework-components';
import { AddServiceComponent } from 'src/app/features/inspectors/components/add-service/add-service.component';
import { AvatarModelComponent } from 'src/app/shared/components/avatar-model/avatar-model.component';
import { ApplySortingService } from 'src/app/core/services/uitls/sorting-util/apply-sorting.service';
import { ReplaySubject } from 'rxjs';
import { InspectorGridService } from '../../services/inspectors-grid/inspectors-grid.service';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';
import { QualificationService } from '../../services/qualification/qualification.service';
import { inspectorGridColumns } from '../../inspector-grid-columns';
import { ExportExcel } from 'src/app/core/services/uitls/exportExcel';
import { RegisterAsInspector } from 'src/app/models/enums/register-as.enum';
@Component({
  selector: 'app-inspector-grid',
  templateUrl: './inspector-grid.component.html',
  styleUrls: ['./inspector-grid.component.scss'],
})
export class InspectorGridComponent implements OnInit {
  public gridApi;
  public gridColumnApi;
  public columnDefs;
  public rowData;
  public frameworkComponents;
  public defaultColDef;
  public rowSelection;
  public search;
  inspectorId: any;
  deleteInspectorIndex: any;
  public rowDataTracker = {};
  public deleteMsg: string = 'هل أنت متأكد من حذف هذا الفاحص؟';
  public confirmMsg: string = '';
  public imgSuccess: boolean = true;
  config: {
    id: string;
    itemsPerPage: number;
    currentPage: any;
    totalItems: any;
  };
  page: number = 1;
  totalRecords: number = 0;
  paginationPageSize: number;
  cellUpdated: any;
  getRowId: any;
  services: any;
  disabledDelete: boolean = true;
  disableActionProfile: boolean = true;
  disabledAddService: boolean = true;
  inspectorIDs: any;
  inspectors: any;
  filterData;
  filterParamsUrl: any = {};
  filterDataInit: any = {};
  filterPage: string = 'INSPECTORS';
  pageTitle: string = 'الفاحصين';
  savedFilters: any = [];
  accountCategory: any;
  qualified: any = '';
  actionList: any[] = [];
  commandtype: string = '';
  commandName: string = 'اختر أمر للتنفيذ';
  disableActionBtn: boolean = true;
  disableAction: boolean = true;
  btn_text = 'تم';
  disableSelect: boolean = false;
  selectedRows = [];
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  ServiceDialogRef: MatDialogRef<AddServiceComponent>;
  avatarDialogRef: MatDialogRef<AvatarModelComponent>;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  gridExcelApi: any;
  excelData: any[] = [];
  download: boolean = false;
  totalRowsSelected: boolean = false;
  currentItemsPerPage: any;
  registerAsInspector = RegisterAsInspector;
  constructor(
    private inspectorGridService: InspectorGridService,
    private inspectorsService: InspectorsService,
    private qualificationService: QualificationService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private filterUtil: FilterUtilService,
    private filter: FiltersService,
    private applySortingService: ApplySortingService
  ) {}

  ngOnInit(): void {
    this.initGrid();
    this.getInspectorsFilter();
    this.route.params.subscribe((params) => {
      this.qualified = params.qualified;
    });
    if (this.qualified == 'qualified') {
      this.getQualifierActions();
    }
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        this.search = params['query'] ? params['query'] : '';
        this.filterParamsUrl = params;
        this.filterDataInit = params;
      }
    });
    this.filterUtil.getSavedFilters(this, this.filterPage);
    this.applySortingService.currentSortCol.subscribe((data) => {
      if (data.length > 0) {
        this.getInspectorsByPage(data);
      }
    });
  }
  initGrid() {
    const gridDef = new inspectorGridColumns(
      this.route,
      this,
      this.inspectorGridService
    );
    this.columnDefs = gridDef.COLUMN_DEF;
    this.frameworkComponents = frameworkComponents.FRAME_WORK_COMPONENTS;
    this.defaultColDef = GridDefinitions.DEFAULT_GRID_COLS_DEFINITIONS;
    this.config = {
      id: 'myPagination',
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
    };
    this.rowSelection = 'multiple';
    this.paginationPageSize = 20;
    this.getRowId = function (data) {
      return data.data.id;
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.filter.changeSelectedFilter(this.filterDataInit);
    this.filterUtil.applySelectedFilter(this, this.getInspectorsByPage);
  }
  excelGridReady(params) {
    this.gridExcelApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getInspectorsFilter() {
    let params = {
      filter_page: this.filterPage,
    };
    this.filter.getFiltersData(params).subscribe(
      (data) => {
        this.filterData = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }
  filterFormValues(event) {
    this.filterParamsUrl = event.filterParamsUrl;
    this.getInspectorsByPage();
  }

  quickSearch(event) {
    this.search = event;
    this.config.currentPage = 1;
    this.getInspectorsByPage();
  }

  getInspectorsByPage(ordering?) {
    this.commandName = 'اختر أمر للتنفيذ';
    this.selectedRows = [];
    this.totalRowsSelected = false;
    this.disableActionBtn = true;
    let params = { ...this.filterParamsUrl };
    params['page'] = this.config.currentPage;
    params['page_size'] = this.config.itemsPerPage;
    if (this.search) {;
      params['query'] = this.search;
    }
    if (ordering) {
      params['ordering'] = ordering;
    }
    params['qualified'] = this.qualified == 'qualified' ? false : true;
    this.inspectorGridService.getInspectorsByPage(params).subscribe(
      (data) => {
        this.page = this.config.currentPage;
        this.inspectors = data.results;
        this.rowData = data.results;
        this.config['totalItems'] = data.count;
        this.currentItemsPerPage = data.results.length;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: this.filterParamsUrl,
          queryParamsHandling: '',
        });
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  delete(event) {
    let inspectorId = event.id;
    let index = event.index;
    this.inspectorsService.deleteInspector(inspectorId).subscribe(
      (data) => {
        this.rowData.splice(index, 1);
        this.gridApi.setRowData(this.rowData);
      },
      (error) => {
        this.imgSuccess = false;
        this.confirmMsg =
          'عذرًا لا يمكن حذف هذا الفاحص لوجود طلبات متعلقة بحسابه.';
        this.showAlertBox();
      }
    );
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.imgSuccess,
        message: this.confirmMsg,
        button: this.btn_text,
      },
    });
  }

  onRowSelected(event) {
    this.disableSelect = false;
    this.disabledDelete = false;
    this.disabledAddService = false;
    this.totalRowsSelected = false;
    this.selectedRows = this.gridApi.getSelectedRows();
    this.inspectorId = this.selectedRows[0]?.id;
    this.deleteInspectorIndex = this.rowData.indexOf(this.selectedRows[0]);
    this.disableAction = this.selectedRows.length > 0 ? false : true;
    this.disableActionProfile = this.selectedRows.length == 1 ? false : true;
    if (this.selectedRows.length == 0) {
      this.disabledDelete = true;
    }
    if (this.selectedRows.length == 0 && this.commandtype != '') {
      this.disableActionBtn = true;
      this.commandName = 'اختر أمر للتنفيذ';
      this.commandtype = '';
    }
    if (this.selectedRows.length > 0 && this.commandtype != '') {
      this.disableActionBtn = false;
    }
    if (this.selectedRows.length > 0 && this.commandtype == '') {
      this.disableActionBtn = true;
    }
    this.selectedRows.forEach((element) => {
      if (element.account_info !== 'A') {
        this.disableSelect = true;
      }
    });
  }

  selectTotalRows(event) {
    this.totalRowsSelected = event;
  }

  addService() {
    this.inspectorIDs = this.gridApi.getSelectedNodes().map((node) => node.id);
    this.ServiceDialogRef = this.dialog.open(AddServiceComponent, {});
    this.ServiceDialogRef.afterClosed().subscribe((result) => {
      if (result?.service) {
        this.refreshGrid(result);
      }
    });
  }

  refreshGrid(event) {
    let services = [];
    services.push(event.service);
    for (let inspectorID of this.inspectorIDs) {
      let newNode = this.gridApi.getRowNode(inspectorID);
      newNode.setDataValue('services', services);
    }
  }

  onCellValueChanged(event) {
    let ValueChanged = {};
    let inspectorID = event.data.id;
    let key = event.colDef.field;
    if (
      key.indexOf('_file') > -1 ||
      key.indexOf('overall_rating') > -1 ||
      (event.oldValue && event.newValue === 0)
    )
      return;

    if (
      event.newValue &&
      event.oldValue &&
      event.oldValue.toString() !== event.newValue.toString()
    ) {
      this.storeEdit(
        event.rowIndex,
        event.colDef.field,
        event.oldValue,
        event.newValue
      );
    }
    let newVal = event.newValue;
    ValueChanged[key] = newVal;
    this.save(inspectorID, ValueChanged, event);
  }

  resetColunm(inspectorID, event) {
    if(event.column.colId === 'registration_in_ayen_as' && event.newValue !== this.registerAsInspector.facility) {
      let newNode = this.gridApi.getRowNode(inspectorID);
      newNode.setDataValue('facility', null);
    }
  }

  save(inspectorID, ValueChanged, event) {
    this.inspectorGridService
      .updateInspectorsData(inspectorID, ValueChanged)
      .subscribe(
        (data) => {
          this.resetColunm(inspectorID, event);
          if (
            this.qualified == 'qualified' ||
            ValueChanged.qualification_status ||  ValueChanged.tbc_qualification_status
          ) {
            this.getInspectorsByPage();
          }
        },
        (error) => {
          this.cancelEdited(error);
        }
      );
  }

  storeEdit(rowId, columnId, oldValue, newValue) {
    if (!this.rowDataTracker[rowId]) {
      this.rowDataTracker[rowId] = {};
    }
    this.rowDataTracker[rowId][columnId] = [oldValue, newValue];
  }

  cancelEdited(error) {
    let that = this;
    this.imgSuccess = false;
    this.confirmMsg = error.error;
    this.gridApi.forEachNode(function (node) {
      if (Object.keys(that.rowDataTracker).indexOf('' + node.rowIndex) > -1) {
        that.showAlertBox();
        let data = node.data;
        Object.keys(that.rowDataTracker[node.rowIndex]).forEach(function (key) {
          data[key] = that.rowDataTracker[node.rowIndex][key][0];
        });
        node.updateData(data);
      }
    });
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.selectedRows = [];
    this.totalRowsSelected = false;
    this.disableAction = true;
    this.getInspectorsByPage();
  }
  pageSizeChanged(event) {
    this.config.itemsPerPage = event;
    this.getInspectorsByPage();

  }
  getQualifierActions() {
    this.qualificationService.listQualifiersActions().subscribe(
      (data) => {
        this.actionList = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }
  getcommandType(type, name) {
    this.commandtype = type;
    this.commandName = name;
    let selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      this.disableActionBtn = false;
    }
  }
  applyCommandAction() {
    let inspectors = [];
    let selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      for (let selectedRow of selectedRows) {
        inspectors.push(selectedRow.id);
      }
    }
    let inspectorsObj = {
      inspectors,
    };
    this.qualificationService
      .ApplyQualifiersActions(this.commandtype, inspectorsObj)
      .subscribe(
        (data) => {
          this.imgSuccess = true;
          this.confirmMsg = 'تم ' + this.commandName + ' بنجاح.';
          this.getInspectorsByPage();
          this.showAlertBox();
          this.commandName = 'اختر أمر للتنفيذ';
          this.disableActionBtn = true;
        },
        (error) => {
          this.imgSuccess = false;
          this.handleBackendError(error);
        }
      );
  }

  goProfile() {
    let selectedRow = this.gridApi.getSelectedRows()[0];
    this.router.navigateByUrl(
      `/pages/inspectors/inspector-profile/${selectedRow.id}`
    );
  }

  handleBackendError(error) {
    try {
    } catch {}
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.filter.changeSelectedFilter({});
  }

  handelExportExcel() {
    ExportExcel.exportExcel(this);
  }

  getAllData(params) {
    params['qualified'] = this.qualified == 'qualified' ? false : true;
    this.inspectorGridService.getAllInspectors(params).subscribe(
      (data) => {
        this.excelData = data;
        this.gridExcelApi.setRowData(this.excelData);
        ExportExcel.exportGridCSV(this, 'الفاحصيين');
        this.download = false;
      },
      (error) => {
        try {
          this.download = false;
        } catch (error) {}
      }
    );
  }
}
