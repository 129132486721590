<div class="dialog-container">
  <div mat-dialog-title><h5>تحميل ملف</h5></div>
  <div mat-dialog-content>
    <p clas="fileName">
      اسم الملف: <span>{{ fileName }}</span>
    </p>
    <p clas="fileSize">
      حجم الملف : <span>{{ fileSize }}</span>
    </p>
    @if (discardUpload) {
      <p style="color: #d9727f">{{ message }}</p>
    }
    <div class="progress">
      <div
        class="progress-bar progress-bar-info progress-bar-striped"
        role="progressbar"
        attr.aria-valuenow="{{ progress }}"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{ width: progress + '%' }">
        {{ progress }}%
      </div>
    </div>
  </div>
  <div class="actions-double" mat-dialog-actions>
    <a class="btn btn-cancel" (click)="deleteFile()" mat-dialog-close>إلغاء</a>
    <button
      class="btn btn-confirm"
      [disabled]="disable"
      (click)="finishUpload(true)">
      موافق
    </button>
  </div>
</div>
