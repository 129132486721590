import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BuildingTypeService } from 'src/app/core/services/buildingType/building-type.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddClientComponent } from 'src/app/shared/components/add-client/add-client.component';
import { DynamicServices } from 'src/app/features/ayen-services/services/dynamic-services/dynamic-services.service';
import { OrderType } from 'src/app/models/enums/order-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { BuildingType } from 'src/app/models/enums/building-type.enum';
import { AyenServices } from 'src/app/core/services/ayen-services/ayen-services.service';
import { ClientsService } from 'src/app/features/customers/services/clients.service';
import { ProjectsService } from 'src/app/features/projects/services/projects.service';
import { AdminOrdersService } from '../../../services/orders/admin-orders.service';
import { ScriptService } from 'src/app/core/services/script/script.service';
import { AyenMapComponent } from 'ayen-google-map';

@Component({
  selector: 'app-build-details',
  templateUrl: './build-details.component.html',
  styleUrls: ['./build-details.component.scss'],
})
export class BuildDetailsComponent implements OnInit {
  @Output() building_view = new EventEmitter();
  orderId;
  order;
  buildingDetailsFormGroup: UntypedFormGroup;
  clients: any[] = [];
  buildingTypes;
  latitude: number = 23.8859;
  longitude: number = 45.0792;
  google_address_country;
  google_address_area;
  google_address_city;
  @ViewChild('client') client: NgSelectComponent;
  @ViewChild('buildingUnit') buildingUnit: NgSelectComponent; 
  @ViewChild('orderTypeField') orderTypeField: NgSelectComponent;
  showAlertMsg: boolean = false;
  uploading?: boolean = false;
  is_test: boolean = false;
  params = '?page=';
  page: number = 1;
  page_count: number = 0;
  searchInput: any;
  timeout: any = null;
  projects: any = [];
  buildingType = BuildingType;
  @ViewChild('ayenMap') ayenMap: AyenMapComponent;
  private onScroll = (event: any) => {
    if (this.client?.isOpen || this.buildingUnit?.isOpen || this.orderTypeField?.isOpen) {
      const isScrollingInScrollHost =
        (event.target.className as string).indexOf('ng-dropdown-panel-items') >
        -1;
      if (isScrollingInScrollHost) {
        return;
      }
      this.client.close();
      this.buildingUnit.close();
      this.orderTypeField.close();
    }
  };

  public clientDialogRef: MatDialogRef<AddClientComponent>;
  pageCount: any;
  projectsList = [];
  servicesList = [];
  selectedContract: any;
  orderType = OrderType;
  orderTypes: any;
  services: any;
  servicesPageCount: any;
  selectedService: any;
  alertMessage: string = 'برجاء ادخال الحقول المطلوبة';
  apiLoaded!: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private buildingTypeService: BuildingTypeService,
    private clientsService: ClientsService,
    private ayenServices: AyenServices,
    private orderService: AdminOrdersService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private dynamicServices: DynamicServices,
    private projectService: ProjectsService,
    private GoogleMapLoader: ScriptService
  ) {}

  ngOnInit(): void {
    this.GoogleMapLoader.obsCurrentApiStatus.subscribe((status) => {
      this.apiLoaded = status.valueOf();
    });
    this.createForm();
    this.createFormControls();
    this.clients = [];
    this.getBuildingType();
    this.getBasicServices();
    this.route.queryParams.subscribe((params) => {
      this.orderId = +params.id;
      if (this.orderId) {
        this.getAdminOrder();
      }
    });
    window.addEventListener('scroll', this.onScroll, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  getAdminOrder() {
    this.orderService.getAdminOrder(this.orderId).subscribe(
      (data) => {
        this.order = data;
        let loc = this.order.location.split(',');
        this.longitude = +loc[1];
        this.latitude = +loc[0];
        this.ayenMap.reCenterMap(this.latitude, this.longitude);
        this.is_test = this.order.is_test;
        this.patchValues();
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  patchValues() {
    if (this.order?.order_details?.order_client_object?.id) {
      let obj = {
        id: this.order.order_details.order_client_object.id,
        full_name: this.order.order_details.order_client_object.name,
      };
      this.clients = [];
      this.clients.push(obj);
    }
    if (this.order.contract?.pk) {
      this.projectsList = [];
      let contractObj = {
        id: this.order.contract.pk,
        name: this.order.contract.name,
      };
      this.projectsList.push(contractObj);
      this.selectedContract = this.order.contract.pk;
    }
    if (this.order.dynamic_service) {
      this.servicesList = [];
      let contractObj = {
        id: this.order.order_details.dynamic_service.id,
        name: this.order.order_details.dynamic_service.name,
      };
      this.servicesList.push(contractObj);
      this.selectedService = this.order.dynamic_service;
    }
    this.changeOrderType(this.order.order_type);
    this.buildingDetailsFormGroup.patchValue({
      reference_number:
        this.order.reference_number === 'null'
          ? ' '
          : this.order.reference_number,
      order_client: this.order.order_details.order_client_object
        ? this.order.order_details.order_client_object.id
        : null,
      building_type: this.order.building_type,
      building_status: this.order.building_status,
      inspector_academic_major: this.order.inspector_academic_major,
      custom_property_type: this.order.building_data?.custom_property_type,
      initial_space: this.order.initial_space,
      contract: this.order.contract?.pk,
      is_test: this.order.is_test,
      allow_order_notifications: this.order.allow_order_notifications,
      address_neighborhood:
        this.order.address_neighborhood === 'null'
          ? ' '
          : this.order.address_neighborhood,
      address_building_code:
        this.order.address_building_code === 'null'
          ? ' '
          : this.order.address_building_code,
      address_unit_code:
        this.order.address_unit_code === 'null'
          ? ' '
          : this.order.address_unit_code,
      order_type: this.order.order_type,
      dynamic_service: this.order.dynamic_service,
      has_attached_building: this.order.has_attached_building,
      floors_number: this.order.floors_number,
    });
  }

  searchtext() {
    this.searchInput = this.client.searchTerm;
    this.params = '?full_name=';
    this.params = this.params + this.searchInput;
    this.params = this.params + '&ordering= full_name';
    this.clients = [];
    clearTimeout(this.timeout);
    var $this = this;
    $this.timeout = setTimeout(() => {
      if ($this.searchInput != '') {
        $this.getClients(this.params);
      } else {
        this.params = '?page=';
        $this.getClients(this.params + '1');
      }
    }, 600);
  }

  resetSearch() {
    this.clients = [];
    this.params = '?page=';
    this.getClients(this.params + '1');
  }

  onScrollPagination() {
    if (this.page < this.page_count) {
      this.page++;
      this.params = this.params + this.page;
      this.getClients(this.params);
      this.params = '?page=';
    }
  }

  getProjectsByParams(params) {
    if (params.clearList) {
      this.projects = [];
      this.setProjectData(null);
    }
    this.getProjects(params);
  }

  setProjectData(project) {
    this.buildingDetailsFormGroup.get('contract').setValue(project?.pk);
  }

  setServiceData(service) {
    this.buildingDetailsFormGroup.get('dynamic_service').setValue(service?.id);
  }

  createFormControls() {
    return this.buildingDetailsFormGroup.controls;
  }

  createForm() {
    this.buildingDetailsFormGroup = this.formBuilder.group({
      reference_number: [null],
      order_client: new UntypedFormControl(null, Validators.required),
      building_type: [null, Validators.required],
      custom_property_type: [null],
      initial_space: [null],
      contract: [null],
      is_test: [false, Validators.required],
      allow_order_notifications: [true],
      address_neighborhood: [null, Validators.required],
      address_building_code: [null],
      address_unit_code: [null],
      order_type: [null, Validators.required],
      dynamic_service: [null],
      has_attached_building: [false],
      floors_number: [null],
    });
  }

  changeOrderType(event) {
    if (event === this.orderType.DynamicServices) {
      this.setRequiredValidatorVal('dynamic_service');
    } else {
      this.changeFormControlValidators('dynamic_service');
    }
  }

  setRequiredValidatorVal(formControl) {
    this.buildingDetailsFormGroup.controls[formControl].setValidators(
      Validators.required
    );
    this.buildingDetailsFormGroup.controls[
      formControl
    ].updateValueAndValidity();
  }

  changeFormControlValidators(formControl) {
    this.buildingDetailsFormGroup.controls[formControl].clearValidators();
    this.buildingDetailsFormGroup.controls[
      formControl
    ].updateValueAndValidity();
  }

  getBasicServices() {
    this.ayenServices.getAyenServices().subscribe(
      (data) => {
        this.orderTypes = data;
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  getDynamicServices(params) {
    let servicesParams = { ...params.params };

    if (params.clearList) {
      this.services = [];
      this.setServiceData(null);
    }

    let contract = this.buildingDetailsFormGroup.get('contract').value;
    if (contract) {
      servicesParams['contract'] = contract;
    }

    this.dynamicServices.getServices(servicesParams).subscribe(
      (data) => {
        this.services.push(...data.results);
        this.servicesList = [...this.services];
        this.servicesPageCount = data.pages_count;
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  buildingTypeChanged(buildingType) {
    if (buildingType === this.buildingType.Other) {
      this.setRequiredValidatorVal('custom_property_type');
    } else {
      this.changeFormControlValidators('custom_property_type');
    }
  }

  getBuildingType() {
    this.buildingTypeService.getBuildingTypes().subscribe(
      (data) => {
        this.buildingTypes = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  getClients(page) {
    this.clientsService.getAllClients(page).subscribe(
      (data) => {
        this.clients.push(...data.results);
        this.page_count = data.pages_count;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  getProjects(params) {
    this.projectService.getProjects(params.params).subscribe(
      (data) => {
        this.projects.push(...data.results);
        this.projectsList = [...this.projects];
        this.pageCount = data.pages_count;
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  save() {
    if (this.buildingDetailsFormGroup.status == 'INVALID') {
      this.showAlertMsg = true;
      let fields = document.querySelectorAll(
        'ng-select.ng-invalid , input.ng-invalid '
      );
      fields.forEach((field) => {
        field.parentElement.classList.add('invalid-field');
      });

      return;
    }
    this.uploading = true;
    let orderUpdated = {};
    orderUpdated = this.buildingDetailsFormGroup.value;
    orderUpdated['google_address_city'] = this.google_address_city;
    orderUpdated['google_address_area'] = this.google_address_area;
    orderUpdated['google_address_country'] = this.google_address_country;
    orderUpdated['location'] = this.latitude + ',' + this.longitude;
    if (this.orderId) {
      this.updateOrder(orderUpdated);
    } else {
      this.createNewOrder(orderUpdated);
    }
  }

  createNewOrder(order) {
    this.orderService.createNewOrder(order).subscribe(
      (data) => {
        this.addOrder(data.id);
      },
      (error) => {
        try {
          this.showAlertMsg = true;
          this.alertMessage = Object.values(error.error)[0].toString();
        } catch {}
      }
    );
  }

  addOrder(id) {
    let params = {
      id: id,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: '',
    });
    this.uploading = false;
    this.building_view.emit({ view: 'inspectoring', id: id });
  }

  updateOrder(order) {
    this.orderService.updateOrder(this.orderId, order).subscribe(
      (data) => {
        this.addOrder(data.id);
      },
      (error) => {
        try {
          this.showAlertMsg = true;
          this.alertMessage = Object.values(error.error)[0].toString();
        } catch {}
      }
    );
  }

  handleBackendError(error) {
    try {
    } catch {}
  }

  addClient() {
    this.clientDialogRef = this.dialog.open(AddClientComponent, {});
    this.clientDialogRef.afterClosed().subscribe((result) => {
      if (result?.id) {
        this.addNewClient(result);
      }
    });
  }

  addNewClient(event) {
    this.getClients(this.params + '1');
    setTimeout(() => {
      this.buildingDetailsFormGroup.patchValue({
        order_client: event?.id,
      });
    }, 200);
  }

  mapValues(event) {
    this.google_address_city = event.city ? event.city : '';
    this.google_address_country = event.country;
    this.google_address_area = event.state;
    this.latitude = event.latitude;
    this.longitude = event.longitude;
  }

  codeValueChanged(event) {
    this.buildingDetailsFormGroup.get(event.labelEn).setValue(event.value);
  }

  hideErrorMsg() {
    this.showAlertMsg = false;
  }
}
