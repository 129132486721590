import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';
@Injectable({
  providedIn: 'root',
})
export class CompanyClientService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }
  getOwners(params): Observable<any> {
    return this.http.get<any>(this.baseURL + '/admin/company-admins/', {
      params: params,
    });
  }

  createOwner(data): Observable<any> {
    return this.http.post<any>(this.baseURL + '/admin/company-admins/', data);
  }

  getMembers(): Observable<any> {
    return this.http.get<any>(this.baseURL + '/admin/company-members/');
  }

  createMember(data): Observable<any> {
    return this.http.post<any>(this.baseURL + '/admin/company-members/', data);
  }
}
