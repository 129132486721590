import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AYENServiceBase } from '../base/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AyenServices extends AYENServiceBase{

  constructor(private http: HttpClient) {
    super();
  }

  getAyenServices(): Observable<any> {
    return this.http.get(this.baseURL + '/inspector-services/');
  }
}
