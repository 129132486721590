import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { DeleteService, GetNewServiceList } from '../../state/service-action';
import { NewServicesState } from '../../state/ayen-service.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
})
export class ServicesListComponent implements OnInit {
  disabledDelete: boolean = true;
  config: any;
  page: number = 1;
  totalRecords: any = 0;
  services: any;
  searchValue: any;
  showSpinner: boolean = true;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  @Select(NewServicesState.getNewServicesList) serviceList$: Observable<any>;
  payload: { page: number; query: string; page_size: number };
  currentItemsPerPage: number;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private store: Store
  ) {
    this.config = {
      id: 'dynamicServicePagination',
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
    };
  }

  ngOnInit(): void {
    this.getDynamicServices();
  }

  getDynamicServices() {
    this.showSpinner = true;
    this.payload = {
      page: this.config.currentPage,
      query: '',
      page_size: this.config.itemsPerPage,
    };
    if (this.searchValue) {
      this.payload['query'] = this.searchValue;
    }
    this.serviceList$.subscribe(
      (data) => {
        if (data && data['results']) {
          this.showSpinner = false;
          this.services = data.results;
          this.config['totalItems'] = data.count;
          this.currentItemsPerPage = data.results.length;
        } else {
          this.store.dispatch(new GetNewServiceList(this.payload));
        }
      },
      (error) => {
        try {
          this.showSpinner = false;
        } catch {}
      }
    );
  }

  showDeleteConfirmationBox(id, index, name) {
    this.deleteDialogRef = this.dialog.open(ConfirmationBoxComponent, {
      data: {
        id: id,
        index: index,
        message: `هل أنت متأكد من حذف خدمة "${name}"؟`,
      },
    });
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteService(result);
      }
    });
  }

  deleteService(event) {
    this.store.dispatch(new DeleteService(event.id));
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.payload['page'] = event;
    this.store.dispatch(new GetNewServiceList(this.payload));
  }
  pageSizeChanged(event) {
    this.config.itemsPerPage = event;
    this.payload['page_size'] = event;
    this.store.dispatch(new GetNewServiceList(this.payload));
  }
  quickSearch(event) {
    this.searchValue = event;
    this.config.currentPage = 1;
    this.payload = {
      page: this.config.currentPage,
      query: this.searchValue,
      page_size: this.config.itemsPerPage,
    };
    this.store.dispatch(new GetNewServiceList(this.payload));
  }

  goServiceDetails(id) {
    this.router.navigate([`pages/services/edit/${id}`]);
  }
  goServiceSettings(id) {
    this.router.navigate([`pages/services/settings/${id}`]);
  }
}
