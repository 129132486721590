import { Component, Input, OnInit, Output , EventEmitter} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceGlobalValueService } from 'src/app/core/services/service-engine/service-global-value/service-global-value.service';
import { ServiceQuatationsService } from 'src/app/core/services/service-engine/service-quatations/service-quatations.service';
/**
 * declare component metadata
 */
@Component({
  selector: 'app-service-quatation-info',
  templateUrl: './service-quatation-info.component.html',
  styleUrls: ['./service-quatation-info.component.scss'],
})
export class ServiceQuatationInfoComponent implements OnInit {
  /**
   * declare variable for selected quatation
   */
  selectedquatation = [];
  /**
   * declare variable for quatation options
   */
  quatations: any = [];
  /**
   * declare variable for order id
   */
  orderId: string;
  /**
   * declare variable for quotationData
   */
  @Input() quotationData;
  @Input() orderStatus;
  @Output() updateQuotationData = new EventEmitter();
  providers: any;
  facilities =[];
   /**
   * declare component constructor
   */
  constructor(
    private serviceQuatations: ServiceQuatationsService,
    private activatedRoute: ActivatedRoute,
    private globalServiceStatus: ServiceGlobalValueService
  ) {}
  /**
   * declare life cycle hook on initialize component
   */
  ngOnInit(): void {
    // get order id from router
    this.orderId = this.activatedRoute.snapshot.paramMap.get('id');
    this.providers = this.quotationData.data;
    this.providers.forEach(provider => {
      this.facilities.push(provider.facility);
    });
    this.getQuatations();
  }
  /**
   * Method gquations from server
   */
  getQuatations(): void {
    this.serviceQuatations.getServiceQutationOptions(this.orderId).subscribe(
      (res) => {
        this.quatations = res;
        this.globalServiceStatus.changeProviders(this.quatations);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**
   * Method for selected quatations
   */
  setquatation(event) {
    this.selectedquatation = event;
  }
  /**
   * Method for sened selected quatations options to server
   */
  sendSelectedQuatations() {
    const sentData = {
      order_pk: this.orderId,
      facilities: this.selectedquatation,
    };
    this.serviceQuatations
      .sendSelectedServiceQuatationOptions(sentData)
      .subscribe(
        (res) => {
          this.quatations.forEach(elem => {
            this.selectedquatation.forEach( selected =>{
              if(elem.id == selected)
                this.facilities.push(elem);
            });
          });
          this.updateQuotationData.emit(true);
          this.getQuatations();
          this.selectedquatation = [] ;
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
