import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-registar-as-render',
  templateUrl: './registar-as-render.component.html',
  styleUrls: []
})
export class RegistarAsRenderComponent implements ICellRendererAngularComp {

  value: string;
  params:any;

  agInit(params: any): void {
    this.setStatus(params);
  }

  setStatus(params){
    let list = params.values;
    list.forEach(element => {
      if((element.id || element.pk) == params.value){
        this.value= element.name;
      }
    });  
  }

  refresh(params: any): boolean {
    this.setStatus(params);
    return true;
  }
}
