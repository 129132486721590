import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ayen-selected-count',
  templateUrl: './selected-count.component.html',
  styleUrls: ['./selected-count.component.scss']
})
export class SelectedCountComponent {

  @Input() itemsPerPage;
  @Input() selectedRows;
  @Input() totalRowsSelected;
  @Input() pageTitle;
  @Input() totalItems;
  @Output() setTotalRowsSelected = new EventEmitter();

  selectTotalRows() {
    this.totalRowsSelected = true;
    this.setTotalRowsSelected.emit(this.totalRowsSelected);
  }

}
