@if (rating_existing) {
  <div class="rating_stars" dir="rtl">
    <img
      src="../../../../../assets/images/svg/star-icon.svg"
      alt=""
      height="21px"
      width="20px"
      />
      <span class="rating_value">{{ params.value }}</span>
    </div>
  }
  @if (!rating_existing) {
    <p>لا يوجد</p>
  }
