<div class="row">
  @for (file of orderFilesList; track file; let i = $index) {
    <div class="col-6">
      <file-input 
        [file]="file"
        [fileIndex]="i"
        [fileName]="'document'"
        [fileLabel]="'file'"
        (delete)="deleteFile($event)"
        (save)="save($event)"></file-input>
    </div>
    <!-- <div class="col-md-4" >
      <div class="checkBox-wrapper">
        <label class="checkbox-container" >
          <input type="checkbox" [formControlName]="file.orderlabel" (click)="fileValueChanged(file.orderlabel , $event)">
          <span class="checkmark"> </span>
        </label>
        <span class="checkbox-label">{{file.label}}</span>
      </div>
    </div> -->
  }
</div>
<!-- <div  class="row">
  <div class="col-md-12 save-btn-wrapper">
    <button class="btn btn-green" (click)="save()">
      @if (showSpinner) {
        <img class="spin-img" src="assets/images/svg/Spinner-s.svg" alt=""/>
      }
      حفظ
    </button>
  </div>
</div> -->
