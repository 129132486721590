import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AYENServiceBase } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class RatingService extends AYENServiceBase {

  constructor(private http: HttpClient) {
    super();
  }

  createQcSurvey(survey): Observable<any> {
    return this.http.post<any>(this.baseURL + "/quality-control-review/", survey).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getQualityControlReview(orderID) {
    return this.http.get<any>(this.baseURL + "/quality-control-review/" + orderID).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateQualityControlReview(orderID, survey) {
    return this.http.put<any>(this.baseURL + "/quality-control-review/" + orderID + '/', survey).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getCustomerReview(orderID) {
    return this.http.get<any>(this.baseURL + "/admin-customer-review/" + orderID).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateCustomerReview(orderID, survey) {
    return this.http.put<any>(this.baseURL + "/admin-customer-review/" + orderID + '/', survey).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getInspectorReview(orderID) {
    return this.http.get<any>(this.baseURL + "/inspector-overall-rating/" + orderID).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  canAddCustomerReview(orderID){
    return this.http.get<any>(this.baseURL + `/admin-customer-review/${orderID}/can-add-review/`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  canAddQCReview(orderID){
    return this.http.get<any>(this.baseURL + `/quality-control-review/${orderID}/can-add-review/`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  hideCustomerReview(orderID){
    return this.http.delete<any>(this.baseURL + `/admin-customer-review/${orderID}/`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  hideQCReview(orderID){
    return this.http.delete<any>(this.baseURL + `/quality-control-review/${orderID}/`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
