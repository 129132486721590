<app-header (showOverlay)="getOverlayStatus($event)"></app-header>
<!-- START MAIN SECTION -->
<main class="home-layout">
  <div class="row">
    <div class="fixed">
      <app-sidebar></app-sidebar>
    </div>
    <div id="overlay" [hidden]="!overlayStatus"></div>
    <div class="scrollit">
      <router-outlet></router-outlet>
    </div>
  </div>
</main>
