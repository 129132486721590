<div #container>
  <form [formGroup]="form" novalidate>
    <ng-select #Selecter class="single-select"
      [items]="values" [bindValue]="bindValue" bindLabel="name" [multiple]="true"
      formControlName="selectedValue">
      @if (multiple) {
        <ng-template ng-header-tmp>
          <div>
            <button class="btn btn-link selectBtn" (click)="onSelectAll()"> تحديد الكل </button>
          </div>
        </ng-template>
      }

    </ng-select>
  </form>
</div>
