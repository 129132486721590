import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../../services/clients.service';
import { CustomerRatingsColumns } from './customers-rating-cols';
import { GridDefinitions } from 'src/app/shared/global/grid-definition';
import { ActivatedRoute, Router } from '@angular/router';
import { AyenServices } from 'src/app/core/services/ayen-services/ayen-services.service';
import { FiltersService } from 'src/app/core/services/filters/filters.service';
import { FilterUtilService } from 'src/app/core/services/uitls/filter-utilizer/filter-util.service';
import { ApplySortingService } from 'src/app/core/services/uitls/sorting-util/apply-sorting.service';
import { ReplaySubject } from 'rxjs';
import { ExportExcel } from 'src/app/core/services/uitls/exportExcel';

@Component({
  selector: 'app-customers-rating-grid',
  templateUrl: './customers-rating-grid.component.html',
  styleUrls: ['./customers-rating-grid.component.scss'],
})
export class CustomersRatingGridComponent implements OnInit {
  public gridApi;
  public gridColumnApi;
  public rowData;
  public columnDefs;
  public defaultColDef;
  public paginationPageSize: number;
  public frameworkComponents;
  public rowDataTracker = {};
  config: {
    id: string;
    itemsPerPage: number;
    currentPage: any;
    totalItems: any;
  };
  page: number = 1;
  totalRecords: number = 0;
  searchValue: string;
  pageTitle: string = 'تقيمات العملاء';
  finalStatusList = [];
  filterParamsUrl: any = {};
  filterDataInit: any = {};
  filterData: any;
  spinnerKey: string = 'spinner';
  filterPage: string = 'CUSTOMER_RATINGS';
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  download: boolean;
  excelData: any;
  gridExcelApi: any;
  currentItemsPerPage: number;

  constructor(
    private clientsService: ClientsService,
    private filter: FiltersService,
    private filterUtil: FilterUtilService,
    private route: ActivatedRoute,
    private router: Router,
    private applySortingService: ApplySortingService
  ) {
    const columnsDefinitions = new CustomerRatingsColumns(this.route);
    this.columnDefs = columnsDefinitions.columns;
    this.defaultColDef = GridDefinitions.DEFAULT_GRID_COLS_DEFINITIONS;
    this.paginationPageSize = 10;
    this.config = {
      id: 'myPagination',
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
    };
    this.frameworkComponents = columnsDefinitions.frameworkComponents;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.filterUtil.clearfilterParams(this.filterParamsUrl);
      this.filterUtil.clearfilterParams(this.filterDataInit);
      if (Object.keys(params).length > 0) {
        this.searchValue = params['query'] ? params['query'] : '';
        this.filterParamsUrl = { ...params };
        this.filterDataInit = { ...params };
      }
    });
    this.getRatingFilter();
    this.filterUtil.getSavedFilters(this, this.filterPage);
    this.applySortingService.currentSortCol.subscribe((data) => {
      if (data.length > 0) {
        this.getCustomerRating();
      }
    });
  }

  getRatingFilter() {
    let params = {
      filter_page: this.filterPage,
    };
    this.filter.getFiltersData(params).subscribe((data) => {
      this.filterData = data;
    });
  }

  handelExportExcel() {
    ExportExcel.exportExcel(this);
  }

  getAllData(params) {
    this.clientsService.getAllCustomerRating(params).subscribe(
      (data) => {
        this.excelData = data;
        this.gridExcelApi.setRowData(this.excelData);
        ExportExcel.exportGridCSV(this, 'تقيمات العملاء');
        this.download = false;
      },
      (error) => {
        try {
          this.download = false;
        } catch (error) {}
      }
    );
  }
  getCustomerRating() {
    let params = { ...this.filterParamsUrl };
    params['page'] = this.config.currentPage;
    params['page_size'] = this.config.itemsPerPage;
    if(this.searchValue) {
      params['query'] = this.searchValue;
    }
    this.clientsService.getCustomersRating(params).subscribe((data) => {
      this.rowData = data.results;
      this.config['totalItems'] = data.count;
      this.currentItemsPerPage = data.results.length;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.filterParamsUrl,
        queryParamsHandling: '',
      });
    });
  }

  quickSearch(event) {
    this.searchValue = event;
    this.config.currentPage = 1;
    this.getCustomerRating();
  }

  filterFormValus(event) {
    this.filterParamsUrl = event.filterParamsUrl;
    this.getCustomerRating();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.filter.changeSelectedFilter(this.filterDataInit);
    this.filterUtil.applySelectedFilter(
      this,
      this.getCustomerRating,
      this.spinnerKey
    );
  }

  excelGridReady(params) {
    this.gridExcelApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onCellValueChanged(event) {
    if (
      event.oldValue &&
      event.newValue &&
      event.oldValue.toString() !== event.newValue.toString()
    ) {
      this.storeEdit(
        event.rowIndex,
        event.colDef.field,
        event.oldValue,
        event.newValue
      );
    }
    let clientValueChanged = {};
    let clientRateID = event.data.pk;
    clientValueChanged[event.colDef.field] = event.newValue;
    this.clientsService
      .updateClientRateById(clientRateID, clientValueChanged)
      .subscribe((data) => {
        Object.keys(data).forEach((key) => {
          event.data[key] = data[key];
        });
        event.api.refreshCells();
      });
  }

  storeEdit(rowId, columnId, oldValue, newValue) {
    if (!this.rowDataTracker[rowId]) {
      this.rowDataTracker[rowId] = {};
    }
    this.rowDataTracker[rowId][columnId] = [oldValue, newValue];
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.getCustomerRating();
  }
  pageSizeChanged(event) {
    this.config.itemsPerPage = event;
    this.getCustomerRating();

  }
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.filter.changeSelectedFilter({});
  }
}
