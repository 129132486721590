<form [formGroup]="multiSelectFormGroup">
  <div class="form-group">
    <ng-select
      #select
      class="single-select"
      [multiple]="true"
      [clearSearchOnAdd]="true"
      [items]="optionItems"
      [closeOnSelect]="false"
      (change)="onSelectionChange($event)"
      (search)="changeOnSearch()"
      (removed)="resetSearch()"
      (clear)="resetSearch()"
      (open)="openSelect(optionItems)"
      [placeholder]=" placeholder "
      [id]="id"
      [appendTo]="'div'"
      [bindLabel]="bindLabel"
      [readonly]="disableSelect"
      clearable="false"
      [virtualScroll]="true"
      formControlName="services"
      (scrollToEnd)="onScrollPagination(optionItems)"
      [bindValue]="bindValue" >

      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        @for (item of items | slice:0:maxLength; track item) {
          <div class="ng-value">
            <span class="ng-value-label">{{item[bindLabel]}}</span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">
              <img src="/assets/images/svg/cancel-tag.svg" alt="">
            </span>
          </div>
        }
        <!-- | slice:0:4 -->
        @if (items.length > maxLength) {
          <div class="ng-value">
            <span class="ng-value-label">{{items.length - maxLength}}+...</span>
          </div>
        }
      </ng-template>

      <ng-template ng-header-tmp let-items="items">
        <label class="checkbox-container" >
          <input type="checkbox"
            type="checkbox" [checked]="selectAll"  value="0" #allSelected (change)="toggleCheckAll($event)"  >
            <span class="checkmark"></span>
          </label>
          <span class="checkbox-label">   تحديد الكل  </span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <label class="checkbox-container">
            <input type="checkbox" (click)="checkmark(item)"
              id="item-{{index}}" type="checkbox" [checked]="item$.selected"   >
              <span class="checkmark" > </span>
            </label>
            <span class="checkbox-label"> {{item[bindLabel]}}  
              @if(item.color) {
                <span class="label-bg" [ngStyle]="{'background-color': item.color}"></span>
              }
            </span>
          </ng-template>
        </ng-select>
      </div>
    </form>
