<div class="dialog-container">
  <h2 mat-dialog-title> إضافة الطلبات لمشروع </h2>
  <div mat-dialog-content>
    <div class="form-group">
      <label for="" class="form-label"> اسم المشروع  </label>
      <ng-select bindLabel="name" (scrollToEnd)="onScrollPagination()" #selectInsp
        placeholder="يرجى كتابة اسم المشروع" appendTo="body" [searchable]="true" (removed)="resetSearch()"
        (search)="searchtext()" (clear)="resetSearch()" (change)="projectsData($event)" (open)="resetSearch()"
        [virtualScroll]="true" [clearable]="true">
        @for (project of projects; track project) {
          <ng-option [value]="project.pk">
            {{project.name}}
          </ng-option>
        }
      </ng-select>
    </div>
  </div>
  <div mat-dialog-actions class="actions-double">
    <button class="btn btn-cancel" type="button" mat-dialog-close>إلغاء</button>
    <button class="btn btn-green" type="submit" [mat-dialog-close]="data">إضافة</button>
  </div>
</div>
