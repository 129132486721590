@if (savedFilters?.length > 0) {
  <div class="filters-wrapper">
    <h2> التصفيات:</h2>
    @for (item of savedFilters; track item; let i = $index) {
      <div class="item-wrapper" #item (click)="selectFilter(item, i)">
        <span>{{item.title}}</span>
        <div class="img-wrapper" (click)="stopPropagation($event);deleteSavedFilter(item.pk, i)">
          <img src="assets/images/svg/delete-filter.svg" alt="">
        </div>
      </div>
    }
  </div>
}
