import { Directive, HostListener } from '@angular/core';
@Directive({
  selector: '[appPhoneNumber]',
})
export class PhoneNumberDirective {

  @HostListener('input', ['$event'])

  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let trimmed = input.value.replace(/\s+/g, '');
    if (trimmed.length > 9) {
      trimmed = trimmed.substr(0, 9);
    }

    let numbers = [];
    let start = 0;

    switch (trimmed.length) {
      case 3:
        numbers.push(trimmed.substr(start, 2));
        numbers.push(' ');
        numbers.push(trimmed.substr(2, 3));
        input.value = `${numbers[0]}${numbers[1]}${numbers[2]}`;
        break;

      case 6:
        numbers.push(trimmed.substr(start, 2));
        numbers.push(' ');
        numbers.push(trimmed.substr(2, 3));
        numbers.push(' ');
        numbers.push(trimmed.substr(5, 6));
        input.value = `${numbers[0]}${numbers[1]}${numbers[2]}${numbers[3]}${numbers[4]}`;
        break;
    }
  }
}
