import {Pipe, PipeTransform } from "@angular/core";
import { Coupon } from 'src/app/models/interfaces/coupon';
/**
  declare search coupon pipe
 *  
*/
@Pipe({
    name: 'searchCoupon'
  })
  export class SearchCouponPipe implements PipeTransform {
/**
  declare transform
 *  
*/
    transform(coupons: Coupon[], searchValue: string): Coupon[] {
        if (!coupons || !searchValue) {
            return coupons;
          }
          return coupons.filter(
            (coupon) =>
            coupon.pk.toString().includes(searchValue) ||
            coupon?.code.toLocaleLowerCase().includes(searchValue) ||
            coupon?.discount_value?.toString().includes(searchValue) ||
            coupon?.start_date_display.includes(searchValue)||
            coupon?.end_date_display.includes(searchValue)||
            coupon?.status_object.name.includes(searchValue)||
            coupon?.current_redeem_number?.toString().includes(searchValue)||
            coupon?.discount_type_object.name.includes(searchValue)
          );
    }
  }