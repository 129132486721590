import { Component, OnInit } from '@angular/core';
import { ScriptService } from 'src/app/core/services/script/script.service';

@Component({
  selector: 'dynamic-map',
  templateUrl: './dynamic-map.component.html',
  styleUrl: './dynamic-map.component.scss'
})
export class DynamicMapComponent implements OnInit{
  apiLoaded!: boolean;
  google_address_city: any;
  google_address_country: any;
  google_address_area: any;
  latitude: number = 23.8859;
  longitude: number = 45.0792;
  
constructor(private GoogleMapLoader: ScriptService){}
  ngOnInit() {
    this.GoogleMapLoader.obsCurrentApiStatus.subscribe(status => {
      this.apiLoaded = status.valueOf();
    });
  }
  mapValues(event) {
    this.google_address_city = event.city ? event.city : '';
    this.google_address_country = event.country;
    this.google_address_area = event.state;
    this.latitude = event.latitude;
    this.longitude = event.longitude;
  }

}
