@if (showSpinner) {
  <app-spinner></app-spinner>
  }
  
  <div class="inner-page-header">
    <h3>الإضافات</h3>
  </div>
  <div class="request-control">
    <div class="delete-icon-grid">
      <button class="btn btn-custom-delete" [disabled]="disabledDelete">
        <img src="assets/images/svg/delete-icon-ins.svg" alt="" />
      </button>
    </div>
    <quick-search (searchResults)="quickSearch($event)"></quick-search>
    <div class="new-element">
      <button class="btn btn-add" [routerLink]="['/pages/services/additions/new']">
        <img src="assets/images/svg/add-icon.svg" alt="" />
        إضافة جديدة
      </button>
    </div>
  </div>
  
  <div class="custom-card">
    @if (additionList) {
    <div class="row custom-container">
      @for (addition of additionList | paginate: config; track addition.id; let i = $index)
      {
      <div class="col-6 item">
        <div>
          <label class="checkbox-container">
            <input type="checkbox" />
            <span class="checkmark"></span>
          </label>
          <span class="checkbox-label">
            {{ addition.name }} @if (!addition.is_active) {
            <span class="inActive">(غير مفعل)</span>
            }</span
          >
        </div>
        <div class="setting-container">
          <div class="setting-item wrench" (click)="goAdditionDetails(addition.id)">
            <img src="assets/images/svg/wrench.svg" alt="" />
          </div>
          <div
            class="setting-item delete"
            (click)="showDeleteConfirmationBox(addition.id, i, addition.name)"
          ></div>
        </div>
      </div>
      }
    </div>
    } @else {
    <div class="empty-services">لا يوجد إضافات</div>
    }
  </div>
  @if (additionList && additionList.length > 0) {
  <ayen-pagination
    (pageData)="pageChanged($event)"
    (pageSize)="pageSizeChanged($event)"
    [id]="'additionList'"
    [totalCount]="config.totalItems"
  >
  </ayen-pagination>
  }
  