import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PaymentService } from '../../../services/payment/payment.service';
/**
 *  define and export AmountDetailsComponent class
 */
@Component({
  selector: 'app-amount-details',
  templateUrl: './amount-details.component.html',
  styleUrls: ['./amount-details.component.scss'],
})
export class AmountDetailsComponent implements OnInit {
  /**
   *  declare addAmountFormGroup property
   */
  addAmountFormGroup: UntypedFormGroup;
  /**
   *  declare reasonType property
   */
  reasonType: string = '';
  /**
   *  declare amount_details_view property
   */
  @Output() amount_details_view = new EventEmitter();
  /**
   *  declare reasons property
   */
  reasons: any;
  /**
   *  declare alertmsg property
   */
  alertmsg: boolean = false;
  /**
   *  declare errorrMsg property
   */
  errorrMsg = 'برجاء إدخال الحقول المطلوبة';
  /**
   *  declare confirmMsg property
   */
  confirmMsg: string;
  /**
   *  implement constructor and declare injectors
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private paymentService: PaymentService
  ) {}
  /**
   *  implement ngOnInit
   */
  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.getReasons();
  }
  /**
   *  implement createFormControls
   */
  createFormControls() {
    return this.addAmountFormGroup.controls;
  }
  /**
   *  implement createForm
   */
  createForm() {
    this.addAmountFormGroup = this.formBuilder.group({
      reference_number: [null, Validators.required],
      reason_pk: [null, Validators.required],
      sub_order_value: [null],
    });
  }
  /**
   *  implement patch values to addAmountFormGroup
   */
  patchAddAmountForm() {
    let formValues = JSON.parse(localStorage.getItem('additional_amount'));
    this.addAmountFormGroup.patchValue({
      reference_number: formValues.reference_number,
      reason_pk: formValues.reason_pk,
      sub_order_value: formValues.sub_order_value,
    });
    this.checkType(formValues.reason_pk);
  }
  /**
   *  implement getReasons to get order reasons from API
   */
  getReasons() {
    this.paymentService.getOrderReasons().subscribe(
      (data) => {
        this.reasons = data;
        if (localStorage.getItem('additional_amount'))
          this.patchAddAmountForm();
      },
      (error) => {
        try {
          console.log(error);
        } catch {
          console.log(error);
        }
      }
    );
  }
  /**
   *  implement checkType to detect witch input render
   */
  checkType(event) {
    for (let reason of this.reasons) {
      if (reason.pk == event) {
        this.reasonType = reason.reason_type;
      }
    }
  }
  /**
   *  implement save additional amount on order
   */
  save() {
    if (this.reasonType === 'مبلغ' || this.reasonType === 'المساحة') {
      this.addAmountFormGroup.controls['sub_order_value'].setValidators(
        Validators.required
      );
      this.addAmountFormGroup.controls[
        'sub_order_value'
      ].updateValueAndValidity();
    }
    if (this.addAmountFormGroup.status == 'INVALID') {
      this.alertmsg = true;
      let fields = document.querySelectorAll(
        'ng-select.ng-invalid , input.ng-invalid '
      );
      fields.forEach((field) => {
        field.parentElement.classList.add('invalid-field');
      });

      return;
    }
    let formData = this.addAmountFormGroup.value;
    this.paymentService.calcSubOrderAmount(formData).subscribe(
      (data) => {
        this.amount_details_view.emit({ viewName: 'summary', data: data });
        localStorage.setItem('additional_amount', JSON.stringify(formData));
      },
      (error) => {
        this.alertmsg = true;
        this.errorrMsg = Object.values(error.error)[0].toString();
      }
    );
  }

  hideErrorMsg() {
    this.alertmsg = false;
  }
}
