<!-- START MAIN SECTION -->
<main class="login-layout recover-password">
  <div class="login-card">
    <h3 class="login-title">استعادة كلمة المرور</h3>
    <form
      class="needs-validation"
      id="login-form"
      novalidate
      id="recoverPasswordForm"
      [formGroup]="recoverFormGroup"
      >
      <div class="form-group">
        <label class="form-label">البريد الإلكتروني</label>
        <input
          type="email"
          required
          class="form-control"
          name="email"
          formControlName="username_field"
          required
          class="form-control"
          id="userValidation"
          />
          @if (sucessMsg) {
            <div class="success-box">
              <p class="success-msg"
                >تم إرسال رسالة إعادة التعيين إلى بريدك الإلكتروني</p
                >
              </div>
            }
            @if (alertMsg) {
              <div class="alert-box">
                <img
                  src="assets/images/svg/error-icon.svg"
                  class="close-alert"
                  alt=""
                  (click)="closeError()"
                  />
                  <p class="alert-msg">خطأ في البريد الإلكتروني</p>
                </div>
              }
            </div>
            <div class="login-btn">
              <button
                class="btn btn-auth"
                [disabled]="!recoverFormGroup?.valid"
                type="submit"
                (click)="save()"
                >
                إرسال
              </button>
            </div>

          </form>
        </div>
      </main>
