import { AutoCompleteComponent } from 'src/app/shared/components/ag-grid/auto-complete/auto-complete.component';
import { AvatarRenderComponent } from 'src/app/shared/components/ag-grid/avatar-render/avatar-render.component';
import { CountryRenderComponent } from 'src/app/shared/components/ag-grid/country-render/country-render.component';
import { CustomHeaderComponent } from 'src/app/shared/components/ag-grid/custom-header/custom-header.component';
import { DatepickerComponent } from 'src/app/shared/components/ag-grid/datepicker/datepicker.component';
import { FileUploadRenderComponent } from 'src/app/shared/components/ag-grid/file-upload-render/file-upload-render.component';
import { InspectorAvaliablityRenderComponent } from 'src/app/shared/components/ag-grid/inspector-avaliablity-render/inspector-avaliablity-render.component';
import { InspectorMultiselectRenderComponent } from 'src/app/shared/components/ag-grid/inspector-multiselect-render/inspector-multiselect-render.component';
import { InspectorMultiselectComponent } from 'src/app/shared/components/ag-grid/inspector-multiselect/inspector-multiselect.component';
import { RatingStarEditorComponent } from 'src/app/shared/components/ag-grid/rating-star-editor/rating-star-editor.component';
import { RatingStarsRenderComponent } from 'src/app/shared/components/ag-grid/rating-stars-render/rating-stars-render.component';
import { RegistarAsRenderComponent } from 'src/app/shared/components/ag-grid/registar-as-render/registar-as-render.component';
import { RegisterAsComponent } from 'src/app/shared/components/ag-grid/register-as/register-as.component';
import { SelectEditorComponent } from 'src/app/shared/components/ag-grid/select-editor/select-editor.component';
import { SelectRenderComponent } from 'src/app/shared/components/ag-grid/select-render/select-render.component';
import { StatusEditorComponent } from 'src/app/shared/components/ag-grid/status-editor/status-editor.component';
import { StatusComponent } from 'src/app/shared/components/ag-grid/status/status.component';
import { TagElementRenderComponent } from 'src/app/shared/components/ag-grid/tag-element-render/tag-element-render.component';

export class frameworkComponents {
  public static FRAME_WORK_COMPONENTS = {
    inspectorMultiselect: InspectorMultiselectComponent,
    inspectorMultiselectRender: InspectorMultiselectRenderComponent,
    AutoComplete: AutoCompleteComponent,
    CountryRender: CountryRenderComponent,
    ratingStarsRenderComponent: RatingStarsRenderComponent,
    StatusComponent: StatusComponent,
    statusEditor: StatusEditorComponent,
    selectEditor: SelectEditorComponent,
    selectRender: SelectRenderComponent,
    registerAsComponent: RegisterAsComponent,
    registarAsRenderComponent: RegistarAsRenderComponent,
    datepickerComponent: DatepickerComponent,
    ratingEditor: RatingStarEditorComponent,
    avatarRenderComponent: AvatarRenderComponent,
    fileUploadRenderComponent: FileUploadRenderComponent,
    inspectorAvaliablityRenderComponent: InspectorAvaliablityRenderComponent,
    customHeader: CustomHeaderComponent,
    tagElementRenderComponent: TagElementRenderComponent,
  };
}
