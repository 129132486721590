<div class="dialog-container addCategories">
  <h5 mat-dialog-title>تعديل / اضافة التصنيف</h5>
  <form class="needs-validation" [formGroup]="addCategoryFormGroup">
    <div mat-dialog-content class="text-center">
      @for (categoryForm of categories.controls; track categoryForm; let i = $index) {
        <div
          class="row new-row"
          formArrayName="categories"
          >
          <ng-container [formGroup]="categoryForm">
            <div class="item-wrapper">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  formControlName="name"
                  placeholder="اسم التصنيف"
                  />
                </div>
              </div>
              <div class="item-wrapper">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    aria-label=""
                    formControlName="ratio"
                    placeholder="نسبة الانجاز"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
                <div class="action-wrapper">
                  @if (i == 0) {
                    <button
                      class="btn btn-add"
                      (click)="addCategories()"
                      >
                      <img alt="" src="assets/images/svg/add-service.svg" />
                    </button>
                  } @else {
                    <button class="btn btn-add" (click)="deleteItem(i)">
                      <img alt="" src="assets/images/svg/DELETE1.svg" />
                    </button>
                  }
                </div>
              </ng-container>
            </div>
          }
        </div>
        @if (alertmsg) {
          <div class="alert-box">
            <img
              src="assets/images/svg/error-icon.svg"
              class="close-alert"
              (click)="closeError()"
              alt=""
              />
              <p class="alert-msg">{{ errorMsg }}</p>
            </div>
          }
          <div mat-dialog-actions class="actions-double">
            <a class="btn btn-cancel center-btn" mat-dialog-close>إلغاء</a>
            <button
              class="btn btn-green center-btn"
              type="submit"
              (click)="saveCategories()"
              >
              تغير
            </button>
          </div>
        </form>
      </div>
