<div>
  <label class="form-label file-label">{{file[fileName]}}</label>
  @if (file[fileLabel]) {
  <div class="existance-wrapper">
    <div class="existance">
      <img src="assets/images/svg/file-name-icon.svg" alt="">
      <a [href]="file[fileLabel]" target="_blank">{{file[fileName]}}</a>
    </div>
    <div>
      <button class="btn delete-wrapper" (click)="deleteFile(file.id ,  fileIndex)">
        <img src="assets/images/svg/DELETE1.svg" alt="">
      </button>
    </div>
  </div>
  }
  @if (file.progress && !file[fileLabel]) {
  <div class="file-progress">
    <div class="file-progress-boxs col-md-12">
      <div class="progress-item">
        <img src="assets/images/svg/file-progress.svg" alt="">
      </div>
      <div class="progress-item upload-progress">
        <span class="file-name">{{file[fileName]}}</span>
        <progress id="bar" value="{{file.progressVal}}" max="100">
        </progress>
      </div>
    </div>
  </div>
  }
  @if (!(file[fileLabel] || file.progress)) {
  <div class="drag-btn">
    <button type="button" class="btn btn-outline-secondary add-new-file">
      <img src="assets/images/svg/upload-img.svg" alt="" />
      رفع ملف
    </button>
    <input type="file" name="" (change)="handleFileInput($event.target.files , file.id , fileIndex)"
      accept="image/jpeg,image/png,application/pdf" class="file-new form-control" />
    @if (file.error) {
    <div class="file-error">
      <span>عفوا لا يمكن رفع صور حجم اكبر من 5GB </span>
    </div>
    }
  </div>
  }
</div>
