import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AdminOrdersService } from 'src/app/features/orders/services/orders/admin-orders.service';
import { ProjectsService } from 'src/app/features/projects/services/projects.service';

@Component({
  selector: 'linked-order',
  templateUrl: './linked-order.component.html',
  styleUrl: './linked-order.component.scss'
})
export class LinkedOrderComponent {
  orders: any;
  page_count: number = 0;
  params: any;
  page: number = 1;
  searchInput: any;
  timeout: any = null;
  order_inspector: any;
  @ViewChild('selectOrder') selectInsp: NgSelectComponent;

  constructor(
    private _mdr: MatDialogRef<LinkedOrderComponent>,
    private ordersService: AdminOrdersService,
    private projectsService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  getOrders(params) {
    params['unlinked'] = true;
    if(this.data.pageIdentifier === 'individual') {
      this.getIndividualOrders(params);
    } else {
      this.getBusinessOrders(params);
    }
  }

  getIndividualOrders(params) {
    this.ordersService.getAdminOrdersByPage(params).subscribe(
      (data) => {
        this.orders.push(...data.results)
        this.page_count = data.pages_count;
      },
      (error) => {}
    );
  }

  getBusinessOrders(params) {
    this.projectsService.getProjectsOrdersByPage(params).subscribe(
      (data) => {
        this.orders.push(...data.results)
        this.page_count = data.pages_count;
      },
      (error) => {}
    );
  }

  orderData(order) {
    if (order) {
      this.data = this.orders.find(item => item.id == order);
    }
  }

  resetSearch() {
    this.orders = [];
    this.params = { page: '1' }
    this.getOrders(this.params);
  }

  searchtext() {
    this.searchInput = this.selectInsp.searchTerm;
    this.params = { reference_number: this.searchInput, ordering: 'reference_number' };
    this.orders = [];
    clearTimeout(this.timeout);
    var $this = this;
    $this.timeout = setTimeout(() => {
      if ($this.searchInput != '') {
        $this.getOrders(this.params);
      }
      else {
        this.params = { page: '1' }
        $this.getOrders(this.params);
      }
    }, 600);
  }

  onScrollPagination() {
    if (this.page < this.page_count) {
      this.page++;
      this.params = { page: this.page };
      this.getOrders(this.params);
    }
  }

  CloseDialog() {
    this._mdr.close(false);
  }
}
