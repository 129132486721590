import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable , BehaviorSubject} from 'rxjs';
import { map } from 'rxjs/operators';
import { AYENServiceBase } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends AYENServiceBase{

 private download = new BehaviorSubject(false);
 currentDownload = this.download.asObservable();

  constructor(private http: HttpClient) { super(); }
 
  preivewReport(id: number): Observable<any> {
    return this.http.get(`${this.baseURL}/report/${id}/`);
  }
 
  changeDownload(updateDownload: boolean) {
    this.download.next(updateDownload);
  }

  acceptReport(id, data): Observable<any> {
    return this.http.post<any>(`${this.baseURL}/accept-reject-report/${id}/`,data).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  generateReport(orderID) :Observable<any>{
    return this.http.get(`${this.baseURL}/generate-pdf-report/${orderID}/`);
  }
  
  generateAlbumReport(orderID) :Observable<any>{
    return this.http.get(`${this.baseURL}/generate-pdf-gallery-report/${orderID}/`);
  }
}
