import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Order } from 'src/app/models/interfaces/order';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { AdminOrdersService } from '../../services/orders/admin-orders.service';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OrderState } from '../../state/order.state';
import { GetOrderMsg } from '../../state/order-action';

@Component({
  selector: 'app-client-notes',
  templateUrl: './client-notes.component.html',
  styleUrls: ['./client-notes.component.scss'],
})
export class ClientNotesComponent implements OnInit {
  orderId: string;

  public orders: Order;
  headerTxt: string = 'إرسال تنبيه';
  /**
   *  declare deleteMsg property
   */
  deleteMsg: string = 'هل أنت متأكد من حذف هذا الرسالة؟';
  /**
   *  declare messagePk property
   */
  messagePk: number;
  messages: any[] = [];
  messageIndex: any;
  @Input() inspector_status;
  child: string = 'ClientNotesComponent';
  @Input() reciver_status;
  confirmBtn: string = 'حذف';
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  @Select(OrderState.getOrderMsg) orderMsg$: Observable<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private adminOrdersService: AdminOrdersService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.orderId = params['id'];
      this.store.dispatch(new GetOrderMsg({ orderId: +this.orderId }));
      this.getMessagesLog(this.orderId);
    });
  }

  getMessagesLog(orderID) {
    // this.adminOrdersService.getMessagesLog(orderID).subscribe(
    //   (data) => {
    //     this.messages = data;
    //   },
    //   (error) => {
    //     this.handleBackendError(error);
    //   }
    // );
    this.orderMsg$.subscribe((data) => {
      this.messages = data;
    });
  }

  deleteMessage(messagePk, index) {
    this.messagePk = messagePk;
    this.messageIndex = index;
    this.showDeleteConfirmationBox();
  }

  showDeleteConfirmationBox() {
    this.deleteDialogRef = this.dialog.open(ConfirmationBoxComponent, {
      data: {
        id: this.messagePk,
        index: this.messageIndex,
        message: this.deleteMsg,
        confirmBtn: this.confirmBtn,
        isDelete: true,
      },
    });
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.doAction(result);
      }
    });
  }

  doAction(event) {
    let id = event.id;
    let index = event.index;
    this.adminOrdersService.deleteMessage(id).subscribe(
      (data) => {
        this.messages.splice(index, 1);
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  handleBackendError(error) {
    try {
      console.log(error);
    } catch {
      console.log(error);
    }
  }
}
