import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Element } from 'src/app/models/interfaces/serviceEngine/element.model';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: []
})
export class InputsComponent implements OnInit {
  @Input() inputs:Element;
  /**
  *  declare subForm property  
  */
  @Input() subForm: UntypedFormGroup;
  isRequired : boolean = false ;
  readOnly: boolean = false;

  ngOnInit(): void {
    this.isRequired = this.inputs?.validation?.required ?? false;
    // this.readOnly = !this.inputs.is_editable_admin;
  }

}
