import { Component, Input, OnInit , ViewChild,ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Element } from 'src/app/models/interfaces/serviceEngine/element.model';


@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit {
  @Input() inputs:Element;
  /**
  *  declare subForm property  
  */
   @Input() subForm: UntypedFormGroup;
  /**
    declare  option item variable
  *  
  */
  optionItems: any[] = []; // this.inputs.options
  /**
    declare  maxLength Input  
  *  
  */
  maxLength= 4; // this.inputs.validation.maxLength
  /**
    declare  selected value array variable 
  *  
  */
    selectedValues: any[] = [];
  /**
    declare  allSelected 
  *  
  */
    @ViewChild('container', { read: ViewContainerRef }) public container;

    @ViewChild('allSelected') allSelected;
  /**
    declare  select all boolean  variable 
  *  
  */
    selectAll: boolean;
  /**
    declare  disableSelect variable
  *  
  */
    @Input() disableSelect : boolean = false ;
  /**
   select view child 
  *  
  */
   @ViewChild('select') select: NgSelectComponent;
   private onScroll = (event: any) => {
       if (this.select && this.select.isOpen) {
           const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
           if (isScrollingInScrollHost) { return; }
           this.select.close();
       }
   }
 
  isRequired: boolean = false;

  ngOnInit(): void {
    this.isRequired = this.inputs.validation.required;
    this.optionItems = this.inputs.choices;
    this.disableSelect = !this.inputs.is_editable_admin;
    window.addEventListener('scroll', this.onScroll, true);
  }

  /**
   ng on destory function
  *  
  */
   ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
}
  /**
  on change function 
  *  
  */  
  onChange(selected) {
    if (selected.target && selected.target.defaultValue == '0')
      return;
    else {
      if (selected.length < this.optionItems?.length )
        {
          this.selectAll=false;
        }
      else if (selected.length == this.optionItems?.length )
      {
        this.selectAll=true;
      }
    }
  }
  /**
  toggle select All function 
  *  
  */
  toggleSelectAll(vals: any) {
    this.selectAll=true;
    if (vals.currentTarget.checked) {
      this.selectAllItems();      
      vals.currentTarget.checked = true;
    } else {
      this.unselectAllItems();
    }
  }
  /**
  selcet AllItems function 
  *  
  */
  private selectAllItems() {    
    this.subForm.controls[this.inputs.field_key_name]
      .patchValue([...this.optionItems?.map(
        item => item.choice_value
      )]);
      this.selectedValues = this.subForm.controls[this.inputs.field_key_name].value;
  }
  /**
  unselcet AllItems function 
  *  
  */
  private unselectAllItems() {
    this.subForm.controls[this.inputs.field_key_name].patchValue([]);
    this.selectedValues = [];
  }
  /**
  click checkmark function 
  *  
  */
  checkmark(item , index , checked){
    if(checked)
      this.selectedValues.splice(index , 1)
    else
      this.selectedValues.push(item);
    this.subForm.controls[this.inputs.field_key_name] 
    .patchValue([
      ...this.selectedValues
    ]);
  }

}
