import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class WhatsNewService extends AYENServiceBase {
  private updatedWhatsNewList = new BehaviorSubject(false);
  newWhatsNewList = this.updatedWhatsNewList.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  getWhatsNewList(params): Observable<any> {
    return this.http
      .get(this.baseURL + `/admin/list-whats-new-notifications?${params}`)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  updateWhatsNewList(data) {
    this.updatedWhatsNewList.next(data);
  }

  deleteWhatsNew(ids: any[]): Observable<any> {
    return this.http
      .post(this.baseURL + '/admin/soft-delete-whats-new-notification', {
        ids: ids,
      })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getWhatsNewById(id): Observable<any> {
    return this.http
      .get(this.baseURL + `/admin/retrieve-whats-new-notification/${id}`)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getUserTypes(): Observable<any> {
    return this.http
      .get(this.baseURL + `/admin/list-whats-new-user-types`)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  updateWhatsNew(id, data): Observable<any> {
    return this.http
      .put(this.baseURL + `/admin/update-whats-new-notification/${id}`, data)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  createWhatsNew(data): Observable<any> {
    return this.http
      .post(this.baseURL + `/admin/create-whats-new-notification`, data)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getWhatsNewModalList(params): Observable<any> {
    return this.http
      .get(
        this.baseURL + `/admin/published-whats-new-notification-list${params}`
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  toggleReadItem(id): Observable<any> {
    return this.http
      .get(this.baseURL + `/admin/toggle-whats-new-notifications/${id}`)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  toggleReadAllItem(): Observable<any> {
    return this.http
      .get(this.baseURL + `/admin/read_all-whats-new-notifications`)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  /**
    handel error function
   *  
  */
  handleError(error) {
    let errorMessage;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
