import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Images } from 'src/app/shared/global/image-constants';

export interface SortParams {
  orderingState: {};
  column: string;
  imgSrc: string;
  ordering: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ApplySortingService {

  private sortColumns = new BehaviorSubject([]);
  public currentSortCol = this.sortColumns.asObservable();

  constructor() { }

  applySorting(columns) {
    this.sortColumns.next(columns);
  }

  toggleSort(params: SortParams) {
    switch (params.orderingState[params.column]) {
      case null:
        params.orderingState[params.column] = false;
        params.imgSrc = Images.ASC_SORT;
        break;

      case false:
        params.orderingState[params.column] = true;
        params.imgSrc = Images.DESC_SORT;
        break;

      case true:
        params.orderingState[params.column] = null;
        params.imgSrc = Images.DEFAULT_SORT;
        break;
    }
    for (const [key, value] of Object.entries(params.orderingState)) {
      switch (value) {
        case false:
          params.ordering.push(key);
          break;
        case true:
          params.ordering.push('-' + key);
          break;
      }
    }
    this.applySorting(params.ordering);
    return params.imgSrc;
  }
}
