import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class MetersService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }
  /**
   *  implement getOrderMeters method
   */
  getOrderMeters(orderID): Observable<any> {
    return this.http.get<any>(this.baseURL + `/admin-meters/${orderID}/`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  deleteMeter(meterID): Observable<any> {
    return this.http
      .delete(this.baseURL + '/admin-del-meter/' + meterID + '/')
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  updateMeter(orderID, meter): Observable<any> {
    return this.http
      .put<any>(this.baseURL + '/admin-meters/' + orderID + '/', meter)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  addMeter(orderID, data): Observable<any> {
    return this.http
      .post<any>(this.baseURL + '/admin-meter/' + orderID + '/', data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
