import { Injectable } from '@angular/core';
import { AYENServiceBase } from '../base/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService extends AYENServiceBase{

  constructor(private http: HttpClient) {
    super();
  }
  getOrderStats(params): Observable<any> {
    return this.http.get(this.baseURL + '/admin/statistics', { params: params });
  }
}

