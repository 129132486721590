import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AYENServiceBase } from '../../base/base.service';
import { Observable } from 'rxjs';

/**
 * declare service injectable
 */
@Injectable({
  providedIn: 'root',
})
export class ServiceQuatationsService extends AYENServiceBase {
  /**
   * declare service constructor
   */
  constructor(private http: HttpClient) {
    /**
     * active service as super
     */
    super();
  }

  /**
   * Service for get service qutation options
   */
  getServiceQutationOptions(orderId , all = false ): Observable<any> {
    // return service qutation options data from api
    let endPoint = all ? '/service-providers/' + orderId +'/'+ '?all=true' : '/service-providers/' + orderId + '/'
    return this.http.get<any>(
      this.baseURL + endPoint
    );
  }

  /**
   * Service for get service qutations
  */
  getServiceQuatations(orderId): Observable<any> {
    // return service qutations data from api
    return this.http.get<any>(
      this.baseURL + '/order-quotations/' + orderId + '/'
    );
  }

  /**
   * Service for send selected service qutation options
   */
  sendSelectedServiceQuatationOptions(data): Observable<any> {
    // return service qutation options data from api
    return this.http.post<any>(
      this.baseURL + '/create-order-quotations/',
      data
    );
  }

  /**
   * Service for send qautation options
   */
  sendOrderQuatation(data): Observable<any> {
    // return service qutation options data from api
    return this.http.post<any>(this.baseURL + '/send-order-quotation/', data);
  }
}
