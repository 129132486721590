<div class="main-card">
  <div class="req-row">
    <div class="req-box">
      <div class="req-title">  تفاصيل الطلب الأساسي </div>
    </div>
  </div>
  <div class="req-body">
    <div class="req-row ">
      <div class="req-box">
        <div class="req-title">رقم الطلب</div>
        <div class="req-data">{{summaryData?.parent_reference_number}}</div>
      </div>
      <div class="req-box">
        <div class="req-title">اسم العميل</div>
        <div class="req-data">  {{summaryData?.client}}</div>
      </div>
      <div class="req-box">
        <div class="req-title"> العنوان</div>
        <div class="req-data">{{address}} </div>
      </div>
    </div>
  </div>
  <div class="req-row">
    <div class="req-box">
      <div class="req-title last">  تفاصيل الخدمة الإضافية </div>
    </div>
  </div>
  <div class="req-body">
    <div class="req-row last">
      <div class="req-box">
        <div class="req-title"> السبب</div>
        <div class="req-data"> {{summaryData.reason}}</div>
      </div>
      @if (summaryData.type == 'المساحة') {
        <div class="req-box">
          <div class="req-title"> المساحة</div>
          <div class="req-data">  {{summaryData.sub_order_value}} <span>م<sup>2</sup></span></div>
        </div>
      }
      @if (summaryData.type == 'المبلغ') {
        <div class="req-box">
          <div class="req-title"> المبلغ المطلوب </div>
          <div class="req-data">  {{summaryData.sub_order_value}} <span>ر.س</span></div>
        </div>
      }
    </div>
  </div>



</div>
<!--- cost -->
<div class="row total-cost">
  <div class="col-md-6"></div>
  <div class="col-md-6">
    <div class="main-card">
      <div class="req-row">
        <div class="req-box">
          <div class="req-title"> المجموع </div>
        </div>
      </div>
      <div class="req-body">
        <div class="req-row">
          <div class="req-box">
            <div class="req-title" >   مجموع الطلب </div>
            <div class="req-data">{{summaryData.sub_order_amount}} <span>ر.س</span></div>
          </div>
          <div class="req-box">
            <div class="req-title">  <strong> ضريبة القيمة المضافة <span>(15%)</span></strong> </div>
            <div class="req-data">{{summaryData.tax}} <span>ر.س</span> </div>
          </div>
          <div class="req-box">
            <div class="req-title">  <strong>المجموع </strong> </div>
            <div class="req-data greenText">{{summaryData.taxed_amount}} <span>ر.س</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div  class="row">
  <div class="col-md-12 confirm-next-step double-action">
    <button class="btn btn-grey" type="button"  (click)="prevStep()">
      السابق
    </button>
    <button class="btn btn-green" type="button" (click)="goOrderList()">
      إضافة طلب
    </button>
  </div>
</div>
