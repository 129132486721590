import { Component } from '@angular/core';
/**
  declare auth component
 *  
*/
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
}
