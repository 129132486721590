import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RefundService } from '../../services/refund/refund.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'refund-modal',
  templateUrl: './refund-modal.component.html',
  styleUrls: ['./refund-modal.component.scss']
})
export class RefundModalComponent implements OnInit {

  refundFormGroup: UntypedFormGroup;
  isCancel: boolean = false;
  valueMark: string = 'ر.س';
  refundType: string = 'PERCENTAGE';
  isError: boolean = false;
  disableSave: boolean = true;
  errorMsg: string = '';
  apiError: string = '';
  refundReasons = [];
  refundTypes: any[] = [];
  insp_fees: boolean = false;
  inspector_fees: string = '';
  payment_fee: string = '';
  constant: boolean = false;
  payment_fees: boolean = false;
  tax: string = '';
  tax_status: boolean = false;
  remain_cost: string = '0.00';

  constructor(private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<RefundModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private refundService: RefundService) { }

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.getRefundReasons();
    this.getRefundType();
  }

  createFormControls() {
    return this.refundFormGroup.controls;
  }

  createForm() {
    this.refundFormGroup = this.formBuilder.group({
      value: [null],
      reason: [null, Validators.required],
      type: [null, Validators.required],
      change_order_status_to_be_cancel: [false]
    });
  }

  getRefundReasons() {
    this.refundService.getRefundReasons().subscribe(data => {
      this.refundReasons = data;
    },
      (error) => {
        this.handleBackendError(error)
      })
  }

  getRefundType() {
    this.refundService.getRefundType().subscribe(data => {
      this.refundTypes = data;
    },
      (error) => {
        this.handleBackendError(error);
      })
  }

  handleBackendError(error) {
    try {
      console.log(error);
    }
    catch {
      console.log(error);
    }
  }

  reasonChanged(event) {
    this.disableFn();
  }

  TypeChanged(event) {
    this.tax = '';
    if (event == 'EPFIV') {
      this.insp_fees = true;
      this.payment_fees = true
      this.constant = false;
      this.tax_status = true;
    }
    else if (event == 'CONSTANT_VALUE') {
      this.constant = true;
      this.insp_fees = false;
      this.payment_fees = false;
      this.tax_status = true;
    }
    else if (event == 'EPF') {
      this.payment_fees = true
      this.insp_fees = false;
      this.constant = false;
      this.tax_status = true;
    }
    else if (event == 'ALL') {
      this.tax_status = false;
      this.payment_fees = false
      this.insp_fees = false;
      this.constant = false;
    }
    else {
      this.payment_fees = false
      this.insp_fees = false;
      this.constant = false;
    }
    if (event != 'CONSTANT_VALUE') {
      this.calculateRefundValue();
      this.refundFormGroup.controls.value.setValue(0)
    }
    this.disableFn();
  }
  
  disableFn() {
    if (this.refundFormGroup.controls.type.value != null && this.refundFormGroup.controls.reason.value != null && this.apiError == '') {
      this.disableSave = false;
      if (this.constant) {
        this.disableSave = this.refundFormGroup.controls.value.value >= 1 ? false : true;
      }
    }
    else
      this.disableSave = true;
  }

  calculateRefundValue() {
    let obj = {
      value: this.refundFormGroup.controls.value.value ? this.refundFormGroup.controls.value.value : 0,
      type: this.refundFormGroup.controls.type.value
    }
    this.isError = false;
    this.disableSave = true;
    let cost = (+this.data.final_cost.replace(',', '')).toFixed(2);
    if (!this.isError && obj.value <= cost) {
      this.disableFn();
      document.querySelector('#value')?.classList?.remove('invalid-input');
      this.refundService.calculateRefundValue(this.data.orderID, obj).subscribe(
        data => {
          this.data.final_cost = data.final_cost_before_refund;
          this.remain_cost = data.amount;
          this.inspector_fees = data.inspector_visit_fee;
          this.payment_fee = data.payment_fee;
          this.tax = data.tax;
          this.apiError = '';
        },
        (error) => {
          try {
            this.apiError = Object.values(error.error)[0].toString();
            this.disableSave = true;
          }
          catch {
            console.log(error);
          }
        }
      )
    }
    else {
      this.showErrorvalueMsg(this.data.final_cost);
    }
  }

  showErrorvalueMsg(maxvalue) {
    this.isError = true;
    this.errorMsg = 'لا يمكن ادخال اكثر من ' + maxvalue;
    document.querySelector('#value')?.classList?.add('invalid-input');
  }

  save() {
    this.disableSave = true;
    this.refundFormGroup.controls.value.setValue(this.remain_cost)
    this.refundService.createCostRefund(this.data.orderID, this.refundFormGroup.value).subscribe(
      data => {
        this.dialogRef.close(data);
      },
      (error) => {
        try {
          this.apiError = Object.values(error.error)[0].toString();
        }
        catch {
          console.log(error);
        }
      }
    )
  }
}
