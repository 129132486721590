import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';

@Injectable({
  providedIn: 'root'
})
export class ShowAlertUtilService {

  saveDialogRef: MatDialogRef<AlertBoxComponent>;

  constructor(private dialog: MatDialog) { }

  showAlertBox(params) {
    let data = { ...params };
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: data,
    });
  }
}
