import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'file-input',
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss'
})
export class FileInputComponent {

  @Input() file;
  @Input() fileIndex;
  @Input() fileName = 'certificate';
  @Input() fileLabel = 'certificate_file';
  @Output() delete = new EventEmitter();
  @Output() save = new EventEmitter();

  deleteFile(fileId, index) {
    this.delete.emit({fileId, index});
  }

  handleFileInput(files , fileId , index) {
    this.file.error = false;
    const size = files[0].size;
    if (size < 5000000) {
      this.save.emit({files, fileId, index});
    } else {
      this.file.error = true;
    }
  }

}
