<div class="mini-card">
  <div class="card-flex-content" (click)="cardFilter()">
    <div>
      <p class="data {{ card.textColor }}">{{ card.value }}</p>
      <p class="title">{{ card.name }}</p>
    </div>
    <div class="donut-chart-wrapper">
      <div [id]="cardId"></div>
    </div>
  </div>
</div>
