import { Component, EventEmitter, Input, Output } from '@angular/core';
declare var Highcharts: any;
@Component({
  selector: 'region-map',
  templateUrl: './region-map.component.html',
  styleUrls: ['./region-map.component.scss'],
})
export class RegionMapComponent {
  @Input() regionData;
  @Input() mapTitle;
  @Input() tooltipValue;
  @Input() tooltipTitle;
  @Output() selectedRegion = new EventEmitter();
  regionName: string;
  chart: any;
  data = [
    ['sa-tb', 'sa-tb'],
    ['sa-jz', 'sa-jz'],
    ['sa-nj', 'sa-nj'],
    ['sa-ri', 'sa-ri'],
    ['sa-md', 'sa-md'],
    ['sa-ha', 'sa-ha'],
    ['sa-qs', 'sa-qs'],
    ['sa-hs', 'sa-hs'],
    ['sa-jf', 'sa-jf'],
    ['sa-sh', 'sa-sh'],
    ['sa-ba', 'sa-ba'],
    ['sa-as', 'sa-as'],
    ['sa-mk', 'sa-mk'],
  ];
  selectedState: boolean;
  ngOnInit() {}


  getSelectedRegion(region) {
    this.selectedRegion.emit(region);
  }

  ngAfterViewInit() {
    let that = this;
    this.chart = Highcharts.mapChart('regionMap', {
      chart: {
        map: 'countries/sa/sa-all',
        spaces: [10, 0, 0, 0],
      },

      title: {
        text: '',
      },

      subtitle: {
        text: '',
      },

      mapNavigation: {
        enabled: false,
        buttonOptions: {
          verticalAlign: 'bottom',
        },
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        borderColor: '#FFFFFF',
        borderRadius: 10,
        borderWidth: 0,
        shared: true,
        shadow: false,
        useHTML: true,
        percentagedecimals: 2,
        className: 'list',
        headerFormat: '<span>',
        formatter: function () {
          if (that.regionData[this.point?.value]) {
            return (
              '<span class="header">' +
              that.regionData[this.point.value]?.region_name +
              '</span> ' +
              `<span class="item"><span class="data"><span class="dot green"></span><span>${that.tooltipTitle}</span></span><span class="statistics green">` +
               that.regionData[this.point.value][that.tooltipValue] +'</span></span>');
          }
        },
        footerFormat: '</span>',
        valueDecimals: 1,
        style: {
          zIndex: 9999,
        },
      },

      plotOptions: {
        series: {
          point: {
            events: {
              select: function () {
                that.regionName =
                  that.regionData[this.options.value].region_name;
                that.getSelectedRegion(
                  {'code':that.regionData[this.options.value].map_code, 'name':that.regionName}
                );
              },
              unselect: function () {
                if (that.regionName) {
                  return false;
                }
              },
            },
          },
        },
      },

      series: [
        {
          keys: ['hc-key', 'value', 'selected'],
          data: this.data,
          name: this.mapTitle,
          // colorByPoint: true,
          borderColor: '#272F47',
          borderWidth: 0.2,
          allowPointSelect: true,
          states: {
            hover: {
              cursor: 'pointer',
              color: '#A799B1',
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontFamily: 'cairo-semibold',
              pointerEvents: 'none',
              textShadow: false,
              textOutline: '0px',
              zIndex: 0,
            },

            formatter: function () {
              return (
                "<span class='datalabel'>" +
                that.regionData[this.point.value]?.region_name +
                '</span>'
              );
            },
          },
          color: '#F7EFFC',
        },
      ],
    });
  }
  resetFilter() {
    this.regionName = null;
    this.chart.getSelectedPoints()[0].select(false);
    this.getSelectedRegion(null);
  }
}
