import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SendNotificationComponent } from '../send-notification/send-notification.component';

@Component({
  selector: 'notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent implements OnInit {

  @Input() disableAction;
  @Input() selectedRows;
  @Input() totalRowsSelected;
  @Input() query;
  public notificationDialogRef: MatDialogRef<SendNotificationComponent>;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void { }

  openSendNotificationModal(isBrowser) {
    this.notificationDialogRef = this.dialog.open(SendNotificationComponent, {
      data: {
        selectedRecipient: this.selectedRows,
        isBrowser: isBrowser,
        totalRowsSelected: this.totalRowsSelected,
        query: this.query
      }
    });
  }

}
