import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SideBarMenu } from 'src/app/shared/global/sidebarMenu';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  ordersCount;
  sidebarstatus;
  isChild: boolean = false;
  sidebarMenu: any = [];
  showMenu: boolean = false;
  dropdowns: any = {};
  constructor(
    private router: Router,
    private titleService: Title,
  ) { }
 
  ngOnInit(): void {
   const currentUser = JSON.parse(localStorage.getItem('currentUser'));
   this.sidebarMenu = SideBarMenu.SIDEBAR_MENU_DATA[currentUser.user.profile.user_type];
   this.dropdowns = SideBarMenu.DROPDOWNS;
   this.activeSideItem();
  }

  checkMenu(name){
    if (this.router.url.includes(name)) {
      return true
    }
  }

  setStatus(status, title, isChild?) {
    this.sidebarstatus = status;
    this.titleService.setTitle(title);
    this.isChild = isChild ? isChild : false;
  }

  toggleSubMenu(name) {
    this.dropdowns[name] = !this.dropdowns[name];
  }

  clickItem(status, title, path, hideSubMenu) {
    this.sidebarstatus = status;
    this.titleService.setTitle(title);
    this.router.navigateByUrl(path);
    if (hideSubMenu) {
      Object.keys(this.dropdowns).forEach(item => {
        this.dropdowns[item] = false;
      })
    }
  }
  activeSideItem() {
    this.sidebarMenu.forEach(item => {
      if(item.isSubMenu) {
        item.subMenuItems.forEach(subItem => {
          if(this.router.url === subItem.url) {
            this.dropdowns[item.sidebarstatus] = true;
            this.setStatus(subItem.sidebarstatus, subItem.pageTile, true);
          }
        })
      }
      else {
        if(this.router.url === item.url) {
          this.setStatus(item.sidebarstatus, item.pageTile);
        }
      }
    })
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/auth/login');
  }
}
