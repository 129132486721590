<div class="dialog-container">
  <h2 mat-dialog-title>{{ title }}</h2>
  <div mat-dialog-content>
    <form class="needs-validation" [formGroup]="addClientFormGroup">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label">الاسم الأول</label>
            <input formControlName="first_name" class="form-control" type="text">
            <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label">الاسم الأخير </label>
            <input formControlName="last_name" class="form-control" type="text">
            <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="form-label"> البريد الإلكتروني </label>
            <input formControlName="email" class="form-control" type="email">
            <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group ">
            <label class="form-label"> رقم الجوال </label>
            <input value="" formControlName="phone_number" name="phone_number" type="tel" maxlength="11"
              class="form-control" id="phoneNumber" appPhoneNumber placeholder="5X XXX XXXX" />
              <span class="static-code">966+</span>
              <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">

            </div>
          </div>
          <div class="col-md-12">
            <app-switch-input [label]="'حساب تجريبي'" [labelEn]="'is_test'" [value]="is_test" [index]='2'
              (valueChanged)="codeValueChanged($event)">
            </app-switch-input>
          </div>
        </div>
        @if (alertmsg) {
          <div class="alert-box">
            <img src="assets/images/svg/error-icon.svg" class="close-alert" (click)="closeError()" alt="" />
            <p class="alert-msg">{{message}}</p>
          </div>
        }
      </form>
    </div>
    <div mat-dialog-actions class="actions-double">
      <a class="btn btn-cancel center-btn" mat-dialog-close>إلغاء</a>
      <button class="btn btn-green center-btn" (click)="addClient()">إضافة</button>
    </div>
  </div>
