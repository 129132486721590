import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-dynamic',
  templateUrl: './modal-dynamic.component.html',
  styleUrls: []
})
export class ModalDynamicComponent {

}
