import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AcadamicMajorResolver,
  AccountCategoryResolver,
  AccountInfoResolver,
  CertificatesResolver,
  CitiesResolver,
  CountryResolver,
  ExperinceYearsResolver,
  ExpertiseFocusResolver,
  FacilitiesResolver,
  QualificationsResolver,
  RegionResolver,
  RegistrationAsResolver,
  ServicesResolver,
  SpecialQualificationsResolver,
} from 'src/app/core/_helpers/resolvers/inspector.resolver';
import { InspectorsComponent } from './inspectors.component';
import { InspectorGridComponent } from './pages/inspector-grid/inspector-grid.component';
import { InspectorProfileComponent } from './pages/inspector-profile/inspector-profile.component';
import { InspectorStatisticsComponent } from './pages/inspector-statistics/inspector-statistics.component';
import { InspectorsSettingsComponent } from './pages/inspectors-settings/inspectors-settings.component';

const routes: Routes = [
  {
    path: '',
    component: InspectorsComponent,
    resolve: {
      qualifactions: QualificationsResolver,
      acadamicMajor: AcadamicMajorResolver,
      experinceYears: ExperinceYearsResolver,
      registrationAs: RegistrationAsResolver,
      expertiseFocus: ExpertiseFocusResolver,
      specialQualifications : SpecialQualificationsResolver,
      countries: CountryResolver,
      regions: RegionResolver,
      coveredCities: CitiesResolver,
      services: ServicesResolver,
      accountInfo: AccountInfoResolver,
      accountCategory: AccountCategoryResolver,
      certificates: CertificatesResolver,
      facilities: FacilitiesResolver
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'inspectors-grid' },
      { path: 'inspectors-grid', component: InspectorGridComponent },
      { path: 'inspector-profile/:id', component: InspectorProfileComponent },
      {
        path: 'inspectors-statistics',
        component: InspectorStatisticsComponent,
      },
      { path: 'settings', component: InspectorsSettingsComponent },
      { path: ':qualified', component: InspectorGridComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    QualificationsResolver,
    AcadamicMajorResolver,
    ExperinceYearsResolver,
    RegistrationAsResolver,
    ExpertiseFocusResolver,
    SpecialQualificationsResolver,
    CountryResolver,
    RegionResolver,
    CitiesResolver,
    ServicesResolver,
    AccountInfoResolver,
    AccountCategoryResolver,
    CertificatesResolver,
    FacilitiesResolver
  ],
})
export class InspectorsRoutingModule {}
