import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
/**
declar select render component 
 *  
*/
@Component({
  selector: 'app-select-render',
  templateUrl: './select-render.component.html',
  styleUrls: []
})
export class SelectRenderComponent implements ICellRendererAngularComp {
/**
declare value variable
 *  
*/
  public value ;
/**
declar allValues array variable
 *  
*/
  public allValues :any[];
/**
declar aginit function
 *  
*/
  agInit(params: any): void {
    this.allValues = params.values
    this.setValue(params);
  }
/**
declar setValue function
 *  
*/
  setValue(params) {
    if(params.value) {
      this.allValues.forEach((value)=> {
        if(value.id == params.value) {
         this.value = value.name;
        }
      });
    } else {
      this.value = params.value;
    }
  }
/**
declar refresh function 
 *  
*/
  refresh(params: any): boolean {
    this.setValue(params);
    return true;
  }

}
