import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CouponsService } from 'src/app/features/copoun/services/coupons/coupons.service';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { BankCardsService } from '../../../../services/bank-cards/bank-cards.service';

@Component({
  selector: 'app-add-bank-num',
  templateUrl: './add-bank-num.component.html',
  styleUrls: ['./add-bank-num.component.scss'],
})
export class AddBankNumComponent implements OnInit {
  cardNums = [
    {
      code: 'input_0',
    },
  ];
  bin_codes = [];
  message = '';
  alertmsg: boolean = false;
  addbankFormGroup: UntypedFormGroup;
  confirmMsg: string = '';
  imgSuccess: boolean = false;
  bankID: any;
  title: string;
  saveBtn: string;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  @ViewChildren('bankInputs') bankInputs: ElementRef<HTMLInputElement>[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private couponsService: CouponsService,
    private bankService: BankCardsService,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.bankID = this.activatedRoute.snapshot.params.id;
    if (this.bankID > 0) {
      this.title = 'تعديل رقم بطاقة';
      this.saveBtn = 'تعديل';
      this.getBankByID(this.bankID);
      this.confirmMsg = 'تم تعديل البطاقة البنكية بنجاح';
    } else {
      this.title = '  إضافة رقم بطاقة';
      this.saveBtn = 'إضافة';
      this.confirmMsg = 'تم إضافة البطاقة البنكية بنجاح';
    }
    this.createForm();
    this.createFormControls();
  }

  createFormControls() {
    return this.addbankFormGroup.controls;
  }

  createForm() {
    this.addbankFormGroup = this.formBuilder.group({
      name: [null, Validators.required],
      description: [''],
    });
    for (let formModule of this.cardNums) {
      this.addbankFormGroup.addControl(
        formModule.code,
        new UntypedFormControl('', [
          Validators.pattern(/^(?:\d{1,11}|(?=\d+ \d+$)[\d ]{3,12})$/),
        ])
      );
    }
  }

  closeError() {
    this.alertmsg = false;
  }

  addCardNo() {
    let length = this.cardNums.length;
    let name = 'input_' + length;
    this.cardNums.push({ code: name });
    this.addbankFormGroup.addControl(
      name,
      new UntypedFormControl('', [
        Validators.pattern(/^(?:\d{1,11}|(?=\d+ \d+$)[\d ]{3,12})$/),
      ])
    );
    setTimeout(() => {
      this.bankInputs.forEach((item) => {
        if (item.nativeElement.id === name) {
          item.nativeElement.focus();
        }
      });
    }, 200);
  }

  getBankByID(bankID) {
    this.bankService.getBankByID(bankID).subscribe(
      (data) => {
        let bankData = data;
        this.addbankFormGroup.patchValue({
          name: bankData.name,
          description: bankData.description,
        });
        let bank_bin_codes = bankData.bin_codes;
        bank_bin_codes.forEach((code, index) => {
          if (index > 0) {
            this.cardNums.push({ code: 'input_' + index });
            this.addbankFormGroup.addControl(
              'input_' + index,
              new UntypedFormControl('', [
                Validators.pattern(/^(?:\d{1,11}|(?=\d+ \d+$)[\d ]{3,12})$/),
              ])
            );
          }
          this.addbankFormGroup
            .get(this.cardNums[index].code)
            .setValue(this.binCodeFormatter(code.code));
        });
      },
      (error) => {
        try {
          console.log(error);
        } catch {
          console.log(error);
        }
      }
    );
  }

  deleteCardNo(index) {
    this.cardNums.splice(index, 1);
    let control = 'input_' + index;
    this.addbankFormGroup.removeControl(control);
  }

  removeBinCodesSpace(str) {
    return str.replace(/\s/g, '');
  }

  save() {
    let bin_codes = [];
    let data = {
      name: this.addbankFormGroup.get('name').value,
      description: this.addbankFormGroup.get('description').value,
    };
    this.cardNums.forEach((element) => {
      bin_codes.push({
        code: this.removeBinCodesSpace(
          this.addbankFormGroup.get(element.code).value
        ),
      });
    });
    data['bin_codes'] = bin_codes;
    if (this.addbankFormGroup.valid) {
      if (this.bankID > 0) {
        this.bankService.putBankByID(this.bankID, data).subscribe(
          () => {
            this.imgSuccess = true;
            this.showAlertBox();
          },
          (error) => {
            this.handleError(error);
          }
        );
      } else {
        this.bankService.addBankCard(data).subscribe(
          () => {
            this.imgSuccess = true;
            this.showAlertBox();
          },
          (error) => {
            this.handleError(error);
          }
        );
      }
    } else {
      let fields = document.querySelectorAll('input.ng-invalid');
      fields.forEach((field) => {
        field.parentElement.classList.add('invalid-field');
        if (field.id.startsWith('input'))
          this.message = 'يجب ادخال ارقام فقط في ارقام البطاقات';
        else if (field.id == 'bank_name') this.message = 'يرجى إدخال اسم البنك';
      });
      this.alertmsg = true;
    }
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.imgSuccess,
        message: this.confirmMsg,
        backUrl: '/pages/coupons/bank-list',
        button: 'رجوع',
      },
    });
  }

  handleError(error) {
    let bins = error.error.bin_codes;
    var allinputs = document.querySelectorAll('input.cardVal');
    var myLength = allinputs.length;
    var input;
    for (var i = 0; i < myLength; ++i) {
      input = allinputs[i];
      for (let bin of bins) {
        var formattedVal = this.binCodeFormatter(bin);
        if (input.value == formattedVal) {
          input.parentElement.classList.add('invalid-field');
        }
      }
    }
    this.message = 'بعض أرقام البطاقات الموجودة موجودة مسبقاً.';
    this.alertmsg = true;
    try {
      console.log('error');
    } catch (err) {
      console.log(err);
    }
  }

  back() {
    this.router.navigateByUrl('/pages/coupons/bank-list');
    this.titleService.setTitle('البطاقات البنكية | عاين');
  }

  addSpace(e) {
    let value = e.target.value;
    value = value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
    this.addbankFormGroup.get(e.target.id).setValue(value);
  }

  binCodeFormatter(str) {
    return str.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
  }
}
