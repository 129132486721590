import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdminOrdersService } from 'src/app/features/orders/services/orders/admin-orders.service';
import { OrderState } from 'src/app/features/orders/state/order.state';
@Component({
  selector: 'app-inspector-info',
  templateUrl: './inspector-info.component.html',
  styleUrls: ['./inspector-info.component.scss'],
})
export class InspectorInfoComponent {
  orders: any;
  flag: boolean = false;
  paid = {
    is_paid: true,
  };
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;

  constructor(private order: AdminOrdersService) {}
  ngOnInit(): void {
    this.orderDetails$.subscribe((data) => {
      this.orders = data;
    });
  }

  deliveredOrder(id, paid) {
    this.flag = true;
    this.order.changePaymentInspectorStatus(id, paid).subscribe(
      (data) => {
        this.orders.cost.inspector_payment_status = 'مدفوعة';
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }
}
