<div class="form-group switch-container">
  <label for="" class="form-label">
    {{label}}
    @if (labelDesc) {
      <span class="switch-desc">{{labelDesc}}</span>
    }
  </label>
  <div class="md-switch">
    <input type="checkbox"
      #switchBtn
      [disabled]="disableBtn"
      id="text-to-confirm-{{index}}"
      [(ngModel)]="value"
      [checked]="value"
      (change)="changeValue($event.target)"
      name="text-to-confirm-{{index}}"
      class="md-toggle md-toggle-round">
      <label for="text-to-confirm-{{index}}"></label>
    </div>
  </div>
