<!-- START MAIN SECTION -->
<main class="login-layout">
  <p class="dashbard-title">
    منصة تحكم عاين
  </p>
  <div class="login-card">
    <h3 class="login-title">تسجيل الدخول</h3>

    <form class="needs-validation" novalidate id="login-form" ngNativeValidate [formGroup]="loginFormGroup">
      <div class="form-group">
        <label class="form-label">البريد الإلكتروني</label>
        <input type="email" formControlName="email" required class="form-control" name="email" id="email" />
      </div>
      <div class="form-group">
        <label class="form-label">كلمة المرور</label>
        <input type="password" formControlName="password" required class="form-control" name="password"
          id="password" />
        </div>
        <div class="forget-pass">
          <a class="login-action" routerLink="/auth/recover-password">
            نسيت كلمة المرور؟
          </a>
        </div>
        <div class="form-group remeber-me">
          <input type="checkbox" id="remeberMe" formControlName="remember_me" value="false"
            (change)="remeberMe($event.target)" />
            <label for="remeberMe">تذكرنى</label>
          </div>
          <div class="login-btn">
            <button class="btn btn-auth" type="submit" [disabled]="!loginFormGroup?.valid" (click)="save()">
              تسجيل الدخول
            </button>
          </div>

          @if (errorAuth) {
            <div class="alert-box">
              <img src="assets/images/svg/error-icon.svg" class="close-alert" alt="" (click)="closeError()" />
              <p class="alert-msg">خطأ في البريد الإلكتروني أو كلمة المرور</p>
            </div>
          }
        </form>
      </div>
    </main>
