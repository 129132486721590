<!-- start page header-->
<div class="inner-page-header">
  <h3>الفاحصين</h3>
</div>
@if (rowData) {
<div class="option-header">
  <div class="request-control">
    <div class="delete-icon-grid">
      <button
        class="btn btn-custom-delete"
        (click)="goProfile()"
        [disabled]="disableActionProfile"
      >
        <img src="assets/images/svg/account.svg" alt="" />
      </button>
    </div>
    @if (qualified == 'qualified') {
    <delete-selection
      [disabledDelete]="disabledDelete"
      [id]="inspectorId"
      [index]="deleteInspectorIndex"
      [deleteMessage]="deleteMsg"
      (deleteResult)="delete($event)"
    >
    </delete-selection>
    } @if (filterData) {
    <app-filter
      [filterData]="filterData"
      [filterDataInit]="filterDataInit"
      [filterPage]="filterPage"
      (formValus)="filterFormValues($event)"
    >
    </app-filter>
    } @if (qualified != 'qualified') {
    <div class="add-service">
      <button
        class="btn btn-add-service"
        (click)="addService()"
        [disabled]="disabledAddService"
      >
        <img src="assets/images/svg/add-logo.svg" alt="" />
        إضافة خدمة
      </button>
    </div>
    }
    <quick-search (searchResults)="quickSearch($event)"></quick-search>
    <div class="new-element">
      <div class="instruction">
        <notification-menu
          [disableAction]="disableAction"
          [totalRowsSelected]="totalRowsSelected"
          [selectedRows]="selectedRows"
          [query]="search"
        >
        </notification-menu>
      </div>
      <button
        class="btn btn-green mr-2"
        (click)="handelExportExcel()"
        [disabled]="download"
      >
        @if (download) {
        <img
          class="spin-img"
          src="assets/images/svg/spinner-download.svg"
          alt=""
        />
        } تحميل كملف اكسيل
      </button>
    </div>
  </div>
  @if (qualified == 'qualified') {
  <div class="apply-action">
    <button
      class="btn btn-add"
      mat-button
      [matMenuTriggerFor]="menu"
      [ngClass]="{ disabled: disableSelect == true }"
    >
      {{ commandName }}
      <img src="assets/images/svg/select-icon.svg" alt="" />
    </button>
    <mat-menu #menu="matMenu" backdropClass="action-menu">
      @for (action of actionList; track action) {
      <a
        mat-menu-item
        (click)="getcommandType(action.command_type, action?.name_arabic)"
        >{{ action?.name_arabic }}
      </a>
      }
    </mat-menu>
    <button
      class="btn btn-primary"
      (click)="applyCommandAction()"
      [disabled]="disableActionBtn"
    >
      تنفيذ أمر
    </button>
  </div>
  }
</div>
}
<div class="saved-filters">
  <saved-filters></saved-filters>
</div>

@if (inspectors && inspectors.length > 0) {
<ayen-selected-count
  [itemsPerPage]="currentItemsPerPage"
  [selectedRows]="selectedRows"
  [totalRowsSelected]="totalRowsSelected"
  [pageTitle]="pageTitle"
  [totalItems]="config.totalItems"
  (setTotalRowsSelected)="selectTotalRows($event)"
>
</ayen-selected-count>
}

<div class="grid-section">
  <ag-grid-angular
    #agGrid
    style="width: 100%; height: 100%"
    id="myGrid"
    enableRtl="true"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [rowData]="rowData | paginate : config"
    (cellValueChanged)="onCellValueChanged($event)"
    [components]="frameworkComponents"
    [defaultColDef]="defaultColDef"
    [getRowId]="getRowId"
    (gridReady)="onGridReady($event)"
    [rowSelection]="rowSelection"
    (rowSelected)="onRowSelected($event)"
    [paginationPageSize]="paginationPageSize"
  >
  </ag-grid-angular>
</div>

@if (inspectors && inspectors.length > 0) {
<ayen-pagination
  (pageData)="pageChanged($event)"
  (pageSize)="pageSizeChanged($event)"
  [totalCount]="config.totalItems"
  [pageTitle]="pageTitle"
>
</ayen-pagination>
}
<ag-grid-angular
  #agGrid
  id="myGrid2"
  enableRtl="true"
  class="ag-theme-alpine"
  [columnDefs]="columnDefs"
  [components]="frameworkComponents"
  [defaultColDef]="defaultColDef"
  (gridReady)="excelGridReady($event)"
>
</ag-grid-angular>
