import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AYENServiceBase } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class InspectionService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getInspectionPurpose(): Observable<any> {
    return this.http.get(this.baseURL + '/inspection-purposes/').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getInspectionStatus(): Observable<any> {
    return this.http.get(this.baseURL + '/status/').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getinspectionPeriods(): Observable<any> {
    return this.http.get(this.baseURL + '/inspection-periods/').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  requestReinspection(orderIds): Observable<any> {
    return this.http
      .post(this.baseURL + '/admin/orders/create-reinspection-order/', orderIds)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  handleError(error) {
    let errorMessage;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
