import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RatingService } from 'src/app/core/services/rating/rating.service';

@Component({
  selector: 'qc-survey',
  templateUrl: './qc-survey.component.html',
  styleUrls: ['./qc-survey.component.scss']
})
export class QcSurveyComponent implements OnInit {

  surveyFormGroup: UntypedFormGroup;
  public ratingArr = [1, 2, 3, 4, 5];
  msgError: string = '';
  constructor(private formBuilder: UntypedFormBuilder,
    private ratingService: RatingService,
    public dialogRef: MatDialogRef<QcSurveyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.data['orderID'] = this.data['orderID'];
    this.data['title'] = this.data['title'] ?? 'تقييم ضمان الجودة';
    this.data['btnSubmit'] = this.data['btnSubmit'] ?? 'تقييم';
    this.createForm();
    this.createFormControls();
  }

  ngOnChanges() {
    this.msgError = '';
  }

  createFormControls() {
    return this.surveyFormGroup.controls;
  }

  createForm() {
    this.surveyFormGroup = this.formBuilder.group({
      order: [this.data.orderID],
      inspector_rating: [1],
      qc_notes: [""],
      active: [true]
    });
  }

  onClick(rating: number, formControl) {
    this.surveyFormGroup.controls[formControl].setValue(rating);
  }

  showIcon(index: number, formControl) {
    let rating = this.surveyFormGroup.controls[formControl].value;
    if (rating >= index + 1) {
      return 'assets/images/svg/star-icon.svg';
    } else {
      return 'assets/images/svg/star-border-icon.svg';
    }

  }

  addSurvey() {
    this.createQcSurvey();
  }

  createQcSurvey() {
    this.ratingService.createQcSurvey(this.surveyFormGroup.value).subscribe(data => {
      if (data.success) {
        let resData = {};
        resData['results'] = {
          active: data.active,
          id: data.id,
          inspector_rating: data.inspector_rating,
          order: data.order,
          qc_notes: data.qc_notes,
          submitted_by: data.submitted_by
        }
        resData['success'] = data.success
        resData['msg'] = data.msg
        this.dialogRef.close(resData);
      }
      else {
        this.msgError = data.msg;
      }
    },
      err => {
        try {
          this.msgError = "لا يمكن اضافة اكثر من تقييم";
        }
        catch {}
      });
  }
}
