import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { CustomersComponent } from './customers.component';
import { CustomersRoutingModule } from './customers-routing.module';
import { CustomersGridComponent } from './pages/customers-grid/customers-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatMenuModule } from '@angular/material/menu';
import { CustomerProfileComponent } from './pages/customer-profile/customer-profile.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomersRatingGridComponent } from './pages/customers-rating-grid/customers-rating-grid.component';

@NgModule({
  declarations: [
    CustomersComponent,
    CustomersGridComponent,
    CustomerProfileComponent,
    CustomersRatingGridComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CustomersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatMenuModule,
    MatExpansionModule,
    NgSelectModule,
    AgGridModule,
  ],
})
export class CustomersModule {}
