import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddBankNumComponent } from './pages/bank-cards/pages/add-bank-num/add-bank-num.component';
import { BankListComponent } from './pages/bank-cards/pages/bank-list/bank-list.component';
import { CopounDetailsComponent } from './pages/copoun-details/copoun-details.component';
import { CopounListComponent } from './pages/copoun-list/copoun-list.component';
import { CopounComponent } from './copoun.component';

const routes: Routes = [
  {
    path: '',
    component: CopounComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'copoun-list' },
      { path: 'copoun-list', component: CopounListComponent },
      { path: 'bank-list', component: BankListComponent },
      { path: 'add-bank/0', component: AddBankNumComponent },
      { path: 'add-bank/:id', component: AddBankNumComponent },
      { path: '0', component: CopounDetailsComponent },
      { path: ':id', component: CopounDetailsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CopounRoutingModule {}
