import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { expand, reduce } from 'rxjs/operators';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsService extends AYENServiceBase {

  constructor(private http: HttpClient) {
    super();
  }

  getClientByPage(query): Observable<any> {
    return this.http.get(this.baseURL + '/clients/', { params: query });
  }

  deleteClients(clientId): Observable<any> {
    return this.http.delete(this.baseURL + '/clients/' + clientId);
  }

  updateClientById(id, client) {
    return this.http.put<any>(this.baseURL + "/clients/" + id + '/', client);
  }

  getClientByID(id): Observable<any> {
    return this.http.get(this.baseURL + `/clients/${id}`);
  }

  addClients(client): Observable<any> {
    return this.http.post(this.baseURL + '/user/', client);
  }

  getAllClients(params): Observable<any> {
    let param = params ? params : '?';
    return this.http.get(this.baseURL + `/all-clients/${param}`);
  }

  getCustomersRating(query): Observable<any> {
    return this.http.get(this.baseURL + `/customer-ratings/`, { params: query });
  }

  getFinalStatus(): Observable<any> {
    return this.http.get(this.baseURL + `/final-status`);
  }

  updateClientRateById(id, clientRate) {
    return this.http.put<any>(this.baseURL + "/customer-ratings/" + id + '/', clientRate);
  }

  getAllCustomerRating(query) {
    return this.http.get(this.baseURL + '/all-customer-ratings', { params: query });
  }
}
