<div #container [formGroup]="subForm" class="form-group">
  <label>
    {{inputs.field_label_name}}
    @if (!isRequired) {
      <span class="optional">(اختياري)</span>
    }
  </label>
  <ng-select #select [multiple]="true" class="single-select"
    [items]="optionItems"
    [closeOnSelect]="false"
    (change)="onChange($event)"
    placeholder="{{inputs.field_placeholder}}"
    [appendTo]="'div'"
    bindLabel="choice_display_value"
    [readonly]="disableSelect"
    clearable="false"
    formControlName="{{inputs.field_key_name}}"
    bindValue="choice_value">

    <ng-template ng-multi-label-tmp let-items="optionItems" let-clear="clear">
      @for (item of optionItems | slice:0:maxLength; track item) {
        <div class="ng-value">
          <span class="ng-value-label">{{item.choice_display_value}}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">
            <img src="/assets/images/svg/cancel-tag.svg" alt=""></span>
          </div>
        }
        <!-- | slice:0:4 -->
        @if (items.length > maxLength) {
          <div class="ng-value">
            <span class="ng-value-label">{{items.length - maxLength}}+...</span>
          </div>
        }
      </ng-template>

      <ng-template ng-header-tmp let-items="items">
        <label class="checkbox-container">
          <input type="checkbox" type="checkbox" [checked]="selectAll" value="0" #allSelected
            (change)="toggleSelectAll($event)">
            <span class="checkmark"></span>
          </label>
          <span class="checkbox-label"> تحديد الكل </span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <label class="checkbox-container">
            <input type="checkbox" id="item-{{index}}" type="checkbox" [checked]="item$.selected">
            <span class="checkmark" (click)="checkmark(item.choice_value,index,item$.selected)"> </span>
          </label>
          <span class="checkbox-label"> {{item.choice_display_value}} </span>
        </ng-template>
      </ng-select>
    </div>
