<div class="dialog-container">
  <h2 mat-dialog-title>الإرجاع</h2>
  <div mat-dialog-content>
    <form [formGroup]="refundFormGroup">
      <div class="form-group">
        <label for="" class="form-label">
          نوع الإرجاع
        </label>
        <ng-select bindLabel="name" class="single-select" placeholder=" يرجى اختيار نوع الإرجاع " appendTo="body"
          [searchable]="true" formControlName="type" (change)="TypeChanged($event)" [clearable]="true">
          @for (type of refundTypes; track type) {
            <ng-option [value]="type.id">
              {{type.name}}
            </ng-option>
          }
        </ng-select>
      </div>
      @if (constant) {
        <div class="form-group">
          <label for="" class="form-label">
            مبلغ الإرجاع
          </label>
          <span class="static-code">{{valueMark}}</span>
          <input type="number" id="value" class="form-control" formControlName="value" (change)="calculateRefundValue()">
        </div>
      }
      <div class="form-group">
        <label for="" class="form-label">
          سبب الإرجاع
        </label>
        <ng-select bindLabel="name" class="single-select" placeholder=" اختر من القائمة " appendTo="body"
          [searchable]="true" formControlName="reason" (change)="reasonChanged($event)" [clearable]="true">
          @for (reason of refundReasons; track reason) {
            <ng-option [value]="reason.id">
              {{reason.name}}
            </ng-option>
          }
        </ng-select>
      </div>
      @if (isError) {
        <p class="invalid">{{errorMsg}}</p>
      }
    </form>
    <div class="req-refund">
      <div class="req-row">
        <div class="req-full-box">
          <div class="req-data"> المبلغ المدفوع </div>
          <div class="req-title">{{data.final_cost}}<span> ر.س </span></div>
        </div>
        @if (insp_fees || payment_fees) {
          <div class="req-full-box">
            <div class="req-data"> خصم الرسوم الإدارية </div>
            <span> - </span>
            <div class="req-title">{{payment_fee}}<span> ر.س </span></div>
          </div>
        }
        @if (insp_fees) {
          <div class="req-full-box">
            <div class="req-data"> خصم رسوم زيارة الفاحص </div>
            <span> - </span>
            <div class="req-title"> {{inspector_fees}}<span> ر.س </span></div>
          </div>
        }
        @if (tax_status) {
          <div class="req-full-box">
            <div class="req-data"> ضريبة القيمة المضافة </div>
            <span> - </span>
            <div class="req-title"> {{tax}}<span> ر.س </span></div>
          </div>
        }
        <div class="req-full-box total-refund">
          <div class="req-data"> إجمالي مبلغ الإرجاع </div>
          <div class="req-title">{{remain_cost}}<span> ر.س </span></div>
        </div>
      </div>
    </div>
    <p class="invalid">{{apiError}}</p>
  </div>

  <div mat-dialog-actions class="actions-double">
    <button class="btn btn-grey2" mat-dialog-close>إلغاء</button>
    <button class="btn btn-success" (click)="save()" [disabled]="disableSave">موافق</button>
  </div>
</div>
