import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class OrderFilesService extends AYENServiceBase {

  orderFilesList: any = [];
  orderFile = {};
  fileLabel = {
    has_structural_guarantee_file: 'شهادة ضمان الهيكل الإنشائي',
    has_finishes_warranty_year_file: ' شهادة ضمان التشطيبات',
    has_original_iron_receipt_reports_file: 'تقرير استلام حديد التسليح',
    has_business_completion_certificate_file: ' شهادة إشراف من المكتب الهندسي',
    has_construction_license_file: 'رخصة البناء',
    has_soil_report_file: 'تقرير فحص التربة',
    has_architectural_blueprints: 'المخططات المعمارية',
    has_structural_drawings: 'المخططات الإنشائية',
    has_electrical_diagrams: 'المخططات الكهربائية',
    has_mechanical_diagrams: 'المخططات الميكانيكية',
    has_form_cadastral: 'استمارة الرفع المساحي',
  }

  file = {};
  constructor(private http: HttpClient) {
    super();
  }

  createOrderFilesList(order) {
    this.orderFilesList = [];
    Object.entries(order).forEach(
      ([key, value]: any[]) => {
        if (key === 'has_structural_guarantee_file' ||
          key === 'has_finishes_warranty_year_file' ||
          key === 'has_original_iron_receipt_reports_file' ||
          key === 'has_business_completion_certificate_file' ||
          key === 'has_construction_license_file' ||
          key === 'has_architectural_blueprints' ||
          key === 'has_structural_drawings' ||
          key === 'has_electrical_diagrams' ||
          key === 'has_mechanical_diagrams' ||
          key === 'has_form_cadastral' ||
          key === 'has_soil_report_file') {
          this.orderFile = {
            label: this.fileLabel[key],
            orderlabel: key,
            name: value,
            exist: value ? true : false
          }
          this.orderFilesList.push(this.orderFile)
        }
      });
    return this.orderFilesList;
  }

  getEmptyFileList() {
    let files = [];
    Object.entries(this.fileLabel).forEach(
      ([key, value]: any[]) => {
        this.file = {
          name: '',
          enLabel: key,
          arLabel: value,
          exist: false
        }
        files.push(this.file);
      });
    return files;
  }


  updateOrderDocuments(orderID, data): Observable<any> {
    return this.http.put(this.baseURL + `/admin-order-documentations/${orderID}/`, data, {
      reportProgress: true,
      observe: 'events',
    })
  }
}
