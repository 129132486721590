import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewServiceComponent } from './pages/new-service/new-service.component';
import { ServicesListComponent } from './pages/services-list/services-list.component';
import { ServicesComponent } from './services.component';
import { DynamicAdditionsComponent } from './pages/dynamic-additions/dynamic-additions.component';
import { DynamicAdditionsDetailsComponent } from './pages/dynamic-additions-details/dynamic-additions-details.component';
import { ServicesSettingsComponent } from './pages/services-settings/services-settings.component';

const routes: Routes = [
  {
    path: '',
    component: ServicesComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      { path: 'list', component: ServicesListComponent },
      { path: 'settings/:id', component: ServicesSettingsComponent },
      { path: 'new', component: NewServiceComponent },
      { path: 'edit/:id', component: NewServiceComponent },
      { path: 'additions', component: DynamicAdditionsComponent },
      { path: 'additions/new', component: DynamicAdditionsDetailsComponent },
      { path: 'additions/edit/:id', component: DynamicAdditionsDetailsComponent }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ServicesRoutingModule {}
