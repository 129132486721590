<div class="inner-page-header">
  <h3>{{ title }}</h3>
</div>
<form
  class="needs-validation"
  id="general-form"
  [formGroup]="projectFormGroup"
  (ngSubmit)="save()"
  >
  <div class="main-card">
    <div class="row">
      <div class="flex-wrapper">
        <div class="client-deliver flex-child">
          @if (contractAcquiringUnitUrl) {
            <div>
              <button
                type="button"
                class="btn btn-yellow"
                (click)="copyReportLink()"
                >
                <img src="assets/images/svg/copy-report-link.svg" alt="" />
                <span>نسخ الرابط تسليم الوحدة</span>
              </button>
            </div>
          }
          <app-switch-input
            [label]="'جاهز لتسليم العملاء'"
            [labelEn]="'is_acquirable'"
            [value]="projectFormGroup.controls['is_acquirable'].value"
            (valueChanged)="clientStatus($event)"
            >
          </app-switch-input>
        </div>
        <div class="project-link">
          <a (click)="goToOrders()">الطلبات المتعلقه بالمشروع</a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label"> اسم المشروع </label>
          <input
            type="text"
            class="form-control"
            formControlName="name"
            placeholder="يرجى كتابة اسم المشروع"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label"> تابع لشركة</label>
            <ng-select
              #selectcomp
              bindLabel="name_arabic"
              (scrollToEnd)="onScrollPagination()"
              class="single-select"
              formControlName="company"
              placeholder="يرجى كتابة اسم الشركة"
              [searchable]="true"
              (removed)="resetSearch()"
              (search)="searchText()"
              (clear)="resetSearch()"
              (open)="resetSearch()"
              [virtualScroll]="true"
              [clearable]="true"
              >
              @for (company of companies; track company) {
                <ng-option [value]="company.pk">
                  {{ company.name_arabic }}
                </ng-option>
              }
            </ng-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label">
              كود المشروع
              <span class="optional">(اختياري)</span>
            </label>
            <input
              type="text"
              class="form-control"
              formControlName="shortcode"
              placeholder="يرجى كتابة كود المشروع"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="" class="form-label">
                عدد الوحدات
                <span class="optional">(اختياري)</span>
              </label>
              <input
                type="number"
                class="form-control"
                formControlName="units_count"
                placeholder="يرجى كتابة عدد الوحدات"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="form-label">تاريخ إعتماد المشروع</label>
                <mat-form-field (click)="picker.open()" appearance="outline">
                  <input
                    matInput
                    formControlName="active_date"
                    [matDatepicker]="picker"
                    disabled
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker">
                      <mat-icon matDatepickerToggleIcon class="calender-icon">
                        <img src="assets/images/svg/calendar-admin-icon.svg" alt="" />
                      </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="" class="form-label">تاريخ تسليم العملاء</label>
                  <mat-form-field (click)="picker_end.open()">
                    <input
                      matInput
                      formControlName="expiry_date"
                      [matDatepicker]="picker_end"
                      disabled
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker_end">
                        <mat-icon matDatepickerToggleIcon class="calender-icon">
                          <img src="assets/images/svg/calendar-admin-icon.svg" alt="" />
                        </mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker_end disabled="false"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label file-label">
                      ملف العقد
                      <span class="optional">(اختياري)</span>
                    </label>
                    @if (fileName) {
                      <div class="existance-wrapper">
                        <div class="existance">
                          <img src="assets/images/svg/file-name-icon.svg" alt="" />
                          <span>{{ sliceFileName(fileName) }}</span>
                        </div>
                        <div>
                          <button class="btn delete-wrapper" (click)="deleteFile()">
                            <img src="assets/images/svg/DELETE1.svg" alt="" />
                          </button>
                        </div>
                      </div>
                    }
                    @if (!fileName) {
                      <div class="drag-btn">
                        <button
                          type="button"
                          class="btn btn-outline-secondary add-new-file"
                          >
                          <img src="assets/images/svg/upload-img.svg" alt="" />
                          رفع ملف
                        </button>
                        <input
                          type="file"
                          name=""
                          (change)="handleFileInput($event.target.files)"
                          accept="application/pdf"
                          class="file-new form-control"
                          />
                          @if (fileSizeErr) {
                            <div class="file-error">
                              <span
                                >عفوا لا يمكن رفع صور حجم اكبر من
                                {{ MAX_FILE_SIZE / 1000000 }} GB
                              </span>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="" class="form-label">
                        وصف العقد
                        <span class="optional">(اختياري)</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="description"
                        placeholder="يرجى كتابة وصف العقد"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 custom-control">
                      <div class="form-group">
                        <label for="" class="form-label"> خدمات العقد</label>
                        <app-select
                          [optionItems]="services"
                          [placeholder]="'يرجى تحديد خدمات العقد'"
                          [id]="'services'"
                          [bindValue]="'pk'"
                          [initValues]="initServices"
                          (selectedOptions)="setServices($event)"
                          >
                        </app-select>
                      </div>
                    </div>
                    <div class="col-md-6 custom-control">
                      <div class="form-group">
                        <label for="" class="form-label"> خدمات البنود</label>
                        <app-select
                          [optionItems]="dynamicServicesArr"
                          [placeholder]="'يرجى تحديد خدمات البنود'"
                          [id]="'dynamic_services'"
                          [bindLabel]="'name'"
                          [bindValue]="'id'"
                          [dynamicServicesItems]="dynamicServicesItemsData"
                          [initValues]="initDynamicServices"
                          (selectedOptions)="setDynamicServices($event)"
                          (onScrollValues)="onScrollOptions($event)"
                          (updateOptions)="updateDynamicServices($event)"
                          (openSelect)="getDynamicServices()"
                          >
                        </app-select>
                      </div>
                    </div>
                    <div class="col-md-6 custom-control">
                      <div class="form-group">
                        <label for="" class="form-label">
                          إضافة فاحصين للمشروع
                          <span class="optional">(اختياري)</span>
                        </label>
                          <app-select
                          [optionItems]="inspectors"
                          [id]="'inspector'"
                          [placeholder]="'إضافة فاحصين للمشروع'"
                          [bindLabel]="'full_name'"
                          [bindValue]="'id'"
                          [initValues]="initInspectors"
                          (selectedOptions)="setInspectors($event)"
                          (onScrollValues)="onScrollInspectorOptions($event)"
                          (updateOptions)="updateInspectors($event)"
                          >
                        </app-select>
                       </div>
                    </div>
                  </div>
                  @if (alertmsg) {
                    <div class="alert-box">
                      <img
                        src="assets/images/svg/error-icon.svg"
                        class="close-alert"
                        (click)="closeError()"
                        alt=""
                        />
                        <p class="alert-msg">{{ message }}</p>
                      </div>
                    }
                  </div>
                  <div class="row">
                    <div class="col-md-12 double-action">
                      <button class="btn btn-cancel" type="button" (click)="goProjects()">
                        رجوع
                      </button>
                      <button class="btn btn-green" id="saveBtn" type="submit">
                        {{ saveBtn }}
                      </button>
                    </div>
                  </div>
                </form>
