import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs';
import { AYENServiceBase } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService extends AYENServiceBase{

  selectedFilter = new BehaviorSubject({});
  currentSelectedFilter = this.selectedFilter.asObservable();

  constructor(private http: HttpClient) { 
    super();
  }

  getFiltersData(params): Observable<any> {
    return this.http.get(this.baseURL + '/filters-data', { params: params });
  }

  saveFilter(param, filterData): Observable<any> {
    return this.http.post(this.baseURL + '/saved-filters', filterData, { params: param });
  }

  getSavedFilters(params): Observable<any> {
    return this.http.get(this.baseURL + '/saved-filters', { params: params });
  }

  deleteSavedFilter(pk): Observable<any> {
    return this.http.delete(this.baseURL + `/saved-filters`, { body: {pk: pk}});
  }

  changeSelectedFilter(updateFilter) {
    this.selectedFilter.next(updateFilter);
  }
 
}
