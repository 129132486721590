<div class="form-group" [formGroup]="subForm">
  <p class="custom-label">
    {{inputs.field_label_name}}
    @if (!isRequired) {
      <span class="optional">(اختياري)</span>
    }
  </p>
  <div class="checkboxs">
    @for (choice of inputs.choices; track choice) {
      <div class="checkBox-wrapper" >
        <label class="checkbox-container" >
          <input #checkbox  type="checkbox"
            disabled='{{!inputs.is_editable_admin}}'
            (change)="change($event)"
            value="{{choice.choice_display_value}}"
            name='{{choice.choice_value}}' >
            <span class="checkmark"> </span>
          </label>
          <span class="checkbox-label">
            {{choice.choice_display_value}}
          </span>
        </div>
      }
    </div>
  </div>
