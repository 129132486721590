@if (inputs.is_editable_admin) {
  <div class="form-group" [formGroup]="subForm">
    <label class="form-label">
      {{inputs.field_label_name}}
      @if (!isRequired) {
        <span class="optional">(اختياري)</span>
      }
    </label>
    @if (inputs.field_value?.length <10) {
      <div class="drag-btn">
        @if (displayUpload) {
          <div>
            <button type="button" class="btn btn-outline-secondary add-new-file" [ngClass]="{'error-file':alertmsg}">
              <img src="../../../../../assets/images/svg/upload-img.svg" alt="">
              <span class="upload-desc"></span>
            </button>
            <input type="file"
              multiple
              class="file-upload form-control"
              accept="{{inputs.validation.acceptance}}"
              (change)="uploadFile(inputs.field_key_name , $event)"
              />
            </div>
          }
        </div>
      }
      @if (successUpload && progress >0) {
        <div class="file-progress" >
          @for (name of fileName; track name) {
            <div class="file-progress-boxs col-md-12">
              <div class="progress-item">
                <img src="../../../../../assets/images/svg/Path 37746.svg" alt="">
              </div>
              <div class="progress-item upload-progress">
                <span class="file-name"> {{name}}</span>
                <progress id="bar" value="{{progress}}" max="100">
                </progress>
              </div>
              <div class="progress-item">
                <img id="cancel_upload" src="../../../../../assets/images/svg/upload-cancel-icon.svg" alt="">
              </div>
            </div>
          }
        </div>
      }
      <div class="upload-notes">
        <span>
          يرجى اختيار ملفات بامتداد (PNG، JPG) فقط وبحجم أقصى 5 MP لكل ملف
        </span>
      </div>
      @if (inputs.field_value) {
        <div >
          @for (value of inputs.field_value ; track value; let i = $index) {
            <div class="existance-wrapper">
              <div class="existance">
                <img src="../../../../../assets/images/svg/Path 37745.svg" alt="">
                <span>{{value?.name}}</span>
              </div>
              <div class="delete-file" (click)="deleteFile(value , i)">
                <img src='../../../../../assets/images/svg/Trash.svg' alt="">
              </div>
            </div>
          }
        </div>
      }
      @if (alertmsg) {
        <div class="alert-box">
          <p class="alert-msg">
            {{errorMessage}}
          </p>
        </div>
      }
    </div>
  }
  @if (!inputs.is_editable_admin) {
    <div class="form-group" [formGroup]="subForm">
      <label class="form-label">
        {{inputs.field_label_name}}
        @if (!isRequired) {
          <span class="optional">(اختياري)</span>
        }
      </label>
      <div class="arrayImg" >
        @for (img of inputs.field_value; track img) {
          <div class="imgs-value" (click)="openImg(img.src)">
            <img src="{{img?.src}}" alt="">
          </div>
        }
      </div>
    </div>
  }
