import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { ServicesRoutingModule } from './services-routing.module';
import { ServicesComponent } from './services.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgSelectModule } from '@ng-select/ng-select';
import { ServicesListComponent } from './pages/services-list/services-list.component';
import { NewServiceComponent } from './pages/new-service/new-service.component';
import { ChangeCategoryComponent } from './components/change-category/change-category.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxsModule } from '@ngxs/store';
import { NewServicesState } from './state/ayen-service.state';
import { AddCategoriesComponent } from './components/add-categories/add-categories.component';
import { MatMenuModule } from '@angular/material/menu';
import { ServiceCredentialsComponent } from './components/service-credentials/service-credentials.component';
import { DynamicAdditionsComponent } from './pages/dynamic-additions/dynamic-additions.component';
import { DynamicAdditionsDetailsComponent } from './pages/dynamic-additions-details/dynamic-additions-details.component';
import { ServicesSettingsComponent } from './pages/services-settings/services-settings.component';
import { ListInputsDialogeComponent } from './components/list-inputs-dialoge/list-inputs-dialoge.component';
import { RecommendationDialogeComponent } from './components/recommendation-dialoge/recommendation-dialoge.component';

@NgModule({
  declarations: [
    ServicesComponent,
    ServicesListComponent,
    NewServiceComponent,
    ChangeCategoryComponent,
    AddCategoriesComponent,
    ServiceCredentialsComponent,
    DynamicAdditionsComponent,
    DynamicAdditionsDetailsComponent,
    ServicesSettingsComponent,
    ListInputsDialogeComponent,
    RecommendationDialogeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatExpansionModule,
    MatDialogModule,
    NgSelectModule,
    SharedModule,
    ServicesRoutingModule,
    MatMenuModule,
    NgxsModule.forFeature([NewServicesState]),
  ],
})
export class ServicesModule {}
