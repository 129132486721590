import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SendSmsComponent } from './components/send-sms/send-sms.component';
import { SmsCampingsComponent } from './sms-campings.component';

const routes: Routes = [
  {
    path: '',
    component: SmsCampingsComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'send-sms' },
      { path: 'send-sms', component: SendSmsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SMSCampingsRoutingModule {}
