<div class="dialog-container">
  <h5 mat-dialog-title>محصلة التقييمات</h5>
  <div mat-dialog-content>
    <div class="text-center">
      <div class="rating-dev">
        <div class="rating-title">تقييم العملاء لمستوى تقديم للخدمة</div>
        <div class="rating-data">
          @if (CustomerhasRating) {
            <div class="stars" dir="rtl">
              <img
                src="../../../../../assets/images/svg/star-icon.svg"
                alt=""
                height="21px"
                width="20px"
                />
                <span>{{ inspectorReviewData?.client_service_avg_rating }}</span>
                <span
                  >({{ inspectorReviewData?.client_reviews_count
                  }}<span>تقييم)</span></span
                  >
                </div>
              }
              @if (!CustomerhasRating) {
                <span>لم يتم التقييم بعد</span>
              }
            </div>
          </div>
          <div class="rating-dev">
            <div class="rating-title">تقييم قسم الجودة لمستوى الفاحص</div>
            <div class="rating-data">
              @if (QChasRating) {
                <div class="stars" dir="rtl">
                  <img
                    src="../../../../../assets/images/svg/star-icon.svg"
                    alt=""
                    height="21px"
                    width="20px"
                    />
                    <span>{{ inspectorReviewData?.qc_inspector_avg_rating }}</span>
                    <span
                      >({{ inspectorReviewData?.qc_reviews_count
                      }}<span>تقييم)</span></span
                      >
                    </div>
                  }
                  @if (!QChasRating) {
                    <span>لم يتم التقييم بعد</span>
                  }
                </div>
              </div>
            </div>
            <div mat-dialog-actions>
              <a class="btn btn-cancel" mat-dialog-close>إغلاق</a>
            </div>
          </div>
        </div>
