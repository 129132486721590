import { EventEmitter, Output, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReportService } from 'src/app/core/services/report/report.service';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { AdminOrdersService } from '../../services/orders/admin-orders.service';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OrderState } from '../../state/order.state';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.scss'],
})
export class OrderReportComponent implements OnInit {

  fixedReport: boolean = false;
  acceptReportObject = {
    client_response: 'accept',
    notes: null,
  };

  confirmMsg = 'تم قبول التقرير';
  @Output() save = new EventEmitter();
  download: boolean = false;
  headerTxt: string = 'اعتراض على التقرير';
  generate_count: number = 0;
  @Output() errorMsg = new EventEmitter();
  @Output() notes = new EventEmitter();

  child: string = 'OrderReportComponent';
  confirmViewMsg: string =
    'هذا الإجراء سيؤدي لإتاحة اطلاع العميل على التقرير رغم وجود مبلغ مستحق عليه.';
  confirmBtn: string = 'تأكيد';

  imgSuccess: boolean;
  message: string;
  generate_album_count: number = 0;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  reportModalDialogRef: MatDialogRef<ReportModalComponent>;
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;
  orderData;
  downloadAlbum: boolean= false;
  downloadExcel: boolean = false;
  constructor(
    private report: ReportService,
    private orderService: AdminOrdersService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.orderDetails$.subscribe((data) => {
      this.orderData = data;
      this.fixedReport = this.orderData.status == 3;
    });
  }

  reviewReport(downloadStatus) {
    if (this.orderData.report_link) window.open(this.orderData.report_link);
    else {
      if (downloadStatus === 'view') {
        this.report.changeDownload(false);
        this.router.navigateByUrl(`report/basic-report/${this.orderData.id}`);
      } else if (downloadStatus === 'download') {
        this.report.changeDownload(true);
        this.router.navigateByUrl(`report/basic-report/${this.orderData.id}`);
      }
    }
  }

  editReport() {
    this.reportModalDialogRef = this.dialog.open(ReportModalComponent, {
      data: { headerTxt: this.headerTxt, flag: false },
    });
    this.reportModalDialogRef.afterClosed().subscribe((res) => {
      this.notes.emit(res.data.notes);
    });
  }

  acceptReport() {
    this.report.acceptReport(this.orderData.id, this.acceptReportObject).subscribe(
      (data) => {
        this.save.emit({
          message: this.confirmMsg,
          imgSuccess: true,
          button: 'تم',
        });
        this.fixedReport = false;
      },
      (error) => {
        try {
          console.log(error);
        } catch {
          console.log(error);
        }
      }
    );
  }

  downloadReport() {
    if (this.orderData.is_report_generated && this.orderData.report_file) {
      this.downloadAsPDF();
    } else {
      this.generateReport();
    }
  }

  downloadAlbumReport() {
    if (this.orderData.is_gallery_report_generated && this.orderData.gallery_report_file) {
      this.downloadGalleryAsPDF();
    } else {
      this.generateAlbumReport();
    }
  }

  generateAlbumReport() {
    this.downloadAlbum = true;
    this.report.generateAlbumReport(this.orderData.id).subscribe(
      (data) => {
        this.generate_album_count++;
        setTimeout(() => {
          if (data.is_gallery_report_generated && data.gallery_report_file) {
            this.orderData.gallery_report_file = data.gallery_report_file;
            this.downloadGalleryAsPDF();
          } else {
            if (this.generate_album_count < 2) this.generateAlbumReport();
            else {
              this.showErrorModal();
            }
          }
        }, 10000);
      },
      (error) => {
        try {
          //show error modal
          this.showErrorModal();
        } catch {}
      }
    );
  }

  generateReport() {
    this.download = true;
    this.report.generateReport(this.orderData.id).subscribe(
      (data) => {
        this.generate_count++;
        setTimeout(() => {
          if (data.is_report_generated && data.report_file) {
            this.orderData.report_file = data.report_file;
            this.downloadAsPDF();
          } else {
            if (this.generate_count < 2) this.generateReport();
            else {
              // show error modal
              this.showErrorModal();
            }
          }
        }, 10000);
      },
      (error) => {
        try {
          //show error modal
          this.showErrorModal();
        } catch {}
      }
    );
  }

  showErrorModal() {
    this.errorMsg.emit({
      message: 'حدثت مشكلة أثناء تحميل الملف. الرجاء المحاولة مجددا.',
      imgSuccess: false,
    });
    this.download = false;
    this.downloadAlbum = false;
  }

  downloadAsPDF() {
    this.download = false;
    var link = document.createElement('a');
    link.href = this.orderData.report_file;
    link.download = 'report.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  }

  downloadGalleryAsPDF() {
    this.downloadAlbum = false;
    var link = document.createElement('a');
    link.href = this.orderData.gallery_report_file;
    link.download = 'report_gallery.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  }

  copyReportLink() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.orderData.report_link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  reportViewStatus(event, elementRef) {
    if (event.value) {
      this.showDeleteConfirmationBox(elementRef);
    } else {
      this.doAction({ value: false }, elementRef);
    }
  }

  showDeleteConfirmationBox(elementRef) {
    this.deleteDialogRef = this.dialog.open(ConfirmationBoxComponent, {
      data: {
        message: this.confirmViewMsg,
        confirmBtn: this.confirmBtn,
        isDelete: true,
      },
    });
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        result['value'] = true;
        this.doAction(result, elementRef);
      } else {
        this.closeModal(elementRef);
      }
    });
  }

  doAction(event, elementRef) {
    let data = {
      is_report_accessible: event.value,
    };
    this.orderService.updateOrder(this.orderData.id, data).subscribe(
      () => {
        this.message = 'تم تحديث البيانات بنجاح.';
        this.imgSuccess = true;
        this.showModal();
      }, (error) => {
        elementRef.switchBtn.nativeElement.checked = !event.value;
        this.message = Object.values(error.error)[0].toString();
        this.imgSuccess = false;
        this.showModal();
      }
    );
  }

  showModal() {
    this.errorMsg.emit({
      message: this.message,
      imgSuccess: this.imgSuccess,
    });
  }

  closeModal(elementRef) {
    this.orderData.is_report_accessible = false;
    elementRef.switchBtn.nativeElement.checked = false;
  }

  previewAsDemo() {
    if (this.orderData.report_link)
      window.open(this.orderData.report_link + ';is_demo=true');
  }
  
  downloadAsExcel() {
    var link = document.createElement('a');
    link.href = `${environment.AYEN_API_URL}/admin/export-order-notes/${this.orderData.id}`;
    link.download = 'report_Excel.csv';
    link.dispatchEvent(new MouseEvent('click'));
  }
}
