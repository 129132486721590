<div class="row" [formGroup]="orderMainDetailsFormGroup" >
  <div class="col-md-6">
    <div class="form-group">
      <label for="" class="form-label">رقم الطلب</label>
      <input type="text" class="form-control" readonly formControlName="reference_number"/>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label for="" class="form-label"> الفاحص </label>
      <ng-select bindLabel="name" (scrollToEnd)="onScrollPagination()"
        #selectInsp
        placeholder="يرجى تحديد الفاحص"
        appendTo="body"
        formControlName="order_inspector"
        [searchable]="true"
        (removed)="resetSearch()"
        (search)="searchtext()"
        (clear)="resetSearch()"
        (change)="inspectorData($event)"
        (open)="resetSearch()"
        [virtualScroll]="true"
        [clearable]="true">
        @for (inspector of inspectors; track inspector) {
          <ng-option [value]="inspector.id" >
            {{inspector.full_name}}
          </ng-option>
        }
      </ng-select>
    </div>
  </div>
  @if (orderType == 5) {
    <div class="col-md-6">
      <div class="form-group">
        <label for="" class="form-label"> تخصص مزود الخدمة</label>
        <ng-select bindLabel="name" class="single-select"
          #selectStatus
          placeholder="يرجى تحديد تخصص مزود الخدمة"
          appendTo="body"
          formControlName="inspector_academic_major"
          [searchable]="true"
          [clearable]="true">
          @for (item of acadamicMajor; track item) {
            <ng-option [value]="item.id" >
              {{item.name}}
            </ng-option>
          }
        </ng-select>
      </div>
    </div>
  }
  <div class="col-md-6">
    <div class="form-group">
      <label for="" class="form-label"> حالة الفحص </label>
      <ng-select bindLabel="name" class="single-select"
        #selectStatus
        placeholder="  يرجى تحديد  حالة الفحص  "
        appendTo="body"
        formControlName="status"
        [searchable]="true"
        [clearable]="true">
        @for (item of status; track item) {
          <ng-option [value]="item.id" >
            {{item.name}}
          </ng-option>
        }
      </ng-select>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label for="" class="form-label">{{dateLabel}} </label>
      <mat-form-field (click)="picker.open()" appearance="outline" >
        <input matInput formControlName="order_date" [matDatepicker]="picker" disabled />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker"
          ><mat-icon matDatepickerToggleIcon class="calender-icon">
          <img src="assets/images/svg/calendar-admin-icon.svg" alt="" >
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker [disabled]="disableDate" ></mat-datepicker>
    </mat-form-field>
    <div class="checkBox-wrapper">
      <label class="checkbox-container" >
        <input type="checkbox" formControlName="is_inspection_later" (click)="toggleDisableDate($event)">
        <span class="checkmark"> </span>
      </label>
      <span class="checkbox-label"> موعد مفتوح
        <small>(تحديد الموعد لاحقا)</small> </span>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label for="" class="form-label">{{periodLabel}} </label>
      <app-select
        [optionItems]="inspectionPeriods"
        [placeholder]="'يرجى تحديد الفتره'"
        [initValues] = "selectedPeriods"
        [id]="'order_periods'"
        [disableSelect] = "disableSelect"
        (selectedOptions)="setOrderPeriods($event , true)">
      </app-select>
    </div>
  </div>
  <div class="col-md-12">
    <div class="notes-section">
      <label for="" class="form-label">ملاحظات العميل</label>
      <textarea
        class="form-control"
        formControlName="notes"
        name="notes"
        maxlength="500"
      ></textarea>
    </div>
  </div>
  <div class="col-md-12">
    <div class="form-group automatic-status">
      <p class="form-label">تغيير اتوماتيكي لحالة الطلب</p>
      <input type="checkbox" class="" formControlName="change_delivered_order_to_complete_after_3_days" />
      <label for="" class="order-change-label">
        تحويل الطلب من "تم التسليم" الى "مكتمل" بعد 3 ايام
        تلقائيا</label
        >
      </div>
    </div>
  </div>

  <div  class="save-section">
    <div class="save-btn-wrapper">
      <button type="button" class="btn btn-green"  (click)="save()">
        @if (showSpinner) {
          <img class="spin-img" src="assets/images/svg/Spinner-s.svg" alt=""/>
        }
        حفظ
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      @if (alertmsg) {
        <div class="alert-box">
          <img alt=""
            src="assets/images/svg/error-icon.svg"
            class="close-alert"
            (click)="hideErrorMSG()"
            alt=""
            />
            <p class="alert-msg">{{errorMessage}}</p>
          </div>
        }
      </div>
    </div>
