import {Component, OnInit, Inject} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  files: FileList;
  orderlabel: string;
  url: string;
  index: number;
}
@Component({
  selector: 'app-file-modal',
  templateUrl: './file-modal.component.html',
  styleUrls: ['./file-modal.component.scss'],
})
export class FileModalComponent implements OnInit {
  progress: number = 0;
  message = '';
  fileUpload: any;
  fileSize: any;
  fileName: any;
  orderId: any;
  disable: boolean = true;
  discardUpload: boolean = false;
  value: any;

  constructor(
    private baseUrl: AYENServiceBase,
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<FileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.data.index = 0;
    this.activatedRoute.params.subscribe((params) => {
      this.orderId = params['id'];
    });
    this.fileUpload = this.data?.files?.item(0);
    this.fileSize = this.fileUpload?.size;
    this.fileName = this.fileUpload?.name;
    let fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
    let i = 0;
    while (this.fileSize > 900) {
      this.fileSize /= 1024;
      i++;
    }
    this.fileSize = Math.round(this.fileSize * 100) / 100 + ' ' + fSExt[i];
    if (this.fileUpload.size > 5000000) {
      this.discardUpload = true;
      this.message = 'عذراً، لا يمكن تحميل ملف أو صورة بحجم أكثر من 5MB';
    } else if (
      this.fileUpload.type != 'application/pdf' &&
      !this.fileUpload.type.includes('image')
    ) {
      this.discardUpload = true;
      this.message =
        'عذراً، لا يمكن رفع ملفات إلا ضمن أحدى اللواحق التالية PDF, JPG, PNG';
    } else this.upload();
  }

  finishUpload(exist) {
    this.dialogRef.close({
      index: this.data.index,
      fileName: this.fileName,
      value: this.value,
      exist: exist,
    });
  }
 
  upload(): void {
    let uploadInterval = setInterval(() => {
      this.progress += 1;
      if (this.progress >= 100) {
        this.progress = 0;
      }
    }, 200);
    let formData: FormData = new FormData();
    formData.append(this.data.orderlabel, this.fileUpload);
    this.updateFile(formData, uploadInterval);
  }
 
  deleteFile() {
    let formData: FormData = new FormData();
    formData.append(this.data.orderlabel, '');
    this.updateFile(formData, false);
    this.finishUpload(false);
  }

  updateFile(formData, uploadInterval) {
    const userData = JSON.parse(localStorage.getItem('currentUser'));
    const token = userData.access_token;
    fetch(this.baseUrl.baseURL + this.data.url, {
      method: 'PUT',
      headers: {
        Authorization: `JWT ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        this.value = data[this.data.orderlabel];
        this.progress = 100;
        this.disable = false;
        clearInterval(uploadInterval);
      })
      .catch((e) => console.error('something went wrong', e));
  }
}
