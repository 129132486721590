import { Component, Input } from '@angular/core';
import { AdminOrdersService } from '../../../services/orders/admin-orders.service';
/**
 *  define and export AdditionalAmountViewComponent class
 */
@Component({
  selector: 'app-additional-view',
  templateUrl: './additional-amount-view.component.html',
  styleUrls: ['./additional-amount-view.component.scss'],
})
export class AdditionalAmountViewComponent {
  @Input() orderData: any;
  flag: boolean = false;
  received = {
    is_received: true,
  };

  constructor(private order: AdminOrdersService) {}

  deliveredOrder(id, received) {
    this.flag = true;
    this.order.changePaymentStatus(id, received).subscribe(
      () => {
        this.orderData.sub_order.forEach((order) => {
          if (order.pk == id) {
            order.payment_status = 'مستلم';
          }
        });
      },
      (error) => {
        try {
          console.log(error);
        } catch {
          console.log(error);
        }
      }
    );
  }
}
