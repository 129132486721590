<div class="dialog-container" id="changeCategory">
  <h5 mat-dialog-title> تغير التصنيف </h5>
  <form
    class="needs-validation"
    [formGroup]="addCategoryFormGroup"
    (ngSubmit)="changeCategory()">
    <div mat-dialog-content class="text-center">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <ng-select
              bindLabel="name"
              class="single-select"
              placeholder=" يرجى تحديد التصنيف "
              appendTo="body"
              formControlName="category"
              [searchable]="true"
              [clearable]="true">
              @for (category of categories; track category) {
                <ng-option [value]="category.id">
                  {{ category.name }}
                </ng-option>
              }
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="actions-double">
      <a class="btn btn-cancel center-btn" mat-dialog-close>إلغاء</a>
      <button
        class="btn btn-green center-btn"
        [disabled]="disable"
        type="submit">
        تغير
      </button>
    </div>
  </form>
</div>
