import { Component, Input, Output , EventEmitter } from '@angular/core';
import { RatingService } from 'src/app/core/services/rating/rating.service';

@Component({
  selector: 'rating-view',
  templateUrl: './rating-view.component.html',
  styleUrls: ['./rating-view.component.scss']
})
export class RatingViewComponent{

  @Input() orderID;
  @Input() ratingData;
  @Output() changeQCSurveyData = new EventEmitter;
  public ratingArr = [ 1 , 2 , 3 , 4 , 5];
  @Input() qcReview;
  @Input() customerReview;
  @Input() qcReviewExist;
  @Input() customerReviewExist;
  delID: any;
  delIndex: any;
  delMsg : string = '';
  child : string = 'RatingViewComponent';
  @Input() disableQC : boolean = false ;
  @Input() disableCustomer : boolean = false ;

  constructor(private ratingService : RatingService) { }

  ngOnChanges(){
    if(this.ratingData){
      this.qcReview = this.ratingData.results ;
      this.qcReviewExist = this.ratingData.success ;
    }    
  }

  is_report_returned(field){
    return field ? 'نعم' : 'لا'
  }

  hideValueChanged(event, elementRef){
    if (event.labelEn == "qcReview")
      this.updateQcReview(event.value, elementRef);
    else 
      this.updateCustomerReview(event.value, elementRef);
  }

  updateQcReview(activeVal, elementRef){
    this.ratingService.hideQCReview(this.orderID).subscribe( data =>{
      console.log(data);
    },
    error =>{
      try{
        elementRef.switchBtn.nativeElement.checked = !activeVal ;
      }
      catch{

      }
    })
  }

  updateCustomerReview(activeVal, elementRef){
    this.ratingService.hideCustomerReview(this.orderID).subscribe( data =>{
      //rating updated successfully
    },
    error =>{
      try{
        elementRef.switchBtn.nativeElement.checked = !activeVal ;
      }
      catch{

      }
    })
  }

  showIcon(index:number ,  field ) {
    let rating = field ;
    if (rating >= index + 1) {
      return 'assets/images/svg/star-icon.svg';
    } else {
      return 'assets/images/svg/star-border-icon.svg';
    }
  }
}
