<header>
  <!-- nav bar -->
  <nav class="navbar-header">
    <div class="navbar main-nav navbar-expand-lg navbar-light">
      <a class="navbar-brand" href="#">
        <img src="assets/images/svg/report-logo.svg" alt="AYEN-Logo" />
      </a>
      <div class="navbar-actions">
        <div class="collapse navbar-collapse notification" id="user-navbar">
          <ul class="navbar-nav pr-0">
            <li class="nav-item">
              <a class="nav-link actions p-0" id="setting" (click)="setting()">
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link actions p-0"
                id="notification"
                [matMenuTriggerFor]="sideMenu"
                (menuClosed)="menuClosed()"
                (menuOpened)="menuOpened()"
                >
                <div class="notify_counter">
                  {{ unread_count }}@if (unread_count >= 99) {
                  <span>+</span>
                }
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="avatar-name">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link"
              id="user-action"
              [matMenuTriggerFor]="whatsNewMenu"
              >
              <span> {{ userName }}</span>
              <div class="avatar-icon">
                <img src="{{ avatar }}" alt="company-logo" />
              </div>
            </a>
          </li>
        </ul>
        <mat-menu
          #whatsNewMenu="matMenu"
          [overlapTrigger]="false"
          backdropClass="whatsnew-menu"
          >
          <ul class="whatsnew-list">
            <li class="whatsnew-item" (click)="openWhatsNew()">
              <img src="assets/images/svg/whats-new.svg" alt="" />
              ما الجديد؟
            </li>
            <li class="whatsnew-item logout" (click)="logout()">
              <img src="assets/images/svg/logout.svg" alt="" />
              تسجيل الخروج
            </li>
          </ul>
        </mat-menu>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#user-navbar"
        aria-controls="user-navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
        >
        <img src="assets/images/svg/list-icon.svg" alt="" />
      </button>
    </div>
  </div>
</nav>
</header>
<mat-menu
  #sideMenu="matMenu"
  [overlapTrigger]="false"
  backdropClass="notification-menu"
  >
  <div class="sideMenu-container">
    <div class="sideMenu-header" (click)="notifications()">
      <span> عرض جميع الاشعارات </span>
      <img src="assets/images/svg/slide left.svg" alt="" />
    </div>
    @for (item of notificationList; track item; let i = $index) {
      <div
        class="sideMenu-items"
        (click)="stopclose($event)"
        >
        <a class="notify-link" href="{{ item.url }}">
          <div class="item-container">
            <div class="item-msg icon">
              <img
                [src]="
                item?.notification_type?.icon
                ? item?.notification_type?.icon
                : 'assets/images/svg/notify-icon.svg'
                "
                alt=""
                />
              </div>
              <div class="item-msg">
                <div [innerHtml]="item.body"></div>
                <div>{{ item.date_added }}</div>
              </div>
            </div>
          </a>
          <div class="droppoints">
            <div class="radio-div">
              <input
                type="radio"
                [id]="item.id"
                class="notifyBtn"
                [checked]="!item.is_read"
                (click)="radioBtnToggle(item?.is_read, item?.id, i)"
                />
                <label class="notify-label custom-tooltip" for="{{ item.id }}">
                  <span
                    class="tooltip-text"
                    [ngClass]="{
                    read: item.is_read == false,
                    'not-read': item.is_read == true
                    }"
                    >{{ item.is_read ? "جعله غير مقروء" : "جعله مقروء" }}</span
                    >
                  </label>
                </div>
              </div>
            </div>
          }
        </div>
      </mat-menu>
