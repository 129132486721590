import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { Images } from 'src/app/shared/global/image-constants';
import { ApplySortingService } from 'src/app/core/services/uitls/sorting-util/apply-sorting.service';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent implements IHeaderAngularComp {

  imgSrc: string;
  params: IHeaderParams;
  orderingState = {};
  ordering: any;
  columnId: any;

  constructor(private applySortingService: ApplySortingService) { }

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.imgSrc = Images.DEFAULT_SORT;
    this.columnId = this.params.column['colId'];
    this.orderingState[this.columnId] = null;
  }

  toggleSort() {
    this.ordering = [];
    const sortParams = {
      orderingState: this.orderingState,
      column: this.columnId,
      imgSrc: this.imgSrc,
      ordering: this.ordering
    }
    this.imgSrc = this.applySortingService.toggleSort(sortParams);
  }

  refresh() {
    return true;
  }

}
