
<div [formGroup]="subForm" class="form-group">
  <label class="form-label">
    {{inputs.field_label_name || inputs.name}}
    @if (!isRequired) {
      <span class="optional">(اختياري)</span>
    }
  </label>
  <input class="form-control"
    readonly='{{readOnly}}'
    formControlName='{{inputs?.field_key_name || inputs.id}}'
    value="{{inputs?.field_value}}"
    type="{{inputs.field_type || 'text'}}">
  </div>

