@if (spinner) {
<app-spinner></app-spinner>
}
<div class="inner-page-header">
  <h3>الطلبات</h3>
</div>
@if (servicesOrders) {
<div class="request-control">
  <!-- <div class="delete-icon-grid">
    <button
      class="btn btn-custom-delete"
      (click)="deleteSelectedRows()"
      [disabled]="disabledDelete"
    >
      <img src="assets/images/svg/new-delete.svg" alt="" />
    </button>
  </div> -->
  @if (filterData) {
  <app-filter
    [filterData]="filterData"
    [filterDataInit]="filterDataInit"
    [filterPage]="filterPage"
    (formValus)="filterFormValus($event)"
  >
  </app-filter>
  }
  <quick-search (searchResults)="quickSearch($event)"></quick-search>
</div>
}
<div class="saved-filters">
  <saved-filters></saved-filters>
</div>
<!-- request table -->

@if (servicesOrders) {
<div class="request-table">
  <div class="table-responsive">
    <table id="order-table" class="table table-striped" aria-label="">
      <thead>
        <tr>
          <th id="" class="width-13">
            <label class="checkbox-container">
              <input
                type="checkbox"
                #selectAllElm
                (click)="selectAll($event.target)"
              />
              <span class="checkmark"></span>
            </label>
            <span
              class="checkbox-label"
              (click)="toggleSrc('reference_number', img1)"
            >
              رقم الطلب
              <img #img1 [src]="imgDefault" alt="" />
            </span>
          </th>
          <th id="" class="width-15">اسم العميل</th>
          <th id="" class="width-15">اسم مزود الخدمة</th>
          <th id="" class="width-10">نوع الخدمة</th>
          <th id="" class="width-10">مدينة الطلب</th>
          <th id="" class="width-10" (click)="toggleSrc('created_at', img2)">
            تاريخ الطلب
            <img #img2 [src]="imgDefault" alt="" />
          </th>
          <th id="" class="width-12" (click)="toggleSrc('order_date', img3)">
            تاريخ الفحص
            <img #img3 [src]="imgDefault" alt="" />
          </th>
          <th id="" class="width-15">حالة الطلب</th>
          <th id="" class="width-15">التسعير</th>
        </tr>
      </thead>
      <tbody>
        @if (servicesOrders) { @for ( order of servicesOrders | paginate :
        config; track order; let i = $index) {
        <tr
          (click)="openServiceOrder(order.id)"
          class="table-row"
          data-href="requests-waitingForCompleted.html"
          role="row"
        >
          <td>
            <label class="checkbox-container" (click)="stopPropagation($event)">
              <input
                #checkBoxList
                type="checkbox"
                (click)="selectsSingleRow($event, order.id, i)"
                [id]="i + '_' + order.id"
              />
              <span class="checkmark"></span>
            </label>
            <span class="checkbox-label">
              <a href="/pages/orders/service-order/{{ order.id }}">{{
                order.reference_number
              }}</a>
            </span>
          </td>
          <td>
            {{
              order.order_client_object ? order.order_client_object.name : "-"
            }}
          </td>
          <td>
            {{
              order.service_provider_object
                ? order.service_provider_object.name
                : "-"
            }}
          </td>
          <td class="table-row-lg">
            {{ order.service_object ? order.service_object.name : "-" }}
          </td>
          <td>
            {{ order.google_address_city ? order.google_address_city : "-" }}
          </td>
          <td>
            {{ order.date_added_object ? order.date_added_object : "-" }}
          </td>
          <td>
            {{
              order.inspection_date_object ? order.inspection_date_object : "-"
            }}
          </td>
          <td>
            <button
              class="btn-checked"
              [ngClass]="getStatusClass(order.status)"
            >
              {{ order.status ? order.status_object.name : "--" }}
            </button>
          </td>
          <td
            (click)="
              stopPropagation($event); openServiceOrderWithQuatation(order.id)
            "
          >
            <button class="btn-checked btn btn-green">تسعير</button>
          </td>
        </tr>
        } @if (servicesOrders.length === 0) {
        <tr>
          <td colspan="9" class="text-center">لا توجد بيانات</td>
        </tr>
        } }
      </tbody>
    </table>
  </div>
</div>
} @if (servicesOrders && servicesOrders.length > 0) {
<ayen-pagination
  (pageData)="pageChanged($event)"
  (pageSize)="pageSizeChanged($event)"
  [totalCount]="config.totalItems"
>
</ayen-pagination>
}
