import { Injectable } from '@angular/core';
import { AYENServiceBase } from '../../base/base.service';
import { Store } from '@ngxs/store';
import { SavedFilterList } from '../../../state/filter-state/actions';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilterUtilService extends AYENServiceBase {
  
  constructor(private store: Store) {
    super();
  }
  
  applySelectedFilter(scope, getterFn, loader?) {
    scope.filter.currentSelectedFilter.pipe(takeUntil(scope.destroyed$)).subscribe(
      (data) => {
          if (Object.keys(data).length > 0) {
            scope[loader] = true
            scope.filterParamsUrl = data;
            scope.filterDataInit = data;
          } else {
            scope.filterParamsUrl = {};
            scope.filterDataInit = {};
          }
        scope.config.currentPage = 1;
        scope[getterFn.name]();
      },
      (error) => {
        try {
          console.log(error)
        } catch {}
      }
    );
  }

  getSavedFilters(scope, filerPage) {
    let params = {
      filter_page: filerPage,
    };
    this.store.dispatch(new SavedFilterList(params)).subscribe(state => {
      scope.savedFilters = state.filterState.savedFilters;
    });
  }

  clearfilterParams(params) {
    for (const key in params) {
      delete params[key];
    }
  }
}
