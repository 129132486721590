import { Component } from '@angular/core';

@Component({
  selector: 'app-additional-amount',
  templateUrl: './additional-amount.component.html',
  styleUrls: [],
})
export class AdditionalAmountComponent {
  selectedView: string = 'details';
  summaryData: any;

  getView(event) {
    this.selectedView = event.viewName;
    this.summaryData = event.data;
  }
}
