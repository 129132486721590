<div class="row" [formGroup]="buildingDetailsFormGroup" >
  @if (orderType != 5) {
    <div class="col-md-6">
      <div class="form-group">
        <label for="" class="form-label">النشاط بحسب رخصة البناء  </label>
        <ng-select bindLabel="name"
          #selectStatus
          placeholder=" النشاط بحسب رخصة البناء "
          appendTo="body"
          formControlName="licence_type"
          [searchable]="true"
          [clearable]="true">
          @for (item of licenceTypes; track item) {
            <ng-option [value]="item.id" >
              {{item.name}}
            </ng-option>
          }
        </ng-select>
      </div>
    </div>
  }
  <div class="col-md-6">
    <div class="form-group">
      <label for="" class="form-label">نوع العقار  </label>
      <ng-select bindLabel="name"
        #selectStatus
        placeholder="نوع العقار "
        appendTo="body"
        formControlName="building_type"
        [searchable]="true"
        (change)="changeBuildingType($event)"
        [clearable]="true">
        @if (orderType != 5) {
          @for (item of buildingTypes; track item) {
            @if (item.id != 7) {
              <ng-option [value]="item.id"  >
                {{item.name}}
              </ng-option>
            }
          }
        } @else {
          @for (item of buildingTypes; track item) {
            <ng-option [value]="item.id" >
              {{item.name}}
            </ng-option>
          }
        }
      </ng-select>
    </div>
  </div>
  @if (buildingDetailsFormGroup.get('building_type').value != null &&
    buildingDetailsFormGroup.get('building_type').value != 10 &&
    buildingDetailsFormGroup.get('building_type').value != 7) {
    <div class="col-md-6">
      <div class="form-group">
        <label for="" class="form-label">حالة العقار  </label>
        <ng-select bindLabel="name"
          #selectStatus
          placeholder="حالة العقار "
          appendTo="body"
          formControlName="status"
          [searchable]="true"
          [clearable]="true">
          @for (item of buildindStatus; track item) {
            <ng-option [value]="item.id" >
              {{item.name}}
            </ng-option>
          }
        </ng-select>
      </div>
    </div>
  }
  @if (orderType == 5 && buildingDetailsFormGroup.get('building_type').value == 10) {
    <div class="col-md-6" >
      <div class="form-group">
        <label for="" class="form-label">أدخل نوع العقار</label>
        <input type="text" formControlName="custom_property_type" class="form-control"
          placeholder="أدخل نوع العقار"
          />
        </div>
      </div>
    }
    <div class="col-md-6">
      <div class="form-group">
        <label for="" class="form-label">رقم الصك <span class="optional">(اختياري)</span></label>
        <input type="text" formControlName="instrument_number"  class="form-control" placeholder="رقم الصك"/>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="" class="form-label">رقم رخصة البناء <span class="optional">(اختياري)</span></label>
        <input type="text" formControlName="licence_number"  class="form-control" placeholder="رقم رخصة البناء"/>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="" class="form-label">المالك<span class="optional">(اختياري)</span></label>
        <input type="text" formControlName="owner"  class="form-control" placeholder="المالك"/>
      </div>
    </div>
    @if (buildingDetailsFormGroup.get('building_type').value != 7) {
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">المطور العقاري<span class="optional">(اختياري)</span></label>
          <input type="text" formControlName="realty_developer"  class="form-control" placeholder="المطور العقاري"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">المكتب المصمم<span class="optional">(اختياري)</span></label>
          <input type="text" formControlName="designer"  class="form-control" placeholder="المكتب المصمم"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">المشرف الهندسي<span class="optional">(اختياري)</span></label>
          <input type="text" formControlName="engineering_supervisor"  class="form-control" placeholder="المشرف الهندسي"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">المقاول الرئيسي<span class="optional">(اختياري)</span></label>
          <input type="text" formControlName="main_contractor"  class="form-control" placeholder="المقاول الرئيسي"/>
        </div>
      </div>
    }
  </div>
  @if (meters) {
    <form class="needs-validation">
      <div id="electricity_sec">
        @for (elect of meters.electricity_meters ; track elect; let i = $index) {
          <div class="row meterRow">
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="form-label">عداد الكهرباء<span class="meter-count">{{i+1}}</span></label>
                <input type="text" (input)="updateMeter()" class="form-control" [ngModelOptions]="{standalone: true}"  [(ngModel)]="elect.device_number" placeholder="عداد الكهرباء"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="reading-container">
                <div class="form-group">
                  <label for="" class="form-label">قراءة عداد الكهرباء<span class="meter-count">{{i+1}}</span></label>
                  <input type="text" class="form-control" (input)="updateMeter()" [ngModelOptions]="{standalone: true}"  [(ngModel)]="elect.device_reading" placeholder="قراءة عداد الكهرباء"/>
                </div>
                @if (i > 0) {
                  <div class="delete-wrapper" (click)="deleteMeter('electricity',elect.id , i)">
                    <img class="deleteMeter" src="/assets/images/svg/grid-delete-icon.svg" alt="">
                  </div>
                }
              </div>
            </div>
          </div>
        }
        @if (meters.electricity_meters.length > 0) {
          <div>
            <div class="add-service">
              <button class="btn btn-add-service" type="button" (click)="addMeter('electricity')" [disabled]="disableAdd_electricity">
                <img src="assets/images/svg/add-logo.svg" alt="">
                إضافة عداد
              </button>
            </div>
          </div>
        }
      </div>
      <div id="water_sec">
        @for (wat of meters.water_meters ; track wat; let i = $index) {
          <div class="row meterRow">
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="form-label">عداد الماء<span class="meter-count">{{i+1}}</span></label>
                <input type="text" (input)="updateMeter()" class="form-control" [ngModelOptions]="{standalone: true}"  [(ngModel)]="wat.device_number" placeholder="عداد الماء"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="reading-container">
                <div class="form-group">
                  <label for="" class="form-label">قراءة عداد الماء <span class="meter-count">{{i+1}}</span></label>
                  <input type="text" class="form-control" (input)="updateMeter()" [ngModelOptions]="{standalone: true}"  [(ngModel)]="wat.device_reading" placeholder="قراءة عداد الماء"/>
                </div>
                @if (i > 0) {
                  <div class="delete-wrapper">
                    <img (click)="deleteMeter('water' , wat.id , i)" class="deleteMeter" src="/assets/images/svg/grid-delete-icon.svg" alt="">
                  </div>
                }
              </div>
            </div>
          </div>
        }
        @if (meters.water_meters.length > 0) {
          <div>
            <div class="add-service">
              <button class="btn btn-add-service" type="button" (click)="addMeter('water')" [disabled]="disableAdd_water">
                <img src="assets/images/svg/add-logo.svg" alt="">
                إضافة عداد
              </button>
            </div>
          </div>
        }
      </div>
    </form>
  }
  <div  class="save-section">
    <div class="save-btn-wrapper">
      <button class="btn btn-green" (click)="save()">
        @if (showSpinner) {
          <img class="spin-img" src="assets/images/svg/Spinner-s.svg" alt=""/>
        }
        حفظ
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      @if (alertmsg) {
        <div class="alert-box">
          <img alt=""
            src="assets/images/svg/error-icon.svg"
            class="close-alert"
            (click)="hideErrorMSG()"
            alt=""
            />
            <p class="alert-msg">{{errorMessage}}</p>
          </div>
        }
      </div>
    </div>
