import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
/**
  declare recover password component
 *  
*/
@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent implements OnInit {
/**
  declare alert message variable
 *  
*/
  // alert msg for error
  alertMsg: boolean = false;
/**
  declare success message variable
 *  
*/
  sucessMsg : boolean = false;
/**
  declare recover formgroup variable
 *  
*/
  recoverFormGroup: UntypedFormGroup;
/**
  declare constructor
 *  
*/
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private recoverPassword: AuthenticationService,
    private titleService: Title,
  ) {}
/**
  declare ngAfterViewInit
 *  
*/
  ngAfterViewInit() {
    document.querySelector('body').classList.add('login');
  }
/**
  declare ngonDestroy
 *  
*/
  ngOnDestroy() {
   document.querySelector('body').classList.remove('login');
  }
/**
  declare ngoninit
 *  
*/
  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.titleService.setTitle(' استعادة كلمة المرور | عاين');
  }
/**
  declare create form controls function
 *  
*/
  createFormControls() {
    // Initilize controlers for building form
    return this.recoverFormGroup.controls;
  }
/**
  declare create form 
 *  
*/
  createForm() {
    // Create Login form
    this.recoverFormGroup = this.formBuilder.group({
      username_field: [null, Validators.required],
    });
  }
/**
  declare submit recover data
 *  
*/
  save() {
    const username = {
      username_field: this.recoverFormGroup.controls['username_field'].value,
    };
    this.recoverPassword.recoverPaswword(username).subscribe((res) => {
      console.log(res);
      this.sucessMsg = true;
      this.alertMsg = false;
    },
    () => {
      this.alertMsg = true;
      this.sucessMsg = false;
      try {
        console.log('error');
      } catch (err) {
        console.log(err);
      }
    });
    
  }

  closeError(){
    this.alertMsg = false;
  }
}
