<div  [formGroup]="subForm" class="form-group">
  <p class="custom-lable">
    {{inputs.field_label_name}}
    @if (!isRequired) {
      <span class="optional">(اختياري)</span>
    }
  </p>
  <div class="droppoints">
    @for (choice of inputs.choices; track choice) {
      <div class="dropItem radio-div">
        <input type="radio"
          id="PERCENTAGE"
          formControlName="{{inputs.field_key_name}}"
          name="{{inputs.field_key_name}}"
          value="{{choice.choice_value}}"
          (change)="getValue(choice.sub_elements)"
          >
          <label for="PERCENTAGE">
            {{choice.choice_display_value}}
          </label>
        </div>
      }
    </div>

  </div>

  <div [formGroup]="subForm">
    @if (totalElments) {
      <div class="row">
        @for (elem of totalElments; track elem) {
          <div [class]="elem.width == 'half' ? 'col-md-6' : 'col-md-12'">
            <app-component-host
              [inputs]="elem"
              [subForm] = "subForm"
              [orderId] = "orderId"
              [component]="getComponentName(elem)">
            </app-component-host>
          </div>
        }
      </div>
    }
  </div>