import { Component, Input, Output , EventEmitter} from '@angular/core';

@Component({
  selector: 'activat-notifications',
  templateUrl: './activat-notifications.component.html',
  styleUrls: []
})
export class ActivatNotificationsComponent {
 
  @Input() is_Email :boolean = false;
  
  @Input() is_SMS : boolean = false;

  @Output() switchEvent = new EventEmitter();

  switchBtnChanged(event){
    this.switchEvent.emit(event);
  }

}
