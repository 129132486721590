import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../core/guards/role-guard/role-guard.guard';
import { UserType } from '../models/enums/user-type.enum';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { SettingComponent } from './setting/setting.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { FeaturesComponent } from './features.component';
import { RootGuard } from '../core/guards/root-guard/root-guard.guard';
const routes: Routes = [
  {
    path: '',
    component: FeaturesComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'home' },
      { path: 'home', component: HomeComponent ,
        canActivate: [RootGuard],
      },
      { path: 'NotFound', component: NotFoundComponent },
      { path: 'ServerError', component: ServerErrorComponent },
      {
        path: 'setting',
        component: SettingComponent,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin],
        },
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin],
        },
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./orders/orders.module').then((m) => m.OrdersModule),
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin, UserType.Assistant, UserType.Supervisor,],
        },
      },
      {
        path: 'coupons',
        loadChildren: () =>
          import('./copoun/copoun.module').then((m) => m.CopounModule),
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin],
        },
      },
      {
        path: 'notification-center',
        loadChildren: () =>
          import('./notification-center/notification-center.module').then(
            (m) => m.NotificationCenterModule
          ),
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin],
        },
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./customers/customers.module').then((m) => m.CustomersModule),
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin, UserType.Supervisor],
        },
      },
      {
        path: 'inspectors',
        loadChildren: () =>
          import('./inspectors/inspectors.module').then(
            (m) => m.InspectorsModule
          ),
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin, UserType.Supervisor],
        },
      },
      {
        path: 'pricing',
        loadChildren: () =>
          import('./pricing/pricing.module').then((m) => m.PricingModule),
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin],
        },
      },
      {
        path: 'whats-new',
        loadChildren: () =>
          import('./whats-new/whats-new.module').then((m) => m.WhatsNewModule),
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin],
        },
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./projects/projects.module').then((m) => m.ProjectsModule),
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin, UserType.Assistant, UserType.Supervisor]
        },
      },
      {
        path: 'company',
        loadChildren: () =>
          import('./companies/companies.module').then(
            (m) => m.CompaniessModule
          ),
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin],
        },
      },
      {
        path: 'markting',
        loadChildren: () =>
          import('./sms-campings/sms-campings.module').then(
            (m) => m.SmsCampingsModule
          ),
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Markting]
        },
      },
      {
        path: 'services',
        loadChildren: () =>
          import('./ayen-services/services.module').then(
            (m) => m.ServicesModule
          ),
        canActivate: [RoleGuard],
        data: {
          expectedRole: [UserType.Admin],
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule {}
