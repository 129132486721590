import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddNewEventComponent } from './pages/add-new-event/add-new-event.component';
import { WhatsNewGridComponent } from './pages/whats-new-grid/whats-new-grid.component';
import { WhatsNewComponent } from './whats-new.component';

const routes: Routes = [
  {
    path: '',
    component: WhatsNewComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'whats-new-grid' },
      { path: 'whats-new-grid', component: WhatsNewGridComponent },
      { path: 'add-new-event/:id', component: AddNewEventComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WhatsNewRoutingModule {}
