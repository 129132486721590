import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyClientService } from 'src/app/core/services/companies-client/companies-client.services';
import { ClientsService } from 'src/app/features/customers/services/clients.service';
@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss'],
})
export class AddClientComponent implements OnInit {
  addClientFormGroup: UntypedFormGroup;
  alertmsg: boolean;
  message: string;
  is_test: boolean = false;
  title: string = 'إضافة عميل جديد';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private clientService: ClientsService,
    private CompanyClientService: CompanyClientService,
    public dialogRef: MatDialogRef<AddClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data?.isCompanyOwner) {
      this.title = 'إضافة ممثل الشركة';
    } else if (this.data?.isCompanyMember) {
      this.title = 'إضافة عضو الشركة';
    } else {
      this.data = {};
    }
    this.createForm();
    this.createFormControls();
  }

  createFormControls() {
    return this.addClientFormGroup.controls;
  }

  createForm() {
    this.addClientFormGroup = this.formBuilder.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      email: [null, Validators.required],
      is_test: [false, Validators.required],
      phone_number: [null, Validators.required],
    });
  }

  addClient() {
    this.alertmsg = false;
    if (this.addClientFormGroup.status == 'INVALID') {
      this.alertmsg = true;
      this.message =
        'هناك خطأ في خانة أو بعض الخانات يرجى التأكد من المعلومات المدخلة';
      let fields = document.querySelectorAll(
        'ng-select.ng-invalid , input.ng-invalid '
      );
      fields.forEach((field) => {
        field.parentElement.classList.add('invalid-field');
      });
      return;
    }
    const phoneNumber = this.addClientFormGroup.controls[
      'phone_number'
    ].value.replace(/\s+/g, '');
    const clientData = {
      email: this.addClientFormGroup.controls['email'].value,
      first_name: this.addClientFormGroup.controls['first_name'].value,
      last_name: this.addClientFormGroup.controls['last_name'].value,
      is_test: this.addClientFormGroup.controls['is_test'].value,
      phone_number: '00966' + phoneNumber,
    };
    if (this.data.isCompanyOwner) {
      this.createOwner(clientData);
    } else if (this.data.isCompanyMember) {
      this.createMember(clientData);
    } else {
      this.createClient(clientData);
    }
  }

  createOwner(ownerData) {
    this.CompanyClientService.createOwner(ownerData).subscribe(
      (response) => {
        this.setClientData(response);
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  createMember(memberData) {
    this.CompanyClientService.createMember(memberData).subscribe(
      (response) => {
        this.setClientData(response);
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  createClient(clientData) {
    this.clientService.addClients(clientData).subscribe(
      (response) => {
        this.setClientData(response);
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  handleError(error) {
    this.alertmsg = true;
    this.message = Object.values(error.error)[0].toString();
  }

  setClientData(response) {
    this.data['first_name'] = this.addClientFormGroup.get('first_name').value;
    this.data['last_name'] = this.addClientFormGroup.get('last_name').value;
    this.data['email'] = this.addClientFormGroup.get('email').value;
    this.data['mobile'] = this.addClientFormGroup.get('phone_number').value;
    this.data['is_test'] = this.addClientFormGroup.get('is_test').value;
    this.data['id'] = response.pk || response.id;
    this.data['is_email_verified'] = response.profile?.is_email_verified;
    this.data['orders_count'] = response.profile?.orders_count;
    this.data['date_joined'] = response.profile?.date_joined;
    this.dialogRef.close(this.data);
  }

  closeError() {
    this.alertmsg = false;
  }

  codeValueChanged(event) {
    this.addClientFormGroup.get(event.labelEn).setValue(event.value);
  }
}
