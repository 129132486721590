import { Component, ElementRef, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from 'moment';

import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { PaymentService } from '../../services/payment/payment.service';
import { RefundService } from '../../services/refund/refund.service';
import { AdminOrdersService } from '../../services/orders/admin-orders.service';
import { OrderState } from '../../state/order.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OrderDetails } from '../../state/order-action';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss'],
})
export class RefundComponent implements OnInit {
  orderId: any;
  order: any;
  refundFormGroup: FormGroup;
  refundTypes = [];
  refundReasons = [];
  showValueField: boolean = false;
  valueMark: string = 'ر.س';
  spinner: boolean = true;
  alertmsg: boolean = false;
  errorMessage: string = 'يوجد خطأ في بعض البيانات';
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  modelImg: boolean;
  confirmMsg: string;
  backUrl: string;
  changeOrderStatus: boolean = false;
  paymentMethods: any[] = [];
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private element: ElementRef,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private paymentService: PaymentService,
    private refundService: RefundService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.orderId = this.route.snapshot.params.id;
    this.createForm();
    this.createFormControls();
    this.getOrderById(this.orderId);
    this.getRefundType();
    this.getRefundReasons();
    this.getPaymentMethods();
  }

  getOrderById(id) {
    this.orderDetails$.subscribe(
      (res) => {
        if (res?.id) {
          if (!res.is_paid) {
            this.router.navigate(['pages/orders', id]);
          }
          this.spinner = false;
          this.order = res;
          this.refundFormGroup
            .get('orderNumber')
            .setValue(this.order?.reference_number);
        } else {
          this.store
            .dispatch(new OrderDetails({ orderId: +id }))
            .subscribe((res) => {});
        }
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  createForm() {
    this.refundFormGroup = this.formBuilder.group({
      orderNumber: [null, Validators.required],
      refundType: [null, Validators.required],
      refundDate: [null, Validators.required],
      qoudNotificationNumber: [null, Validators.required],
      bondNumber: [null, Validators.required],
      refundReason: [null, Validators.required],
      qoudClinetNumber: [null, Validators.required],
      changeOrderStatusToCancel: [false, Validators.required],
      paymentMethod: [null, Validators.required],
    });
  }

  createFormControls() {
    return this.refundFormGroup.controls;
  }

  getRefundType() {
    this.refundService.getRefundType().subscribe(
      (data) => {
        this.refundTypes = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  getPaymentMethods() {
    this.paymentService.getPaymentMethods().subscribe((data) => {
      this.paymentMethods = data;
    });
  }

  getRefundReasons() {
    this.refundService.getRefundReasons().subscribe(
      (data) => {
        this.refundReasons = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  TypeChanged(event) {
    if (event === 'CONSTANT_VALUE') {
      this.showValueField = true;
      this.refundFormGroup.addControl(
        'value',
        new UntypedFormControl('', Validators.required)
      );
    } else {
      this.showValueField = false;
      this.refundFormGroup.removeControl('value');
    }
  }

  changeOrderStatusHandler(event) {
    this.refundFormGroup.get(event.labelEn).setValue(event.value);
  }

  save() {
    this.removeInvalidClass();
    this.alertmsg = false;
    if (!this.refundFormGroup.valid) {
      this.alertmsg = true;
      const fields = this.element.nativeElement.querySelectorAll(
        '.form-control.ng-invalid, ng-select.ng-invalid, .mat-form-field.ng-invalid'
      );
      fields.forEach((field) => {
        field.classList.add('invalid-field');
      });
    } else {
      this.formateFormControlTypeDate('refundDate');
      this.createManualRefund(this.refundFormGroup.value);
    }
  }

  formateFormControlTypeDate(formControl) {
    const refundDateFormated = moment(
      this.refundFormGroup.get(formControl).value
    ).format('YYYY-MM-DD');
    this.refundFormGroup.get(formControl).setValue(refundDateFormated);
  }

  createManualRefund(refundData) {
    this.refundService.createManualRefund(this.orderId, refundData).subscribe(
      (response) => {
        this.modelImg = true;
        this.confirmMsg = 'تم حفظ البيانات بنجاح';
        this.backUrl = `pages/orders/${this.orderId}`;
        this.showAlertBox();
      },
      (error) => {
        this.alertmsg = true;
        this.errorMessage = error.error.error;
      }
    );
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.confirmMsg,
        backUrl: this.backUrl,
      },
    });
  }

  removeInvalidClass() {
    const fields =
      this.element.nativeElement.querySelectorAll('.invalid-field');
    fields.forEach((field) => {
      field.classList.remove('invalid-field');
    });
  }

  hideErrorMSG() {
    this.alertmsg = false;
  }

  goOrder() {
    this.router.navigate(['pages/orders', this.orderId]);
  }

  handleBackendError(error) {
    try {
      /* TODO document why this block is empty */
    } catch {}
  }
}
