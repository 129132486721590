<div class="service-offers-container">
  <div class="order-inspection">
    @if (quotationData?.data?.length > 0) {
      <div class="table-responsive">
        <table id="inspection-log-table" class="table table-striped" aria-label="">
          <thead>
            <tr>
              <th scope="col">مزود الخدمة</th>
              <th scope="col">التكلفة</th>
              <th scope="col">سعر المبيع</th>
              <th scope="col">صلاحية العرض</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            @for (provider of quotationData?.data; track provider) {
              <tr>
                <td>{{ provider.facility.name }}</td>
                <td>{{ provider.cost.text }} {{ provider.cost.sign }}</td>
                <td>
                  {{ provider.price_include_vat?.text }}
                  {{ provider.price_include_vat?.sign }}
                </td>
                <td>{{ provider.offer_validity_per_hour }}</td>
                <td>
                  @if (provider.approval_status !== 'ABA' && provider.approval_status !== 'PBC') {
                    <button
                      class="btn btn-green"
                      data-toggle="modal"
                      data-target="#requestQuatation"
                      (click)="openQuatationModal('q', provider)"
                      [ngClass]="{
                      disabled: provider.approval_status !== 'P'
                      }"
                      >
                      إرسال التسعيرة
                    </button>
                  }
                  @if (provider.approval_status === 'ABA') {
                    <p>
                      <img src="assets/images/svg/right-mark.svg" alt="" />
                      تم إرسال التسعيرة
                    </p>
                  }
                  @if (provider.approval_status === 'PBC') {
                    <p>
                      <img src="assets/images/svg/right-mark.svg" alt="" />
                      تم  الدفع
                    </p>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }

    @if (quotationData?.data?.length == 0) {
      <p class="no-providers">
        لا يوجد لديك عروض حاليا.
      </p>
    }

    <button
      class="btn btn-add"
      data-toggle="modal"
      data-target="#requestQuatation"
      (click)="openQuatationModal('c-q', null)"
      [ngClass]="{
      disabled: quotationData?.quotation_has_sent_to_client || orderStatus !== 'UP'
      }"
      >
      إضافة مخصصة
    </button>
  </div>
</div>

@if (showModal) {
  <app-quatation-modal
    [displayOrderConfirmation]="showModal"
    [quatationType]="quatationType"
    [orderId]="orderId"
    [facility]="selectedFacility"
  ></app-quatation-modal>
}
