import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-stats',
  templateUrl: './card-stats.component.html',
  styleUrl: './card-stats.component.scss',
})
export class CardStatsComponent {
  @Input() card;
  @Input() valueDescription;
}
