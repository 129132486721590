import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-service-main-info',
  templateUrl: './service-main-info.component.html',
  styleUrls: ['./service-main-info.component.scss']
})
export class ServiceMainInfoComponent {
  @Input() basicInfo;

  dateFormate(date){
    if(date)
      return moment(date).format('YYYY-MM-DD hh:mm A');
    else
      return '-';
  }

  getAddressDescription(value){
    let address = '-';
    if(value){
      address = '';
      address += value.address_street !== 'Unnamed Road' ? value.address_street : '';
      address +=" ";
      address += value?.address_neighborhood != undefined ? value.address_neighborhood : '';
      address +=" ";
      address += value?.google_address_city  ;
      address +=" ";
      address += value?.google_address_area  ;
    }
    return address;
  }

  copyAddress(){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = "https://www.google.com/maps/search/?api=1&query="+this.basicInfo.address.location;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  openLocation(){
    let link = "https://www.google.com/maps/search/?api=1&query="+this.basicInfo.address.location;
    window.open(link, "_blank");
  }
}
