<form
  class="needs-validation"
  id="general-form"
  [formGroup]="buildingDetailsFormGroup"
  (ngSubmit)="save()"
>
  <div class="single-card">
    <div class="row">
      <div class="col-md-12">
        <app-switch-input
          [label]="'طلب تجريبي'"
          [labelEn]="'is_test'"
          [value]="is_test"
          [index]="1"
          (valueChanged)="codeValueChanged($event)"
        >
        </app-switch-input>
      </div>
    </div>
  </div>
  <div class="main-card">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label"> اسم العميل </label>
          <a class="add-item" (click)="addClient()"
            ><img alt="" src="/assets/images/svg/add-icon-blue.svg" /> إضافة
            عميل
          </a>
          <ng-select
            bindLabel="name"
            (scrollToEnd)="onScrollPagination()"
            #client
            placeholder="يرجى تحديد العميل"
            appendTo="body"
            formControlName="order_client"
            [searchable]="true"
            (search)="searchtext()"
            (clear)="resetSearch()"
            (open)="resetSearch()"
            [virtualScroll]="true"
            [clearable]="true"
          >
            @for (client of clients; track client) {
            <ng-option [value]="client.id">
              {{ client.full_name }}
            </ng-option>
            }
          </ng-select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label"> نوع الوحدة </label>
          <ng-select bindLabel="name" class="single-select" #buildingUnit placeholder="يرجى تحديد نوع الوحدة" appendTo="body"
            (change)="buildingTypeChanged($event)" formControlName="building_type" [searchable]="true"
            [clearable]="true">
            @for (type of buildingTypes; track type) {
            <ng-option [value]="type.id">
              {{ type.name }}
            </ng-option>
            }
          </ng-select>
        </div>
      </div>
      @if (buildingDetailsFormGroup.get('building_type').value ==
      buildingType.Other) {
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">أدخل نوع العقار</label>
          <input
            type="text"
            formControlName="custom_property_type"
            class="form-control"
            placeholder="أدخل نوع العقار"
          />
        </div>
      </div>
      }
      <div class="col-6">
        <div class="form-group">
          <label for="" class="form-label">عدد الأدوار</label>
          <input
            type="number"
            formControlName="floors_number"
            class="form-control"
            placeholder=" يرجى تحديد عدد الأدوار "
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label"> المساحة   </label>
          <span class="static-code">م<sup>2</sup></span>
          <input
            type="number"
            formControlName="initial_space"
            class="form-control"
            placeholder=" يرجى تحديد مساحة العقار "
          />
          <img
            class="form-control-feedback"
            alt=""
            src="assets/images/svg/error-icon1.svg"
          />
        </div>
      </div>
      <div class="col-6">
        <select-paginated
          [placeholder]="'يرجى كتابة اسم المشروع'"
          [label]="'اسم المشروع'"
          [items]="projectsList"
          [selectedContract]="selectedContract"
          [pageCount]="pageCount"
          (params)="getProjectsByParams($event)"
          (itemData)="setProjectData($event)"
        >
        </select-paginated>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="" class="form-label"> نوع الطلب </label>
          <ng-select bindLabel="name" class="single-select" #orderTypeField placeholder="يرجى تحديد نوع الطلب"
            appendTo="body" [readonly]="orderId" formControlName="order_type" [searchable]="true"
            [clearable]="true" (change)="changeOrderType($event)" >
            @for (type of orderTypes; track type) {
            <ng-option [value]="type.pk">
              {{ type.name }}
            </ng-option>
            }
          </ng-select>
        </div>
      </div>
      <div
        class="col-6"
        *ngIf="
          buildingDetailsFormGroup.get('order_type').value ==
          orderType.DynamicServices
        "
      >
        <select-paginated
          [placeholder]="'يرجى كتابة اسم الخدمة'"
          [label]="'الخدمات'"
          [items]="servicesList"
          [selectedContract]="selectedService"
          [pageCount]="servicesPageCount"
          (params)="getDynamicServices($event)"
          (itemData)="setServiceData($event)"
        >
        </select-paginated>
      </div>
      <div class="col-6">
        <div class="checkBox-wrapper">
          <label class="checkbox-container">
            <input
              type="checkbox"
              formControlName="has_attached_building"
            />
            <span class="checkmark"> </span>
          </label>
          <span class="checkbox-label">يوجد ملحق بالعقار</span>
        </div>
      </div>
      <div class="col-6" *ngIf="buildingDetailsFormGroup.get('is_test').value">
        <div class="checkBox-wrapper">
          <label class="checkbox-container">
            <input
              type="checkbox"
              formControlName="allow_order_notifications"
            />
            <span class="checkmark"> </span>
          </label>
          <span class="checkbox-label"> تفعيل التنبيهات للطلب التجريبي</span>
        </div>
      </div>
      <div class="border-line"></div>
      <div class="col-md-12">
        <ayen-map
          [apiLoaded]="apiLoaded"
          #ayenMap
          [lat]="latitude"
          [lang]="longitude"
          (mapValues)="mapValues($event)"
        ></ayen-map>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">الحي </label>
          <input
            type="text"
            formControlName="address_neighborhood"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">رقم المبنى</label>
          <input
            type="text"
            formControlName="address_building_code"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">رقم الوحدة</label>
          <input
            type="text"
            formControlName="address_unit_code"
            class="form-control"
          />
        </div>
      </div>
      @if (showAlertMsg) {
      <div class="alert-box">
        <img
          alt=""
          src="assets/images/svg/error-icon.svg"
          class="close-alert"
          alt=""
          (click)="hideErrorMsg()"
        />
        <p class="alert-msg">{{ alertMessage }}</p>
      </div>
      }
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 confirm-next-step">
      <button class="btn btn-green" type="submit">التالي</button>
    </div>
  </div>
</form>
