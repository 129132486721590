import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotesRecommendationsService } from '../../services/notes-recommendations/notes-recommendations.service';

@Component({
  selector: 'notes-recommendations',
  templateUrl: './notes-recommendations.component.html',
  styleUrls: ['./notes-recommendations.component.scss'],
})
export class NotesRecommendationsComponent implements OnInit {
  notesForm: UntypedFormGroup;
  cards$: Observable<any>;
  severity$: Observable<any>;
  cardsSeverity$: Observable<any>;
  notesData;
  cardMenuIcon = [
    { src: 'assets/images/svg/arrow-down-icon.svg', valid: true },
  ];
  severityMenuIcon = [
    { src: 'assets/images/svg/arrow-down-icon.svg', valid: true },
  ];
  @Input() orderID;
  alertmsg: boolean = false;
  message: string = 'الرجاء تعبئة جميع الحقول الإلزامية.';
  @Output() errorMsg = new EventEmitter();
  showSpinner: boolean;
  notes: UntypedFormArray;
  @ViewChild('parentElement') parentElementRef!: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private elementRef: ElementRef,
    private notesRecommendationsService: NotesRecommendationsService
  ) {}

  ngOnInit(): void {
    this.cardsSeverity$ =
      this.notesRecommendationsService.getSeverityRecommendations();
    this.cards$ = this.cardsSeverity$.pipe(map((res) => res.cards));
    this.severity$ = this.cardsSeverity$.pipe(map((res) => res.severity));
    this.createForm();
    this.getNotesRecommendations();
    this.notes = this.notesForm.get('notes') as UntypedFormArray;
  }

  createForm() {
    this.notesForm = this.fb.group({
      notes: new UntypedFormArray([]),
    });
  }

  getNotesRecommendations() {
    this.notesRecommendationsService
      .getNotesRecommendations(this.orderID)
      .subscribe(
        (data) => {
          this.notesData = data;
          if (this.notesData.length > 0) {
            this.patchValues(data);
          } else {
            this.addNote();
          }
        },
        (error) => {
          try {
            this.notesData = [];
          } catch {}
        }
      );
  }

  patchValues(data) {
    data.forEach((item, index) => {
      if (this.cardMenuIcon[index]) {
        this.cardMenuIcon[
          index
        ].src = `assets/images/svg/${item.card_icon}.svg`;
      } else {
        this.cardMenuIcon.push({
          src: `assets/images/svg/${item.card_icon}.svg`,
          valid: true,
        });
      }
      if (this.severityMenuIcon[index]) {
        this.severityMenuIcon[
          index
        ].src = `assets/images/svg/${item.severity_icon}.svg`;
      } else {
        this.severityMenuIcon.push({
          src: `assets/images/svg/${item.severity_icon}.svg`,
          valid: true,
        });
      }
      this.addNote(item, true);
    });
  }

  addNote(data?, hasID?) {
    this.notes.push(
      this.fb.group({
        note: [data?.note, Validators.required],
        recommendation: [data?.recommendation ? data.recommendation : ''],
        id: [hasID ? data?.id : null],
        order_id: [+this.orderID],
        card: [data?.card, Validators.required],
        severity: [data?.severity, Validators.required],
      })
    );
  }

  addDefaultIcons() {
    this.cardMenuIcon.push({
      src: 'assets/images/svg/arrow-down-icon.svg',
      valid: true,
    });
    this.severityMenuIcon.push({
      src: 'assets/images/svg/arrow-down-icon.svg',
      valid: true,
    });
  }

  copyNote(index) {
    this.parentElementRef.nativeElement.classList.add('active');
    let formValues = this.notesForm.value.notes[index];
    this.cardMenuIcon.push({ src: this.cardMenuIcon[index].src, valid: true });
    this.severityMenuIcon.push({
      src: this.severityMenuIcon[index].src,
      valid: true,
    });
    this.addNote(formValues, false);
  }

  deleteNote(index) {
    this.parentElementRef.nativeElement.classList.add('active');
    if (this.notesData[index]) {
      let noteID = this.notesData[index].id;
      this.notesRecommendationsService
        .deleteNoteRecommendation(noteID)
        .subscribe(
          (data) => {
            this.notesData.splice(index, 1);
            this.notes.removeAt(index);
            this.cardMenuIcon.splice(index, 1);
            this.severityMenuIcon.splice(index, 1);
          },
          (error) => {
            try {
            } catch {}
          }
        );
    } else {
      this.notes.removeAt(index);
      this.cardMenuIcon.splice(index, 1);
      this.severityMenuIcon.splice(index, 1);
    }
  }

  getSrc(icon) {
    return `assets/images/svg/${icon}.svg`;
  }

  setCardSeverity(type, value, icon, index) {
    this.notes.at(index).get(type).setValue(value);
    this[type + 'MenuIcon'][index] = {
      src: `assets/images/svg/${icon}.svg`,
      valid: true,
    };
  }

  save() {
    this.alertmsg = false;
    this.resetClassError();
    if (this.notesForm.status == 'INVALID') {
      this.alertmsg = true;
      const invalidControls = this.findInvalidControls();
      invalidControls.forEach((control) => {
        if (control.name === 'card') {
          this.cardMenuIcon[control.index].valid = false;
        }
        if (control.name === 'severity') {
          this.severityMenuIcon[control.index].valid = false;
        }
        if (control.name === 'note') {
          const noteElements =
            this.elementRef.nativeElement.querySelectorAll("[name='note']");
          noteElements.forEach((element) => {
            element.classList.add('error');
          });
        }
      });
      return;
    }
    this.showSpinner = true;
    let data = this.notesForm.get('notes').value;
    this.notesRecommendationsService
      .postNotesRecommendations(this.orderID, data)
      .subscribe(
        (res) => {
          this.notesData = res;
          this.showSpinner = false;
          this.clearFormArray(this.notes);
          this.patchValues(res);
          this.showConfirmationModal();
        },
        (error) => {
          try {
            this.showSpinner = false;
            this.alertmsg = true;
          } catch {}
        }
      );
  }

  showConfirmationModal() {
    this.errorMsg.emit({
      message: 'تم تحديث البيانات بنجاح.',
      imgSuccess: true,
    });
  }

  resetClassError() {
    const noteElements =
      this.elementRef.nativeElement.querySelectorAll("[name='note']");
    noteElements.forEach((element) => {
      element.classList.remove('error');
    });
    this.cardMenuIcon.forEach((item) => {
      item.valid = true;
    });
    this.severityMenuIcon.forEach((item) => {
      item.valid = true;
    });
  }

  findInvalidControls() {
    const invalid = [];
    const formGroups = this.notesForm.get('notes')['controls'];
    formGroups.forEach((item, index) => {
      const controls = item['controls'];
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push({ name: name, index: index });
        }
      }
    });
    return invalid;
  }

  clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  closeError() {
    this.alertmsg = false;
  }
}
