import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-inspector-multiselect',
  templateUrl: './inspector-multiselect.component.html',
  styleUrls: ['./inspector-multiselect.component.scss']
})
export class InspectorMultiselectComponent implements ICellEditorAngularComp, AfterViewInit {

  selected: string[];
  @ViewChild('container', { read: ViewContainerRef }) public container;
  @ViewChild('Selecter') ngselect;
  multiple: boolean = false;
  public status: string[];
  values: [] = [];
  experiences = [];
  form: UntypedFormGroup;
  bindValue = 'pk'
  constructor(private fb: UntypedFormBuilder) { }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.ngselect.open();
    });
  }

  agInit(params: any): void {
    this.form = this.fb.group({
      selectedValue: ''
    })
    this.values = params.values;
    this.multiple = params.isMultiple;
    this.bindValue = params.bindValue ?? this.bindValue;
    this.setStatus(params.value);
  }

  getValue(): any {
    return this.form.get('selectedValue').value;
  }

  isPopup(): boolean {
    return true;
  }

  setStatus(event): void {
    this.form.patchValue({
      selectedValue: event
    });
  }

  onSelectAll() {
    const selected = this.values.map((item: { pk: number, name: string }) => item[this.bindValue]);
    this.form.get('selectedValue').patchValue(selected);
  }
}
