import { Component, Input, OnInit } from '@angular/core';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { Order } from 'src/app/models/interfaces/order';

/**
*  define and export OrderReportsLogComponent class
*/
@Component({
  selector: 'app-order-reports-log',
  templateUrl: './order-reports-log.component.html',
  styleUrls: ['./order-reports-log.component.scss']
})
export class OrderReportsLogComponent implements OnInit {

  /**
  *  declare orderId property  
  */
 orderId: string;
  /**
  *  declare orders list property  
  */
 public orders: Order;
  /**
  *  declare orderType enum  
  */
 public orderType: any ; 
  /**
  *  declare orderStatus enum  
  */
  public orderStatus: any = OrderStatus;
  @Input() orderData; 
  
  /**
  *  implement ngOnInit and fetch id from active route params
  */
 ngOnInit(): void {
  this.orders = this.orderData;
 }
}

