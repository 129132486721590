import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CategoriesService } from 'src/app/features/ayen-services/services/categories/categories.service';

@Component({
  selector: 'app-change-category',
  templateUrl: './change-category.component.html',
  styleUrls: ['./change-category.component.scss'],
})
export class ChangeCategoryComponent implements OnInit {
  addCategoryFormGroup: UntypedFormGroup;
  categories: any;
  disable: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private categoriesService: CategoriesService,
    public dialogRef: MatDialogRef<ChangeCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.getCategories();
  }

  createFormControls() {
    return this.addCategoryFormGroup.controls;
  }

  createForm() {
    this.addCategoryFormGroup = this.formBuilder.group({
      category: [null, Validators.required],
    });
  }

  ngDoCheck(): void {
    if (this.addCategoryFormGroup.get('category').value) {
      this.disable = false;
    } else {
      this.disable = true;
    }
  }

  getCategories() {
    this.categoriesService
      .getCategoriesByService(this.data.serviceId)
      .subscribe((data) => {
        this.categories = data['results'];
      });
  }

  changeCategory() {
    let category = this.addCategoryFormGroup.get('category').value;
    this.data['category'] = category;
    this.dialogRef.close(this.data);
  }
}
