import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { InspectorsRoutingModule } from './inspectors-routing.module';
import { MatSelectModule } from '@angular/material/select';
import { NgxPaginationModule } from 'ngx-pagination';
import { InspectorsComponent } from './inspectors.component';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InspectorGridComponent } from './pages/inspector-grid/inspector-grid.component';
import { InspectorStatisticsComponent } from './pages/inspector-statistics/inspector-statistics.component';
import { InspectorProfileComponent } from './pages/inspector-profile/inspector-profile.component';
import { AddServiceComponent } from './components/add-service/add-service.component';
import { InspectorsSettingsComponent } from './pages/inspectors-settings/inspectors-settings.component';
import { SharedModule } from 'src/app/shared/shared.module';

class CustomDateAdapter extends MomentDateAdapter {
  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return [
      'الأحد',
      'الإثنين',
      'الثلاثاء',
      'الأربعاء',
      'الخميس',
      'الجمعة',
      'السبت',
    ];
  }
}

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MMM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [
    InspectorsComponent,
    InspectorGridComponent,
    InspectorStatisticsComponent,
    InspectorProfileComponent,
    AddServiceComponent,
    InspectorsSettingsComponent,
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    NgSelectModule,
    MatInputModule,
    MatMenuModule,
    MatExpansionModule,
    InspectorsRoutingModule,
    FormsModule,
    SharedModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    AgGridModule,
  ],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-eg' },
  ],
})
export class InspectorsModule {}
