export class SaveFilter {
  static readonly type = '[filterState] SaveFilter';
  constructor(
    public payload: { param: { filter_page: string }, data: { title: string, query: {}} }
  ) {}
}

export class SavedFilterList {
  static readonly type = '[filterState] savedFiltersList';
  constructor(public payload: { filter_page: string }) {}
}

export class SelectFilter {
  static readonly type = '[filterState] selectedFilter';
  constructor(public payload: { selectedFilter: {} }) {}
}

export class changeFilterParam {
  static readonly type = '[filterState] changeFilterParams';
  constructor(public payload: {change: boolean}) {}
}
