import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GridDefinitions } from 'src/app/shared/global/grid-definition';
import { FilterUtilService } from 'src/app/core/services/uitls/filter-utilizer/filter-util.service';
import { FiltersService } from 'src/app/core/services/filters/filters.service';
import { AddClientComponent } from 'src/app/shared/components/add-client/add-client.component';
import { CustomerGridColumns } from './customer-cols';
import { ShowAlertUtilService } from 'src/app/core/services/uitls/alert-util/show-alert-util.service';
import { ApplySortingService } from 'src/app/core/services/uitls/sorting-util/apply-sorting.service';
import { ReplaySubject } from 'rxjs';
import { ClientsService } from '../../services/clients.service';

@Component({
  selector: 'app-customers-grid',
  templateUrl: './customers-grid.component.html',
  styleUrls: ['./customers-grid.component.scss']
})
export class CustomersGridComponent implements OnInit {
  public gridApi;
  public gridColumnApi;
  public rowData;
  public columnDefs;
  public defaultColDef;
  public paginationPageSize: number;
  public clients;
  public rowDataTracker = {};
  frameworkComponents;
  deleteMsg: string = 'هل أنت متأكد من حذف هذا المستخدم؟';
  confirmMsg: string = 'عذرًا لا يمكن حذف هذا المستخدم لوجود طلبات متعلقة بحسابه.';
  disabledDelete: boolean = true;
  disableAction: boolean = true;
  selectedRows = [];
  imgSuccess: boolean;
  customerId: any;
  deleteCustomerIndex: any;
  config: { id: string; itemsPerPage: number; currentPage: any; totalItems: any; };
  page: number = 1;
  totalRecords: number = 0;
  searchValue: string;
  filterData;
  filterParamsUrl: any = {};
  filterDataInit: any = {};
  public rowSelection;
  public clientDialogRef: MatDialogRef<AddClientComponent>;
  filterPage: string = 'CLIENTS';
  savedFilters: any = [];
  spinner: boolean = true;
  spinnerKey: string = 'spinner';
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  currentItemsPerPage: number;

  constructor(private clientsService: ClientsService,
    private filter: FiltersService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private showAlertUtilService: ShowAlertUtilService,
    private router: Router,
    private filterUtil: FilterUtilService,
    private applySortingService: ApplySortingService) {
    const columnsDefinitions = new CustomerGridColumns();
    this.columnDefs = columnsDefinitions.columns;
    this.defaultColDef = GridDefinitions.DEFAULT_GRID_COLS_DEFINITIONS;
    this.paginationPageSize = 10;
    this.config = {
      id: 'myPagination',
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
    };
    this.rowSelection = 'multiple';
    this.frameworkComponents = columnsDefinitions.frameworkComponents;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        this.searchValue = params["query"] ? params["query"] : '';
        this.filterParamsUrl = params;
        this.filterDataInit = params;
      }
    });
    this.getClientsFilter();
    this.filterUtil.getSavedFilters(this, this.filterPage);
    this.applySortingService.currentSortCol.subscribe(data => {
      if(data.length > 0) {
        this.getClientsByPage(data);
      }
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.filter.changeSelectedFilter(this.filterDataInit);
    this.filterUtil.applySelectedFilter(this, this.getClientsByPage, this.spinnerKey);
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.getClientsByPage();
  }
  pageSizeChanged(event) {
    this.config.itemsPerPage = event;
    this.getClientsByPage();

  }
  getClientsByPage(ordering?) {
    this.spinner = false;
    let params = {...this.filterParamsUrl};
    params['page'] = this.config.currentPage;
    params['page_size'] = this.config.itemsPerPage;
    if(this.searchValue) {
      params['query'] = this.searchValue;
    }
    if (ordering) {
      params['ordering'] = ordering;
    }
    this.clientsService.getClientByPage(params).subscribe(data => {
      this.rowData = data.results;
      this.clients = data.results;
      this.config['totalItems'] = data.count;
      this.currentItemsPerPage = data.results.length;
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: this.filterParamsUrl,
          queryParamsHandling: '',
        });
      },(error) => {
          this.handleError(error);
      }
    );
  }

  getClientsFilter() {
    let params = {
      filter_page: this.filterPage
    }
    this.filter.getFiltersData(params).subscribe(data => {
      this.filterData = data;
    },
      (error) => {
        this.handleError(error);
      })
  }

  quickSearch(event) {
    this.searchValue = event;
    this.config.currentPage = 1;
    this.getClientsByPage();
  }

  filterFormValus(event){
    this.filterParamsUrl = event.filterParamsUrl ;
    this.getClientsByPage();
  }

  onCellValueChanged(event) {
    if (event.oldValue && event.newValue && event.oldValue.toString() !== event.newValue.toString()) {
      this.storeEdit(event.rowIndex, event.colDef.field, event.oldValue, event.newValue);
    }
    let clientValueChanged = {};
    let clientID = event.data.id;
    clientValueChanged[event.colDef.field] = event.newValue;
    this.clientsService.updateClientById(clientID, clientValueChanged).subscribe(
      () => { },
      error => {
        this.cancelEdited();
        this.handleError(error);
      }
    )
  }

  onRowSelected(event) {
    this.disabledDelete = false;
    this.disableAction = true;
    this.selectedRows = this.gridApi.getSelectedRows();
    this.customerId = this.selectedRows[0]?.id;
    this.deleteCustomerIndex = this.rowData.indexOf(this.selectedRows[0]);
    if (this.selectedRows.length != 1) {
      this.disabledDelete = true;
    }
    if (this.selectedRows.length > 0) {
      this.disableAction = false;
    }
  }

  storeEdit(rowId, columnId, oldValue, newValue) {
    if (!this.rowDataTracker[rowId]) {
      this.rowDataTracker[rowId] = {};
    }
    this.rowDataTracker[rowId][columnId] = [oldValue, newValue];
  }

  cancelEdited() {
    this.imgSuccess = false;
    this.confirmMsg = 'البريد الإلكتروني أو رقم الجوال المدخل موجود مسبقاً.';
    let that = this;
    this.gridApi.forEachNode(function (node) {
      if (Object.keys(that.rowDataTracker).indexOf(node.id) > -1) {
        that.showAlertBox();
        let data = node.data;
        Object.keys(that.rowDataTracker[node.id]).forEach(function (key) {
          data[key] = that.rowDataTracker[node.id][key][0];
        });
        node.updateData(data);
      }
    });
  }

  handleError(error) {
    try { }
    catch (err) { }
  }

  showAlertBox() {
    this.showAlertUtilService.showAlertBox({imgSuccess: this.imgSuccess, confirmMsg: this.confirmMsg, button: 'تم'});
  }

  delete(event) {
    let customerId = event.id
    let index = event.index[0];
    this.clientsService.deleteClients(customerId).subscribe((data) => {
      this.rowData.splice(index, 1);
      this.gridApi.setRowData(this.rowData);
    }, (error) => {
      this.imgSuccess = false;
      this.showAlertBox();
      this.handleError(error);
    })
  }

  addClient() {
    this.clientDialogRef = this.dialog.open(AddClientComponent, {});
    this.clientDialogRef.afterClosed().subscribe(result => {
      if (result?.id) {
        this.addNewClient(result);
      }
    });
  }

  addNewClient(e) {
    this.rowData.push(e);
    this.gridApi.setRowData(this.rowData);
  }

  goProfile() {
    let selectedRow = this.gridApi.getSelectedRows()[0];
    this.router.navigateByUrl(`/pages/customers/cusotmer-profile/${selectedRow.id}`);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.filter.changeSelectedFilter({});
  }
}
