import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from '../alert-box/alert-box.component';
import { AssignInspectorComponent } from '../assign-inspector/assign-inspector.component';
import { AssignOrdersProjectComponent } from '../assign-orders-project/assign-orders-project.component';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';
import { InspectionService } from 'src/app/core/services/inspection/inspection.service';
import { ProjectsService } from 'src/app/features/projects/services/projects.service';
import { LinkedOrderComponent } from '../linked-order/linked-order.component';
import { AdminOrdersService } from 'src/app/features/orders/services/orders/admin-orders.service';

@Component({
  selector: 'orders-management',
  templateUrl: './orders-management.component.html',
  styleUrls: ['./orders-management.component.scss'],
})
export class OrdersManagementComponent {
  @Input() selectedRowsPK: any;
  @Input() assignedInspectorId: any;
  @Input() pageIdentifier: string;
  @Output() sipnnerState = new EventEmitter();
  @Output() updateOrders = new EventEmitter();
  confirmMsg: string;
  modelImg: boolean;
  imgPath: string;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  orderDialogRef: MatDialogRef<AssignOrdersProjectComponent>;
  matDialogRef: MatDialogRef<AssignInspectorComponent>;
  linkedOrderDialogRef: MatDialogRef<LinkedOrderComponent, any>;
  linkedOrderId: any;

  constructor(
    private inspectionService: InspectionService,
    private inspectorService: InspectorsService,
    private projectsService: ProjectsService,
    private orderService: AdminOrdersService,
    private matDialog: MatDialog
  ) {}

  requestReinspection() {
    this.changeSpinnerState(true);
    if (this.selectedRowsPK.length > 0) {
      let data = {
        orders: this.selectedRowsPK,
      };
      this.inspectionService.requestReinspection(data).subscribe(
        (data) => {
          this.changeSpinnerState(false);
          this.confirmMsg =
            'تم إنشاء طلب إعادة فحص  #' + this.selectedRowsPK + '.';
          this.modelImg = true;
          this.showAlertBox();
        },
        (error) => {
          try {
            this.changeSpinnerState(false);
            this.imgPath =
              '../../../../assets/images/svg/refusal-icon-admin.svg';
            this.confirmMsg = error.error.message;
            this.modelImg = false;
            this.showAlertBox();
          } catch {}
        }
      );
    } else {
      this.confirmMsg = 'يرجى تحديد الطلبات المراد إعادة الفحص لها';
      this.noSelectedOrder();
    }
  }

  addOrderToProject() {
    if (this.selectedRowsPK.length > 0) {
      this.orderDialogRef = this.matDialog.open(AssignOrdersProjectComponent, {
        disableClose: true,
      });
      this.orderDialogRef.afterClosed().subscribe((result) => {
        if(result) {
          let data = {
            orders: this.selectedRowsPK,
          };
          this.projectsService.addOrdersProject(result.pk, data).subscribe(
            (data) => {
              let validOrders = [];
              data.valid_orders.forEach((order) => {
                validOrders.push('#' + order.reference_number);
              });
              this.confirmMsg =
                'تم تعيين المشروع (  ' +
                result.name +
                ' ) للطلبات التالية (' +
                validOrders +
                ')';
              this.modelImg = true;
              this.showAlertBox();
            },
            (error) => {
              try {
                let invalidOrders = [];
                error.error.invalid_orders.forEach((order) => {
                  invalidOrders.push('#' + order.reference_number);
                });
                this.confirmMsg =
                  'عذراً لا يمكن إضافة الطلبات التالية (' +
                  invalidOrders +
                  ') لمشروع ( ' +
                  result.name +
                  ' )';
                this.modelImg = false;
                this.imgPath =
                  '../../../../assets/images/svg/refusal-icon-admin.svg';
                this.showAlertBox();
              } catch {}
            }
          );
        }
      });
    } else {
      this.confirmMsg = 'يرجى تحديد الطلبات المراد تعيين مشروع لها';
      this.noSelectedOrder();
    }
  }

  addInspector() {
    if (this.selectedRowsPK.length > 0) {
      this.matDialogRef = this.matDialog.open(AssignInspectorComponent, {
        disableClose: true,
      });

      this.matDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.assignedInspectorId = result.id;
          let data = {
            orders: this.selectedRowsPK,
          };
          this.inspectorService
            .assignInspector(this.assignedInspectorId, data)
            .subscribe(
              (res) => {
                this.confirmMsg =
                  'تم تعيين الفاحص (  ' +
                  result.full_name +
                  ' ) للطلبات التالية (#' +
                  this.selectedRowsPK +
                  ')';
                this.modelImg = true;
                this.showAlertBox();
                // this.getOrdersByPage();
                this.updateOrders.emit(true);
              },
              (error) => {
                try {
                  this.confirmMsg = error.error.message[0];
                  this.modelImg = false;
                  this.imgPath =
                    '../../../../assets/images/svg/refusal-icon-admin.svg';
                  this.showAlertBox();
                } catch {}
              }
            );
        }
      });
    } else {
      this.confirmMsg = 'يرجى تحديد الطلبات المراد تعيين فاحص لها';
      this.noSelectedOrder();
    }
  }

  linkOrder() {
    if (this.selectedRowsPK.length > 0) {
      this.linkedOrderDialogRef = this.matDialog.open(LinkedOrderComponent, {
        disableClose: true,
        data: {
          pageIdentifier: this.pageIdentifier
        }
      });

      this.linkedOrderDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.linkedOrderId = result.id;
          let data = {
            orders: this.selectedRowsPK,
          };
          //change endpoint 
          this.orderService.linkOrders(this.linkedOrderId, data).subscribe(
            (data) => {
              let validOrders = [];
              data.valid_orders.forEach((order) => {
                validOrders.push('#' + order.reference_number);
              });
              this.confirmMsg =
                'تم ربط الطلب  ( # ' +
                result.reference_number +
                ' ) للطلبات التالية (' +
                validOrders +
                ')';
              this.modelImg = true;
              this.showAlertBox();
            },
            (error) => {
              try {
                let invalidOrders = [];
                error.error.invalid_orders.forEach((order) => {
                  invalidOrders.push('#' + order.reference_number);
                });
                this.confirmMsg =
                  'عذراً لا يمكن ربط الطلبات التالية (' +
                  invalidOrders +
                  ') للطلب ( ' +
                  result.reference_number +
                  ' )';
                this.modelImg = false;
                this.imgPath =
                  '../../../../assets/images/svg/refusal-icon-admin.svg';
                this.showAlertBox();
              } catch {}
            }
          );
        }
      });
    } else {
      this.confirmMsg = 'يرجى تحديد الطلبات المراد ربطها';
      this.noSelectedOrder();
    }
  }

  unLinkOrder() {
    let data = {
      orders: this.selectedRowsPK,
    };
    this.orderService.unLinkOrders(data).subscribe( data => {
      this.modelImg = true;
      this.confirmMsg = 'تم فك ارتباط الطلبات';
      this.showAlertBox();
    }, error => {
      try {}
      catch {}
    });
  }

  changeSpinnerState(state: boolean) {
    this.sipnnerState.emit(state);
  }

  noSelectedOrder() {
    this.imgPath = '../../../../assets/images/svg/warning-icon.svg';
    this.modelImg = false;
    this.showAlertBox();
  }

  changePaymentInspectorsStatus() {
    if (this.selectedRowsPK.length > 0) {
      this.inspectorService
        .changePaymentInspectorsStatus(this.selectedRowsPK, true)
        .subscribe(
          (data) => {
            this.modelImg = true;
            this.confirmMsg = data.message;
            this.showAlertBox();
          },
          (error) => {
            try {
              this.modelImg = false;
              this.confirmMsg = error.error.error;
              this.showAlertBox();
            } catch {}
          }
        );
    } else {
      this.confirmMsg = 'يرجى تحديد الطلبات المراد الدفع للفاحصين';
      this.noSelectedOrder();
    }
  }

  showAlertBox() {
    this.saveDialogRef = this.matDialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.confirmMsg,
        imgPath: this.imgPath,
        button: 'تم',
      },
    });
  }
}
