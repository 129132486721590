import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UpdateOrderDetails } from 'src/app/features/orders/state/order-action';
import { OrderState } from 'src/app/features/orders/state/order.state';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-info',
  templateUrl: './main-info.component.html',
  styleUrls: ['./main-info.component.scss'],
})
export class MainInfoComponent implements OnInit {
  orderId: string;
  @Output() errorMsg = new EventEmitter();
  address: string = '';
  is_test: boolean;
  allowed_in_order_bool: boolean;
  imgSuccess: boolean;
  message: string;
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  orders: any;

  constructor(
    private router: Router,
    private store: Store,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.orderDetails$.subscribe((data) => {
      this.orders = data;
    });
    this.getAddress();

    this.is_test = this.orders.is_test;
    this.allowed_in_order_bool = this.orders.allowed_in_order_bool;
  }

  getAddress() {
    this.address = this.orders.address_unit_code
      ? this.orders.address_unit_code + ' , '
      : '';
    this.address += this.orders.address_building_code
      ? this.orders.address_building_code + ' , '
      : '';
    this.address += this.orders.address_street
      ? this.orders.address_street + ' , '
      : '';
    this.address += this.orders.address_neighborhood
      ? this.orders.address_neighborhood + ' , '
      : '';
    this.address += this.orders.google_address_city
      ? this.orders.google_address_city + ' , '
      : '';
    this.address += this.orders.google_address_area
      ? this.orders.google_address_area + ' , '
      : '';
    this.address += this.orders.google_address_country
      ? this.orders.google_address_country
      : '';
  }

  copyAddress() {
    this.copyLink('https://www.google.com/maps/search/?api=1&query=' + this.orders.location);
  }

  copyOrderLink() {
    if(environment.production) {
      this.copyLink(`https://services.ayen.com.sa/add-request/payment?id=${this.orders.id}`);
    } else {
      this.copyLink(`https://alpha.services.ayen.com.sa/add-request/payment?id=${this.orders.id}`)
    }
  }

  copyLink(link:string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.showAlertBox();
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: true,
        message: 'تم النسخ بنجاح',
        button: 'تم',
      },
    });
  }

  codeValueChanged(event, elementRef) {
    let data = {};
    data[event.labelEn] = event.value;

    this.store.dispatch(
      new UpdateOrderDetails({ orderId: +this.orders.id, order:  data})).subscribe(
      () => {
        this.message = 'تم تحديث البيانات بنجاح.';
        this.imgSuccess = true;
        this[event.labelEn] = event.value;
        this.showModal();
      },
      (error) => {
        elementRef.switchBtn.nativeElement.checked = !event.value;
        this.message = Object.values(error.error)[0].toString();
        this.imgSuccess = false;
        this.showModal();
      }
    );
  }

  showModal() {
    this.errorMsg.emit({
      message: this.message,
      imgSuccess: this.imgSuccess,
    });
  }

  openOrder(id) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/pages/orders/${id}`])
    );
    window.open(url, '_blank');
  }
}
