import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltersService } from 'src/app/core/services/filters/filters.service';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { Order } from 'src/app/models/interfaces/order';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { FilterUtilService } from 'src/app/core/services/uitls/filter-utilizer/filter-util.service';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { tablesHeaderData } from 'src/app/shared/global/tablesHeaderData';
import { AdminOrdersService } from '../../services/orders/admin-orders.service';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
})
export class OrdersListComponent implements OnInit {
  orders: Order[] = [];
  orderType: any;
  orderStatus: any = OrderStatus;
  searchValue: string;
  totalRecords: number = 0;
  page: number = 1;
  config: any;
  selectedRowsIndex: any[] = [];
  selectedRowsPK: any[] = [];
  deletedOrderID: any[] = [];
  deletedOrderIndex: any[] = [];
  modelImg: boolean;
  imgPath: string;
  confirmMsg: string;
  disabledDelete: boolean = true;
  filterData;
  imgDefault: string = '/assets/images/svg/sort-default.svg';
  imgASC: string = '/assets/images/svg/sort-up.svg';
  imgDesc: string = '/assets/images/svg/sort-down.svg';
  filterDataInit = {};
  filterParamsUrl = {};
  name: string;
  assignedInspectorId: any;
  spinner: boolean = true;
  filterPage: string = 'ORDERS';
  pageTitle: string = 'الطلبات';
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  savedFilters: any = [];
  spinnerKey: string = 'spinner';
  tableHeaderData = tablesHeaderData.ORDER_HEADER_DATA;
  updatedOrders = new BehaviorSubject({});
  orderData = this.updatedOrders.asObservable();
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  totalRowsSelected: boolean = false;
  currentItemsPerPage: number;

  constructor(
    private router: Router,
    private adminOrder: AdminOrdersService,
    private titleService: Title,
    private route: ActivatedRoute,
    private filter: FiltersService,
    private filterUtil: FilterUtilService,
    private matDialog: MatDialog
  ) {
    this.config = {
      id: 'myPagination',
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
    };
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.filterUtil.clearfilterParams(this.filterParamsUrl);
      this.filterUtil.clearfilterParams(this.filterDataInit);
      if (Object.keys(params).length > 0) {
        this.searchValue = params['query'] ? params['query'] : '';
        this.filterParamsUrl = { ...params };
        this.filterDataInit = { ...params };
      }
    });
    this.getOrdersFilter();
    this.titleService.setTitle(' الطلبات | عاين');
    this.filterUtil.getSavedFilters(this, this.filterPage);
    this.filter.changeSelectedFilter(this.filterDataInit);
    this.filterUtil.applySelectedFilter(
      this,
      this.getOrdersByPage,
      this.spinnerKey
    );
  }

  quickSearch(event) {
    this.searchValue = event;
    this.config.currentPage = 1;
    this.getOrdersByPage();
  }

  getOrdersByPage(ordering?) {
    this.emptySelectedRows();
    this.totalRowsSelected = false;
    let params = { ...this.filterParamsUrl };

    if (this.searchValue) {
      params['query'] = this.searchValue;
    }
    if (ordering) {
      params['ordering'] = ordering;
    }
    params['page'] = this.config.currentPage;
    params['page_size'] = this.config.itemsPerPage;

    this.adminOrder.getAdminOrdersByPage(params).subscribe(
      (data) => {
        this.loadOrders(data);
        this.updatedOrders.next(data.results);
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  loadOrders(data) {
    this.spinner = false;
    this.orders = data.results;
    this.config['totalItems'] = data.count;
    this.currentItemsPerPage = data.results.length;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.filterParamsUrl,
      queryParamsHandling: '',
    });
  }

  getOrdersFilter() {
    let params = {
      filter_page: this.filterPage,
    };
    this.filter.getFiltersData(params).subscribe(
      (data) => {
        this.filterData = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  handleBackendError(error) {
    try {
      console.log(error);
    } catch {
      console.log(error);
    }
  }

  getSelectedRow(event) {
    this.selectedRowsIndex = event.index;
    this.selectedRowsPK = event.id;
    this.totalRowsSelected = false;
  }

  deleteSelectedRows() {
    this.deletedOrderID = this.selectedRowsPK;
    this.deletedOrderIndex = this.selectedRowsIndex;
    this.showDeleteConfirmationBox();
  }

  showDeleteConfirmationBox() {
    this.deleteDialogRef = this.matDialog.open(ConfirmationBoxComponent, {
      data: {
        id: this.deletedOrderID,
        index: this.deletedOrderIndex,
        message: 'هل أنت متأكد من حذف الطلب',
      },
    });
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete(result);
      }
    });
  }

  selectTotalRows(event) {
    this.totalRowsSelected = event;
  }

  emptySelectedRows() {
    this.selectedRowsIndex.splice(0, this.selectedRowsIndex.length);
    this.selectedRowsPK.splice(0, this.selectedRowsPK.length);
  }

  delete(event) {
    let index = event.index;
    let pk = event.id;
    this.adminOrder.deleteAdminOrder(pk).subscribe(
      (data) => {
        for (let i = index.length - 1; i >= 0; i--) {
          let OrderInd = index[i];
          this.orders.splice(OrderInd, 1);
        }
        this.emptySelectedRows();
      },
      (error) => {
        try {
          this.modelImg = false;
          this.imgPath = '../../../../assets/images/svg/refusal-icon-admin.svg';
          this.confirmMsg = '  عذراً لا يمكن حذف الطلب . ‎';
          this.showAlertBox();
          this.emptySelectedRows();
        } catch {}
      }
    );
  }

  showAlertBox() {
    this.saveDialogRef = this.matDialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.confirmMsg,
        imgPath: this.imgPath,
        button: 'تم',
      },
    });
  }

  openOrder(id, newTab?) {
    if (newTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/pages/orders/${id}`])
      );
      window.open(url, '_blank');
    } else {
      this.router.navigateByUrl('/pages/orders/' + id);
    }
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.totalRowsSelected = false;
    this.getOrdersByPage();
  }
  pageSizeChanged(event) {
    this.config.itemsPerPage = event;
    this.getOrdersByPage();
  }
  onFilterTextBoxChanged() {
    this.config.currentPage = 1;
    this.getOrdersByPage();
  }

  filterFormValues(event) {
    this.filterParamsUrl = event.filterParamsUrl;
    this.spinner = true;
    this.config.currentPage = 1;
    this.getOrdersByPage();
  }

  toggleSorting(event) {
    this.getOrdersByPage(event.col);
  }

  disabledDeleteBtn(event) {
    this.disabledDelete = event;
  }

  sipnnerState(state) {
    this.spinner = state;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.filter.changeSelectedFilter({});
  }
}
