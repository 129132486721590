import { Component,Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ServicesOrdersService } from 'src/app/core/services/servicesOrders/services-orders.service';
import { Element } from "src/app/models/interfaces/serviceEngine/element.model";

@Component({
  selector: 'app-radio-btns',
  templateUrl: './radio-btns.component.html',
  styleUrls: ['./radio-btns.component.scss']
})
export class RadioBtnsComponent implements OnInit {
  /**
  *  declare subForm property  
  */
   @Input() subForm: UntypedFormGroup;
  /**
   declare inputs variable
  *  
  */
  @Input() inputs: Element;
  @Input() orderId : number;
  totalElments:  Element[] = [];
  isRequired: boolean = false;
  value: any;
  constructor(private serviceEngineService: ServicesOrdersService) { }

  ngOnInit(): void {
    if(this.inputs.field_value){
      this.value = this.inputs.field_value ;
    }
    else if(this.inputs?.validation?.default){
      this.value = this.inputs.validation.default ;
    }
    if(this.value){
      this.inputs.choices.forEach(choice =>{
        if(choice.choice_value == this.value){
          this.getValue(choice.sub_elements);  
        }
      });
    }
    if(!this.inputs.is_editable_admin){
      this.subForm.controls[this.inputs.field_key_name].disable();
    }
    this.isRequired = this.inputs.validation.required;
  }
  getValue(event){
    if(this.totalElments.length > 0 ){
      this.totalElments.forEach( elem =>{
        this.subForm.removeControl(elem.field_key_name);
      })
    }
    this.totalElments = event;
      // Create form Builder
      this.createForm();
      // Create controls for form controlers
      this.createFormControls();
  }
  createFormControls() {
    return this.subForm.controls;
  }
  /**-- 
   createForm function
   --**/
  createForm() {
    for (let formModule of this.totalElments) {
      this.subForm.addControl(formModule.field_key_name, new UntypedFormControl());
      this.setValidation(formModule.field_key_name, formModule.validation);
    }
  }
  setValidation(formControl, validation) {
    let validators = [];
    let defaultValidators = ['required', 'maxLength', 'minLength', 'pattern', 'min', 'max'];
    defaultValidators.forEach(validator => {
      if (validation[validator]) {
        if (validator == 'required')
          validators.push(Validators.required);
        else
          validators.push(Validators[validator](validation[validator]));
      }
    });
    this.subForm.controls[formControl].setValidators(Validators.compose(validators));
    this.subForm.controls[formControl].updateValueAndValidity();
  }
  getComponentName(elem) {
    //  return InputsComponent
    return this.serviceEngineService.getComponentByType(elem.field_category, elem.field_type);
  }
}
