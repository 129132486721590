import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-register-as',
  templateUrl: './register-as.component.html',
  styleUrls: ['./register-as.component.scss']
})
export class RegisterAsComponent implements ICellEditorAngularComp, AfterViewInit {

  private params: any;
  @ViewChild('container', { read: ViewContainerRef }) public container;
  @ViewChild('Selecter') Selecter;
  public values: any[];
  registerName: any[] = [];
  registrationAs: any;
  valueSelected: any;
  qualification: boolean;
  register: boolean;
  accountInfo: boolean;

  classList = 'single-select';
  status: any;
  statusEditor: boolean;
  statusEditorTest: boolean;

  private onScroll = (event: any) => {
    if ((this.Selecter && this.Selecter.isOpen)) {
      const isScrollingInScrollHost = (event.target.className as string)?.indexOf('ng-dropdown-panel-items') > -1;
      if (isScrollingInScrollHost) { return; }
      this.closeSelectPanel(this.Selecter);
    }
  }

  closeSelectPanel(select) {
    if (select && select.isOpen) {
      select.close();
      this.params.stopEditing();
    }
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.container.element.nativeElement.focus();
      this.Selecter.open();
    });
    window.addEventListener('scroll', this.onScroll, true);
  }

  agInit(params: any): void {
    this.params = params;
    this.values = params.values
    this.setStatus(params.value);
    this.status = params.value;
  }

  getValue(): any {
    return this.valueSelected;
  }

  isPopup(): boolean {
    return true;
  }

  toggle() {
    return false;
  }

  clickMenu(event) {
    this.setStatus(event);
    this.params.stopEditing();
    this.Selecter.close();
  }

  setStatus(status): void {
    this.valueSelected = status;
    if (this.params.type == 'statusEditor') {
      this.statusEditor = true;
      setTimeout(() => {
        const myCustomClass: string = "status-editor";
        const panel = document.querySelector('.ng-dropdown-panel');
        panel?.classList.add(myCustomClass);
      });
    }
    else if (this.params.type == 'statusEditorTest') {
      this.statusEditorTest = true;
      setTimeout(() => {
        const myCustomClass: string = "status-editorT";
        const panel = document.querySelector('.ng-dropdown-panel');
        panel?.classList.add(myCustomClass);
      });
    }
    else {
      if (this.params.colDef.field == "qualification_status" || this.params.colDef.field == "final_customer_status"
           || this.params.colDef.field == "tbc_qualification_status"
      ) {
        this.qualification = true;
        setTimeout(() => {
          const myCustomClass: string = "qualify";
          const panel = document.querySelector('.ng-dropdown-panel');
          panel?.classList.add(myCustomClass);
        });
      }
      if (this.params.colDef.field == "registration_in_ayen_as") {
        this.register = true;
        setTimeout(() => {
          const myCustomClass: string = "register";
          const panel = document.querySelector('.ng-dropdown-panel');
          panel?.classList.add(myCustomClass);
        });
      }
      if (this.params.colDef.field == "account_info") {
        this.accountInfo = true;
        setTimeout(() => {
          const myCustomClass: string = "accountInfo";
          const panel = document.querySelector('.ng-dropdown-panel');
          panel?.classList.add(myCustomClass);
        });
      }
    }
  }
}
