import { ActivatedRoute } from '@angular/router';
import { registerInspector } from 'src/app/models/enums/register-inspector.enum';
import {
  ConstantEditor,
  ConstantRender,
} from 'src/app/shared/global/grid-constants';
import { InspectorGridService } from './services/inspectors-grid/inspectors-grid.service';
import { RegisterAsInspector } from 'src/app/models/enums/register-as.enum';
export class inspectorGridColumns {
  constructor(
    private route: ActivatedRoute,
    public inspectorComp: any,
    public inspectorService: InspectorGridService
  ) {}
  qualificationsValues = this.route.parent.snapshot.data.qualifactions;
  academicMajors = this.route.parent.snapshot.data.acadamicMajor;
  experinceYears = this.route.parent.snapshot.data.experinceYears;
  registrationAs = this.route.parent.snapshot.data.registrationAs;
  facilities = this.route.parent.snapshot.data.facilities;
  expertiseFocus = this.route.parent.snapshot.data.expertiseFocus;
  specialQualifications = this.route.parent.snapshot.data.specialQualifications;
  countries = this.route.parent.snapshot.data.countries;
  regions = this.route.parent.snapshot.data.regions;
  coveredCities = this.route.parent.snapshot.data.coveredCities;
  services = this.route.parent.snapshot.data.services;
  accountInfo = this.route.parent.snapshot.data.accountInfo;
  accountCategory = this.route.parent.snapshot.data.accountCategory;
  certificates = this.route.parent.snapshot.data.certificates;

  public COLUMN_DEF = [
    {
      headerName: ' ',
      field: 'id',
      hide: true,
      resizable: false,
    },
    {
      headerName: ' ',
      field: 'check_box',
      editable: false,
      pinned: 'right',
      headerCheckboxSelection: true,
      resizable: false,
      checkboxSelection: true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      cellClass: 'checkbox',
    },
    {
      headerName: 'الاسم الأول',
      field: 'first_name',
      editable: true,
      width: 120,
      minWidth: 120,
      maxWidth: 200,
      resizable: true,
      pinned: 'right',
    },
    {
      headerName: 'الاسم الأخير',
      field: 'last_name',
      editable: true,
      pinned: 'right',
      resizable: true,
      width: 120,
      minWidth: 120,
      maxWidth: 200,
    },
    {
      headerName: 'البريد الإلكترونى',
      field: 'email',
      width: 200,
      minWidth: 200,
      maxWidth: 300,
      editable: true,
      resizable: true,
    },
    {
      headerName: 'رقم الجوال',
      field: 'mobile',
      width: 160,
      minWidth: 160,
      maxWidth: 200,
      editable: true,
      resizable: true,
    },
    {
      headerName: 'الرقم الوطنى',
      field: 'id_number',
      width: 140,
      minWidth: 140,
      maxWidth: 180,
      editable: true,
      resizable: true,
    },
    {
      headerName: 'الجنسية',
      field: 'nationality',
      cellEditor: 'AutoComplete',
      cellEditorParams: { values: this.countries },
      cellRenderer: 'CountryRender',
      cellRendererParams: { values: this.countries },
      editable: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      resizable: true,
    },
    {
      headerName: 'المنطقة',
      field: 'region',
      cellEditor: 'AutoComplete',
      cellEditorParams: { values: this.regions },
      cellRenderer: 'CountryRender',
      cellRendererParams: { values: this.regions },
      editable: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      resizable: true,
    },
    {
      headerName: 'المدينة',
      field: 'city',
      editable: true,
      cellEditor: 'AutoComplete',
      cellRenderer: 'CountryRender',
      cellEditorParams: {
        values: this.coveredCities,
      },
      cellRendererParams: {
        values: this.coveredCities,
      },
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      resizable: true,
    },
    {
      headerName: 'هل الفاحص خارجى؟',
      field: 'is_freelancer',
      singleClickEdit: true,
      cellRenderer: 'StatusComponent',
      cellStyle: function (params) {
        if (params.value) {
          return { backgroundColor: '#FFCF59' };
        }
        return { backgroundColor: '#379B79' };
      },
      cellEditor: 'registerAsComponent',
      cellRendererParams: ConstantRender.BOOLEAN_STATUS_RENDER,
      cellEditorParams: {
        type: 'statusEditorTest',
        values: ConstantEditor.BOOLEAN_STATUS_EDITOR,
      },
    },
    {
      headerName: 'حالة الفاحص',
      field: 'inspector_is_available',
      editable: true,
      cellEditor: 'registerAsComponent',
      width: 150,
      minWidth: 150,
      resizable: true,
      cellRenderer: 'inspectorAvaliablityRenderComponent',
      cellRendererParams: ConstantRender.INSPECTOR_STATUS,
      cellEditorParams: {
        type: 'statusEditor',
        values: [
          { id: true, name: 'متاح' },
          { id: false, name: 'غير متاح' },
        ],
      },
    },
    {
      headerName: 'التصنيف',
      field: 'category',
      editable: true,
      singleClickEdit: true,
      cellEditor: 'AutoComplete',
      cellEditorParams: { values: this.accountCategory },
      cellRenderer: 'registarAsRenderComponent',
      cellRendererParams: {
        values: this.accountCategory,
      },
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      resizable: true,
    },
    {
      headerName: 'معلومات الحساب',
      field: 'account_info',
      editable: true,
      singleClickEdit: true,
      cellEditor: 'registerAsComponent',
      cellEditorParams: { values: this.accountInfo },
      cellRenderer: 'registarAsRenderComponent',
      cellRendererParams: {
        values: this.accountInfo,
      },
      cellStyle: function (params) {
        if (params.value == 'U') {
          return {
            color: '#111934',
            backgroundColor: '#E7E8EB',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
        if (params.value == 'A') {
          return {
            color: '#ffffff',
            backgroundColor: '#40B58D',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
        if (params.value == 'R') {
          return {
            color: '#ffffff',
            backgroundColor: '#D9727F',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
      },
      width: 140,
      minWidth: 140,
      maxWidth: 180,
      resizable: true,
    },
    {
      headerName: ' نوع الحساب ',
      field: 'is_test',
      singleClickEdit: true,
      cellRenderer: 'StatusComponent',
      cellStyle: function (params) {
        if (params.value) {
          return { backgroundColor: '#FFCF59' };
        }
        return { backgroundColor: '#379B79' };
      },
      cellEditor: 'registerAsComponent',
      cellRendererParams: ConstantRender.ISTEST_STATUS_RENDER,
      cellEditorParams: {
        type: 'statusEditorTest',
        values: ConstantEditor.ISTEST_STATUS_EDITOR,
      },
    },
    {
      headerName: 'حالة الحساب',
      field: 'is_active',
      editable: true,
      singleClickEdit: true,
      cellRenderer: 'StatusComponent',
      cellStyle: function (params) {
        if (params.value) {
          return { backgroundColor: '#379B79' };
        }
        return { backgroundColor: '#D9727F' };
      },
      cellRendererParams: ConstantRender.ACTIVE_STATUS_RENDER,
      cellEditor: 'registerAsComponent',
      width: 120,
      minWidth: 120,
      maxWidth: 140,
      resizable: true,
      cellEditorParams: {
        type: 'statusEditor',
        values: ConstantEditor.ACTIVE_STATUS_EDITOR,
      },
    },
    {
      headerName: 'التأهيل',
      field: 'qualification_status',
      editable: true,
      singleClickEdit: true,
      cellEditor: 'registerAsComponent',
      cellEditorParams: { values: this.qualificationsValues },
      cellRenderer: 'registarAsRenderComponent',
      cellRendererParams: {
        values: this.qualificationsValues,
      },
      cellStyle: function (params) {
        if (params.value == registerInspector.notQualified) {
          return {
            color: '#ffffff',
            backgroundColor: '#D9727F',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
        if (params.value == registerInspector.underQualification) {
          return {
            color: '#ffffff',
            backgroundColor: '#FFCF59',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
        if (params.value == registerInspector.waitingDevices) {
          return {
            color: '#ffffff',
            backgroundColor: '#F49C6C',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
        if (params.value == registerInspector.qualified) {
          return {
            color: '#ffffff',
            backgroundColor: '#379B79',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
      },
      width: 120,
      minWidth: 120,
      maxWidth: 140,
      resizable: true,
    },
    {
      headerName: 'التقييم',
      field: 'overall_rating',
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      resizable: true,
      stopEditingWhenGridLosesFocus: true,
      headerComponent: 'customHeader',
      singleClickEdit: true,
      cellRenderer: 'ratingStarsRenderComponent',
      cellEditor: 'ratingEditor',
    },
    {
      headerName: 'مؤهل لخدمة TBC',
      field: 'tbc_qualification_status',
      editable: true,
      singleClickEdit: true,
      cellEditor: 'registerAsComponent',
      cellEditorParams: { values: this.qualificationsValues },
      cellRenderer: 'registarAsRenderComponent',
      cellRendererParams: {
        values: this.qualificationsValues,
      },
      cellStyle: function (params) {
        if (params.value == registerInspector.notQualified) {
          return {
            color: '#ffffff',
            backgroundColor: '#D9727F',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
        if (params.value == registerInspector.underQualification) {
          return {
            color: '#ffffff',
            backgroundColor: '#FFCF59',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
        if (params.value == registerInspector.waitingDevices) {
          return {
            color: '#ffffff',
            backgroundColor: '#F49C6C',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
        if (params.value == registerInspector.qualified) {
          return {
            color: '#ffffff',
            backgroundColor: '#379B79',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
      },
      width: 160,
      minWidth: 140,
      maxWidth: 180,
      resizable: true,
    },
    {
      headerName: 'تقييم الجودة',
      field: 'quality_rating',
      editable: true,
      width: 120,
      minWidth: 120,
      maxWidth: 120,
      resizable: true,
    },
    {
      headerName: 'عدد الطلبات الموكلة',
      field: 'orders_count',
      editable: false,
      width: 0,
      minWidth: 150,
      maxWidth: 150,
      resizable: true,
      headerComponent: 'customHeader',
      cellRenderer: this.inspectorService.goOrders,
    },
    {
      headerName: 'اجمالي المبلغ المستحق',
      field: 'total_not_paid_revenue',
      editable: false,
      width: 170,
      minWidth: 170,
      maxWidth: 200,
      resizable: true,
    },
    {
      headerName: 'اجمالي المبلغ المدفوع',
      field: 'total_paid_revenue',
      editable: false,
      width: 170,
      minWidth: 170,
      maxWidth: 200,
      resizable: true,
    },
    {
      headerName: 'اجمالي المبلغ قيد الانتظار',
      field: 'waiting_paid_revenue',
      editable: false,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      resizable: true,
    },
    {
      headerName: 'تاريخ اخر طلب موكل',
      field: 'last_order_date',
      editable: false,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      resizable: true,
      headerComponent: 'customHeader',
    },
    {
      headerName: 'الخدمات',
      field: 'services',
      editable: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      resizable: true,
      cellRenderer: 'inspectorMultiselectRender',
      cellRendererParams: { values: this.services },
      cellEditor: 'inspectorMultiselect',
      cellEditorParams: {
        values: this.services,
        isMultiple: false,
      },
    },
    {
      headerName: 'المدن المغطاة',
      field: 'covered_cities',
      editable: true,
      width: 320,
      minWidth: 320,
      maxWidth: 320,
      resizable: true,
      cellRenderer: 'inspectorMultiselectRender',
      cellRendererParams: { values: this.coveredCities },
      cellEditor: 'inspectorMultiselect',
      cellEditorParams: { values: this.coveredCities, isMultiple: false },
    },
    {
      headerName: 'التخصص الاكاديمي',
      field: 'academic_major',
      editable: true,
      cellEditor: 'selectEditor',
      cellEditorParams: { values: this.academicMajors },
      cellRenderer: 'selectRender',
      cellRendererParams: {
        values: this.academicMajors,
      },
      cellClassRules: {
        tag: function (params) {
          return params.value;
        },
      },
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      resizable: true,
    },
    {
      headerName: 'سنوات الخبرة',
      field: 'years_of_experience',
      editable: true,
      cellEditor: 'selectEditor',
      cellEditorParams: { values: this.experinceYears },
      cellRenderer: 'selectRender',
      cellRendererParams: {
        values: this.experinceYears,
      },
      cellClassRules: {
        tag: function (params) {
          return params.value;
        },
      },
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      resizable: true,
    },
    {
      headerName: 'سنوات الخبرة (مرجعية داخلية)',
      field: 'years_of_experience_internal_reference',
      editable: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      resizable: true,
      headerComponent: 'customHeader',
    },
    {
      headerName: 'رقم عضوية الهيئة السعودية للمهندسين',
      field: 'membership_number_of_the_SCE',
      editable: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      resizable: true,
    },
    {
      headerName: 'تاريخ الانتهاء',
      field: 'expiration_date_of_membership_number_of_the_SCE',
      editable: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      resizable: true,
      cellEditor: 'datepickerComponent',
      headerComponent: 'customHeader',
    },
    {
      headerName: 'تأمين مهني',
      field: 'have_valid_career_insurance',
      editable: true,
      width: 120,
      singleClickEdit: true,
      minWidth: 120,
      maxWidth: 120,
      resizable: true,
      cellRenderer: 'StatusComponent',
      cellStyle: this.inspectorService.booleanCellStyle,
      cellRendererParams: ConstantRender.BOOLEAN_STATUS_RENDER,
      cellEditor: 'registerAsComponent',
      cellEditorParams: {
        type: 'statusEditor',
        values: ConstantEditor.BOOLEAN_STATUS_EDITOR,
      },
    },
    {
      headerName: 'الشهادات',
      field: 'certificates',
      editable: false,
      width: 180,
      minWidth: 180,
      resizable: true,
      cellEditorParams: {
        values: this.certificates,
        isMultiple: true,
        bindValue: 'id'
      },
      cellRenderer: 'inspectorMultiselectRender',
      cellEditor: 'inspectorMultiselect',
      cellRendererParams: { values: this.certificates },
    },
    {
      headerName: 'مجال تركيز الخبرات',
      field: 'expertise_focus_area',
      editable: true,
      width: 180,
      minWidth: 180,
      resizable: true,
      cellEditorParams: {
        values: this.expertiseFocus,
        isMultiple: true,
      },
      cellRenderer: 'inspectorMultiselectRender',
      cellEditor: 'inspectorMultiselect',
      cellRendererParams: { values: this.expertiseFocus },
    },
    {
      headerName: 'التأهيل المخصص',
      field: 'special_qualification_tags',
      editable: true,
      width: 180,
      minWidth: 180,
      resizable: true,
      cellEditorParams: {
        values: this.specialQualifications,
        isMultiple: true,
        bindValue: 'id'
      },
      cellRenderer: 'inspectorMultiselectRender',
      cellEditor: 'inspectorMultiselect',
      cellRendererParams: { values: this.specialQualifications },
    },
    {
      headerName: 'صورة شخصية',
      field: 'avatar',
      editable: false,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      resizable: true,
      cellRenderer: 'avatarRenderComponent',
    },
    {
      headerName: 'نبذة شخصية',
      field: 'bio',
      editable: true,
      width: 200,
      minWidth: 200,
      maxWidth: 500,
      resizable: true,
      wrapText: true,
    },
    {
      headerName: 'تاريخ التسجيل',
      field: 'date_joined',
      editable: false,
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      resizable: true,
      headerComponent: 'customHeader',
      cellStyle: function (params) {
        return { direction: 'ltr' };
      },
    },
    {
      headerName: 'صفة العمل',
      field: 'registration_in_ayen_as',
      cellRenderer: 'registarAsRenderComponent',
      width: 150,
      singleClickEdit: true,
      minWidth: 150,
      maxWidth: 150,
      cellRendererParams: { values: this.registrationAs },
      cellStyle: function (params) {
        if (params.value == RegisterAsInspector.single) {
          return {
            color: '#ffffff',
            backgroundColor: '#9A12D3',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'cairo-regular',
          };
        }
        return {
          color: '#ffffff',
          backgroundColor: '#0885C2',
          textAlign: 'center',
          fontSize: '14px',
          fontFamily: 'cairo-regular',
        };
      },
      cellEditor: 'registerAsComponent',
      cellEditorParams: { values: this.registrationAs },
      editable: true,
      resizable: true,
    },
    {
      headerName: 'اسم المنشأة',
      field: 'facility',
      editable: this.inspectorService.isEditable.bind(this),
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      resizable: true,
      cellEditor: 'selectEditor',
      cellRenderer: 'selectRender',
      cellRendererParams: { values: this.facilities },
      cellEditorParams: { values: this.facilities },
    },
    {
      headerName: 'رقم السجل التجاري',
      field: 'commercial_registration_no',
      editable: true,
      width: 180,
      minWidth: 180,
      maxWidth: 200,
      resizable: true,
    },
    {
      headerName: 'رقم الحساب البنكي',
      field: 'bank_account_number',
      editable: true,
      width: 180,
      minWidth: 180,
      maxWidth: 200,
      resizable: true,
    },
    {
      headerName: 'IBAN',
      field: 'IBAN',
      editable: true,
      width: 120,
      minWidth: 120,
      maxWidth: 250,
      resizable: true,
    },
    {
      headerName: 'اسم البنك',
      field: 'bank_name',
      editable: true,
      width: 200,
      minWidth: 180,
      maxWidth: 250,
      resizable: true,
      cellRenderer: 'tagElementRenderComponent',
    },

    {
      headerName: '',
      field: '',
      editable: false,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      resizable: false,
    },
  ];
}
