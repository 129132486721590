<div class="inner-page-header">
  <h3>الطلبات</h3>
</div>
@if (orderDetails) {
  <div class="main-content">
    <!-- buttons -->
    <div class="order-btn">
      <div class="instruction">
        <button class="btn btn-add" mat-button [matMenuTriggerFor]="menu">
          تنفيذ أمر
          <img src="assets/images/svg/Polygon-1.svg" alt="">
        </button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item  (click)="updateData()">إرسال تحديث البيانات</a>
        </mat-menu>
      </div>
    </div>
    <form
      class="needs-validation"
      novalidate
      #form="ngForm"
      id="general-form"
      [formGroup]="formName"
      >
      <!-- Order Info -->
      @if (orderDetails) {
        <app-services-order-info
          [basicInfo]="basicInfo"
          [orderStatus] = "orderStatus"
          [financialInfo]="financialInfo"
          [ServiceProvider]="ServiceProvider"
          [servicePaymentProvider]="servicePaymentProvider"
          >
        </app-services-order-info>
      }
      <!-- report-->
      @if (orderDetails?.report_section) {
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            [ngClass]="{
            'closed-panel': panelOpenState == false,
            'open-panel': panelOpenState == true
            }"
            >
            <mat-expansion-panel-header>
              <mat-panel-title> التقرير</mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="panel-body">
              <div class="existing-report" >
                <p>يمكنك الإطلاع على التقرير أو تحميله من هنا</p>
                <div class="reportBtn">
                  <a class="btn btn-outline-primary btn-full"  [href]="orderDetails?.report_section?.src" target="_blank">
                    <img src="assets/images/svg/preview-file.svg" alt=""/>
                    استعراض التقرير
                  </a>
                  <div class="btn-upload btn-full" >
                    <a class="btn btn-primary btn-full" type="button" [href]="orderDetails?.report_section?.src" target="_blank" download="report.pdf" >
                      <img src="assets/images/svg/download-icon-white.svg" alt=""/>
                      تحميل التقرير بصيغة PDF
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      }
      <!-- Order Main Details -->
      <mat-accordion >
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
          [ngClass]="{
          'closed-panel': panelOpenState == false,
          'open-panel': panelOpenState == true
          }"
          >
          <mat-expansion-panel-header>
            <mat-panel-title> التفاصيل الأساسية</mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="panel-body" >
            <app-service-order-main-details
              [subForm]="formName"
              [orderData]="orderDetails"
              (enableSave)="enableSaveChanges($event)"
              >
            </app-service-order-main-details>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- dynamic tabs-->
      @for (dynamic_sec of orderDetails?.dynamic_sections; track dynamic_sec) {
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            [ngClass]="{
            'closed-panel': panelOpenState == false,
            'open-panel': panelOpenState == true
            }"
            >
            <mat-expansion-panel-header >
              <mat-panel-title> {{dynamic_sec?.form_step_name}} </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row panel-body" >
              @for (elem of dynamic_sec?.elements; track elem) {
                <div
                  [class]="elem.field_width == 'half' ? 'col-md-6' : 'col-md-12'">
                  <app-component-host
                    [inputs]="elem"
                    [subForm] = "formName"
                    [component]="getComponentName(elem)">
                  </app-component-host>
                </div>
              }
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      }
      <div class="row">
        <div class="col-md-12">
          @if (alertmsg) {
            <div class="alert-box">
              <img
                src="assets/images/svg/error-icon.svg"
                class="close-alert"
                alt=""
                onclick="this.parentElement.style.display='none';"
                />
                <p class="alert-msg">{{errorMessage}}</p>
              </div>
            }
          </div>
        </div>
        <div class="setup-footer actions-double">
          <button class="btn btn-cancel" type="button" (click)="goList()">رجوع</button>
          <button class="btn btn-green" type="submit" (click)="save()">حفظ</button>
        </div>
      </form>
    </div>
  }
