<div class="dialog-container">
  <div mat-dialog-content>
    <label for="" class="form-label">{{data.label}}</label>
    <input type="text" [(ngModel)]="data.value" class="form-control" [placeholder]=data.placeholder />
  </div>
  <div mat-dialog-actions class="setup-footer actions-double">
    <button class="btn btn-cancel" type="button" mat-dialog-close>إلغاء</button>
    <button class="btn btn-green" [mat-dialog-close]="data" type="submit">
      موافق
    </button>
  </div>
</div>
