import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserType } from 'src/app/models/enums/user-type.enum';
import { RedirectionUrls } from 'src/app/shared/global/redirectionsUrls';

@Injectable({
  providedIn: 'root'
})

export class RootGuard {

  user = UserType;
  redirectURL = RedirectionUrls;

  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      return true;
    }
    this.router.navigate([this.redirectURL.redirectRoot[currentUser.user.profile.user_type]?.url]);
    return false;
  }
}
