import { Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})

export class ConfirmationBoxComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.data['message'] = this.data['message'] || '';
    this.data['confirmBtn'] = this.data['confirmBtn'] || 'حذف';
    this.data['isDelete'] = this.data['isDelete'] || true;
  }
}
