export class SummerNoteConfig {
  public static MAIN_CONFIG ={
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']],
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']],
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
      ],
    },
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['table', 'link']],
    ],
    fontNamesIgnoreCheck: ['cairo-regular', 'cairo-bold', 'cairo-semibold'],
    codeviewFilter: true,
    codeviewFilterRegex:/<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
  };
}
