import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SettingServicesService } from '../../services/setting-services/setting-services.service';

@Component({
  selector: 'app-list-inputs-dialoge',
  templateUrl: './list-inputs-dialoge.component.html',
  styleUrl: './list-inputs-dialoge.component.scss',
})
export class ListInputsDialogeComponent implements AfterViewInit {
  @ViewChildren('input') input: QueryList<any>;
  fn: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingServices: SettingServicesService
  ) {}

  ngAfterViewInit(): void {
    this.editField();
  }

  editField() {
    this.fn = this.data.fnName;
    this.input.toArray().forEach((element) => {
      const searchText$: Observable<string> = fromEvent<any>(
        element.nativeElement,
        'keyup'
      ).pipe(debounceTime(1000), distinctUntilChanged());
      searchText$.subscribe((data) => {
        this.settingServices[this.fn](element.nativeElement.id, {
          name: element.nativeElement.value,
        }).subscribe((data) => {});
      });
    });
  }
}
