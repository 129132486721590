<div [formGroup]="notesForm">
  @for (notesGroup of notesForm.get('notes')['controls']; let i = $index; track notesGroup) {
    <div
      class="row notes-container"
      [formGroup]="notesGroup"
      >
      <div class="col-md-6">
        <div class="note-wrapper">
          <div class="actions-wrapper">
            <div
              class="img-wrapper"
              [ngClass]="{ error: !cardMenuIcon[i].valid }"
              mat-button
              [matMenuTriggerFor]="cardsMenu"
              >
              <img alt="" [src]="cardMenuIcon[i].src" />
              <mat-menu #cardsMenu="matMenu" backdropClass="cards-menu">
                <a
                  mat-menu-item
                  (click)="setCardSeverity('card', card.id, card.icon, i)"
                  *ngFor="let card of cards$ | async as cards"
                  >
                  <img alt="" [src]="getSrc(card.icon)" />
                  {{ card.name }}
                </a>
              </mat-menu>
            </div>
            <div
              class="img-wrapper"
              mat-button
              [ngClass]="{ error: !severityMenuIcon[i].valid }"
              [matMenuTriggerFor]="severityMenu"
              >
              <img alt="" [src]="severityMenuIcon[i].src" />
              <mat-menu #severityMenu="matMenu" backdropClass="severity-menu">
                <a
                  mat-menu-item
                  (click)="setCardSeverity('severity', sev.id, sev.icon, i)"
                  *ngFor="let sev of severity$ | async as severity"
                  >
                  <img alt="" [src]="getSrc(sev.icon)" />
                  {{ sev.name }}
                </a>
              </mat-menu>
            </div>
          </div>
          <div class="form-group">
            <div class="notes-section">
              <label for="" class="form-label">الملاحظة</label>
              <textarea
                class="form-control"
                formControlName="note"
                name="note"
                maxlength="500"
                >
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="note-wrapper">
          <div class="form-group">
            <div class="notes-section">
              <label for="" class="form-label">التوصية</label>
              <textarea
                class="form-control"
                formControlName="recommendation"
                name="recommendation"
                maxlength="500"
              ></textarea>
            </div>
          </div>
          <div class="actions-wrapper" #parentElement>
            <div
              class="img-wrapper copy-wrapper custom-tooltip"
              (click)="copyNote(i)"
              >
              <img alt="" src="assets/images/svg/copy-icon.svg" />
              <span class="tooltip-text">تكرار</span>
            </div>
            <div
              class="img-wrapper del-wrapper custom-tooltip"
              (click)="deleteNote(i)"
              >
              <img alt="" src="assets/images/svg/delete_new.svg" />
              <span class="tooltip-text">حذف</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  @if (alertmsg) {
    <div class="alert-box">
      <img
        src="assets/images/svg/error-icon.svg"
        class="close-alert"
        (click)="closeError()"
        alt=""
        />
        <p class="alert-msg">{{ message }}</p>
      </div>
    }
    <div class="row add-note">
      <div class="col-md-12">
        <button
          class="btn btn-add-service"
          (click)="addNote(); addDefaultIcons()"
          >
          <img src="assets/images/svg/add-logo.svg" alt="" />
          إضافة
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 save-btn-wrapper">
        <button class="btn btn-green" (click)="save()">
          @if (showSpinner) {
            <img
              class="spin-img"
              src="assets/images/svg/Spinner-s.svg"
              alt=""
              />
          }
          حفظ
        </button>
      </div>
    </div>
  </div>
