import { Component } from '@angular/core';

@Component({
  selector: 'app-sms-campings',
  templateUrl: './sms-campings.component.html',
  styleUrls: ['./sms-campings.component.scss']
})
export class SmsCampingsComponent {

}
