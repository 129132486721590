<div [formGroup]="subForm" class="notes-section form-group">
  <label for="" class="form-label">
    {{inputs.field_label_name}}
    @if (!isRequired) {
      <span class="optional">(اختياري)</span>
    }
  </label>
  <textarea
    class="form-control"
    name="{{inputs.field_key_name}}"
    formControlName="{{inputs.field_key_name}}"
    maxlength="500"
  ></textarea>
</div>
