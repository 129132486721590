@if (params.value) {
  <div class="file-exist">
    <a [href]="link" target="_blank">
      <img alt="" [src]="constantImages.FILE_ICON">
    </a>
    <img alt="" (click)="deleteFile()" class="delete-file"  [src]="constantImages.GRIDE_DELETE">
  </div>
} @else {
  <div class="drag-btn">
    <button type="button" class="btn choose-file" style="justify-content: start;">
      <img alt="" [src]="constantImages.UPLOAD_ICON"/>
      اختيار الملف
    </button>
    <input type="file" name="" (input)="uploadFile($event)" accept="application/pdf" class="file-new form-control" />
  </div>
}
