import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements ICellRendererAngularComp {
  public params: any;
  public customerStatus ;
  fieldName: any;

  agInit(params: any): void {
    this.params = params;
    this.fieldName = params?.column?.colId;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

}
