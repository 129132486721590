<div class="dialog-container" id="report_notes">
  <h5 mat-dialog-title>{{ headerTxt }}</h5>

  <div class="text-center" mat-dialog-content>
    <form [formGroup]="reportFormGroup">
      @if (flag) {
        <div class="droppoints">
          <div class="dropClient radio-div">
            <input
              type="radio"
              id="client"
              formControlName="receiver"
              name="receiver"
              value="client"
              />
              <label for="client"> العميل</label>
            </div>
            @if (inspector_status) {
              <div class="dropInsp radio-div">
                <input
                  type="radio"
                  id="inspector"
                  formControlName="receiver"
                  name="receiver"
                  value="inspector"
                  />
                  <label for="inspector"> الفاحص</label>
                </div>
              }
            </div>
          }
          @if (flag) {
            <label class="form-label">النص</label>
          }
          <textarea
            class="form-control text-block"
            [placeholder]="placeholder"
            formControlName="notes"
          ></textarea>
        </form>
        <div mat-dialog-actions class="actions-double">
          <button class="btn btn-cancel center-btn" mat-dialog-close>إلغاء</button>
          <button
            class="btn btn-green center-btn"
            (click)="addnotes()"
            [disabled]="!(reportFormGroup.dirty && reportFormGroup.valid)"
            >
            إرسال
          </button>
        </div>
      </div>
    </div>
