import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
/**
not-found component
 *  
*/
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
/**
message variable
 *  
*/
  msg: string = '';
/**
constructor function
 *  
*/
  constructor(private route: ActivatedRoute,
              private location: Location,
              private router: Router) { }
/**
ngoninit function
 *  
*/
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.msg = params['msg'] || '';
    });
  }
/**
go back to page function
 *  
*/
  goBack(): void {
    window.history.go(-2);
  }
  goHome(){
    this.router.navigate(['/pages/home']);
  }
}
