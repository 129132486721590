import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class BankCardsService extends AYENServiceBase{

  constructor(private http: HttpClient) {
    super();
   }

  getBanks(): Observable<any> {
    return this.http.get(this.baseURL + '/banks/');
  }

  getAllBanks(): Observable<any> {
    return this.http.get(this.baseURL + '/all-banks/');
  }

  getBankByID(id): Observable<any> {
    return this.http.get(this.baseURL + '/banks/' + id + '/');
  }

  putBankByID(id, bankData): Observable<any> {
    return this.http.put(this.baseURL + '/banks/' + id + '/', bankData);
  }

  deletBank(id) {
    return this.http.request('delete', this.baseURL + '/banks/', { body: id });
  }

  addBankCard(data): Observable<any> {
    return this.http.post(this.baseURL + '/banks/', data);
  }

  getBanksByPage(params): Observable<any> {
    return this.http.get(this.baseURL + '/banks/?' ,{
      params: params,
    });
  }
}
