import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AYENServiceBase } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class SmsService extends AYENServiceBase {

  constructor(private http: HttpClient) { super();}
  
  uploadFile(file): Observable<any> {
    return this.http.post<any>(this.baseURL + "/services/send-bulk-sms", file);
  }
}
