import { Component, OnInit,Input } from '@angular/core';
import { Order } from 'src/app/models/interfaces/order';
/**
*  define and export OrderInspectingLogComponent class
*/

@Component({
  selector: 'app-order-inspecting-log',
  templateUrl: './order-inspecting-log.component.html',
  styleUrls: ['./order-inspecting-log.component.scss']
})
export class OrderInspectingLogComponent implements OnInit {

  /**
  *  declare orders property  
  */
  public orders: Order;
  /**
  *  implement constructor and declare the injectors
  */
   @Input() orderData ;
  
  /**
  *  implement ngOnInit and fetch orderID from route params
  */
  ngOnInit(): void {
    this.orders = this.orderData;
  }

}
