<div class="main-info">
  <div class="order-info">
    <h3>معلومات الطلب</h3>
  </div>
  <div class="info-content">
    <ul class="nav nav-tabs horizontal-tab" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link  " id="basicInfo-tab" data-toggle="tab" href="#basicInfo" role="tab" aria-controls="basicInfo" aria-selected="true">التفاصيل الأساسية</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="providerInfo-tab" data-toggle="tab" href="#providerInfo" role="tab" aria-controls="providerInfo" aria-selected="false">معلومات مزود الخدمة</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="financialDetails-tab" data-toggle="tab" href="#financialDetails" role="tab" aria-controls="financialDetails" aria-selected="false">التفاصيل المالية</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="quatationInfo-tab" data-toggle="tab" href="#quatationInfo" role="tab" aria-controls="quatationInfo" aria-selected="false">التسعيرات</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="offersInfo-tab" data-toggle="tab" href="#offersInfo" role="tab" aria-controls="offersInfo" aria-selected="false">العروض</a>
      </li>
    </ul>
    <div class="tab-content horizontal-content" id="myTabContent">
      <div class="tab-pane fade " id="basicInfo" role="tabpanel" aria-labelledby="basicInfo-tab">
        <app-service-main-info [basicInfo]="basicInfo"></app-service-main-info>
      </div>
      <div class="tab-pane fade" id="providerInfo" role="tabpanel" aria-labelledby="providerInfo-tab">
        <app-service-provider-info [ServiceProvider]="ServiceProvider" [servicePaymentProvider]="servicePaymentProvider"></app-service-provider-info>
      </div>
      <div class="tab-pane fade" id="financialDetails" role="tabpanel" aria-labelledby="financialDetails-tab">
        <app-service-financial-info [financialInfo]="financialInfo"></app-service-financial-info>
      </div>
      <div class="tab-pane fade" id="quatationInfo" role="tabpanel" aria-labelledby="quatationInfo-tab">
        @if (quotationData) {
          <app-service-quatation-info [orderStatus]="orderStatus"  [quotationData]="quotationData" (updateQuotationData)="updateQuotationData($event)"></app-service-quatation-info>
        }
      </div>
      <div class="tab-pane fade" id="offersInfo" role="tabpanel" aria-labelledby="offersInfo-tab">
        @if (quotationData) {
          <app-service-offers-info [orderStatus]="orderStatus"  [quotationData]="quotationData"></app-service-offers-info>
        }
      </div>
    </div>
  </div>
</div>
