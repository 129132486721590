import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
@Component({
  selector: 'ayen-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
})
export class OrderTableComponent implements OnInit {
  @Input() tableData;
  @Input() tableHeaderData: any[] = [];
  @Input() pageUrl;
  @Input() paginationConfig;
  dataTable: any[] = [];
  imgDefault: string = '/assets/images/svg/sort-default.svg';
  imgASC: string = '/assets/images/svg/sort-up.svg';
  imgDesc: string = '/assets/images/svg/sort-down.svg';
  orderingState: {} = {};
  @Output() disabledDelete: any = new EventEmitter();
  @Output() checkedRow: any = new EventEmitter();
  @Output() sortingCol: any = new EventEmitter();
  selectedRowsIndex: any[] = [];
  selectedRowsPK: any[] = [];
  ordering: any[] = [];
  @ViewChildren('elements') elements: QueryList<ElementRef>;

  constructor(private router: Router, private element: ElementRef,) {}

  ngOnInit(): void {
    this.tableData.subscribe((data) => {
      this.dataTable = data;
      this.element.nativeElement.querySelector('#custom_check_all').checked = false;
    });
    this.tableHeaderData.forEach((header) => {
      if (header.sortable) {
        this.orderingState[header.value] = null;
      }
    });
  }

  isObject(value) {
    if (typeof value === 'object') {
      return true;
    }
    return false;
  }

  getStatusClass(status) {
    if (status === null) {
      return 'btn-secondary';
    } else if (status === OrderStatus.Not_Finished) {
      return 'not-finished';
    } else if (status === OrderStatus.New) {
      return 'new';
    } else if (status === OrderStatus.Under_Inspectoring) {
      return 'inspectoring';
    } else if (status === OrderStatus.Under_Audit) {
      return 'audit';
    } else if (status === OrderStatus.Processing) {
      return 'processing';
    } else if (status === OrderStatus.Scheduled) {
      return 'scheduled';
    } else if (
      status === OrderStatus.Delivered ||
      status === OrderStatus.InspectorAssigned
    ) {
      return 'delivered';
    } else if (status === OrderStatus.Completed) {
      return 'completed';
    } else if (
      status === OrderStatus.Canceled ||
      status === OrderStatus.NotPaid
    ) {
      return 'canceled';
    }
     else if (status === OrderStatus.Holding) {
       return 'holding';
     }
  }

  toggleSorting(colNam, item) {
    const img = item.querySelector('img');
    this.ordering = [];
    switch (this.orderingState[colNam]) {
      case null:
        this.orderingState[colNam] = false;
        img.src = this.imgASC;
        break;

      case false:
        this.orderingState[colNam] = true;
        img.src = this.imgDesc;
        break;

      case true:
        this.orderingState[colNam] = null;
        img.src = this.imgDefault;
        break;
    }
    for (const [key, value] of Object.entries(this.orderingState)) {
      switch (value) {
        case false:
          this.ordering.push(key);
          break;
        case true:
          this.ordering.push('-' + key);
          break;
      }
    }
    this.sortingCol.emit({ col: this.ordering });
  }

  selectAll(event) {
    let rowsChecked = [];
    this.disabledDelete.emit(true);
    this.selectedRowsIndex = [];
    this.selectedRowsPK = [];
    const selectInput = this.element.nativeElement.querySelectorAll('input[type="checkbox"]:not(event)');
    selectInput.forEach(input => {
      input.checked =  event.checked;
    });
    rowsChecked = this.element.nativeElement.querySelectorAll('input[type="checkbox"]:not(event)');
    if (event.checked) {
      this.disabledDelete.emit(false);
      for (let row of rowsChecked) {
        let elementId = row.id;
        let index = elementId.slice(0, elementId.indexOf('_'));
        let pk = +elementId.slice(elementId.indexOf('_') + 1);
        this.selectedRowsIndex.push(index);
        if(pk) {
          this.selectedRowsPK.push(pk);
        }
      }
    }
    this.checkedRow.emit({index: this.selectedRowsIndex, id: this.selectedRowsPK});
  }

  deleteSelectRow(event, rowPk, index) {
    this.disabledDelete.emit(true);
    if (event.target.checked) {
      this.selectedRowsIndex.push(index);
      this.selectedRowsPK.push(rowPk);
    } else {
      this.element.nativeElement.querySelector('#custom_check_all').checked = false;
      if (this.selectedRowsIndex.includes(index))
        this.selectedRowsIndex.splice(this.selectedRowsIndex.indexOf(index), 1);
      if (this.selectedRowsPK.includes(rowPk))
        this.selectedRowsPK.splice(this.selectedRowsPK.indexOf(rowPk), 1);
    }
    if (this.selectedRowsPK.length > 0) {
      this.disabledDelete.emit(false);
    }
    this.checkedRow.emit({index: this.selectedRowsIndex, id: this.selectedRowsPK});
  }

  openOrder(id, newTab?) {
    const urlPage = this.pageUrl + id;
    if (newTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([urlPage])
      );
      window.open(url, '_blank');
    } else {
      this.router.navigateByUrl(urlPage);
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
