<div class="inner-page-header">
  <h3>{{ title }}</h3>
</div>
@if (showForm) {
  <form
    class="needs-validation"
    id="general-form"
    [formGroup]="whatsNewFormGroup"
    (ngSubmit)="save()"
    >
    <div class="main-card">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label">
              عنوان التحديث
              <span class="label-desc">(150 حرف كحد أقصى)</span>
            </label>
            <input
              type="text"
              class="form-control"
              formControlName="title"
              />
              <img
                class="form-control-feedback"
                src="assets/images/svg/error-icon1.svg"
                alt=""
                />
              </div>
            </div>
            <div class="col-md-6">
              <label for="" class="form-label">الحالة</label>
              <app-switch-input
                [label]="''"
                [labelEn]="'active'"
                [labelDesc]="statusDesc"
                [value]="status"
                (valueChanged)="statusValueChanged($event)"
                >
              </app-switch-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="form-label"> نوع الحساب </label>
                <ng-select bindLabel="name" class="single-select"
                  #selectStatus
                  placeholder="يرجى تحديد نوع الحساب "
                  appendTo="body"
                  formControlName="user_type"
                  [searchable]="true"
                  [clearable]="false">
                  @for (item of userTypes; track item) {
                    <ng-option [value]="item.id" >
                      {{item.name}}
                    </ng-option>
                  }
                </ng-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="form-label">تاريخ التحديث</label>
                <mat-form-field (click)="picker.open()" appearance="outline">
                  <input
                    matInput
                    formControlName="publish_date"
                    [matDatepicker]="picker"
                    disabled
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker">
                      <mat-icon matDatepickerToggleIcon class="calender-icon">
                        <img src="assets/images/svg/calendar-admin-icon.svg" alt=""/>
                      </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-12" id="summernote_editor">
                <label class="form-label">الوصف</label>
                <div formControlName="body" [ngxSummernote]="summerNoteConfig"></div>
              </div>
              <div class="col-md-12">
                <label class="form-label file-label">الغلاف</label>
                @if (file.exist) {
                  <div class="existance-wrapper">
                    <div class="existance">
                      <img src="assets/images/svg/file-name-icon.svg" alt="">
                      <span>{{sliceFileName(file.name)}}</span>
                    </div>
                  </div>
                }
                @if (!file.exist) {
                  <div class="drag-btn">
                    <button type="button" class="btn btn-outline-secondary add-new-file">
                      <img src="assets/images/svg/upload-img.svg" alt=""/>
                      تحديد الملف
                    </button>
                    <input type="file" name="" (change)="handleFileInput($event.target.files)" accept="image/*" class="file-new form-control" />
                    @if (file.error) {
                      <div class="file-error">
                        <span>عفوا لا يمكن رفع صور حجم اكبر من 5MB </span>
                      </div>
                    }
                  </div>
                }
                <span class="files-desc">يمكنك رفع ملفات من نوع JPG, PNG, GIF</span>
              </div>
            </div>
            @if (alertmsg) {
              <div class="alert-box">
                <img
                  src="assets/images/svg/error-icon.svg"
                  class="close-alert"
                  (click)="closeError()"
                  alt=""
                  />
                  <p class="alert-msg">{{ message }}</p>
                </div>
              }
            </div>
            <div class="row action-btn-wrapper">
              <div class="col-md-12 double-action">
                <button class="btn btn-cancel" type="button" (click)="goWhatsNewList()">
                  رجوع
                </button>
                <button class="btn btn-green" id="saveBtn" type="submit" [disabled]="!enableSave">
                  {{ saveBtn }}
                </button>
              </div>
            </div>
          </form>
        }
