import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSummernoteModule } from 'ngx-summernote';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { CopounRoutingModule } from './copoun-routing.module';
import { CopounComponent } from './copoun.component';
import { BankCardsComponent } from './pages/bank-cards/bank-cards.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CopounListComponent } from './pages/copoun-list/copoun-list.component';
import { CopounDetailsComponent } from './pages/copoun-details/copoun-details.component';
import { SearchCouponPipe } from 'src/app/core/_helpers/pipe/search-coupon.pipe';
import { BankListComponent } from './pages/bank-cards/pages/bank-list/bank-list.component';
import { AddBankNumComponent } from './pages/bank-cards/pages/add-bank-num/add-bank-num.component';

class CustomDateAdapter extends MomentDateAdapter {
  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return [
      'الأحد',
      'الإثنين',
      'الثلاثاء',
      'الأربعاء',
      'الخميس',
      'الجمعة',
      'السبت',
    ];
  }
}

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MMM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    CopounComponent,
    CopounListComponent,
    CopounDetailsComponent,
    SearchCouponPipe,
    BankCardsComponent,
    BankListComponent,
    AddBankNumComponent,
  ],
  imports: [
    CommonModule,
    CopounRoutingModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxSummernoteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgSelectModule,
  ],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'ar-eg' },
  ],
})
export class CopounModule {}
