<div class="row">
  <div class="col-sm-12">
    @for (star of stars; track star; let i = $index) {
      <ul class="list-inline rating-list">
        <li (click)="countStar(star)">
          <img [src]='star <= selectedValue ? starSrc : starSrcBorder' alt="" height="21px" width="20px" />
        </li>
      </ul>
    }
  </div>
</div>
