import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CheckboxBtnComponent } from 'src/app/shared/components/dynamicElments/checkbox-btn/checkbox-btn.component';
import { DateRangeComponent } from 'src/app/shared/components/dynamicElments/date-range/date-range.component';
import { DateComponent } from 'src/app/shared/components/dynamicElments/date/date.component';
import { FilesComponent } from 'src/app/shared/components/dynamicElments/files/files.component';
import { InputsComponent } from 'src/app/shared/components/dynamicElments/inputs/inputs.component';
import { MultiselectComponent } from 'src/app/shared/components/dynamicElments/multiselect/multiselect.component';
import { RadioBtnsComponent } from 'src/app/shared/components/dynamicElments/radio-btns/radio-btns.component';
import { SelectDynamicComponent } from 'src/app/shared/components/dynamicElments/select/select.component';
import { TextareaComponent } from 'src/app/shared/components/dynamicElments/textarea/textarea.component';
import { AYENServiceBase } from '../base/base.service';
import { AyenMapComponent } from 'ayen-google-map';
import { DynamicMapComponent } from 'src/app/shared/components/dynamicElements/dynamic-map/dynamic-map.component';

@Injectable({
  providedIn: 'root',
})
export class ServicesOrdersService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getServicesOrdersByPage(query): Observable<any> {
    return this.http
      .get(this.baseURL + '/services-orders/', { params: query })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getServicesOrder(id): Observable<any> {
    return this.http.get<any>(this.baseURL + '/admin/new-orders/' + id).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.errorHandler)
    );
  }

  updateServicesOrder(id, order): Observable<any> {
    return this.http
      .put<any>(this.baseURL + '/admin/new-orders/' + id, order)
      .pipe(
        map((data: any) => {
          return data;
        })
        // catchError(this.errorHandler)
      );
  }

  getNewStatus() {
    return this.http.get<any>(this.baseURL + '/admin/new-order-status').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.errorHandler)
    );
  }

  getComponentByType(elemCategory, elemType) {
    if (elemCategory == 'input') {
      return InputsComponent;
    } else {
      switch (elemType) {
        case 'select':
          return SelectDynamicComponent;
        case 'multiselect':
          return MultiselectComponent;
        case 'files':
          return FilesComponent;
        case 'datepicker':
          return DateComponent;
        case 'date-range':
          return DateRangeComponent;
        case 'textarea':
          return TextareaComponent;
        case 'map':
          return DynamicMapComponent;
        case 'radio':
          return RadioBtnsComponent;
        case 'checkbox':
          return CheckboxBtnComponent;
        default:
          break;
      }
    }
  }

  changePaymentStatus(orderID) {
    return this.http
      .get(this.baseURL + `/admin/client-payment-capture/${orderID}`)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  changeProviderPayment(orderID, service) {
    return this.http
      .post(
        this.baseURL + `/admin/delivered-payment-to-provider/${orderID}`,
        service
      )
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  addFiles(orderId, fileType, files): Observable<any> {
    return null;
  }

  deleteFile(id): Observable<any> {
    return null;
  }

  updateZap(id) {
    return this.http
      .get(this.baseURL + `/admin/update-new-order-zap/${id}`)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server error.');
  }
}
