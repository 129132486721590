import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class AdminOrdersService extends AYENServiceBase {
  private newOrder = new BehaviorSubject({});
  currentNewOrder = this.newOrder.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  changeOrder(updateOrder: {}) {
    this.newOrder.next(updateOrder);
  }

  getAdminOrdersByPage(query): Observable<any> {
    return this.http
      .get(this.baseURL + '/admin/orders/individual', { params: query })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getAdminOrder(id): Observable<any> {
    return this.http.get<any>(this.baseURL + `/admin-orders/` + id).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.errorHandler)
    );
  }

  updateOrder(id, order): Observable<any> {
    return this.http
      .put<any>(this.baseURL + '/admin-orders/' + id + '/', order)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  createNewOrder(order): Observable<any> {
    return this.http.post<any>(this.baseURL + `/admin-orders/`, order).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getOrdersCount(): Observable<any> {
    return this.http.get<any>(this.baseURL + '/home-page-summary/').pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  deleteAdminOrder(orderId: number): Observable<any> {
    return this.http
      .request('delete', this.baseURL + '/admin-orders/', { body: orderId })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getMessagesLog(orderId): Observable<any> {
    return this.http
      .get<any>(this.baseURL + `/admin-order-messages-log/${orderId}/`)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  deleteMessage(msgPK): Observable<any> {
    return this.http
      .delete<any>(this.baseURL + `/admin-delete-order-messages-log/${msgPK}/`)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  changePaymentStatus(orderID, isRecived): Observable<any> {
    return this.http
      .put<any>(this.baseURL + `/order-cost-is-received/${orderID}/`, isRecived)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  changePaymentInspectorStatus(orderID, isPaid): Observable<any> {
    return this.http
      .put<any>(this.baseURL + `/inspector-share-is-paid/${orderID}/`, isPaid)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  updateZapierData(id): Observable<any> {
    let body = {
      send_to_zapier: true,
    };
    return this.http
      .put<any>(this.baseURL + '/zapier/send_manually/' + id + '/', body)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getVisitSummary(id): Observable<any> {
    return this.http
      .get(this.baseURL + `/admin-ayen-visit-summary/${id}`)
      .pipe(shareReplay());
  }

  notifyInspectors(orderId) {
    return this.http.put(this.baseURL + `/admin/order_bool/${orderId}/`, {});
  }

  applyCoupon(orderId): Observable<any> {
    return this.http.post(this.baseURL + '/coupon-apply/', orderId);
  }

  linkOrders(linkedOrderId, data): Observable<any> {
    return this.http.post(this.baseURL + `/admin/orders/${linkedOrderId}/link-orders/`, data);
  }

  unLinkOrders(orders): Observable<any> {
    return this.http.post(this.baseURL + `/admin/unlink-orders/`, orders);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server error.');
  }
}
