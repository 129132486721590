import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterUtilService } from 'src/app/core/services/uitls/filter-utilizer/filter-util.service';
import { FiltersService } from 'src/app/core/services/filters/filters.service';
import { ServicesOrdersService } from 'src/app/core/services/servicesOrders/services-orders.service';
import { ReplaySubject } from 'rxjs';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { SelectUtil } from 'src/app/core/services/uitls/selectUtil';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';

@Component({
  selector: 'app-services-orders-list',
  templateUrl: './services-orders-list.component.html',
  styleUrls: ['./services-orders-list.component.scss'],
})
export class ServicesOrdersListComponent implements OnInit {
  public servicesOrders: any[];
  disabledDelete: boolean = true;
  selectedRowsIndex: any[] = [];
  selectedRowsPK: any[] = [];
  deletedOrderID: any[] = [];
  deletedOrderIndex: any[] = [];
  searchValue: string;
  totalRecords: number = 0;
  page: number = 1;
  config: any;
  filterData;
  imgDefault: string = '/assets/images/svg/sort-default.svg';
  imgASC: string = '/assets/images/svg/sort-up.svg';
  imgDesc: string = '/assets/images/svg/sort-down.svg';
  ordering = {
    reference_number: true,
    created_at: true,
    order_date: true,
  };
  filterDataInit = {};
  filterParamsUrl = {};
  public orderStatus: any = OrderStatus;

  filterPage: string = 'SERVICE_ORDERS';
  savedFilters: any = [];
  spinner: boolean = true;
  spinnerKey: string = 'spinner';
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChildren('checkBoxList') checkBoxList: ElementRef<HTMLInputElement>[];
  @ViewChild('selectAllElm') selectAllElm: ElementRef<HTMLInputElement>;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  currentItemsPerPage: number;

  constructor(
    private serviceOrders: ServicesOrdersService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private filterUtil: FilterUtilService,
    private filter: FiltersService
  ) {
    this.config = {
      id: 'myPagination',
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
    };
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        this.searchValue = params['query'] ? params['query'] : '';
        this.filterParamsUrl = params;
        this.filterDataInit = params;
      }
    });
    this.getServicesOrdersFilter();
    this.titleService.setTitle(' الخدمات التجريبية | عاين');
    this.filterUtil.getSavedFilters(this, this.filterPage);
    this.filter.changeSelectedFilter(this.filterDataInit);
    this.filterUtil.applySelectedFilter(
      this,
      this.getServicesOrdersByPage,
      this.spinnerKey
    );
  }

  quickSearch(event) {
    this.searchValue = event;
    this.config.currentPage = 1;
    this.getServicesOrdersByPage();
  }

  getServicesOrdersByPage(ordering?) {
    let params = { ...this.filterParamsUrl };
    params['page'] = this.config.currentPage;
    params['page_size'] = this.config.itemsPerPage;

    if (this.searchValue) {
      params['query'] = this.searchValue;
    }
    if (ordering) {
      params['ordering'] = ordering;
    }
    this.serviceOrders.getServicesOrdersByPage(params).subscribe(
      (data) => {
        this.spinner = false;
        this.servicesOrders = data.results;
        this.config['totalItems'] = data.count;
        this.currentItemsPerPage = data.results.length;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: this.filterParamsUrl,
          queryParamsHandling: '', // remove to replace all query params by provided
        });
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  handleBackendError(error) {
    try {
      console.log(error);
    } catch {
      console.log(error);
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  getServicesOrdersFilter() {
    let params = {
      filter_page: this.filterPage,
    };
    this.filter.getFiltersData(params).subscribe(
      (data) => {
        this.filterData = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  selectAll(event) {
    this.disabledDelete = true;
    SelectUtil.selectAll(event, this);
  }

  deleteSelectedRows() {
    this.deletedOrderID = this.selectedRowsPK;
    this.deletedOrderIndex = this.selectedRowsIndex;
    // $('#confirmDelete').modal('show');
  }

  selectsSingleRow(event, rowPk, index) {
    this.disabledDelete = true;
    SelectUtil.selectsSingleRow(event, rowPk, index, this);
    if (this.selectedRowsPK.length > 0) {
      this.disabledDelete = false;
    }
  }

  openServiceOrder(id) {
    this.router.navigateByUrl('/pages/orders/service-order/' + id);
  }

  openServiceOrderWithQuatation(id) {
    let state = {
      openQuatation: true,
    };
    this.router.navigate(['/pages/orders/service-order/' + id], {
      state: state,
    });
  }

  filterFormValus(event) {
    this.filterParamsUrl = event.filterParamsUrl;
    this.getServicesOrdersByPage();
  }

  toggleSrc(orderCol, img) {
    img.src = this.imgASC;
    let col = orderCol;
    if (!this.ordering[orderCol]) {
      col = '-' + orderCol;
      img.src = this.imgDesc;
    }
    this.ordering[orderCol] = !this.ordering[orderCol];
    this.getServicesOrdersByPage(col);
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.getServicesOrdersByPage();
  }
  pageSizeChanged(event) {
    this.config.itemsPerPage = event;
    this.getServicesOrdersByPage();
  }
  getStatusClass(status) {
    if (status === 'UP' || status === 'UC') return 'not-finished';
    else if (status === 'AP' || status === 'UW' || status === 'DI')
      return 'delivered';
    else if (status === 'CO') return 'completed';
    else if (status === 'CA') return 'canceled';
    else return 'audit';
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.filter.changeSelectedFilter({});
  }
}
