import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ServiceGlobalValueService } from 'src/app/core/services/service-engine/service-global-value/service-global-value.service';
import { ServiceQuatationsService } from 'src/app/core/services/service-engine/service-quatations/service-quatations.service';

@Component({
  selector: 'app-quatation-modal',
  templateUrl: './quatation-modal.component.html',
  styleUrls: ['./quatation-modal.component.scss'],
})
export class QuatationModalComponent implements OnInit {

  quatationFormGroup: UntypedFormGroup;
  @Input() displayOrderConfirmation: boolean = true;
  @Input() quatationType: string;
  @Input() orderId: string;
  @Input() facility: any;
  selectedquatation = [];
  quatations: any = [];
  vat: any = '-';
  finalCost: any = '-';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private serviceQuatation: ServiceQuatationsService,
    private globalServiceStatus: ServiceGlobalValueService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.getGlobalProviders();
  }

  createFormControls() {
    return this.quatationFormGroup.controls;
  }

  createForm() {
    this.quatationFormGroup = this.formBuilder.group({
      offer_validity_per_hour: [null, Validators.required],
      price_exclude_vat: [null, Validators.required],
      cost_exclude_vat: [null],
      facility: [null],
    });
  }

  getGlobalProviders() {
    this.globalServiceStatus.currentProviders.subscribe((providers) => {
      this.quatations = providers;
      console.log(this.quatations);
    });
  }

  setquatation(event) {
    console.log(event);
  }

  vatCostCalc(event) {
    let price = event.target.value;
    if (price.length > 0) {
      this.vat = (price * 0.15).toFixed(2);
      this.finalCost = (price * 1.15).toFixed(2);
    } else {
      this.vat = '-';
      this.finalCost = '-';
    }
  }
  
  submitQuatation() {
    let sentData;
    if (this.quatationType === 'single') {
      sentData = {
        order_pk: this.orderId,
        facility: this.facility.facility.id,
        cost_exclude_vat:
          this.quatationFormGroup.controls['cost_exclude_vat'].value,
        price_exclude_vat:
          this.quatationFormGroup.controls['price_exclude_vat'].value,
        offer_validity_per_hour:
          this.quatationFormGroup.controls['offer_validity_per_hour'].value,
      };
    } else if (this.quatationType === 'custom') {
      sentData = {
        order_pk: this.orderId,
        facility: this.quatationFormGroup.controls['facility'].value,
        cost_exclude_vat:
          this.quatationFormGroup.controls['cost_exclude_vat'].value,
        price_exclude_vat:
          this.quatationFormGroup.controls['price_exclude_vat'].value,
        offer_validity_per_hour:
          this.quatationFormGroup.controls['offer_validity_per_hour'].value,
      };
    }
    this.serviceQuatation.sendOrderQuatation(sentData).subscribe(
      (res) => {
        this.globalServiceStatus.changeQuatationsStatus('changed');
        this.vat = '-';
        this.finalCost = '-';
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
