import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { CouponsService } from 'src/app/features/copoun/services/coupons/coupons.service';
import { Coupon } from 'src/app/models/interfaces/coupon';
import { CouponStatus } from 'src/app/models/enums/coupon-status.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FiltersService } from 'src/app/core/services/filters/filters.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { FilterUtilService } from 'src/app/core/services/uitls/filter-utilizer/filter-util.service';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { ReplaySubject } from 'rxjs';
import { Images } from 'src/app/shared/global/image-constants';
import { SelectUtil } from 'src/app/core/services/uitls/selectUtil';

@Component({
  selector: 'app-copoun-list',
  templateUrl: './copoun-list.component.html',
  styleUrls: ['./copoun-list.component.scss'],
})
export class CopounListComponent implements OnInit {
  searchValue: string;
  totalRecords: number = 0;
  page: number = 1;
  config: any;
  couponList: Coupon[];
  Coupon_status_enum: any = CouponStatus;
  deletedCouponID;
  deletedCouponIndex;
  couponMsg = 'هل أنت متأكد من حذف كود الخصم؟';
  selectedRowsPK: number[] = [];
  selectedRowsIndex: number[] = [];
  confirmMsg: string;
  modelImg: boolean;
  disabledDelete: boolean = true;
  filterData;
  imgDefault: string = Images.DEFAULT_SORT;
  imgASC: string = Images.ASC_SORT;
  imgDesc: string = Images.DESC_SORT;
  ordering = {
    current_redeem_number: true,
    start_date_display: true,
    end_date_display: true,
  };
  filterParamsUrl: any = {};
  filterDataInit: any = {};
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  filterPage: string = 'COUPONS';
  pageTitle: string = 'اكواد الخصم';
  totalRowsSelected: boolean = false;
  savedFilters: any = [];
  spinner: boolean = true;
  spinnerKey: string = 'spinner';
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChildren('checkBoxList') checkBoxList: ElementRef<HTMLInputElement>[];
  @ViewChild('selectAllElm') selectAllElm: ElementRef<HTMLInputElement>;
  currentItemsPerPage: number;

  constructor(
    private Coupons: CouponsService,
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filter: FiltersService,
    private filterUtil: FilterUtilService
  ) {
    this.config = {
      id: 'myPagination',
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
    };
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        this.searchValue = params['query'] ? params['query'] : '';
        this.filterParamsUrl = params;
        this.filterDataInit = params;
      }
    });
    this.getCouponsFilter();
    this.filterUtil.getSavedFilters(this, this.filterPage);
    this.filter.changeSelectedFilter(this.filterDataInit);
    this.filterUtil.applySelectedFilter(
      this,
      this.getCouponsByPage,
      this.spinnerKey
    );
  }

  getCouponsFilter() {
    let params = {
      filter_page: this.filterPage,
    };
    this.filter.getFiltersData(params).subscribe(
      (data) => {
        this.filterData = data;
      },
      (error) => {
        this.handelBackEndError(error);
      }
    );
  }

  handelBackEndError(err) {
    try {
    } catch {}
  }

  addCoupon() {
    this.router.navigateByUrl('/pages/coupons/0');
    this.titleService.setTitle('إضافة كود خصم | عاين');
  }

  openCoupon(id) {
    this.router.navigateByUrl('/pages/coupons/' + id);
    this.titleService.setTitle('تعديل كود خصم #' + id + '  | عاين');
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  selectAll(event) {
    this.disabledDelete = true;
    SelectUtil.selectAll(event, this);
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.confirmMsg,
        button: 'تم',
      },
    });
  }

  selectsSingleRow(event, rowPk, index) {
    this.disabledDelete = true;
    this.totalRowsSelected = false;
    SelectUtil.selectsSingleRow(event, rowPk, index, this);
    if (this.selectedRowsPK.length > 0) {
      this.disabledDelete = false;
    }
  }

  selectTotalRows(event) {
    this.totalRowsSelected = event;
  }

  deleteSelectedRows() {
    this.deletedCouponID = this.selectedRowsPK;
    this.deletedCouponIndex = this.selectedRowsIndex;
    this.showDeleteConfirmationBox();
  }

  showDeleteConfirmationBox() {
    this.deleteDialogRef = this.dialog.open(ConfirmationBoxComponent, {
      data: {
        id: this.deletedCouponID,
        index: this.deletedCouponIndex,
        message: this.couponMsg,
      },
    });
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete(result);
      }
    });
  }

  delete(event) {
    let index = event.index;
    let unDeletedCoupons = [];
    let deletedCoupons = [];
    for (let item of index) {
      let couponIndex = item;
      if (!this.couponList[couponIndex].current_redeem_number) {
        deletedCoupons.push(this.couponList[couponIndex].pk);
      } else {
        this.modelImg = false;
        unDeletedCoupons.push(this.couponList[couponIndex].code);
      }
    }
    if (unDeletedCoupons.length > 0) {
      this.confirmMsg = 'عذراً لا يمكن حذف كود الخصم‎';
      unDeletedCoupons.forEach((item) => {
        this.confirmMsg += item + ' , ';
      });
      this.confirmMsg += ' كونه مستخدم ضمن الطلبات ‎';
      this.showAlertBox();
    } else {
      // send deletedCoupons to backend
      this.Coupons.deleteCoupon(deletedCoupons).subscribe(
        () => {
          this.confirmMsg = 'تم الحذف بنجاح';
          this.modelImg = true;
          this.showAlertBox();
          this.getCouponsByPage();
        },
        (error) => {
          console.log(Object.values(error.error)[0]);
          const codeError = Object.values(error.error)[0];
          this.confirmMsg = ` لا يمكن حذف الكود ${codeError} `;
          this.confirmMsg += ` تم الغاء العملية.`;
          this.modelImg = false;
          this.showAlertBox();
        }
      );
    }
  }

  toggleSrc(orderCol, img) {
    img.src = this.imgASC;
    let col = orderCol;
    if (!this.ordering[orderCol]) {
      col = '-' + orderCol;
      img.src = this.imgDesc;
    }
    this.ordering[orderCol] = !this.ordering[orderCol];
    this.getCouponsByPage(col);
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.totalRowsSelected = false;
    this.getCouponsByPage();
  }
  pageSizeChanged(event) {
    this.config.itemsPerPage = event;
    this.getCouponsByPage();
  }
  quickSearch(event) {
    this.searchValue = event;
    this.config.currentPage = 1;
    this.getCouponsByPage();
  }

  filterFormValus(event) {
    this.filterParamsUrl = event.filterParamsUrl;
    this.config.currentPage = 1;
    this.getCouponsByPage();
  }

  getCouponsByPage(ordering?) {
    let params = { ...this.filterParamsUrl };
    params['page'] = this.config.currentPage;
    params['page_size'] = this.config.itemsPerPage;

    this.totalRowsSelected = false;
    if (this.searchValue) {
      params['query'] = this.searchValue;
    }
    if (ordering) {
      params['ordering'] = ordering;
    }
    this.Coupons.getCouponsByPage(params).subscribe(
      (data) => {
        this.spinner = false;
        this.couponList = data.results;
        this.config['totalItems'] = data.count;
        this.currentItemsPerPage = data.results.length;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: this.filterParamsUrl,
          queryParamsHandling: '', // remove to replace all query params by provided
        });
      },
      (error) => {
        this.handelBackEndError(error);
      }
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.filter.changeSelectedFilter({});
  }
}
