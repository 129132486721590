import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ServicesOrdersService } from 'src/app/core/services/servicesOrders/services-orders.service';

@Component({
  selector: 'app-service-provider-info',
  templateUrl: './service-provider-info.component.html',
  styleUrls: ['./service-provider-info.component.scss']
})
export class ServiceProviderInfoComponent implements OnInit {
  @Input() ServiceProvider;
  @Input() servicePaymentProvider;
  orderId: any;
  constructor(private servicesOrdersService : ServicesOrdersService,
               private activatedRoute : ActivatedRoute) { }

  ngOnInit(): void {
    this.orderId = this.activatedRoute.snapshot.paramMap.get('id');
    console.log('data', this.ServiceProvider);

  }
  providerPay(id){
    let service={
      service_provider:this.servicePaymentProvider.service_provider
    }
    this.servicesOrdersService.changeProviderPayment(id,service).subscribe(data=>{
      window.location.reload();
    });
  }
  dateFormate(date){
    if(date)
      return moment(date).format('YYYY-MM-DD');
    else
      return '-';
  }
}
