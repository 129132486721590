import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-dynmic-select',
  templateUrl: './select.component.html',
  styleUrls: []
})
export class SelectDynamicComponent implements OnInit {
  /**
  *  declare subForm property  
  */
   @Input() subForm: UntypedFormGroup;
  /**
   declare inputs variable
  *  
  */
  @Input() inputs;
  /**
   declare optionItems variable
  *  
  */
  optionItems ;
  isRequired: boolean = false;
  /**
   select view child 
  *  
  */
   @ViewChild('singleselect') select: NgSelectComponent;
   private onScroll = (event: any) => {
       if (this.select && this.select.isOpen) {
           const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
           if (isScrollingInScrollHost) { return; }
           this.select.close();
       }
   }

  ngOnInit(): void {
    window.addEventListener('scroll', this.onScroll, true);
    this.optionItems = this.inputs.choices ;
    this.isRequired = this.inputs.validation.required;
  }
 
   ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

}
