import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerProfileComponent } from './pages/customer-profile/customer-profile.component';
import { CustomersGridComponent } from './pages/customers-grid/customers-grid.component';
import { CustomersComponent } from './customers.component';
import { CustomersRatingGridComponent } from './pages/customers-rating-grid/customers-rating-grid.component';
import { OrderStatusResolver, ServicesResolver } from 'src/app/core/_helpers/resolvers/inspector.resolver';
import { ClientFinalStatusResolver } from './resolvers/customer.resolver';

const routes: Routes = [
  {
    path: '',
    component: CustomersComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'customers-grid' },
      { path: 'customers-grid', component: CustomersGridComponent },
      { path: 'customers-rating', component: CustomersRatingGridComponent,
      resolve: {
        services: ServicesResolver,
        status: OrderStatusResolver,
        finalStatus: ClientFinalStatusResolver
      }},
      { path: 'cusotmer-profile/:id', component: CustomerProfileComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    ServicesResolver,
    OrderStatusResolver,
    ClientFinalStatusResolver
  ]
})
export class CustomersRoutingModule {}
