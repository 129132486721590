import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { WhatsNewService } from '../../services/whats-new/whats-new.service';
import { SummerNoteConfig } from 'src/app/common/summernote-config';

@Component({
  selector: 'app-add-new-event',
  templateUrl: './add-new-event.component.html',
  styleUrls: ['./add-new-event.component.scss'],
})
export class AddNewEventComponent implements OnInit {
  whatsNewFormGroup: UntypedFormGroup;
  title: string;
  whatsNewID;
  confirmMsg: string;
  Confirmation: boolean = false;
  alertmsg: boolean = false;
  message: string;
  classifications;
  discountTypes;
  formatedStartDate;
  formatedEndDate;
  whatsNewDetails;
  saveBtn;
  enableSave = true;
  currentDate = new Date();
  productsLength;
  discountType = 0;
  showForm: boolean = false;
  @ViewChild('allSelected') allSelected;
  status: any;
  bankdata: any;
  summerNoteConfig = SummerNoteConfig.MAIN_CONFIG;
  userTypes = [];
  file = {
    name: null,
    exist: false,
    error: null,
  };
  formData = new FormData();
  statusDesc: string = 'غير منشور';
  errorHandler: any;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private dialog: MatDialog,
    private whatsNewService: WhatsNewService
  ) {}

  ngOnInit(): void {
    this.whatsNewID = +this.activatedRoute.snapshot.params.id;
    this.getUserTypes();
    if (this.whatsNewID) {
      this.title = 'تعديل التحديث ';
      this.saveBtn = 'تعديل';
      this.getWhatsNewById();
      this.confirmMsg = 'تم تعديل التحديث بنجاح';
    } else {
      this.showForm = true;
      this.title = 'إضافة تحديث جديد';
      this.saveBtn = 'إضافة';
      this.confirmMsg = 'تم إضافة التحديث بنجاح';
    }
    this.createForm();
    this.createFormControls();
  }

  getUserTypes() {
    this.whatsNewService.getUserTypes().subscribe(
      (data) => {
        this.userTypes = data;
      },
      (error) => {
        try {
          this.errorHandler = error;
        } catch (err) {}
      }
    );
  }

  sliceFileName(name) {
    return name.slice(name.lastIndexOf('/') + 1);
  }

  handleFileInput(files) {
    const size = files[0].size;
    if (size < 5000000) {
      this.whatsNewFormGroup.controls.cover.setValue(files[0]);
      this.formData.append('cover', files[0]);
      this.file.exist = true;
      this.file.name = files[0].name;
    } else {
      this.file.error = true;
    }
  }

  createFormControls() {
    return this.whatsNewFormGroup.controls;
  }

  createForm() {
    this.whatsNewFormGroup = this.formBuilder.group({
      active: [false, Validators.required],
      title: [null, [Validators.required, Validators.maxLength(150)]],
      user_type: [null, Validators.required],
      body: [null, Validators.required],
      publish_date: [null, Validators.required],
      cover: [null, Validators.required],
    });
  }

  getWhatsNewById() {
    this.whatsNewService.getWhatsNewById(this.whatsNewID).subscribe(
      (data) => {
        this.whatsNewDetails = data;
        this.status = this.whatsNewDetails.active;
        this.statusDesc = this.whatsNewDetails.active ? 'منشور' : 'غير منشور';
        this.file = {
          name: this.whatsNewDetails.cover,
          exist: this.whatsNewDetails.cover ? true : false,
          error: null,
        };
        this.whatsNewFormGroup.patchValue({
          active: this.whatsNewDetails.active,
          title: this.whatsNewDetails.title,
          user_type: this.whatsNewDetails.user_type,
          body: this.whatsNewDetails.body,
          publish_date: moment(this.whatsNewDetails.publish_date),
          cover: this.whatsNewDetails.cover,
        });
        this.showForm = true;
      },
      (error) => {
        try {
          console.log(error);
        } catch {
          console.log(error);
        }
      }
    );
  }

  goWhatsNewList() {
    this.router.navigateByUrl('/pages/whats-new/whats-new-grid');
    this.titleService.setTitle('تحديثات النظام | عاين');
  }

  closeError() {
    this.alertmsg = false;
  }

  statusValueChanged(event) {
    this.whatsNewFormGroup.get(event.labelEn).setValue(event.value);
    this.statusDesc = event.value ? 'منشور' : 'غير منشور';
  }

  save() {
    this.alertmsg = false;
    this.enableSave = false;
    if (this.whatsNewFormGroup.status == 'INVALID') {
      this.alertmsg = true;
      this.enableSave = true;
      this.message =
        'هناك خطأ في خانة أو بعض الخانات يرجى التأكد من المعلومات المدخلة';
      return;
    }
    let formKeys = Object.keys(this.whatsNewFormGroup.value);
    formKeys.forEach((elem) => {
      if (elem != 'cover') {
        let value = this.whatsNewFormGroup.get(elem).value;
        if (elem.includes('date')) {
          value = moment(value);
          value = value.format('YYYY-MM-DD');
        }
        this.formData.append(elem, value);
      }
    });
    if (this.whatsNewID)
      this.whatsNewService
        .updateWhatsNew(this.whatsNewID, this.formData)
        .subscribe(
          (data) => {
            this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
              data: {
                imgSuccess: true,
                message: this.confirmMsg,
                backUrl: '/pages/whats-new/whats-new-grid',
              },
            });
          },
          (error) => {
            this.handleError(error);
          }
        );
    else {
      this.whatsNewService.createWhatsNew(this.formData).subscribe(
        (data) => {
          this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
            data: {
              imgSuccess: true,
              message: this.confirmMsg,
              backUrl: '/pages/whats-new/whats-new-grid',
            },
          });
        },
        (error) => {
          this.handleError(error);
        }
      );
    }
  }

  handleError(error) {
    this.alertmsg = true;
    this.enableSave = true;
    this.message = Object.values(error.error)[0].toString();
    let key = Object.keys(error.error)[0].toString();
    let field = document.querySelector(
      '[formControlName=' + key + ']'
    ).parentElement;
    field.classList.add('invalid-field');
    try {
      console.log('error');
    } catch (err) {
      console.log(err);
    }
  }
}
