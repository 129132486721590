import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {

  /**
  *  declare subForm property  
  */
   @Input() subForm: UntypedFormGroup;
  /**
   declare inputs variable
  *  
  */
  @Input() inputs;
  isRequired: boolean = false;

  ngOnInit(): void {
    this.isRequired = this.inputs.validation.required;
  }

}
