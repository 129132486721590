import { Component, Input, OnInit } from '@angular/core';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';

@Component({
  selector: 'notifications-log',
  templateUrl: './notifications-log.component.html',
  styleUrls: ['./notifications-log.component.scss'],
})
export class NotificationsLogComponent implements OnInit {
  @Input() userType;
  @Input() userID;
  privatePage: number = 1;
  generalPage: number = 1;
  generalTotalRecords: number = 0;
  privateTotalRecords: number = 0;
  privateNotifications = [];
  privateConfig: {
    id: string;
    itemsPerPage: number;
    currentPage: any;
    totalItems: any;
  };
  generalNotifications = [];
  generalConfig: {
    id: string;
    itemsPerPage: number;
    currentPage: any;
    totalItems: any;
  };

  constructor(private inspectorService: InspectorsService) {}

  ngOnInit(): void {
    this.privateConfig = {
      id: 'privatePagination',
      itemsPerPage: 20,
      currentPage: this.privatePage,
      totalItems: this.privateTotalRecords,
    };
    this.generalConfig = {
      id: 'generalPagination',
      itemsPerPage: 20,
      currentPage: this.generalPage,
      totalItems: this.generalTotalRecords,
    };
    this.getPrivateNotifications();
    this.getGeneralNotifications();
  }

  getPrivateNotifications() {
    this.inspectorService
      .getPrivateNotifications(this.userID, {
        page: this.privateConfig.currentPage,
        page_size: this.privateConfig.itemsPerPage,
      })
      .subscribe((data) => {
        this.privateNotifications = data.results;
        this.privateConfig['totalItems'] = data.count;
      });
  }

  getGeneralNotifications() {
    this.inspectorService
      .getGeneralNotifications({
        notification_type__user_type: this.userType,
        page: this.generalConfig.currentPage,
        page_size: this.generalConfig.itemsPerPage,
      })
      .subscribe((data) => {
        this.generalNotifications = data.results;
        this.generalConfig['totalItems'] = data.count;
      });
  }

  privatePageChanged(event) {
    this.privateConfig.currentPage = event;
    this.getPrivateNotifications();
  }

  generalPageChanged(event) {
    this.generalConfig.currentPage = event;
    this.getGeneralNotifications();
  }
  privatePageSizeChanged(event) {
    this.privateConfig.itemsPerPage = event;
    this.getPrivateNotifications();
  }
  generalPageSizeChanged(event) {
    this.generalConfig.itemsPerPage = event;
    this.getGeneralNotifications();
  }
  convertHtml(text) {
    text = text.replace(/<[^>]+>/g, '');
    return text;
  }
}
