<div class="row">
    <div class="col-md-6 custom-container">
        <app-switch-input 
            [label]="'تفعيل إشعارت الطلبات عبر رسائل SMS'" 
            [labelEn]="'can_receive_phone_notifications'"
            [value]="is_SMS" 
            [index]="1" 
            (valueChanged)="switchBtnChanged($event)">
        </app-switch-input>
    </div>
    <div class="col-md-6 custom-container">
        <app-switch-input 
            [label]="'تفعيل إشعارت الطلبات عبر البريد الإلكتروني'"
            [labelEn]="'can_receive_email_notifications'" 
            [value]="is_Email" 
            [index]="2"
            (valueChanged)="switchBtnChanged($event)">
        </app-switch-input>
    </div>
</div>