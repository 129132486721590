import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { InspectionService } from 'src/app/core/services/inspection/inspection.service';
import { ServiceQuatationsService } from 'src/app/core/services/service-engine/service-quatations/service-quatations.service';
import { ServicesOrdersService } from 'src/app/core/services/servicesOrders/services-orders.service';

@Component({
  selector: 'app-service-order-main-details',
  templateUrl: './service-order-main-details.component.html',
  styleUrls: ['./service-order-main-details.component.scss'],
})
export class ServiceOrderMainDetailsComponent implements OnInit {
  @Input() subForm;
  @Input() orderData;
  @Output() enableSave = new EventEmitter();
  inspectionPeriods: any;
  selectedPeriods = [];
  providers: any;
  status: any;
  minDate = new Date();
  disableDate: boolean = false;
  @ViewChild('selectStatus') selectStatus: NgSelectComponent;
  @ViewChild('selectInspector') selectInspector: NgSelectComponent;
  private onScroll = (event: any) => {
    this.closeSelectMenu(event, this.selectStatus);
    this.closeSelectMenu(event, this.selectInspector);
  };
  constructor(
    private servicesOrdersService: ServicesOrdersService,
    private inspectionService: InspectionService,
    private serviceQuatations: ServiceQuatationsService
  ) {}

  ngOnInit(): void {
    window.addEventListener('scroll', this.onScroll, true);
    this.selectedPeriods =
      this.orderData.essential_details.order_inspection_periods;
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.getinspectionPeriods();
    this.getProviders();
    this.getNewStatus();
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }
  closeSelectMenu(event, element) {
    if (element && element.isOpen) {
      const isScrollingInScrollHost =
        (event.target.className as string).indexOf('ng-dropdown-panel-items') >
        -1;
      if (isScrollingInScrollHost) {
        return;
      }
      element.close();
    }
  }

  getinspectionPeriods() {
    this.inspectionService.getinspectionPeriods().subscribe(
      (data) => {
        this.inspectionPeriods = data;
      },
      (error) => {
        try {
          console.log(error);
        } catch {
          console.log(error);
        }
      }
    );
  }

  getNewStatus(): void {
    this.servicesOrdersService.getNewStatus().subscribe(
      (res) => {
        this.status = res;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getProviders(): void {
    this.serviceQuatations
      .getServiceQutationOptions(this.orderData.id, true)
      .subscribe(
        (res) => {
          this.providers = res;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  setOrderPeriods(event, enableSave) {
    this.subForm.controls.order_inspection_periods.patchValue([...event]);
    if (enableSave && event.length > 0) {
      if (
        event.length ===
        this.orderData.essential_details.order_inspection_periods?.length
      ) {
        for (let i = 0; i < event.length; i++) {
          if (
            event[i] !==
            this.orderData.essential_details.order_inspection_periods[i]
          ) {
            this.changeEnableSave(enableSave);
            return;
          }
        }
      } else this.changeEnableSave(enableSave);
    }
  }

  changeEnableSave(eventSave) {
    this.enableSave.emit({
      enableSave: eventSave,
    });
  }
}
