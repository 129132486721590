<div class="inner-page-header">
  <h3>اعدادات الفاحصين</h3>
</div>

<div class="main-content">
  <form class="needs-validation" [formGroup]="inspectorsSettingsForm">
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngClass]="{
      'closed-panel': panelOpenState == false,
      'open-panel': panelOpenState == true
      }" class="custom-panel">
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title>الشهادات</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">اضافة شهادة</label>
                <input formControlName="certificate" class="form-control" type="text">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              @if (certificateAlert) {
              <div class="alert-box">
                <img src="assets/images/svg/error-icon.svg" class="close-alert" (click)="closeError()" alt="" />
                <p class="alert-msg">{{errorMessage}}</p>
              </div>
              }
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 double-action">
              <button class="btn btn-green" id='saveBtn' type="submit" (click)="savecertificate()">
                حفظ
              </button>
            </div>
          </div>

          <div class="custom-card">
            @if (certificates.length > 0) {
            <div class="row custom-container">
              @for (certificate of certificates; track certificate; let i = $index) {
              <div class="col-6 item">
                <div>
                  <label class="checkbox-container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                  </label>
                  <span class="checkbox-label"> {{certificate.name}} </span>
                </div>
                <div class="setting-container">
                  <div class="setting-item wrench" (click)="updateCertificate(certificate, i)">
                    <img src="assets/images/svg/wrench.svg" alt="">
                  </div>
                </div>
              </div>
              }
            </div>
            }
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngClass]="{
        'closed-panel': panelOpenState == false,
        'open-panel': panelOpenState == true
        }" class="custom-panel">
        <mat-expansion-panel-header class="header-parent">
          <mat-panel-title>التأهيل المخصص</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">اضافة تأهيل مخصص</label>
                <input formControlName="special_qualification_tag" class="form-control" type="text">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              @if (SpecialAlert) {
              <div class="alert-box">
                <img src="assets/images/svg/error-icon.svg" class="close-alert" (click)="closeError()" alt="" />
                <p class="alert-msg">{{errorMessage}}</p>
              </div>
              }
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 double-action">
              <button class="btn btn-green" id='saveBtn' type="submit" (click)="saveSpecialQualification()">
                حفظ
              </button>
            </div>
          </div>

          <div class="custom-card">
            @if (specialQualifications.length > 0) {
            <div class="row custom-container">
              @for (specialQualification of specialQualifications; track specialQualification; let specialIndex = $index) {
              <div class="col-6 item">
                <div>
                  <label class="checkbox-container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                  </label>
                  <span class="checkbox-label"> {{specialQualification.name}} </span>
                </div>
                <div class="setting-container">
                  <div class="setting-item wrench" (click)="updateSpecialQualification(specialQualification, specialIndex)">
                    <img src="assets/images/svg/wrench.svg" alt="">
                  </div>
                </div>
              </div>
              }
            </div>
            }
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</div>
