<div >
  @for (sub of orderData.sub_order; track sub) {
    <div class="sub-content" >
      <div class="row" >
        <div class="col-md-3 col">
          <h4> رقم الطلب</h4>
          <p> #{{sub.reference_number}} </p>
        </div>
        <div class="col-md-3 col">
          <h4> سبب الإيراد الإضافي</h4>
          <p>  {{sub.reason}}</p>
        </div>
        @if (sub?.reason === 'مساحة إضافية') {
          <div class="col-md-3 col">
            <h4>   المساحة </h4>
            <p> {{sub.sub_order_value}} <span class="">م<sup>2</sup></span> </p>
          </div>
        }
        <div class="col-md-3 col">
          <h4>   إجمالي الإيراد الإضافي</h4>
          <p> {{sub?.cost?.final_cost}} <span>ر.س</span> </p>
        </div>
        <div class="col-md-3 col">
          <h4> قيمة الضريبة المضافة </h4>
          <p> {{sub?.cost?.tax_cost}} <span>ر.س</span> </p>
        </div>
        <div class="col-md-3 col">
          <h4>صافي الإيراد الإضافي</h4>
          <p> {{sub?.cost?.cost_after_discount}} <span>ر.س</span> </p>
        </div>
        <div class="col-md-3 col">
          <h4>حصة الفاحص</h4>
          <p> {{sub?.cost?.cost_details?.inspector_share_sends_via_email
            ?sub?.cost?.cost_details?.inspector_share_sends_via_email: '-'}} <span>ر.س</span>
          </p>
        </div>
        <div class="col-md-3 col">
          <h4> حالة الدفع</h4>
          <div class="double-action">
            <p> {{sub?.payment_status}}
              @if (sub?.payment_status === 'مستلم') {
                <img src="assets/images/svg/ellipse-green.svg" alt="">
              }
            </p>
            @if (sub?.payment_status === 'مدفوع') {
              <button (click)="deliveredOrder(sub.pk, received)" class="btn btn-green">
                تم الإستلام
              </button>
            }
          </div>
        </div>
        <div class="col-md-3 col">
          <h4> تاريخ الدفع</h4>
          <p> {{sub.payment_date}} </p>
        </div>
        <div class="col-md-3 col">
          <h4>  طريقة الدفع</h4>
          <p> {{sub.payment_option}}{{sub.card_number}}  </p>
        </div>
        <div class="col-md-3 col">
          <h4> رقم المعاملة</h4>
          <p>{{sub.fort_id}}</p>
        </div>
      </div>
    </div>
  }
</div>
