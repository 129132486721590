import { ActivatedRoute } from '@angular/router';
import { FinalStatus } from 'src/app/models/enums/final-status.enum';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { CustomerRatingEditorComponent } from 'src/app/shared/components/ag-grid/customer-rating-editor/customer-rating-editor.component';
import { RatingStarsRenderComponent } from 'src/app/shared/components/ag-grid/rating-stars-render/rating-stars-render.component';
import { RegistarAsRenderComponent } from 'src/app/shared/components/ag-grid/registar-as-render/registar-as-render.component';
import { RegisterAsComponent } from 'src/app/shared/components/ag-grid/register-as/register-as.component';
import { TagElementRenderComponent } from 'src/app/shared/components/ag-grid/tag-element-render/tag-element-render.component';

export class CustomerRatingsColumns {
  constructor(private route: ActivatedRoute,) {}

  ayenServices = this.route.snapshot.data.services;
  status = this.route.snapshot.data.status;
  finalStatus = this.route.snapshot.data.finalStatus;

  getRecommendationValueSetter(params) {
    if (isNaN(params.newValue)) {
      alert('not valid number');
      return false;
    }

    if (params.newValue > 10) {
      alert('input is larger than 10');
      return false;
    } else {
      params.data[params.colDef.field] = params.newValue;
      return true;
    }
  }

  public columns = [
    {
      headerName: ' ',
      field: 'pk',
      hide: true,
      resizable: false,
    },
    {
      headerName: ' ',
      field: 'check_box',
      editable: false,
      resizable: false,
      checkboxSelection: true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      cellClass: 'checkbox',
    },
    {
      headerName: 'رقم الطلب',
      field: 'reference_number',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: true,
      cellClass: 'my-class',
    },
    {
      headerName: 'اسم العميل',
      field: 'order_client',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: false,
    },
    {
      headerName: 'اسم الفاحص',
      field: 'order_inspector',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: false,
    },
    {
      headerName: 'نوع الخدمة',
      field: 'order_type',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: false,
    },
    {
      headerName: 'مدينة الطلب',
      field: 'google_address_city',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: false,
    },
    {
      headerName: 'تاريخ الطلب',
      field: 'created_at',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: false,
    },
    {
      headerName: 'تاريخ تسليم التقرير',
      field: 'ended_at',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: false,
    },
    {
      headerName: 'تاريخ اعتماد التقرير',
      field: 'approved_at',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: false,
    },
    {
      headerName: 'حالة الطلب',
      field: 'status',
      width: 120,
      minWidth: 120,
      maxWidth: 120,
      editable: false,
      cellEditorParams: { values: this.status },
      cellRenderer: 'registarAsRenderComponent',
      cellRendererParams: {
        values: this.status,
      },
      cellClass: 'btnChecked',
      cellClassRules: {
        notFinished: (params) => params.value === OrderStatus.Not_Finished,
        completed: (params) => params.value === OrderStatus.Completed,
        new: (params) => params.value === OrderStatus.New,
        inspectoring: (params) =>
          params.value === OrderStatus.Under_Inspectoring,
        audit: (params) => params.value === OrderStatus.Under_Audit,
        processing: (params) => params.value === OrderStatus.Processing,
        scheduled: (params) => params.value === OrderStatus.Scheduled,
        delivered: (params) =>
          params.value === OrderStatus.Delivered ||
          params.value === OrderStatus.InspectorAssigned,
        canceled: (params) =>
          params.value === OrderStatus.Canceled ||
          params.value === OrderStatus.NotPaid,
        holding: (params) => params.value === OrderStatus.Holding,
      },
    },
    {
      headerName: 'نوع المستخدم',
      field: 'user_type',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: false,
      cellEditorParams: {
        values: [
          {
            id: 'individual',
            name: 'عملاء',
          },
          {
            id: 'contract',
            name: 'شركات',
          },
        ],
      },
      cellRenderer: 'registarAsRenderComponent',
      cellRendererParams: {
        values: [
          {
            id: 'individual',
            name: 'عملاء',
          },
          {
            id: 'contract',
            name: 'شركات',
          },
        ],
      },
      cellStyle: function (params) {
        let bcColor = '';
        if (params.value == 'individual') {
          bcColor = '#40B58D';
        }
        if (params.value == 'contract') {
          bcColor = '#FFCF59';
        }
        return {
          color: '#ffffff',
          backgroundColor: bcColor,
          textAlign: 'center',
          fontSize: '14px',
          fontFamily: 'cairo-regular',
        };
      },
    },
    {
      headerName: 'تقييم الجودة',
      field: 'inspector_rating',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: true,
      cellRenderer: 'ratingStarsRenderComponent',
      cellEditor: 'customerRatingEditorComponent',
    },
    {
      headerName: 'ملاحظات الجودة',
      field: 'qc_notes',
      width: 250,
      minWidth: 250,
      maxWidth: 800,
      editable: true,
    },
    {
      headerName: 'تقييم الخدمة',
      field: 'service_rating',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: true,
      cellRenderer: 'ratingStarsRenderComponent',
      cellEditor: 'customerRatingEditorComponent',
    },
    {
      headerName: 'احتمالية توصية عاين',
      field: 'service_recommendation',
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      editable: true,
      cellRenderer: 'tagElementRenderComponent',
      valueSetter: this.getRecommendationValueSetter,
    },
    {
      headerName: 'ملاحظات العملاء',
      field: 'customer_notes',
      width: 250,
      minWidth: 250,
      maxWidth: 800,
      editable: true,
    },
    {
      headerName: 'تقييم الموقع',
      field: 'location_rate',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: true,
      cellRenderer: 'tagElementRenderComponent',
      valueSetter: this.getRecommendationValueSetter,
    },
    {
      headerName: 'سهولة التقرير',
      field: 'report_rate',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: true,
      cellRenderer: 'tagElementRenderComponent',
      valueSetter: this.getRecommendationValueSetter,
    },
    {
      headerName: 'جودة المعلومات الفنية',
      field: 'information_quality',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: true,
      cellRenderer: 'tagElementRenderComponent',
      valueSetter: this.getRecommendationValueSetter,
    },
    {
      headerName: 'شكل وديناميكية التقرير',
      field: 'report_view_rate',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: true,
      cellRenderer: 'tagElementRenderComponent',
      valueSetter: this.getRecommendationValueSetter,
    },
    {
      headerName: '⁠احترافية الفاحص',
      field: 'inspector_professionalism',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: true,
      cellRenderer: 'tagElementRenderComponent',
      valueSetter: this.getRecommendationValueSetter,
    },
    {
      headerName: '⁠أسلوب الفاحص',
      field: 'inspector_attitude',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: true,
      cellRenderer: 'tagElementRenderComponent',
      valueSetter: this.getRecommendationValueSetter,
    },
    {
      headerName: 'مظهر الفاحص',
      field: 'inspector_look',
      width: 120,
      minWidth: 120,
      maxWidth: 150,
      editable: true,
      cellRenderer: 'tagElementRenderComponent',
      valueSetter: this.getRecommendationValueSetter,
    },
    {
      headerName: 'الحالة النهائية للعميل',
      field: 'final_customer_status',
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      editable: true,
      singleClickEdit: true,
      cellEditor: 'registerAsComponent',
      cellEditorParams: { values: this.finalStatus },
      cellRenderer: 'registarAsRenderComponent',
      cellRendererParams: {
        values: this.finalStatus,
      },
      cellStyle: function (params) {
        let bcColor = '';
        if (params.value == FinalStatus.NoResponse) {
          bcColor = '#D9727F';
        }
        if (params.value == FinalStatus.UnSatisfied) {
          bcColor = '#FFCF59';
        }
        if (params.value == FinalStatus.UnderConnection) {
          bcColor = '#F49C6C';
        }
        if (params.value == FinalStatus.Satisfied) {
          bcColor = '#40B58D';
        }
        return {
          color: '#ffffff',
          backgroundColor: bcColor,
          textAlign: 'center',
          fontSize: '14px',
          fontFamily: 'cairo-regular',
        };
      },
    },
  ];

  public frameworkComponents = {
    registerAsComponent: RegisterAsComponent,
    registarAsRenderComponent: RegistarAsRenderComponent,
    ratingStarsRenderComponent: RatingStarsRenderComponent,
    customerRatingEditorComponent: CustomerRatingEditorComponent,
    tagElementRenderComponent: TagElementRenderComponent,
  };
}
