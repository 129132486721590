<div class="dialog-container">
  <div mat-dialog-content>
    <label for="" class="form-label">{{ data.label }}</label>
    @for(item of data.list; track item.id; let i = $index) {
    <div class="box-wrapper row">
      <div class="col-6">
        <input
        #input
        [attr.data-index] ="i"
        [id]="item.id"
        type="text"
        [value]="item.name"
        class="form-control"
      />
      </div>
      <div class="col-6">
        <ng-select
        #select
        bindLabel="name"
        class="single-select"
        placeholder=""
        appendTo="body"
        (change)="editColor(item)"
        [(ngModel)]="item.color"
        [searchable]="true"
        [clearable]="true"
      >
        @for (color of data.options; track color) {
        <ng-option [value]="color">
          <span
            class="color-option"
            [ngStyle]="{ 'background-color': color }"
          ></span>
        </ng-option>
        }
      </ng-select>
      </div>
    </div>
    }
  </div>
  <div mat-dialog-actions class="setup-footer actions-double">
    <button class="btn btn-cancel" type="button" mat-dialog-close>إلغاء</button>
    <button class="btn btn-green" [mat-dialog-close]="data" type="submit">
      موافق
    </button>
  </div>
</div>
