import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as moment from 'moment';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { InspectorGridService } from '../../services/inspectors-grid/inspectors-grid.service';

@Component({
  selector: 'app-inspector-profile',
  templateUrl: './inspector-profile.component.html',
  styleUrls: ['./inspector-profile.component.scss'],
})
export class InspectorProfileComponent implements OnInit {
  inspectorProfileForm: UntypedFormGroup;
  panelOpenState = false;
  is_Email: boolean = false;
  is_SMS: boolean = false;
  countries: any;
  regions: any;
  registrationAs;
  orderFilesList: any = [];
  inspectorID: string;
  inspectorData: any;
  inspectorStatus = [
    {
      id: true,
      name: 'متاح ',
    },
    {
      id: false,
      name: 'غير متاح',
    },
  ];
  accountStatus = [
    {
      id: true,
      name: 'مفعل ',
    },
    {
      id: false,
      name: 'غير مفعل',
    },
  ];
  qualificationsValues = [];
  public academicMajors;
  public experinceYears;
  public expertiseFocus;
  selectedExpertiseFocus = [];
  inspectorCertificates = [];
  backUrl: string = '/pages/inspectors/inspectors-grid';
  alertmsg: boolean = false;
  errorMessage: string = '';
  @ViewChild('select') select: NgSelectComponent;
  @ViewChild('selectNationality') selectNationality: NgSelectComponent;
  @ViewChild('selectRegion') selectRegion: NgSelectComponent;
  @ViewChild('selectCity') selectCity: NgSelectComponent;

  saveDialogRef: MatDialogRef<AlertBoxComponent>;

  private onScroll = (event: any) => {
    if (
      this.select?.isOpen || this.selectNationality?.isOpen ||
      this.selectCity?.isOpen || this.selectRegion?.isOpen
    ) {
      const isScrollingInScrollHost =
        (event.target.className as string).indexOf('ng-dropdown-panel-items') >
        -1;
      if (isScrollingInScrollHost) {
        return;
      }
      this.closeSelectPanel(this.select);
      this.closeSelectPanel(this.selectNationality);
      this.closeSelectPanel(this.selectCity);
      this.closeSelectPanel(this.selectRegion);
    }
  };
  cities: any;

  closeSelectPanel(select) {
    if (select && select.isOpen) {
      select.close();
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private inspectorService: InspectorsService,
    private inspectorGridService: InspectorGridService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.inspectorID = this.activatedRoute.snapshot.paramMap.get('id');
    this.countries = this.route.parent.snapshot.data.countries;
    this.cities = this.route.parent.snapshot.data.coveredCities;
    this.regions = this.route.parent.snapshot.data.regions;
    this.registrationAs = this.route.parent.snapshot.data.registrationAs;
    this.qualificationsValues = this.route.parent.snapshot.data.qualifactions;
    this.academicMajors = this.route.parent.snapshot.data.acadamicMajor;
    this.experinceYears = this.route.parent.snapshot.data.experinceYears;
    this.expertiseFocus = this.route.parent.snapshot.data.expertiseFocus;
    window.addEventListener('scroll', this.onScroll, true);
    this.getInspectorData();
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  getInspectorData() {
    this.inspectorService
      .getInspectorByID(this.inspectorID)
      .subscribe((data) => {
        this.inspectorData = data;
        this.is_SMS = this.inspectorData.can_receive_phone_notifications;
        this.is_Email = this.inspectorData.can_receive_email_notifications;
        this.selectedExpertiseFocus = this.inspectorData.expertise_focus_area;
        this.inspectorCertificates = this.inspectorData.usercertificate_set;
        this.createForm();
        this.createFormControls();
      });
  }

  createForm() {
    this.inspectorProfileForm = this.formBuilder.group({
      first_name: [this.inspectorData.first_name, Validators.required],
      last_name: [this.inspectorData.last_name, Validators.required],
      mobile: [this.inspectorData.mobile, Validators.required],
      email: [this.inspectorData.email, Validators.required],
      id_number: [this.inspectorData.id_number, Validators.required],
      nationality: [this.inspectorData.nationality, Validators.required],
      city: [this.inspectorData.city, Validators.required],
      region: [this.inspectorData.region, Validators.required],
      qualification_status: [
        this.inspectorData.qualification_status,
        Validators.required,
      ],
      inspector_is_available: [
        this.inspectorData.inspector_is_available,
        Validators.required,
      ],
      is_active: [this.inspectorData.is_active, Validators.required],
      registration_in_ayen_as: [
        this.inspectorData.registration_in_ayen_as,
        Validators.required,
      ],
      facilty_name: [this.inspectorData.facility_name, Validators.required],
      commercial_registration_no: [
        this.inspectorData.commercial_registration_no,
        Validators.required,
      ],
      can_receive_email_notifications: [
        this.inspectorData.can_receive_email_notifications,
      ],
      can_receive_phone_notifications: [
        this.inspectorData.can_receive_phone_notifications,
      ],
      academic_major: [this.inspectorData.academic_major],
      years_of_experience: [this.inspectorData.years_of_experience],
      membership_number_of_the_SCE: [
        this.inspectorData.membership_number_of_the_SCE,
      ],
      expiration_date_of_membership_number_of_the_SCE: [
        this.inspectorData.expiration_date_of_membership_number_of_the_SCE,
      ],
      expertise_focus_area: [this.inspectorData.expertise_focus_area],
      summary_of_professional_accomplishments: [
        this.inspectorData.summary_of_professional_accomplishments,
      ],
    });
  }

  createFormControls() {
    return this.inspectorProfileForm.controls;
  }

  switchBtnChanged(event) {
    this.inspectorProfileForm.get(event.labelEn).setValue(event.value);
  }

  setExpertiseFocus(event) {
    this.inspectorProfileForm
      .get('expertise_focus_area')
      .patchValue([...event]);
  }

  deleteFile(certiificateID, index) {
    this.inspectorGridService.deleteUserCertificate(certiificateID).subscribe((_data) => {
      this.inspectorCertificates[index].certificate_file = null;
    });
  }

  handleFileInput(files, id, index) {
    this.inspectorCertificates[index].error = false;
    const size = files[0].size;
    if (size < 5000000) {
      let certificateData: FormData = new FormData();
      certificateData.append('certificate_file', files[0], files[0].name);
      certificateData.append('profile', this.inspectorData.profile_id);
      this.inspectorGridService
        .uploadInspectorFiles(certificateData, id)
        .subscribe((event) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.inspectorCertificates[index].progress = false;
              break;
            case HttpEventType.ResponseHeader:
              this.inspectorCertificates[index].progress = false;
              this.inspectorCertificates[index].certificate = event.body.certificate;
              this.inspectorCertificates[index].certificate_file = event.body.certificate_file;
              break;
            case HttpEventType.UploadProgress:
              this.inspectorCertificates[index].progressVal = Math.round(
                (event.loaded / event.total) * 100
              );
              this.inspectorCertificates[index].progress = true;
              break;
            case HttpEventType.Response:
              this.inspectorCertificates[index].progress = false;
              this.inspectorCertificates[index].certificate = event.body.certificate;
              this.inspectorCertificates[index].certificate_file = event.body.certificate_file;
              setTimeout(() => {
                this.inspectorCertificates[index].progressVal = 0;
              }, 0);
          }
        });
    } else {
      this.inspectorCertificates[index].error = true;
    }
  }

  save() {
    let data = {};
    data = this.inspectorProfileForm.value;
    if (
      this.inspectorProfileForm.get(
        'expiration_date_of_membership_number_of_the_SCE'
      ).value
    ) {
      let formatedDate: any = moment(
        this.inspectorProfileForm.get(
          'expiration_date_of_membership_number_of_the_SCE'
        ).value
      );
      formatedDate = formatedDate.format('YYYY-MM-DD');
      data['expiration_date_of_membership_number_of_the_SCE'] = formatedDate;
    }
    this.inspectorGridService
      .updateInspectorsData(this.inspectorID, data)
      .subscribe(
        () => {
          this.showAlertBox();
        },
        (error) => {
          try {
            this.alertmsg = true;
            this.errorMessage = error.error;
          } catch { }
        }
      );
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: true,
        message: 'تم حفظ التعديلات بنجاح',
        backUrl: this.backUrl,
      },
    });
  }

  closeError() {
    this.alertmsg = false;
  }

  back() {
    this.router.navigateByUrl(this.backUrl);
  }
}
