import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AYENServiceBase } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class BuildingTypeService extends AYENServiceBase {

  private buildingType = new BehaviorSubject(null);
  currentBuildingType = this.buildingType.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  getBuildingTypes(): Observable<any> {
    return this.http.get(this.baseURL + '/building-types/').pipe(
      map((data: any) => {
        return data;
      }), catchError(this.handleError)
    );
  }

  getBuildingStatus(): Observable<any> {
    return this.http.get(this.baseURL + '/building-status/').pipe(
      map((data: any) => {
        return data;
      }), catchError(this.handleError)
    );
  }

  getBuildingType(): Observable<any> {
    return this.http.get(this.baseURL + '/building-type/').pipe(
      map((data: any) => {
        return data;
      }), catchError(this.handleError)
    );
  }

  changeBuildingType(buildingType: {}) {
    this.buildingType.next(buildingType);
  }

  handleError(error) {
    let errorMessage;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
