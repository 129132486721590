import { Component } from '@angular/core';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss'],
})
export class NewOrderComponent {
  selectedView: string;
  orderId;
  stepper: string = 'building';

  getView(event) {
    this.selectedView = event.view;
    this.stepper = this.selectedView;
    this.orderId = event.id;
  }
}
