import { Pipe, PipeTransform } from '@angular/core';
import { Order } from 'src/app/models/interfaces/order';
/**
  declare search filter pipe
 *  
*/
@Pipe({
  name: 'searchFilter'
})

export class SearchFilterPipe implements PipeTransform {
/**
  declare transform
 *  
*/
  transform(Orders: Order[], searchValue: string): Order[] {
    let filterdOrders = [] ;
    if (!Orders || !searchValue) {
      return Orders;
    }
    for(let order of Orders){
      let id = "" +order.id ;
      let client_name = order.order_client_object ? order.order_client_object.name : '' ;
      let inspector_name = order.order_inspector ? order.order_inspector.full_name : '' ;
      let order_status_object = order.order_status_object ? order.order_status_object.name : '';
      let order_type_object = order.order_type_object ? order.order_type_object.name : '';
      if (id.includes(searchValue) || 
          order.reference_number.includes(searchValue) ||
          client_name.includes(searchValue) ||
          inspector_name.includes(searchValue)||
          order_status_object.includes(searchValue)||
          order_type_object.includes(searchValue)||
          order.created_at.includes(searchValue)){
            filterdOrders.push(order);
          }
    }
    return filterdOrders ;
  }

}
