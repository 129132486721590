import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceGlobalValueService } from 'src/app/core/services/service-engine/service-global-value/service-global-value.service';
/**
 * declare component metadata
 */
@Component({
  selector: 'app-service-offers-info',
  templateUrl: './service-offers-info.component.html',
  styleUrls: ['./service-offers-info.component.scss'],
})
export class ServiceOffersInfoComponent implements OnInit {
  /**
   * declare boolean for order confirmation
   */
  showModal: boolean = false;
  /**
   * declare quatation type variable
   */
  quatationType: string;
  /**
   * declare variable for order id
   */
  orderId: string;
  /**
   * declare providers array variable
   */
  providers: any;
  /**
   * declare variable for selected facility id
   */
  selectedFacility: any;

  /**
   * declare variable for quatations status
   */
  quatationStatus: string;
  /**
   * declare boolean for custom quatation button
   */
  customQutationButtonStatus: boolean = false;
  /**
   * declare component constructor
   */
  @Input() quotationData;
  @Input() orderStatus;
  constructor(
    private activatedRoute: ActivatedRoute,
    private globalServiceStatus: ServiceGlobalValueService
  ) {}
  /**
   * declare life cycle hook on initialize component
   */
  ngOnInit(): void {
    // get order id from router
    this.orderId = this.activatedRoute.snapshot.paramMap.get('id');
  }
  /**
   * Method for quatation modal
   */
  openQuatationModal(flag, selectedFacility): void {
    if (flag === 'q') {
      this.quatationType = 'single';
      this.selectedFacility = selectedFacility;
    } else if (flag === 'c-q') {
      this.quatationType = 'custom';
    }
    this.showModal = true;
  }
}
