<div class="order-inspection">
  <div class="table-responsive">
    <table id="inspection-log-table" class="table table-striped" aria-label="">
      <thead>
        <tr>
          <th scope="col">رقم العملية</th>
          <th scope="col">تاريخ العملية</th>
        </tr>
      </thead>
      <tbody>
        @for (order of orders?.order_inspection; track order) {
          <tr>
            <td>{{order?.reference_number}}</td>
            <td>{{order?.inspection_date}}</td>
          </tr>
        }

      </tbody>
    </table>
  </div>
</div>