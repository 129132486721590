export class ConstantEditor {
  public static BOOLEAN_STATUS_EDITOR = [
    {
      id: true,
      name: 'نعم',
    },
    {
      id: false,
      name: 'لا',
    },
  ];
  public static ACTIVE_STATUS_EDITOR = [
    {
      id: true,
      name: 'نشط',
    },
    {
      id: false,
      name: 'موقوف',
    },
  ];
  public static ISTEST_STATUS_EDITOR = [
    {
      id: true,
      name: 'تجريبي',
    },
    {
      id: false,
      name: 'عادي',
    },
  ];
}
export class ConstantRender {
  public static BOOLEAN_STATUS_RENDER = {
    active: "نعم",
    notActive: "لا"
  }
  public static ACTIVE_STATUS_RENDER = {
    active: "نشط",
    notActive: "موقوف"
  }
  public static ISTEST_STATUS_RENDER = {
    active: "تجريبي",
    notActive: "عادي"
  }
  public static INSPECTOR_STATUS = {
    active: "متاح",
    notActive: "غير متاح"
  }
}
