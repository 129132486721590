import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { PaymentService } from '../../services/payment/payment.service';
import { ErrorMessage } from 'src/app/shared/global/error-message';
import { Select, Store } from '@ngxs/store';
import { OrderState } from '../../state/order.state';
import { Observable } from 'rxjs';
import { OrderDetails } from '../../state/order-action';
@Component({
  selector: 'purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent implements OnInit {
  purchaseFormGroup: any;
  orderId: number;
  @ViewChild('selectcomp') selectcomp: NgSelectComponent;
  alertmsg: boolean = false;
  errorMessage: string = '';
  submitted: boolean = false;
  paymentMethods: any[] = [];
  confirmMsg: string;
  modelImg: boolean;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  button = 'تم';
  order: any;
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private dialog: MatDialog,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.orderId = this.route.snapshot.params.id;
    this.createForm();
    this.createFormControls();
    this.banksNames();
    this.getOrderById();
  }

  createForm() {
    this.purchaseFormGroup = this.formBuilder.group({
      clientNumInQoud: [null, Validators.required],
      paymentDate: [null, Validators.required],
      bankName: [null, Validators.required],
      bondNumber: [null, Validators.required],
      paymentAmount: [null, Validators.required],
      orderNumber: [null, Validators.required],
      purchaseInvoiceNumber: [null, Validators.required],
    });
  }

  createFormControls() {
    return this.purchaseFormGroup.controls;
  }

  getOrderById() {
    this.orderDetails$.subscribe(
      (res) => {
        if (res?.id) {
          this.applyOrderData(res);
        } else {
          this.store
            .dispatch(new OrderDetails({ orderId: +this.orderId }))
            .subscribe((res) => {});
        }
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  applyOrderData(order) {
    if (order.is_paid) {
      this.router.navigate(['pages/orders', order.id]);
    }
    this.order = order;
    this.purchaseFormGroup
      .get('orderNumber')
      .setValue(this.order?.reference_number);
    this.purchaseFormGroup
      .get('paymentAmount')
      .setValue(this.order?.due_payment_amount);
  }

  closeErrorMsg() {
    this.alertmsg = false;
  }

  isValidField(controlName) {
    if (this.purchaseFormGroup.get(controlName).invalid) {
      return true;
    }
    return false;
  }

  savePurchase() {
    if (this.purchaseFormGroup.status == 'INVALID') {
      this.alertmsg = true;
      this.errorMessage = ErrorMessage.REQUIRED_FIELDS_MSG;
      this.submitted = true;
    } else {
      this.submitted = false;
      this.paymentService
        .createPurchaseProcess(this.purchaseFormGroup.value, this.orderId)
        .subscribe(
          (data) => {
            this.confirmMsg = 'لقد تم حفظ التعديلات بنجاح.';
            this.modelImg = true;
            this.showAlertBox();
          },
          (error) => {
            try {
              this.alertmsg = true;
              this.errorMessage = Object.values(error.error)[0].toString();
            } catch {}
          }
        );
    }
  }

  banksNames() {
    this.paymentService.getBanksNames().subscribe((methods) => {
      this.paymentMethods = methods;
    });
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.confirmMsg,
        button: this.button,
      },
    });
  }

  handleBackendError(error) {
    try {
    } catch {}
  }
}
