<div class="row" [formGroup]="subForm" >
  <div class="col-md-6">
    <div class="form-group">
      <label for="" class="form-label"> حالة الفحص </label>
      <ng-select bindLabel="name" class="single-select"
        #selectInspector
        placeholder="  يرجى تحديد  حالة الفحص  "
        appendTo="body"
        formControlName="status"
        [searchable]="true"
        [clearable]="true">
        @for (item of status; track item) {
          <ng-option [value]="item.id" >
            {{item.name}}
          </ng-option>
        }
      </ng-select>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label for="" class="form-label">مزود الخدمة </label>
      <ng-select bindLabel="name" class="single-select"
        #selectStatus
        placeholder="  يرجى تحديد  مزود الخدمة "
        appendTo="body"
        formControlName="service_provider"
        [searchable]="true"
        [clearable]="true"
        bindValue="id"
        [readonly]="true"
        >
        @for (item of providers; track item) {
          <ng-option [value]="item.id" >
            {{item.name}}
          </ng-option>
        }
      </ng-select>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label for="" class="form-label"> تاريخ الفحص</label>
      <mat-form-field (click)="picker.open()" appearance="outline">
        <input matInput [min]="minDate" formControlName="inspection_date" [matDatepicker]="picker" disabled/>
        <mat-datepicker-toggle
          matSuffix
          [for]="picker"
          ><mat-icon matDatepickerToggleIcon class="calender-icon">
          <img src="assets/images/svg/calendar-admin-icon.svg" alt="">
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker [disabled]="disableDate"></mat-datepicker>
    </mat-form-field>
  </div>
</div>
<div class="col-md-6">
  <div class="form-group">
    <label for="" class="form-label"> فترة الفحص</label>
    <app-select
      [optionItems]="inspectionPeriods"
      [placeholder]="'يرجى تحديد الفتره'"
      [initValues] = "selectedPeriods"
      [id]="'order_periods'"
      (selectedOptions)="setOrderPeriods($event , true)">
    </app-select>
  </div>
</div>
<div class="col-md-6">
  <div class="notes-section">
    <label for="" class="form-label">ملاحظات العميل</label>
    <textarea
      class="form-control"
      formControlName="client_notes"
      name="notes"
      maxlength="500"
    ></textarea>
  </div>
</div>
</div>
