<div class="inner-page-header">
  <h3>اضافة جديدة</h3>
</div>
<div class="new-additions-wrapper">
  <form [formGroup]="additionsFormGroup">
    <div class="main-card">
      <div class="additions">
        <app-switch-input
          [label]="'حالة الإضافة'"
          [labelEn]="'is_active'"
          [labelDesc]="'(مفعل/غير مفعل)'"
          [index]="0"
          [value]="isActive"
          (valueChanged)="isActiveValueChanged($event)"
        >
        </app-switch-input>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label> اسم الإضافة</label>
          <input
            class="form-control"
            type="text"
            formControlName="name"
            placeholder="اسم الإضافة..."
          />
        </div>
        @if(choices && choices.length > 0) {
        <div class="col-md-6">
          <label> حالة إظهار الإضافة </label>
          <app-select
          [optionItems]="choices"
          [placeholder]="'يرجى تحديد حالة ظهور الإضافة '"
          [id]="'choices'"
          [initValues]="InitChoices"
          [maxLength]="2"
          (selectedOptions)="setAdditionChoices($event)"
          >
        </app-select>
        </div>
      }
      </div>
      <div class="field-main-wrapper">
        @for (item of fields.controls; track item; let i = $index) {
        <div class="field-wrapper" formArrayName="fields">
          <ng-container [formGroupName]="i">
            <div class="field-option">
              <label>اسم الحقل</label>
              <input
                type="text"
                class="form-control"
                formControlName="name"
                placeholder="ادخل اسم الحقل ..."
              />
            </div>
            <div class="field-option">
              <label>نوع الحقل </label>
              <ng-select
                bindLabel="name"
                formControlName="type"
                class="single-select"
                placeholder=""
                appendTo="body"
                [searchable]="true"
                [clearable]="true"
              >
                @for (field of fieldChoices; track field.id) {
                <ng-option [value]="field.id">
                  {{ field.text }}
                </ng-option>
                }
              </ng-select>
            </div>
            @if(fields.at(i).get('type').value == 4) {
            <div class="field-option">
              <label> الاختيارات</label>
              <ng-select
                [items]="options[i]"
                bindLabel="text"
                bindValue="id"
                [addTag]="addOptions"
                [multiple]="true"
                (add)="addOption($event, i)"
                formControlName="choices"
                (remove)="onRemove($event, i)"
                placeholder="ادخل الاختيارات..."
              >
              </ng-select>
       
            </div>
            }
            <div class="actions-wrapper">
              @if (i == 0) {
              <button class="btn btn-add" (click)="addField()">
                <img alt="" src="assets/images/svg/add-service.svg" />
              </button>
              } @else {
              <button class="btn btn-add" (click)="deleteItem(i)">
                <img alt="" src="assets/images/svg/DELETE1.svg" />
              </button>
              }
            </div>
          </ng-container>
        </div>
        }
      </div>
    </div>
    <div class="btn-wrapper">
      @if (errorMsg) {
        <div
          class="alert-box"
          >
          <img
            src="assets/images/svg/error-icon.svg"
            class="close-alert"
            (click)="closeError()"
            alt=""
            />
            <p class="alert-msg">{{ message }}</p>
          </div>
        }
      <div class="double-action">
        <button
          class="btn btn-green"
          id="saveBtn"
          type="submit"
          (click)="saveAdditions()"
        >
          حفظ
        </button>
      </div>
    </div>
  </form>
</div>
