<div  [formGroup]="subForm" class="form-group">
  <label for="" class="form-label">
    {{inputs.field_label_name}}
    @if (!isRequired) {
      <span class="optional">(اختياري)</span>
    }
  </label>
  <ng-select bindLabel="choice_display_value"
    class="single-select"
    #singleselect
    placeholder="تحديد"
    appendTo="body"
    formControlName="{{inputs.field_key_name}}"
    [searchable]="true"
    [clearable]="true"
    [items]="optionItems"
    readonly='{{!inputs.is_editable_admin}}'
    bindValue="choice_value" >
    @for (item of optionItems; track item) {
      <ng-option [value]="item.choice_value">{{item.choice_display_value}} </ng-option>
    }
  </ng-select>
</div>
