import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { OrderDetailsService } from '../../services/order-details/order-details.service';

@Component({
  selector: 'infrastructure',
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.scss'],
})
export class InfrastructureComponent implements OnInit {
  @Input() orderID;
  @Output() errorMsg = new EventEmitter();
  infrastructure$: Observable<any>;
  modelImg: boolean;
  button: string;
  confirmMsg: string;
  showSpinner: boolean;

  constructor(private orderService: OrderDetailsService) {}

  ngOnInit(): void {
    this.infrastructure$ = this.orderService
      .getInfrastrucutreAndServices(this.orderID)
      .pipe(map((res) => res.infrastructure_and_services));
  }

  itemValueChanged(list, event, index) {
    list[index].value = event.target.checked;
  }

  save(data) {
    let newData = { infrastructure_and_services: data };
    this.showSpinner = true;
    this.orderService
      .putInfrastrucutreAndServices(this.orderID, newData)
      .subscribe(
        (res) => {
          this.showSpinner = false;
          this.showConfirmationModal();
        },
        (error) => {
          try {
            this.showSpinner = false;
            console.log(error);
          } catch {}
        }
      );
  }

  showConfirmationModal() {
    this.errorMsg.emit({
      message: 'تم تحديث البيانات بنجاح.',
      imgSuccess: true,
    });
  }
}
