import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceGlobalValueService {
  /**
   *  declare quatation status behavior subject
   */
  private serviceQuatationsChangeStatus = new BehaviorSubject('not-changed');
  /**
   *  declare current quatation status variable
   */
  currentServiceQuatation = this.serviceQuatationsChangeStatus.asObservable();

  /**
   *  declare providers behavior subject
   */
  private providers = new BehaviorSubject([]);
  /**
   *  declare current providers variable
   */
  currentProviders = this.providers.asObservable();

  /**
   *  Method for change global quatation status
   */
  changeQuatationsStatus(updateQuatations: string) {
    this.serviceQuatationsChangeStatus.next(updateQuatations);
  }

  /**
   *  Method for change global providers
   */
  changeProviders(updateProviders: []) {
    this.providers.next(updateProviders);
  }
}
