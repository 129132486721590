export class ColorPaletas {
  public static  colorPalet80 = [
    '#4F3364',
    '#FFCB3C',
    '#FF916C',
    '#FD5E83',
    '#33D6A8',
    '#49B1F4',
    '#C081E9',
    '#A5AEB3',
    '#4A5157',
  ];
  public static  colorPalet40 = [
    '#99EBD3',
    '#A799B1',
    '#A5A8AB',
    '#A4D8F9',
    '#FEAFC1',
    '#FFE59D',
    '#FFC8B5',
    '#D2D7D9',
    '#DFC0F4',
  ];
  public static  colorPalet30 = [
    '#A799B1',
    '#FFE59D',
    '#99EBD3',
    '#FEAFC1',
    '#A4D8F9',
    '#FFC8B5',
    '#DFC0F4',
    '#D2D7D9',
    '#FF916C',
    '#33D6A8',
  ];

  public static historyColors = [
    '#FC3664',
    '#379B79'
  ]
}
