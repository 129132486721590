
<div class="form-group" [formGroup]="subForm">
  <label class="form-label">
    {{inputs.field_label_name}}
    @if (!isRequired) {
      <span class="optional">(اختياري)</span>
    }
  </label>
  <mat-form-field (click)="picker.open()" appearance="outline">
    <input matInput formControlName='{{inputs.field_key_name}}' [matDatepicker]="picker" disabled />
    <mat-datepicker-toggle
      matSuffix
      [for]="picker">
      <mat-icon matDatepickerToggleIcon >
      </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker disabled="false" ></mat-datepicker>
  </mat-form-field>
</div>
