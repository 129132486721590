import * as Highcharts from 'highcharts';
import { PieChartParams } from 'src/app/models/enums/charts.enum';

export class Charts {
  public static createBasicColumnChart(params): void {
    Highcharts.chart(params.id as any, {
      chart: {
        type: 'column',
        height: 400,
        spacing: [50, 0, 0, 0],
        style: {
          fontFamily: 'cairo-semibold',
        }
      },
      title: {
        text: null
      },
      xAxis: {
        reversed: true,
        categories: params.xAxisData,
        crosshair: {
          color: '#E9E5EC'
        },
      },
      yAxis: {
        min: 0,
        opposite: true,
        title: {
          text: params.xAxisName,
        }
      },
      tooltip: {
        valueSuffix: null
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          color: '#7B668B',
        },
      },
      series: [
        {
          name: params.yAxisName,
          data: params.yAxisData
        },
      ]
    } as any);
  }

  public static createStylesPie(params: PieChartParams) {
    Highcharts.chart(params.id as any, {
      chart: {
        type: 'pie',
        plotShadow: false,
        height: params.height,
      },
      title: {
        text: params.name,
        style: {
          fontFamily: 'cairo-semibold',
          fontSize: '14px'
        }
      },
      tooltip: {
        pointFormat: '{series.name} {point.y}',
        style: {
          fontFamily: 'cairo-semibold',
          fontSize: '14px'
        }
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: true,
          dataLabels: [{
            enabled: false,
            distance: 20
          }, {
            enabled: true,
            distance: -20,
            format: '{point.percentage:.1f}%',
            style: {
              fontSize: params.fontSize ?? '12px',
              textOutline: 'none',
              fontFamily: 'cairo-semibold',
            },
            filter: {
              operator: '>',
              property: 'percentage',
              value: 5
            }
          }],
        },
      },
      legend: params.legend ?? {},
      series: [
        {
          name: '',
          colorByPoint: true,
          data: params.series
        }
      ]
    } as any);

  }

  public static createDonoutChart(params): void {
    Highcharts.chart(params.id as any, {
      chart: {
        renderTo: params.id,
        type: 'pie',
        height: 100,
        spacing: 20,
      },
      title: {
        text: params.prcentage,
        align: 'center',
        verticalAlign: 'middle',

      },
      tooltip: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false
          },
          size: 80,
          innerSize: '80%',
          center: ['50%', '50%']
        }
      },
      series: [{
        data: [{
          name: 'count',
          color: params.color,
          y: params.data
        }, {
          name: 'No',
          color: '#DDE1E2',
          y: params.total - params.data
        }]
      }]
    } as any);
  }
  
  public static createlargeDonoutChart(params): void {
    Highcharts.chart(params.id as any, {
      chart: {
        height: 250,
        type: 'pie'
      },
      title: {
        text: params.name,
        style: {
          fontFamily: 'cairo-semibold',
          fontSize: '14px'
        }
      },
      tooltip: {
        pointFormat: 'طلب : {point.y}',
        style: {
          fontFamily: 'cairo-semibold',
          fontSize: '14px'
        }
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: true,
          dataLabels: [{
            enabled: false,
            distance: 15
          }, {
            enabled: true,
            distance: -15,
            format: '{point.percentage:.1f}%',
            style: {
              fontSize: params.fontSize ?? '10px',
              textOutline: 'none',
              fontFamily: 'cairo-semibold',
            },
            filter: {
              operator: '>',
              property: 'percentage',
              value: 5
            }
          }],
        },
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '{point.name} '
          },
          
          showInLegend: true
          
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout:'vertical',
      },
      series: [{
        name: 'Percentage',
        colorByPoint: true,
        innerSize: '60%',
        data: params.data
      }]
    } as any);
  }

  public static createSplineChart(params) {
    Highcharts.chart(params.id as any, {
      chart: {
        type: 'spline',
        height: params.height
      },
      title: {
        text: params.name,
        style: {
          fontFamily: 'cairo-semibold',
          fontSize: '14px'
        }
      },
      xAxis: {
        reversed: true,
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      yAxis: {
        opposite: true,
        title: {
          text: null
        },
        labels: {
          format: '{value}'
        }
      },
      tooltip: {
        crosshairs: true,
        shared: true
      },
      series: params.data
    } as any);
  }

}
