import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: []
})
export class StepperComponent {

 @Input() selectedView: string;

}
