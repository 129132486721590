<form>
  <div class="services-quatation">
    <div class="custom-control">
      <div class="form-group">
        <label for="" class="form-label">مزود الخدمة </label>
        @if (facilities.length > 0 ) {
          <div class="providers-wrapper">
            @for (facility of facilities; track facility) {
              <span>{{facility?.name}}</span>
            }
          </div>
        }
        <app-select
          [optionItems]="quatations"
          [disableSelect] = "orderStatus !== 'UP'"
          [placeholder]="'غير محدد'"
          [initValues]="selectedquatation"
          [id]="'service_quatation'"
          (selectedOptions)="setquatation($event)"
          [maxLength]="4"
          >
        </app-select>
      </div>
    </div>
    <div class="send-quatation">
      <button
        class="btn btn-green"
        (click)="sendSelectedQuatations()"
        [ngClass]="{
        disabled: selectedquatation?.length == 0 || orderStatus !== 'UP'
        }"
        >
        إرسال
      </button>
    </div>
  </div>
</form>
