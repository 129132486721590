import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, tap } from "rxjs/operators";
import { FiltersService } from "../../services/filters/filters.service";
import { changeFilterParam, SavedFilterList, SaveFilter, SelectFilter } from "./actions";

@State<any>({
  name: 'filterState',
})
@Injectable()
export class FilterState {

  @Selector()
  static getSavedFilters(state: any): any {
    return state.savedFilters;
  }

  @Selector()
  static getSelectedFilter(state: any): any {
    return state.selectedFilter;
  }

  @Selector()
  static getFilterParamsChanged(state: any): any {
    return state.filterParamsChanged;
  }

  constructor(private filterService: FiltersService) {}

  @Action(SaveFilter)
  saveFilter(ctx: StateContext<any>, action: SaveFilter) {
    return this.filterService.saveFilter(action.payload.param, action.payload.data)
    .pipe(
      tap((res) => {
        const filterList = ctx.getState().savedFilters;
        ctx.patchState({
          savedFilters: [...filterList, res],
          selectedFilter: res
        });        
      }), catchError((err) => {
        return err;
      })
    );
  }

  @Action(SelectFilter)
  selectFilter(ctx: StateContext<any>, action: SelectFilter) {
    return ctx.patchState({
      selectedFilter: action.payload
    });
  }

  @Action(SavedFilterList)
  getSavedFilterList(ctx: StateContext<any>, action:SavedFilterList) {
    return this.filterService.getSavedFilters(action.payload)
    .pipe(
      tap((res => {
        ctx.patchState({
          savedFilters: [...res]
        }); 
      })), catchError((err) => {
        return err;
      })
    );
  }

  @Action(changeFilterParam)
  changeFilterParam(ctx: StateContext<any>, action: changeFilterParam){
    return ctx.patchState({
      filterParamsChanged: action.payload.change
    })
  }
}
