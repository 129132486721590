import { OrderStatus } from "../models/enums/order-status.enum";

export class OrderStatusColors {
  public static getOrderStatusColor(status) {
    if (status === null) {
      return '#111934';
    } else if (status === OrderStatus.Not_Finished) {
      return '#707585';
    } else if (status === OrderStatus.New) {
      return '#0885C2';
    } else if (status === OrderStatus.Under_Inspectoring) {
      return '#FEB503';
    } else if (status === OrderStatus.Under_Audit) {
      return '#BCCB77';
    } else if (status === OrderStatus.Processing) {
      return '#B872E7';
    } else if (status === OrderStatus.Scheduled) {
      return '#FEB503';
    } else if ( status === OrderStatus.Delivered ) {
      return '#B872E7';
    } else if(status === OrderStatus.InspectorAssigned) {
      return '#FC4A73';
    }
    else if (status === OrderStatus.Completed) {
      return '#379B79';
    } else if (
      status === OrderStatus.Canceled ||
      status === OrderStatus.NotPaid
    ) {
      return '#D9727F';
    }
    else if (status === OrderStatus.Holding) {
      return '#FF8359';
    }
  }
}
