import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  addCategory(serviceId, data) {
    return this.http.post(
      `${this.baseURL}/admin/dynamic-services/${serviceId}/categories`,
      data
    );
  }

  updateCategory(categoryId, data): Observable<any> {
    return this.http.put(
      `${this.baseURL}/admin/dynamic-services/categories/${categoryId}`,
      data
    );
  }

  getCategoriesByService(serviceId) {
    const params = { page_size: 30 };
    return this.http.get(
      `${this.baseURL}/admin/dynamic-services/${serviceId}/categories`,
      { params: params }
    );
  }

  createCategoriesRatio(serviceId, data): Observable<any> {
    const requestBody = {
      categories: data
    }
    return this.http.post(`${this.baseURL}/admin/dynamic-services/${serviceId}/categories/bulk`, requestBody);
  }

  changeTermCategory(termId, data) {
    return this.http.patch(
      `${this.baseURL}/admin/dynamic-services/terms/${termId}`,
      data
    );
  }

  deleteCategory(categoryId) {
    return this.http.delete(
      `${this.baseURL}/admin/dynamic-services/categories/${categoryId}`
    );
  }
}
