import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
  selector: 'app-inspector-multiselect-render',
  templateUrl: './inspector-multiselect-render.component.html',
  styleUrls: ['./inspector-multiselect-render.component.scss'],
})
export class InspectorMultiselectRenderComponent
  implements ICellRendererAngularComp
{
  list: any[] = [{ pk: 1, name: '' }];

  values: [] = [];
  tooltipData: any;
  public params: any;
  public customerStatus: string[] = [];
  toolTip: any;

  agInit(params: any): void {
    this.params = params;
    this.list = params.values;
    this.setStatus(params);
  }

  getTooltipMsg() {
    this.tooltipData = this.customerStatus.slice(2);
    return this.tooltipData;
  }

  setStatus(params) {
    this.customerStatus = [];
    this.values = params.value;
    for (let item of this.list) {
      for (let value of this.values) {
        if ((item.pk || item.id) == value) this.customerStatus.push(item.name);
      }
    }
  }

  refresh(params: any) {
    this.setStatus(params);
    return true;
  }
}
