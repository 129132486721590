import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent {

  constructor(private router: Router) { }

/**
go back to page function
 *  
*/
goBack(): void {
  window.history.go(-2);
}
goHome(){
  this.router.navigate(['/pages/home']);
}
}
