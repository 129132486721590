<div class="inner-page-header">
  <h3>إحصائيات الطلبات</h3>
  @if (filterData) {
  <app-filter
    [filterData]="filterData"
    [filterDataInit]="filterDataInit"
    [filterPage]="filterPage"
    (formValus)="filterFormValues($event)"
    [enableSave]="false"
  >
  </app-filter>
  }
</div>

<div class="inner-content">
  <div class="history-sec">
    <div class="right-content">
      <div class="card-wrapper">
        @for (card of cardsData; track card.value; let i = $index) {
        <card-stats
          [card]="card"
          [valueDescription]="
            card.key === 'refunded_orders' ? valueDescription : null
          "
          class="filterCard-comp card-{{ i }}"
        ></card-stats>
        }
      </div>
      <div class="chart" id="orderHistory"></div>
    </div>
    <div class="left-content">
      <div class="pie-chart" id="orderStatus"></div>
      <div class="donut-chart" id="orderTypes"></div>
    </div>
  </div>
  <div class="map-sec">
    @if(regionMap) {
    <region-map
      class="region-map"
      [tooltipValue]="'count'"
      [tooltipTitle]="'عدد الطلبات'"
      [mapTitle]="'توزيع الطلبات'"
      [regionData]="regionMap"
    ></region-map>
    } @if(cities.length > 0) {
    <div class="cities">
      <div class="table-card">
        <div class="card-flex-header">
          <p class="card-title">المدن</p>
          <!-- <button class="btn btn-grey">
            <img [src]="imagePath.DOWNLOAD_ICON" />
          </button> -->
        </div>
        <div class="request-table">
          <div class="table-responsive">
            <table class="table table-striped" aria-label="">
              <thead>
                <tr>
                  <th class="width-60" id="">المدن</th>
                  <th class="width-40" id="">الطلبات</th>
                </tr>
              </thead>
              <tbody>
                @for (city of cities; track city.name) {
                <tr>
                  <td>
                    {{ city.name ? city.name : "غير معروف" }}
                  </td>
                  <td>{{ city.count }}</td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    }

    <div class="pie-chart2" id="buildingTypes"></div>
  </div>
</div>
