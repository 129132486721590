import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminOrdersService } from '../../services/orders/admin-orders.service';

@Component({
  selector: 'visit-summary',
  templateUrl: './visit-summary.component.html',
  styleUrls: ['./visit-summary.component.scss'],
})
export class VisitSummaryComponent implements OnInit {
  orderID: number;
  visitSummary: any;
  visitText$: Observable<any>;
  visitPhotos$: Observable<any>;

  constructor(
    private adminOrdersService: AdminOrdersService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.orderID = +this.activatedRoute.snapshot.params.id;
    let visitData$: Observable<any> = this.adminOrdersService.getVisitSummary(
      this.orderID
    );
    this.visitText$ = visitData$.pipe(map((data) => data.text));
    this.visitPhotos$ = visitData$.pipe(map((data) => data.photos));
    this.adminOrdersService.getVisitSummary(this.orderID).subscribe((data) => {
      this.visitSummary = data;
    });
  }
}
