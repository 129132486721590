<div class="order-title inner-page-header"><h2>الإعدادات</h2></div>

<div class="main-content" >
    <mat-tab-group [@.disabled]="true">
        <mat-tab label="المعلومات الشخصية"> </mat-tab>
        <mat-tab label="كلمة المرور">  </mat-tab>
        <mat-tab label="الإشعارات"> 
            <app-notifications></app-notifications>    
        </mat-tab>
    </mat-tab-group>
</div>
