<div class="dialog-container">
  <div class="main-content" mat-dialog-content>
    <div class="form-group">
      <label for="" class="form-label">{{ data.label }}</label>
      <input
        type="text"
        [(ngModel)]="data.value"
        class="form-control"
        [placeholder]="data.placeholder"
      />
    </div>
    <form [formGroup]="completionStatusForm">
      <div formArrayName="items">
        @for( item of status().controls; track item; let i = $index) {
        <div class="inputs-wrapper" [formGroupName]="i">
          <input
            type="text"
            class="form-control"
            formControlName="name"
            placeholder="يرجى كتابة الأسم  ..."
          />
          <input
            type="number"
            class="form-control"
            formControlName="value"
            placeholder="...يرجى كتابة القيمة"
          />
          @if(i == 0) {
          <button type="button" class="btn btn-add" (click)="addItem()">
            <img alt="" src="assets/images/svg/add-service.svg" />
          </button>
          } @else {
          <button type="button" class="btn btn-add" (click)="deleteItem(i)">
            <img alt="" src="assets/images/svg/DELETE1.svg" />
          </button>
          }
        </div>
        }
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="setup-footer actions-double">
    <button class="btn btn-cancel" type="button" mat-dialog-close>إلغاء</button>
    <button
      class="btn btn-green"
      [mat-dialog-close]="{
        name: data.value,
        choices: completionStatusForm.get('items').value
      }"
      type="submit"
    >
      موافق
    </button>
  </div>
</div>
