import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { InspectionService } from 'src/app/core/services/inspection/inspection.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { OrderType } from 'src/app/models/enums/order-type.enum';
import { BuildingTypeService } from 'src/app/core/services/buildingType/building-type.service';
import { BuildingType } from 'src/app/models/enums/building-type.enum';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';
import { AdminOrdersService } from '../../../services/orders/admin-orders.service';

@Component({
  selector: 'app-inspection-details',
  templateUrl: './inspection-details.component.html',
  styleUrls: ['./inspection-details.component.scss'],
})
export class InspectionDetailsComponent implements OnInit {
  @Output() inspector_view = new EventEmitter();
  @Input() orderId;
  stepper;
  orders: any;
  inspectionDetailsFormGroup: UntypedFormGroup;
  inspectionPeriods: any;
  alertmsg: boolean;
  formatedDate: any;
  inspectionPeriodsLength;
  @ViewChild('allSelected') allSelected;
  selectedPeriods = [];
  Loader: boolean = true;
  disableDate: boolean = false;
  minDate = new Date();
  disableSelect: boolean = false;
  dateLabel = ' تاريخ الفحص ';
  datePlaceholder = 'يرجى تحديد تاريخ الفحص ';
  orderType = OrderType;
  acadamicMajor: any;
  buildingStatus: any;
  buildingType = BuildingType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private InspectionService: InspectionService,
    private inspectorService: InspectorsService,
    private buildingTypeService: BuildingTypeService,
    private AdminOrders: AdminOrdersService
  ) {}

  ngOnInit(): void {
    this.orderId = this.route.snapshot.queryParams.id;
    this.createForm();
    this.createFormControls();
    this.getinspectionPeriods();
    if (this.orderId) {
      this.getOrderById();
    }
  }

  createFormControls() {
    return this.inspectionDetailsFormGroup.controls;
  }

  createForm() {
    this.inspectionDetailsFormGroup = this.formBuilder.group({
      orderDate: [null, Validators.required],
      is_inspection_later: [false],
      order_periods: [null, Validators.required],
      notes: [null],
      order_date: [null],
      inspector_academic_major: [null],
      building_status: [null],
    });
  }

  getOrderById() {
    this.AdminOrders.getAdminOrder(this.orderId).subscribe(
      (data) => {
        this.Loader = false;
        this.orders = data;
        if (this.orders.order_type === this.orderType.Visit) {
          this.getAcadamicMajor();
          this.getBuildingStatus();
        }
        this.selectedPeriods = this.orders.order_periods;
        this.inspectionDetailsFormGroup.patchValue({
          orderDate: moment(this.orders.order_date),
          is_inspection_later: this.orders.is_inspection_later,
          order_periods: this.orders.order_periods,
          notes: this.orders.notes,
          inspector_academic_major: this.orders.inspector_academic_major,
        });
        this.disableDate = this.orders.is_inspection_later;
        setTimeout(() => {
          this.updateDate();
        });
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  getinspectionPeriods() {
    this.InspectionService.getinspectionPeriods().subscribe(
      (data) => {
        this.inspectionPeriods = data;
        this.inspectionPeriodsLength = data.length;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  setOrderPeriods(event) {
    this.inspectionDetailsFormGroup.controls.order_periods.patchValue([
      ...event,
    ]);
  }

  updateOrder() {
    if (this.inspectionDetailsFormGroup.status == 'INVALID') {
      this.alertmsg = true;
      let fields = document.querySelectorAll(
        '.ng-invalid .mat-form-field-flex'
      );
      fields.forEach((field) => {
        field.classList.add('invalid-field');
      });
      return;
    }
    if (this.inspectionDetailsFormGroup.get('orderDate').value) {
      this.formatedDate = moment(
        this.inspectionDetailsFormGroup.get('orderDate').value
      );
      this.formatedDate = this.formatedDate.format('YYYY-MM-DD');
      this.inspectionDetailsFormGroup
        .get('order_date')
        .setValue(this.formatedDate);
    }
    this.AdminOrders.updateOrder(
      this.orderId,
      this.inspectionDetailsFormGroup.value
    ).subscribe(
      (data) => {
        this.inspector_view.emit({ view: 'summary', id: this.orderId });
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  handleBackendError(error) {
    try {
    } catch {}
  }

  prevStep() {
    this.inspector_view.emit({ view: 'building', id: this.orderId });
  }

  updateDate() {
    let items = document.querySelectorAll('.red');
    if (this.disableDate) {
      this.changeFormControlValidators('orderDate', null);
      this.selectedPeriods = [];
      this.changeFormControlValidators('order_periods', this.selectedPeriods);
      items.forEach((item) => {
        item.classList.add('remove-validation');
      });
    } else {
      this.inspectionDetailsFormGroup.controls.orderDate.setValue(
        moment(this.orders.order_date)
      );
      this.selectedPeriods = this.orders.order_periods;
      this.setOrderPeriods(this.orders.order_periods);
      items.forEach((item) => {
        item.classList.remove('remove-validation');
      });
    }
  }

  changeFormControlValidators(formControl, newVal) {
    this.inspectionDetailsFormGroup.controls[formControl].setValue(newVal);
    this.inspectionDetailsFormGroup.controls[formControl].clearValidators();
    this.inspectionDetailsFormGroup.controls[
      formControl
    ].updateValueAndValidity();
  }

  toggleDisableDate(event) {
    this.disableDate = event.target.checked;
    this.disableSelect = event.target.checked;
    this.updateDate();
  }

  hideErrorMsg() {
    this.alertmsg = false;
  }

  getAcadamicMajor() {
    this.inspectorService.getAcadamicMajor().subscribe((data) => {
      this.acadamicMajor = data;
    });
  }

  getBuildingStatus() {
    this.buildingTypeService.getBuildingStatus().subscribe((data) => {
      this.buildingStatus = data;
    });
  }
}
