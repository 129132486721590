import { ElementRef ,AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ICellEditorAngularComp } from "ag-grid-angular";
import {  ICellEditorParams } from "ag-grid-community";
import { Observable } from "rxjs";
import { map, startWith } from 'rxjs/operators';
import { LocationsService } from "src/app/core/services/locations/locations.service";
/**
  auto complete component
 *  
*/
@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements ICellEditorAngularComp ,AfterViewInit{
/**
declare container view child variable
 *  
*/
  @ViewChild('container', { read: ViewContainerRef }) public container;
/**
declare elementref view child variable
 *  
*/
  @ViewChild('autocomplete') public elementRef: ElementRef;
/**
declare my controls formcontrol variable
 *  
*/
  myControl = new UntypedFormControl();
/**
declare option variable
 *  
*/
  options;
/**
declare filtered option observable variable
 *  
*/
  filteredOptions: Observable<any[]>;
/**
declare selected option variable
 *  
*/
  selectedOption = {
    pk : 0 ,
    name : ''
  };
/**
declare constructor
 *  
*/
  constructor(private locations : LocationsService){

  }
/**
declare ngAfterViewInit function
 *  
*/
  ngAfterViewInit() {
    window.setTimeout(() => {
      this.elementRef.nativeElement.focus();
    });
  }
/**
declare aginit function
 *  
*/
  agInit(params: ICellEditorParams): void {   
    this.getOptions(params);
  }
/**
declare get value function
 *  
*/
  getValue() {
    return this.selectedOption.pk ;
  }
/**
declare function to return filter
 *  
*/ 
  _filter(name: string): string[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }
/**
declare pop up function
 *  
*/
  isPopup(): boolean { return false; }
/**
declare autocomplete changed function
 *  
*/
  _autoCompleteChanged(option) {
    this.selectedOption = option ;
  }
/**
declare get options function
 *  
*/
  getOptions(params){
    if(params.colDef.field == "nationality" || params.colDef.field == "region" || params.colDef.field=="category"){
      this.options = params.values;
      this.getFilterdData();
    }
    else if(params.colDef.field == 'city')
      this.getCities(params);
  }
/**
declare get filtered data function
 *  
*/
  getFilterdData(){
    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.options.slice())
    );
  }
/**
declare get cities function 
 *  
*/
  getCities(params){
    let region_id = params.data.region ;
    this.locations.getCoveredCities(region_id).subscribe(data =>{
      this.options = data ;
      this.getFilterdData();
    })
  }
}
