import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AYENServiceBase } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsService extends AYENServiceBase{

  constructor(private http: HttpClient) {
    super();
  }

  getCountries() : Observable<any>{
    return this.http.get(this.baseURL + '/address-locations/').pipe(
      map((data: any) => {
        return data;
      }), catchError(this.handleError)
    );
  }

  getRegionsByCountryPK(country_pk):Observable<any>{
    return this.http.get<any>(this.baseURL + '/regions/' + country_pk).pipe(
      map((data: any) => {
        return data;
      }), catchError(this.handleError)
    );
  }

  getCitiesByRegionPK(region_pk):Observable<any>{
    return this.http.get<any>(this.baseURL + '/cities/' + region_pk).pipe(
      map((data: any) => {
        return data;
      }), catchError(this.handleError)
    );
  }

  getCoveredCities(params ?:any):Observable<any>{
    let url = '';
    if (params){
      url = this.baseURL + '/all-cities/?region_id=' + params
    }
    else 
      url = this.baseURL + '/all-cities/';
    return this.http.get<any>(url).pipe(
      map((data: any) => {
        return data;
      }), catchError(this.handleError)
    );
  }

  handleError(error) {
    let errorMessage;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  getAllRegions() : Observable<any>{
    return this.http.get(this.baseURL + '/all-regions/').pipe(
      map((data: any) => {
        return data;
      }), catchError(this.handleError)
    );
  }
}
