<div class="purchase-wrapper">
  <div class="inner-page-header">
    <h3>دفع يدوي (بنكي)<span class="order-num"> طلب #{{order?.reference_number}}</span></h3>
  </div>
  <form id="purchase" class="needs-validation"  [formGroup]="purchaseFormGroup" (ngSubmit)="savePurchase()">
    <div class="main-card">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label">رقم الطلب</label>
            <input type="number" [ngClass]="{'required-error': submitted && isValidField('orderNumber')}"
              formControlName="orderNumber" class="form-control" readonly>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="" class="form-label"> رقم العميل في منصة قيود </label>
              <input type="text" formControlName="clientNumInQoud" class="form-control"
                [ngClass]="{'required-error': submitted && isValidField('clientNumInQoud')}">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="form-label">القيمة المدفوعة</label>
                <span class="static-code">ر.س</span>
                <input type=""  [ngClass]="{'required-error': submitted && isValidField('paymentAmount')}"
                  formControlName="paymentAmount" class="form-control" readonly>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="" class="form-label">تاريخ الدفع</label>
                  <mat-form-field class="payment-date" (click)="picker.open()" appearance="outline">
                    <input matInput  [ngClass]="{'required-error': submitted && isValidField('paymentDate')}"
                      formControlName="paymentDate" [matDatepicker]="picker" disabled/>
                      <mat-datepicker-toggle matSuffix [for]="picker">
                        <mat-icon matDatepickerToggleIcon class="calender-icon">
                          <img src="assets/images/svg/calendar-admin-icon.svg" alt="" />
                        </mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker disabled="false"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="" class="form-label">طريقة الدفع</label>
                    <ng-select formControlName="bankName" class="single-select"
                      [ngClass]="{'required-error': submitted && isValidField('bankName')}">
                      @for (method of paymentMethods; track method) {
                        <ng-option [value]="method?.id">
                          {{method?.name}}
                        </ng-option>
                      }
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="" class="form-label">رقم فاتورة الشراء</label>
                    <input type="text"  [ngClass]="{'required-error': submitted && isValidField('purchaseInvoiceNumber')}"
                      formControlName="purchaseInvoiceNumber" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="" class="form-label">رقم السند</label>
                      <input type="text" formControlName="bondNumber" class="form-control"
                        [ngClass]="{'required-error': submitted && isValidField('bondNumber')}" >
                      </div>
                    </div>
                  </div>
                  @if (alertmsg) {
                    <div class="alert-box">
                      <img src="assets/images/svg/error-icon.svg" class="close-alert"
                        (click)="closeErrorMsg()" alt="close-img"
                        />
                        <p class="alert-msg">{{ errorMessage }}</p>
                      </div>
                    }
                  </div>
                  <div class="double-action">
                    <a class="btn btn-cancel"  [routerLink]="['/pages/orders/', orderId]">
                      رجوع
                    </a>
                    <button class="btn btn-green" id="saveBtn" type="submit">
                      إضافة
                    </button>
                  </div>
                </form>
              </div>
