import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { NewServicesState } from '../../state/ayen-service.state';
import {
  AddAdditions,
  GetAdditionById,
  GetAdditionsFieldsChoices,
  GetAdditionsGeneralChoices,
  UpdateAdditions,
} from '../../state/service-action';
import { DynamicServices } from '../../services/dynamic-services/dynamic-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';

@Component({
  selector: 'app-dynamic-additions-details',
  templateUrl: './dynamic-additions-details.component.html',
  styleUrl: './dynamic-additions-details.component.scss',
})
export class DynamicAdditionsDetailsComponent implements OnInit {
  additionsFormGroup: FormGroup;
  isActive: boolean = true;
  @Select(NewServicesState.getAdditionById) additionById$: Observable<any>;
  @Select(NewServicesState.serverError) serverError$: Observable<string>;
  @Select(NewServicesState.getAdditionChoices)
  AdditionChoices$: Observable<any>;
  @Select(NewServicesState.getAdditionFieldsChoices)
  AdditionFieldChoices$: Observable<any>;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  choices: any;
  fieldChoices: any;
  AdditionId: any;
  additionDetails: any;
  options: any[] = [];
  successImg: boolean = true;
  confirmMsg: string = '';
  errorMsg: boolean = false;
  message: string;
  InitChoices: any[] = [];
  additionByIdSubscribtion: Subscription;

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.getAdditionChoices();
    this.getAdditionFieldChoices();
    if (this.router.url.includes('new')) {
      this.addField();
      this.confirmMsg = 'تم الإضافة بنجاح';
    } else if (this.router.url.includes('edit')) {
      this.AdditionId = this.activatedRoute.snapshot.params.id;
      this.getAdditionById();
      this.confirmMsg = 'تم التعديل بنجاح';
    }
  }
  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private serviceApi: DynamicServices,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  createFormControls() {
    return this.additionsFormGroup.controls;
  }

  createForm() {
    this.additionsFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      is_active: [true],
      choices: [[], Validators.required],
      fields: this.formBuilder.array([]),
    });
  }

  addField() {
    const item = this.formBuilder.group({
      name: ['', Validators.required],
      id: [null],
      type: [null, Validators.required],
      choices: [[]],
    });
    this.fields.push(item);
    this.options.push([]);
  }

  get fields() {
    return this.additionsFormGroup.get('fields') as FormArray;
  }

  deleteItem(index) {
    this.fields.removeAt(index);
  }

  isActiveValueChanged(event) {
    this.additionsFormGroup.get(event.labelEn).setValue(event.value);
  }

  getAdditionChoices() {
    this.AdditionChoices$.subscribe(
      (data) => {
        if (data && data['results']) {
          this.choices = data.results;
        } else {
          this.store.dispatch(new GetAdditionsGeneralChoices());
        }
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  getAdditionFieldChoices() {
    this.AdditionFieldChoices$.subscribe(
      (data) => {
        if (data && data['results']) {
          this.fieldChoices = data.results;
        } else {
          this.store.dispatch(new GetAdditionsFieldsChoices());
        }
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  saveAdditions() {
    if (this.additionsFormGroup.valid) {
      this.fields.value.forEach((field, index) => {
        field.choices = this.options[index] ? [...this.options[index]] : [];
      });
      if (this.AdditionId) {
     this.store
          .dispatch(
            new UpdateAdditions(this.AdditionId, this.additionsFormGroup.value)
          )
          .subscribe(
            (data) => {
              this.successImg = true;
              this.showAlertBox();
            },
            (error) => {
              try {
                this.successImg = false;
                this.confirmMsg = error.error;
                this.showAlertBox();
              } catch (error) {}
            }
          );
      } else {
        this.additionByIdSubscribtion =  this.store
          .dispatch(new AddAdditions(this.additionsFormGroup.value))
          .subscribe(
            (data) => {
              this.successImg = true;
              this.showAlertBox();
            },
            (error) => {
              try {
                this.successImg = false;
                this.confirmMsg = error.error;
                this.showAlertBox();
              } catch (error) {}
            }
          );
      }
    } else {
      this.errorMsg = true;
      this.message = 'برجاء تعبئة  جميع الخانات ';
    }
  }

  ngOnDestroy(): void {
    this.additionByIdSubscribtion.unsubscribe();
  }
  getAdditionById() {
    this.additionByIdSubscribtion = this.additionById$.subscribe(
      (data) => {
        if (data?.id && data?.id == +this.AdditionId) {
          let fieldsArr = <FormArray>this.additionsFormGroup.controls['fields'];
          fieldsArr.clear();
          this.additionDetails = data;
          this.InitChoices = this.additionDetails.choices;
          this.isActive = this.additionDetails.is_active;
          this.additionsFormGroup.patchValue({
            name: this.additionDetails.name,
            is_active: this.additionDetails.is_active,
            choices: this.additionDetails.choices,
          });
          this.additionDetails.fields.forEach((element, index) => {
            this.addField();
            this.options[index] = element.choices;
            const selectedOptions = this.options[index].map((opt) => opt.id);
            this.fields.at(index).get('name').setValue(element.name);
            this.fields.at(index).get('type').setValue(element.type);
            this.fields.at(index).get('id').setValue(element.id);
            this.fields
              .at(index)
              .get('choices')
              .setValue([...selectedOptions]);
          });
        } else {
          this.store.dispatch(new GetAdditionById(this.AdditionId));
        }
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }
  addOptions = (option) => ({ id: null, text: option });

  onRemove(option, index) {
    this.options[index] = this.options[index].filter(
      (item) => item.id !== option.id
    );
  }

  addOption(option, index) {
    this.options[index].push(option);
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.successImg,
        message: this.confirmMsg,
        button: 'تم',
      },
    });
  }

  setAdditionChoices(event) {
    if (event) {
      this.additionsFormGroup.controls.choices.patchValue([...event]);
    }
  }

  closeError() {
    this.errorMsg = false;
  }
}
