<div class="new-element">
  <button class="btn btn-add" (click)="newOrder()">
    <img src="assets/images/svg/add-icon.svg" alt="">
    إنشاء طلب
  </button>
  <button class="btn btn-add" (click)="amount()">
    <img src="assets/images/svg/add-icon.svg" alt="">
    إنشاء خدمة إضافية 
  </button>
</div>
