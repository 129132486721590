<div class="inner-page-header">
  <h3>احصائيات الفاحصين</h3>
</div>
<div class="inner-content">
  @if (selectedRegionName) {
  <div class="main-card">
    <div class="filter-map">
      <p>
        تم تصفيه الاحصائيات حسب <span> منطقة {{ selectedRegionName }} </span
        ><span>, {{ selectedCardName }}</span>
      </p>
      <a class="btn-green-link" (click)="resetFilter()"> إلغاء التصفية</a>
    </div>
  </div>
  }
  <div class="mini-cards-Wrapper">
    @for (card of inspectorCardsState; track card; let i = $index) {
    <card-filter
      [card]="card"
      class="filterCard-comp"
      [cardId]="'donutChart' + i"
      (cardSelected)="cardFilter($event)"
    ></card-filter>
    }
  </div>
  <div class="charts-wrapper">
    <div class="map-chart">
      @if (regionData) {
        <region-map
          #regionChild
          [mapTitle]="'توزيع الفاحصين'"
          [tooltipTitle] = "'المتاحين'"
          [regionData]="regionData"
          [tooltipValue]="'available_count'"
          (selectedRegion)="getSelectedRegion($event)"
        ></region-map>
      }
    </div>
    <div class="bar-chart">
      <div class="chart" id="ExpertiseBasicColumn"></div>
    </div>
  </div>

  <div class="box-container">
    <div class="stat-container row d-flex">
      @if (inspectorsStats && inspectorsStats.inspectors_by_city.length > 0) {
      <div class="col-4">
        <div class="table-card">
          <div class="card-flex-header">
            <p class="card-title">المدن</p>
            <button
              class="btn btn-grey"
              (click)="exportDataXLSX(inspectorsStats.inspectors_by_city)"
            >
              <img [src]="imagePath.DOWNLOAD_ICON" />
            </button>
          </div>
          <div class="request-table">
            <div class="table-responsive">
              <table class="table table-striped" aria-label="">
                <thead>
                  <tr>
                    <th class="width-60" id="">المدن</th>
                    <th class="width-40" id="">عدد الفاحصين</th>
                  </tr>
                </thead>
                <tbody>
                  @for (city of inspectorsStats.inspectors_by_city; track city)
                  {
                  <tr>
                    <td>
                      {{
                        city.city__city_name
                          ? city.city__city_name
                          : "غير معروف"
                      }}
                    </td>
                    <td>{{ city.count }}</td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      } @if ( inspectorsStats &&
      inspectorsStats.inspectors_by_nationality.length > 0 ) {
      <div class="col-4">
        <div class="table-card">
          <div class="card-flex-header">
            <p class="card-title">الجنسيات</p>
            <button
              class="btn btn-grey"
              (click)="
                exportDataXLSX(inspectorsStats.inspectors_by_nationality)
              "
            >
              <img [src]="imagePath.DOWNLOAD_ICON" />
            </button>
          </div>
          <div class="request-table">
            <div class="table-responsive">
              <table class="table table-striped" aria-label="">
                <thead>
                  <tr>
                    <th class="width-60" id="">الجنسية</th>
                    <th class="width-40" id="">عدد الفاحصين</th>
                  </tr>
                </thead>
                <tbody>
                  @if ( inspectorsStats.inspectors_by_nationality.length > 0) {
                  @for ( nationalityItem of
                  inspectorsStats.inspectors_by_nationality ; track
                  nationalityItem) {
                  <tr>
                    <td>
                      {{
                        nationalityItem.nationality__name
                          ? nationalityItem.nationality__name
                          : "غير معروف"
                      }}
                    </td>
                    <td>{{ nationalityItem.count }}</td>
                  </tr>
                  } } @else {
                  <tr>
                    <td colspan="4">لا يوجد فاحصين فى هذه المنطقة</td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      }
      <div class="col-4">
        <div class="chart" id="MajorsPie"></div>
      </div>
    </div>
  </div>
</div>
