import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class NotesRecommendationsService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getSeverityRecommendations(): Observable<any> {
    return this.http
      .get(this.baseURL + `/admin/severities-and-cards/`)
      .pipe(shareReplay());
  }

  getNotesRecommendations(orderID): Observable<any> {
    return this.http.get(
      this.baseURL + `/admin/order-notes-recommendations/retrieve/${orderID}`
    );
  }

  postNotesRecommendations(orderID, data): Observable<any> {
    return this.http.post(
      this.baseURL + `/admin/order-notes-recommendations/create/${orderID}`,
      data
    );
  }

  deleteNoteRecommendation(noteID): Observable<any> {
    return this.http.delete(
      this.baseURL + `/admin/order-notes-recommendations/delete/${noteID}`
    );
  }
}
