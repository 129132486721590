<div #container class="mood" tabindex="0" (keydown)="onKeyDown($event)">
    <div
      (click)="onClick(true)"
      class="active"
      [ngClass]="{'test' : is_test == true}"
    >{{statusTrue}}</div>
    <div
      (click)="onClick(false)"
      class="not-active"
      [ngClass]="{'not-test' : is_test == true}"
    > {{statusFalse}}</div>
  </div>
