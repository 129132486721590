export class GetNewServiceList {
  static readonly type = '[NewServicesState] GetNewServiceList';
  constructor(
    public payload: {
      page: number;
      query?: string;
      page_size?: number;
    }
  ) {}
}
export class GetAdditionsList {
  static readonly type = '[AdditionsList] GetAdditionsList';
  constructor(
    public payload: {
      page: number;
      query?: string;
      page_size?: number;
    }
  ) {}
}
export class GetAdditionsGeneralChoices {
  static readonly type = '[AdditionsGeneralChoices] GetAdditionsGeneralChoices';
}
export class GetAdditionsFieldsChoices {
  static readonly type = '[AdditionsFieldsChoices] GetAdditionsFieldsChoices';
}

export class AddService {
  static readonly type = '[NewServicesState] AddService';
  constructor(
    public payload: {
      name: string;
      is_active: boolean;
      show_terms: string;
      completion_status: number;
      building_data_to_show: string[];
    }
  ) {}
}
export class UpdateService {
  static readonly type = '[NewServicesState] UpdateService';
  constructor(
    public serviceId: number,
    public payload: {
      name: string;
      is_active: boolean;
      show_terms: string;
      completion_status: number;
      building_data_to_show: string[];
    }
  ) {}
}
export class addServiceSetting {
  static readonly type = '[NewServicesState] addServiceSetting';
  constructor(
    public serviceId: number,
    public payload: {}
  ) {}
}
export class DeleteService {
  static readonly type = '[NewServicesState] DeleteService';
  constructor(public id: number) {}
}
export class GetServiceById {
  static readonly type = '[NewServicesState] GetServiceById';
  constructor(public id: number) {}
}
export class GetAdditionById {
  static readonly type = '[AdditionByIdState] GetAdditionById';
  constructor(public id: number) {}
}

export class AddNewCategory {
  static readonly type = '[NewServicesState] AddNewCategory';
  constructor(
    public id: number,
    public categoryData: {
      name: string;
      service: number;
      terms: any[];
    }
  ) {}
}

export class UpdateCategory {
  static readonly type = '[NewServicesState] UpdateCategory';
  constructor(
    public categoryId: number,
    public categoryData: any,
    public index: number
  ) {}
}

export class DeleteCategory {
  static readonly type = '[NewServicesState] DeleteCategory';
  constructor(public id: number) {}
}
export class ChangeTermCategory {
  static readonly type = '[NewServicesState] ChangeTermCategory';
  constructor(public termId: number, public category: any) {}
}

export class ChangeCategoriesRatio {
  static readonly type = '[NewServicesState] ChangeCategoriesRatio';
  constructor(public serviceId: number, public categories: any) {}
}

export class RedistrbuteRatios {
  static readonly type = '[NewServicesState] RedistrbuteRatios';
  constructor(public serviceId: number) {}
}
export class AddAdditions {
  static readonly type = '[NewAdditionsState] AddAdditions';
  constructor(
    public payload: {
      name: string;
      is_active: boolean;
      choices: any[];
      fields: any;
    }
  ) {}
}
export class UpdateAdditionFields {
  static readonly type = '[NewAdditionState] UpdateAdditionFields';
  constructor(
    public id: number,
    public name: any,
    public type: number
  ) {}
}
export class UpdateAdditions{
  static readonly type = '[NewAdditionState] UpdateAdditions';
  constructor(
    public additionId: number,
    public payload: {
      name: string;
      is_active: boolean;
      choices: any[];
      fields: any[];
    }
  ) {}
}

export class DeleteAddition {
  static readonly type = '[NewAdditionState] DeleteAddition';
  constructor(public id: number) {}
}

export class HandleError {
  static readonly type = '[NewServicesState] HandleError';
  constructor(public error: any) {}
}
