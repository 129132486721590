import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-completion-status',
  templateUrl: './completion-status.component.html',
  styleUrl: './completion-status.component.scss',
})
export class CompletionStatusComponent implements OnInit {
  completionStatusForm: UntypedFormGroup;
  items: FormArray;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CompletionStatusComponent>
  ) {}

  ngOnInit() {
    this.completionStatusForm = new FormGroup({
      items: new FormArray([]),
    });
    this.addItem();
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      id: null,
      name: '',
      value: '',
    });
  }

  status(): FormArray {
    return this.completionStatusForm.get('items') as FormArray;
  }

  addItem(): void {
    this.status().push(this.createItem());
  }

  deleteItem(index) {
    this.status().removeAt(index);
  }
}
