import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { SaveFilter } from 'src/app/core/state/filter-state/actions';
import { AlertBoxComponent } from '../alert-box/alert-box.component';

@Component({
  selector: 'save-filter',
  templateUrl: './save-filter.component.html',
  styleUrls: ['./save-filter.component.scss']
})
export class SaveFilterComponent implements OnInit {
  saveFilterFormGroup: UntypedFormGroup;
  MSG = 'تم حفظ التصفية بنجاح.';
  modelImg = true;
  button: string = 'تم';
  saveDialogRef: MatDialogRef<AlertBoxComponent>;

  constructor(private formBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<SaveFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store) { }

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
  }
  
  createFormControls() {
    return this.saveFilterFormGroup.controls;
  }
  
  createForm() {
    this.saveFilterFormGroup = this.formBuilder.group({
      filterName: [null, Validators.required]
    });
  }

  saveFilter() {
    const payload = {
      param: {
        filter_page: this.data.filterPage,
      },
      data: {
        title: this.saveFilterFormGroup.controls['filterName'].value,
        query: this.data.filterData
      }
    }
    this.store.dispatch(new SaveFilter(payload)).subscribe( filterState => {
      this.showAlertBox();
      this.saveFilterFormGroup.reset();
      this.dialogRef.close();
    });
  }

  showAlertBox() {
    this.saveDialogRef = this.matDialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.MSG,
        button: 'تم'
      },
    });
  }
}
