import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ayen-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Output() pageData = new EventEmitter();
  @Output() pageSize = new EventEmitter();
  @Input() totalCount = 0;
  @Input() id = 'myPagination';
  @Input() pageTitle = '';
  pageSizeValues: number[] =[20,25,50,75,100]
  selectedItem: number = this.pageSizeValues[0];

  pageChanged(event) {
    this.pageData.emit(event)
  }

  pageSizeChanged(event) {
   this.pageSize.emit(event);
  }
}
