import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { ClientsService } from '../../services/clients.service';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss'],
})
export class CustomerProfileComponent implements OnInit {
  customerProfileForm: UntypedFormGroup;
  panelOpenState = false;
  is_Email: boolean = false;
  is_SMS: boolean = false;
  customerID: any;
  customerData: any;
  alertmsg: boolean = false;
  verifications = [
    {
      id: true,
      name: 'مفعل',
    },
    {
      id: false,
      name: 'غير مفعل',
    },
  ];
  errorMessage: string = '';
  backUrl: string = '/pages/customers/customers-grid';
  saveDialogRef: MatDialogRef<AlertBoxComponent>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private clientsService: ClientsService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.customerID = this.activatedRoute.snapshot.paramMap.get('id');
    this.getCustomerData();
  }

  createForm() {
    this.customerProfileForm = this.formBuilder.group({
      first_name: [this.customerData.first_name, Validators.required],
      last_name: [this.customerData.last_name, Validators.required],
      mobile: [this.customerData.mobile, Validators.required],
      email: [this.customerData.email, Validators.required],
      email_status: [this.customerData.is_email_verified, Validators.required],
      account_status: [this.customerData.is_active, Validators.required],
      can_receive_phone_notifications: [
        this.customerData.can_receive_phone_notifications,
        Validators.required,
      ],
      can_receive_email_notifications: [
        this.customerData.can_receive_email_notifications,
        Validators.required,
      ],
    });
  }

  getCustomerData() {
    this.clientsService.getClientByID(this.customerID).subscribe(
      (data) => {
        this.customerData = data;
        this.is_SMS = this.customerData.can_receive_phone_notifications;
        this.is_Email = this.customerData.can_receive_email_notifications;
        this.createForm();
        this.createFormControls();
      },
      (error) => {
        try {
          console.log(error);
        } catch {
          console.log(error);
        }
      }
    );
  }

  createFormControls() {
    return this.customerProfileForm.controls;
  }

  switchBtnChanged(event) {
    this.customerProfileForm.get(event.labelEn).setValue(event.value);
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: true,
        message: 'تم حفظ التعديلات بنجاح',
        backUrl: this.backUrl,
        button: 'رجوع',
      },
    });
  }

  save() {
    const data = this.customerProfileForm.value;
    this.clientsService.updateClientById(this.customerID, data).subscribe(
      () => {
        this.showAlertBox();
      },
      (error) => {
        try {
          this.alertmsg = true;
          this.errorMessage = error.error;
        } catch {}
      }
    );
  }

  closeError() {
    this.alertmsg = false;
  }

  back() {
    this.router.navigateByUrl(this.backUrl);
  }
}
