import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-customer-rating-editor',
  templateUrl: './customer-rating-editor.component.html',
  styleUrls: ['./customer-rating-editor.component.scss']
})
export class CustomerRatingEditorComponent implements ICellEditorAngularComp {
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number = 0;
  params: any;
  starSrcBorder = "../../../../../assets/images/svg/star-border-icon.svg";
  starSrc = "../../../../../assets/images/svg/star-icon.svg";

  agInit(params: any): void {
    this.params = params;
    this.selectedValue = params.value;
  }

  getValue() {
    return this.selectedValue;
  }

  countStar(star) {
    this.selectedValue = star;
    this.params.api.stopEditing();
  }

}
