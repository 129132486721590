import { Component } from '@angular/core';
/**
 customer parent component
 *  
*/
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: []
})
export class CustomersComponent {

}
