import { Injectable } from "@angular/core";

import { ClientsService } from "../services/clients.service";
import { Observable } from "rxjs";

@Injectable()
export class ClientFinalStatusResolver  {
  constructor(
    private clientSerive: ClientsService
  ) {}

  resolve(): Observable<any> {
    return this.clientSerive.getFinalStatus();
  }
}
