import { CustomHeaderComponent } from "src/app/shared/components/ag-grid/custom-header/custom-header.component";
import { RegisterAsComponent } from 'src/app/shared/components/ag-grid/register-as/register-as.component';
import { StatusEditorComponent } from 'src/app/shared/components/ag-grid/status-editor/status-editor.component';
import { StatusComponent } from 'src/app/shared/components/ag-grid/status/status.component';
export class CustomerGridColumns {
  public columns = [
    {
      headerName: " ",
      field: 'id',
      hide: true,
      resizable: false,
    },
    {
      headerName: " ",
      field: 'check_box',
      editable: false,
      resizable: false,
      checkboxSelection: true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      cellClass: "checkbox"
    },
    {
      headerName: "الاسم الأول",
      field: "first_name",
      width: 80,
      minWidth: 80,
      maxWidth: 120,
      editable: true,
      cellClass: 'my-class'
    },
    {
      headerName: 'الاسم الأخير',
      field: 'last_name',
      width: 80,
      minWidth: 80,
      maxWidth: 120,
      editable: true,
    },
    {
      headerName: 'البريد الإلكتروني',
      field: 'email',
      editable: true,
      width: 220,
      suppressSizeToFit: true,
    },
    {
      headerName: 'حالة البريد',
      field: 'is_email_verified',
      resizable: true,
      singleClickEdit: true,
      cellRenderer: 'customerStatusComponent',
      cellStyle: this.statusCellStyle,
      cellEditor: 'registerAsComponent',
      width: 120,
      minWidth: 120,
      maxWidth: 140,
      cellRendererParams: {
        active: "مفعل",
        notActive: "غير مفعل"
      },
      cellEditorParams: {
        type: "statusEditor",
        values: [{
          id: true,
          name: "مفعل"
        },
        {
          id: false,
          name: "غير مفعل"
        }],
      }
    },
    {
      headerName: ' نوع الحساب ',
      field: 'is_test',
      width: 80,
      singleClickEdit: true,
      editable: false,
      minWidth: 80,
      maxWidth: 120,
      cellRenderer: 'customerStatusComponent',
      cellStyle: function (params) {
        if (params.value) {
          return { color: '#111934', backgroundColor: '#FFCF59', textAlign: 'center', fontSize: '14px', fontFamily: 'cairo-regular' };
        }
        return { color: '#ffffff', backgroundColor: '#379B79', textAlign: 'center', fontSize: '14px', fontFamily: 'cairo-regular' };
      },
      cellEditor: 'statusEditor',
      cellRendererParams: {
        active: "تجريبي",
        notActive: "عادي"
      },
      cellEditorParams: {
        active: "تجريبي",
        notActive: "عادي",
        isTest: true
      }
    },
    {
      headerName: ' حالة الحساب ',
      field: 'is_active',
      width: 120,
      minWidth: 120,
      maxWidth: 140,
      resizable: true,
      singleClickEdit: true,
      cellRenderer: 'customerStatusComponent',
      cellStyle: function (params) {
        if (params.value) {
          return { color: '#ffffff', backgroundColor: '#379B79', textAlign: 'center', fontSize: '14px', fontFamily: 'cairo-regular' };
        }
        return { color: '#ffffff', backgroundColor: '#D9727F', textAlign: 'center', fontSize: '14px', fontFamily: 'cairo-regular' };
      },
      cellEditor: 'registerAsComponent',
      cellRendererParams: {
        active: "مفعل",
        notActive: "موقوف"
      },
      cellEditorParams: {
        type: "statusEditor",
        values: [{
          id: true,
          name: "مفعل"
        },
        {
          id: false,
          name: "غير مفعل"
        }],
      }
    },
    {
      headerName: ' رقم الجوال ',
      field: 'mobile',
    },
    {
      headerName: 'حالة الجوال',
      field: 'is_phone_verified',
      width: 120,
      minWidth: 120,
      maxWidth: 140,
      resizable: true,
      singleClickEdit: true,
      cellRenderer: 'customerStatusComponent',
      cellStyle: this.statusCellStyle,
      cellEditor: 'registerAsComponent',
      cellRendererParams: {
        active: "مفعل",
        notActive: "غير مفعل"
      },
      cellEditorParams: {
        type: "statusEditor",
        values: [{
          id: true,
          name: "مفعل"
        },
        {
          id: false,
          name: "غير مفعل"
        }],
      }
    },
    {
      headerName: 'عبر الحملة',
      field: 'is_leads',
      width: 80,
      minWidth: 100,
      maxWidth: 120,
      editable: false,
      cellRenderer: 'customerStatusComponent',
      cellStyle: this.statusCellStyle,
      cellRendererParams: {
        active: "مفعل",
        notActive: "غير مفعل"
      }
    },
    {
      headerName: ' عدد الطلبات ',
      field: 'orders_count',
      width: 50,
      minWidth: 50,
      maxWidth: 120,
      editable: false
    },
    {
      headerName: 'تاريخ التسجيل ',
      field: 'date_joined',
      editable: false,
      width: 120,
      minWidth: 120,
      maxWidth: 180,
      headerComponent: 'customHeader'
    },
    {
      headerName: ' آخر دخول ',
      field: 'last_login',
      editable: false,
      width: 120,
      minWidth: 120,
      maxWidth: 180,
      headerComponent: 'customHeader'
    },
  ];

  public frameworkComponents = {
    customerStatusComponent: StatusComponent,
    statusEditor: StatusEditorComponent,
    registerAsComponent: RegisterAsComponent,
    customHeader: CustomHeaderComponent
  };

  statusCellStyle(params) {
    if (params.value) {
      return {
        color: '#ffffff', backgroundColor: '#379B79', textAlign: 'center', fontSize: '14px', fontFamily: 'cairo-regular',
        borderLeft: '2px solid var(--ag-row-border-color, var(--ag-secondary-border-color, #dde2eb))'
      };
    }
    return {
      color: '#ffffff', backgroundColor: '#D9727F', textAlign: 'center', fontSize: '14px', fontFamily: 'cairo-regular',
      borderLeft: '2px solid var(--ag-row-border-color, var(--ag-secondary-border-color, #dde2eb))'
    };
  }
}
