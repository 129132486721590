import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ServicesOrdersService } from 'src/app/core/services/servicesOrders/services-orders.service';
import { Element } from 'src/app/models/interfaces/serviceEngine/element.model';
import { ImgViewerComponent } from '../../img-viewer/img-viewer.component';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {
  @Input() inputs: Element;
  @Input() subForm: UntypedFormGroup;
  @Input() orderId: number;
  progress: number = 0;
  fileName: string[] = [];
  successUpload: boolean = false;
  displayUpload: boolean = true;
  allData: any = [];
  fileSize: any;
  errorMessage: string = '';
  alertmsg: boolean = false;
  isRequired: boolean = false;
  imgViewerDialogRef: MatDialogRef<ImgViewerComponent>;

  constructor(private ServiceEngine: ServicesOrdersService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.isRequired = this.inputs.validation.required;
  }

  uploadFile(fileType, event) {
    let files = [];
    this.fileName = [];
    files = [].slice.call(event.target.files);
    let totalLength = files.length + (this.inputs.field_value?.length ? this.inputs.field_value.length : 0);
    if (totalLength <= 10) {
      files.forEach(file => {
        this.fileName.push(file.name);
        this.getFileSize(file);
        if (file.size < 5000000 && file.type.includes("image")) {
          this.alertmsg = false;
        }
        else {
          this.handleFileTypesError(file);
          this.alertmsg = true;
        }
      });
    }
    else {
      this.errorMessage = "عفوا لا يمكن رفع اكثر من 10 صور ";
      this.alertmsg = true;
    }
    if (!this.alertmsg) {
      this.ServiceEngine.addFiles(this.orderId, fileType, files).subscribe((eventRes: HttpEvent<any>) => {
        this.detectUploadStatus(eventRes);
      }, (error) => {
        this.handleBackendError(error);
      });
    }
  }

  handleFileTypesError(file) {
    if (!file.type.includes("image")) {
      this.errorMessage = "عذراً، لا يمكن رفع ملفات إلا ضمن أحدى اللواحق التالية JPG,JPEG, JPG, PNG";
    }
    else {
      this.errorMessage = "عفوا لا يمكن رفع صور حجم اكبر من 5GB ";
    }
  }
  getFileSize(file) {
    this.fileSize = file.size;
    let fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
    let i = 0; while (this.fileSize > 900) { this.fileSize /= 1024; i++; }
    this.fileSize = (Math.round(this.fileSize * 100) / 100) + ' ' + fSExt[i];
  }

  detectUploadStatus(eventRes) {
    switch (eventRes.type) {
      case HttpEventType.Sent:
        this.successUpload = true;
        this.displayUpload = false
        break;
      case HttpEventType.ResponseHeader:
        this.displayUpload = false;
        break;
      case HttpEventType.UploadProgress:
        this.progress = Math.round(eventRes.loaded / eventRes.total * 100);
        this.displayUpload = false;
        break;
      case HttpEventType.Response:
        this.displayUpload = true;
        this.updateJson(eventRes.body);
        setTimeout(() => {
          this.progress = 0;
        }, 0)
    }
  }

  updateJson(data) {
    this.allData = [];
    if (this.inputs.field_value) {
      this.inputs.field_value.forEach(element => {
        this.allData.push(element);
      });
      data.forEach(element => {
        this.allData.push(element);
      });
      this.inputs.field_value = this.allData;
      this.subForm.get(this.inputs.field_key_name).setValue(this.allData);
    }
    else {
      this.inputs.field_value = data;
      this.subForm.get(this.inputs.field_key_name).setValue(data);
    }

  }

  openImg(src) {
    this.imgViewerDialogRef = this.dialog.open(ImgViewerComponent,{
      data: {
        src: src
      }
    })
  }

  deleteFile(value, index) {
    this.ServiceEngine.deleteFile(value.id).subscribe(data => {
      this.inputs.field_value.splice(this.inputs.field_value[index], 1);
    },
      (error) => {
        this.handleBackendError(error);
      })
  }

  handleBackendError(error) {
    try {
      console.log(error);
    } catch {
      console.log(error);
    }
  }
}
