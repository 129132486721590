import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import * as XLSX from 'xlsx';

import { SmsService } from 'src/app/core/services/sms.service';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.scss'],
})
export class SendSmsComponent implements OnInit {
  file = {
    name: '',
    progress: false,
    progressVal: 0,
    exist: false,
    error: false,
  };

  MIN_ROWS: number = 2;
  MAX_ROWS: number = 100;
  errorMSG: string;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  formData: FormData;
  disableBtn: boolean = false;
  constructor(private smsServices: SmsService, private matDialog: MatDialog) {}

  ngOnInit(): void {
    this.formData = new FormData();
  }

  sliceFileName(name) {
    return name.slice(name.lastIndexOf('/') + 1);
  }

  handleFileInput(event) {
    this.file.error = false;
    this.file.name = event.target.files[0].name;
    let fileType =  this.file.name.substring(this.file.name.lastIndexOf('.'));
    const reader: FileReader = new FileReader();
    if(['.csv'].includes(fileType)) {
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        let rowsLength;
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        rowsLength = data.length;
  
        if (rowsLength < this.MIN_ROWS) {
          this.handleErrorMsg('لا يمكن رفع ملف فارغ');
        } else if (rowsLength > this.MAX_ROWS) {
          this.handleErrorMsg('عفوا لا يمكن رفع اكثر من 100 سطر فى الملف');
        } else {
          this.formData.append('sms_sheet', event.target.files[0]);
          this.file.progress = false;
          this.file.exist = true;
        }
      };
    } else {
      this.handleErrorMsg('عذرا برجاء رفع ملف csv .')
    }
    reader.readAsBinaryString(event.target.files[0]);
  }

  uploadFile() {
    this.smsServices.uploadFile(this.formData).subscribe(() => {
     this.disableBtn = true;
     this.showAlertBox();
    }, (error) => {
      try {
        this.disableBtn = false;
        this.handleErrorMsg(error.error.error);
      } catch (error) {}
    });
  }

  handleErrorMsg(errorMsg) {
    this.errorMSG = errorMsg;
    this.file.error = true;
  }

  closeError() {
    this.file.error = false;
  }

  showAlertBox() {
    this.saveDialogRef = this.matDialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: true,
        message: 'تم الارسال بنجاح',
        button: 'تم'
      },
    });
  }
}
