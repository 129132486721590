export enum OrderStatus {
  Not_Finished = 0,
  New = 1,
  Under_Inspectoring = 2,
  Under_Audit = 3,
  Processing = 4,
  Scheduled = 5,
  Delivered = 6,
  Completed = 7,
  Canceled = 8,
  InspectorAssigned = 9 ,
  NotPaid = 10 ,
  Holding = 11,
}
