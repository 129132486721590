import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { FiltersService } from 'src/app/core/services/filters/filters.service';
import { changeFilterParam } from 'src/app/core/state/filter-state/actions';
import { SaveFilterComponent } from '../save-filter/save-filter.component';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() filterData;
  @Input() filterDataInit;
  @Input() filterPage;
  @Input() enableSave: boolean = true;
  @Output() formValus = new EventEmitter();
  columns: any[] = [
    {
      value: '',
      display_name: '',
    },
  ];
  filtersForm: UntypedFormGroup;
  columnType: any[] = [];
  selectOptions: any[] = [];
  selectedVals: any[] = [];
  placeholder = 'القيمة';
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  columnsFormArray: UntypedFormArray;
  filterParamsUrl: {};
  saveFilterDialogRef: MatDialogRef<SaveFilterComponent>;
  filterRowsLength: number = 0;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private filterService: FiltersService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.columns = this.filterData.columns;
    this.createForm();
    this.createFormControls();
    this.transformParams();
    this.columnsFormArray = this.filtersForm.get(
      'columnData'
    ) as UntypedFormArray;
    this.filterService.currentSelectedFilter.subscribe((data) => {
      if (Object.keys(data).length > 0) {
        this.filterDataInit = data;
        this.clearFilters(false);
        this.applyUrlDataToForm();
        this.addSingleRow();
      }
    });
  }

  menuOpened() {
    this.clearFilters(false);
    this.applyUrlDataToForm();
    this.addSingleRow();
  }

  transformParams() {
    let cloneParams = JSON.parse(JSON.stringify(this.filterDataInit));
    if (cloneParams['query']) delete cloneParams['query'];
    if (cloneParams['page']) delete cloneParams['page'];
    this.filterDataInit = cloneParams;
  }

  initFormValue(index, columnName, val) {
    index = this.columnsObject.length;
    if (index > 0) this.addSingleRow();
    if (columnName && this.filterData.data[columnName] && val) {
      val = typeof val === 'string' && val.toLowerCase() == 'true' ? true : val;
      val =
        typeof val === 'string' && val.toLowerCase() == 'false' ? false : val;
      this.columnType[index] = this.filterData.data[columnName]?.type;
      this.selectOptions[index] = this.filterData.data[columnName].values
        ? this.filterData.data[columnName].values
        : [];
      this.columnsFormArray.at(index).get('columnName').setValue(columnName);
      if (this.filterData.data[columnName].type == 'multi_select') {
        let selectedVals = '[' + val + ']';
        selectedVals = this.isJson(selectedVals)
          ? JSON.parse(selectedVals)
          : val.split(',');
        this.columnsFormArray.at(index).get('value').setValue(selectedVals);
        this.selectedVals[index] = selectedVals;
      } else if (this.filterData.data[columnName].type == 'date') {
        this.columnsFormArray
          .at(index)
          .get('startDate')
          .setValue(val.startDate);
        this.columnsFormArray.at(index).get('endDate').setValue(val.endDate);
      } else if (this.filterData.data[columnName].type == 'select') {
        this.columnsFormArray.at(index).get('value').setValue(JSON.parse(val));
      } else {
        this.selectedVals[index] = [];
        this.columnsFormArray.at(index).get('value').setValue(val);
      }
    }
  }

  applyUrlDataToForm() {
    let valDate = {};
    this.filterRowsLength = Object.keys(this.filterDataInit).length;
    let keys = Object.keys(this.filterDataInit);
    for (let i = 0; i < keys.length; i++) {
      if (Object.keys(this.filterDataInit)[i].includes('_after')) {
        valDate['startDate'] = moment(Object.values(this.filterDataInit)[i]);
      } else if (Object.keys(this.filterDataInit)[i].includes('_before')) {
        valDate['endDate'] = moment(Object.values(this.filterDataInit)[i]);
        let colName = Object.keys(this.filterDataInit)[i];
        colName = colName.substring(0, colName.indexOf('_before'));
        this.initFormValue(i - 1, colName, valDate);
      } else
        this.initFormValue(
          i,
          Object.keys(this.filterDataInit)[i],
          Object.values(this.filterDataInit)[i]
        );
    }
    for (let i = 0; i < this.columnsObject.length; i++) {
      if (!this.columnsObject.at(i).get('columnName').value) {
        this.deleteRow(i);
      }
    }
  }

  createFormControls() {
    return this.filtersForm.controls;
  }

  get forms() {
    return this.filtersForm.controls;
  }

  get columnsObject() {
    return this.columnsFormArray;
  }

  createForm() {
    this.filtersForm = this.formBuilder.group({
      columnData: new UntypedFormArray([]),
    });
    this.columnsFormArray = this.filtersForm.get(
      'columnData'
    ) as UntypedFormArray;
    this.addSingleRow();
  }

  addSingleRow() {
    this.columnsObject.push(
      this.formBuilder.group({
        columnName: [null],
        value: [''],
        startDate: [null],
        endDate: [null],
      })
    );
  }

  addRow() {
    let count = 0;
    for (let i = 0; i < this.columnsObject.length; i++) {
      if (this.columnsObject.at(i).get('columnName').value) {
        count += 1;
      }
    }
    if (count == this.columnsObject.length) {
      if (this.columnsObject.length < 5) {
        this.columnsObject.push(
          this.formBuilder.group({
            columnName: [null],
            value: [''],
            startDate: [null],
            endDate: [null],
          })
        );
      }
    }
  }

  selectedType(event, index) {
    if (event) {
      this.selectedVals[index] = '';
      if (this.columnType[index]) {
        this.columnType[index] = this.filterData.data[event].type;
      } else {
        this.columnType.push(this.filterData.data[event].type);
      }
      if (this.columnType[index] == 'multi_select') {
        this.selectedTypeMulitSelect(index, event);
      } else if (this.columnType[index] == 'select') {
        this.selectedTypeSelect(index, event);
      } else {
        this.setSelectedOptions('', index);
        this.selectOptions.push([]);
      }
    } else {
      this.selectOptions[index] = [];
      this.columnType[index] = 'text';
      this.setSelectedOptions('', index);
    }
  }

  selectedTypeMulitSelect(index, event) {
    if (this.selectOptions[index]) {
      this.setSelectedOptions([], index);
      this.selectedVals[index] = [];
      this.selectOptions[index] = this.filterData.data[event].values;
    } else this.selectOptions.push(this.filterData.data[event].values);
  }

  selectedTypeSelect(index, event) {
    if (this.selectOptions[index]) {
      this.setSelectedOptions([], index);
      this.selectOptions[index] = this.filterData.data[event].values;
    } else this.selectOptions.push(this.filterData.data[event].values);
  }

  clearFilters(emitValues) {
    while (this.columnsObject.length !== 0) {
      this.columnsObject.removeAt(0);
    }
    this.columnType = [];
    this.selectOptions = [];
    this.filterRowsLength = 0;
    this.columnsObject.push(
      this.formBuilder.group({
        columnName: [null],
        value: [''],
        startDate: [null],
        endDate: [null],
      })
    );
    if (emitValues) {
      this.formValus.emit('');
      this.store.dispatch(new changeFilterParam({ change: true }));
    }
  }

  changeDate(index) {
    let dateValue = {
      begin: this.filtersForm.value.columnData[index].startDate,
      end: this.filtersForm.value.columnData[index].endDate,
    };
    this.columnsFormArray.at(index).get('value').setValue(dateValue);
    this.addRow();
  }

  filterParam() {
    let filterParamsUrl = {};
    let columnData = this.filtersForm.value.columnData;
    for (let col of columnData) {
      if (
        this.filterData.data[col.columnName] &&
        this.filterData.data[col.columnName].type == 'multi_select'
      ) {
        let value = '';
        for (let colVal of col.value) {
          value += colVal + ',';
        }
        value = value.substring(0, value.length - 1);
        filterParamsUrl[col.columnName] = value;
      } else if (
        this.filterData.data[col.columnName] &&
        this.filterData.data[col.columnName].type == 'date'
      ) {
        let begin: any = moment(col.value['begin']);
        begin = begin.format('YYYY-MM-DD');
        let end: any = moment(col.value['end']);
        end = end.format('YYYY-MM-DD');
        filterParamsUrl[col.columnName + '_after'] = begin;
        filterParamsUrl[col.columnName + '_before'] = end;
      } else {
        filterParamsUrl[col.columnName] = col.value;
      }
    }
    delete filterParamsUrl['null'];
    this.filterParamsUrl = filterParamsUrl;
  }

  search() {
    this.filterParam();
    this.filterRowsLength = Object.keys(this.filterParamsUrl).length;
    this.formValus.emit({ filterParamsUrl: this.filterParamsUrl });
    this.store.dispatch(new changeFilterParam({ change: true }));
  }

  setSelectedOptions(event, index) {
    this.columnsFormArray.at(index).get('value').setValue(event);
    if (event.length > 0) {
      this.addRow();
    }
  }

  deleteRow(index) {
    this.columnsObject.removeAt(index);
    this.columnType.splice(index, 1);
    this.selectOptions.splice(index, 1);
    this.selectedVals.splice(index, 1);
  }

  saveFilter() {
    this.filterParam();
    this.saveFilterDialogRef = this.dialog.open(SaveFilterComponent, {
      data: {
        filterData: this.filterParamsUrl,
        filterPage: this.filterPage,
      },
      hasBackdrop: false,
    });
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
