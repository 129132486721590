import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AYENServiceBase } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends AYENServiceBase {

  constructor(private http: HttpClient) {
    super();
  }

  // shared notifiaction
  sendPrivateNotification(data, params): Observable<any> {
    return this.http.post(this.baseURL + `/send-private-notification`, data, {params: params}).pipe(
      map(res => {
        return res
      })
    )
  }
// shared
  getTestedNotificationList() {
    return {
      links: {
        next: null,
        previous: null
      },
      count: 15,
      pages_count: 1,
      results: [
        {
          id: 86,
          notification_type: "الإدارة, الفاحص, العميل",
          title: null,
          body: "test",
          url: null,
          date_added: "2021/11/14"
        },
        {
          id: 88,
          notification_type: "الإدارة, الفاحص, العميل",
          title: null,
          body: "test",
          url: null,
          date_added: "2021/11/14"
        },
        {
          id: 89,
          notification_type: "الإدارة, الفاحص, العميل",
          title: null,
          body: "test",
          url: null,
          date_added: "2021/11/14"
        }
      ]
    };
  }
}
