<div class="report-log">
  <div class="table-responsive">
    @if (messages.length == 0) {
      <div>
        <div class="no-message">
          <span>لا يوجد نقاش على الطلب حتى الآن.</span>
        </div>
      </div>
    }
    @if (messages.length > 0) {
      <table class="table" aria-label="">
        <thead>
          <tr>
            <th id=""></th>
            <th id=""></th>
            <th id=""></th>
          </tr>
        </thead>
        <tbody>
          @for (message of messages; track message; let i = $index) {
            <tr>
              <td class="width-20" id="">
                <div class="person-col">
                  {{message.creator}}
                  @if (message.creator_is_admin) {
                    <span class="role-admin">(الإدارة)</span>
                  }
                </div>
                <div class="time-sec">{{message.created_at}}</div>
              </td>
              <td class="width-70" id="">{{message.message}}</td>
              <td class="width-10" id="">
                @if (message.allow_delete) {
                  <div (click)="deleteMessage(message.pk , i)">
                    <img src="./assets/images/svg/grid-delete-icon.svg" alt="">
                    <span class="delete-msg">حذف</span>
                  </div>
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    }
  </div>
</div>
