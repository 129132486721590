import { Component } from '@angular/core';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: []
})
export class DateRangeComponent {

}
