import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';
import { InspectorGridService } from 'src/app/features/inspectors/services/inspectors-grid/inspectors-grid.service';
import { QualificationService } from 'src/app/features/inspectors/services/qualification/qualification.service';

@Component({
  selector: 'service-credentials',
  templateUrl: './service-credentials.component.html',
  styleUrls: ['./service-credentials.component.scss']
})
export class ServiceCredentialsComponent implements OnInit {

  credentialsFormGroup: UntypedFormGroup;
  academicMajors: [];
  selectedAcademicMajors = [];
  selectedYearsOfExperience = [];
  selectedCertificates = [];
  yearsOfExperience: [];
  certificates: [] = [];
  qualifications = [];
  selectedQualifications = [];
  categories = [];
  selectedCategories = [];
  inspectorTypes = [];
  selectedInspectorTypes = [];
  expertiseAreas = [];
  selectedExpertiseAreas = [];
  specialQualifications = [];
  selectedSpecialQualifications = [];

  constructor(private formBuilder: UntypedFormBuilder,
    private inspectorService: InspectorsService,
    private inspectorGridService: InspectorGridService,
    public dialogRef: MatDialogRef<ServiceCredentialsComponent>,
    private qualification: QualificationService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.createForm();
    this.getAcademicMajors();
    this.getSpecialQualifications();
    this.getExperienceYears();
    this.getCertificates();
    this.getQualifications();
    this.getCategories();
    this.getInspectorType();
    this.getExpertiseAreas();
    this.patchValues();
  }

  createForm() {
    this.credentialsFormGroup = this.formBuilder.group({
      academic_major: [null],
      years_of_experience: [null],
      certificates: [null],
      qualification_choice: [null],
      category: [null],
      inspector_type: [null],
      expertise_areas: [null],
      special_qualification_tags: [null]
    });
  }

  patchValues() {
    this.selectedAcademicMajors = [...this.data.academic_major];
    this.selectedYearsOfExperience = [...this.data.years_of_experience];
    this.selectedQualifications = [...this.data.qualification_choice];
    this.selectedCertificates = [...this.data.certificates];
    this.selectedCategories = [...this.data.category];
    this.selectedInspectorTypes = [...this.data.inspector_type];
    this.selectedExpertiseAreas = [...this.data.expertise_areas];
    this.selectedSpecialQualifications = [...this.data.special_qualification_tags];

    this.credentialsFormGroup.patchValue({
      academic_major: this.data.academic_major,
      years_of_experience: this.data.years_of_experience,
      certificates: this.data.certificates,
      category: this.data.category,
      qualification_choice: this.data.qualification_choice,
      inspector_type: this.data.inspector_type,
      expertise_areas: this.data.expertise_areas,
      special_qualification_tags: this.data.special_qualification_tags
    });
  }

  getCategories() {
    this.inspectorService.getCategories().subscribe(data => {
      this.categories = data;
    })
  }

  getQualifications() {
    this.qualification.getQualificationStatus().subscribe(data => {
      this.qualifications = data;
    })
  }

  getInspectorType() {
    this.inspectorGridService.getRegistrationAs().subscribe( data => {
      this.inspectorTypes = data;
    })
  }

  getExpertiseAreas() {
    this.inspectorGridService.getExpertiseFocus().subscribe( data => {
      this.expertiseAreas = data;
    })
  }

  getAcademicMajors() {
    this.inspectorService.getAcadamicMajor().subscribe(data => {
      this.academicMajors = data;
    });
  }

  getSpecialQualifications() {
    this.inspectorService.getSpecialQualifications().subscribe(data => {
      this.specialQualifications = data;
    });
  }

  getExperienceYears() {
    this.inspectorGridService.getExperinceYears().subscribe(data => {
      this.yearsOfExperience = data;
    });
  }

  getCertificates() {
    this.inspectorGridService.getCertificates().subscribe(data => {
      this.certificates = data;
    });
  }

  setMultiSelectValue(event, controlName) {
    this.credentialsFormGroup.get(controlName).setValue([...event]);
  }

  isValueChanged(event) {
    this.credentialsFormGroup.get(event.labelEn).setValue(event.value);
  }

  save() {
    this.dialogRef.close(this.credentialsFormGroup.value);
  }

}
