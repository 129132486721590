import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { InspectorGridService } from '../../services/inspectors-grid/inspectors-grid.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { PromptBoxComponent } from 'src/app/shared/components/prompt-box/prompt-box.component';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';

@Component({
  selector: 'app-inspectors-settings',
  templateUrl: './inspectors-settings.component.html',
  styleUrls: ['./inspectors-settings.component.scss']
})
export class InspectorsSettingsComponent implements OnInit {
  
  inspectorsSettingsForm: UntypedFormGroup;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  certificateAlert: boolean = false;
  SpecialAlert: boolean = false;
  errorMessage: string = '';
  certificates: any[] = [];
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  promptDialogRef: MatDialogRef<PromptBoxComponent>;
  panelOpenState = false;
  specialQualifications: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private inspector: InspectorGridService,
    private _inspectors: InspectorsService) {}

  ngOnInit(): void {
    this.createForm();
    this.getCertificates();
    this.getSpecialQualifications();
  } 

  getCertificates() {
    this.inspector.getCertificates().subscribe( data => {
      this.certificates = data;
    });
  }

  getSpecialQualifications() {
    this._inspectors.getSpecialQualifications().subscribe( data => {
      this.specialQualifications = data
    });
  }
  
  createForm() {
    this.inspectorsSettingsForm = this.formBuilder.group({
      certificate: [null],
      special_qualification_tag: [null]
    });
  }

  saveSpecialQualification() {
    this.SpecialAlert = false;
    const data = this.inspectorsSettingsForm.get('special_qualification_tag').value;
    this._inspectors.addSpectialQualification(data).subscribe( data => {
      this.showAlertBox();
      this.specialQualifications.unshift(data);
    }, error => {
      try {
        this.SpecialAlert = true;
        this.errorMessage = error.error.name[0];
      }
      catch {}
    })
  }

  updateSpecialQualification(event, index) {
    this.promptDialogRef = this.dialog.open(PromptBoxComponent, {
      data: {
        label: 'تعديل تأهيل مخصص',
        value: event.name,
        id: event.id
      }
    });
    this.promptDialogRef.afterClosed().subscribe( result => {
      if(result?.id) {
        this._inspectors.updateSpecialQualification(result.value, result.id).subscribe( data => {
          this.specialQualifications[index].name = data.name;
        }); 
      }           
    });
  }
  
  savecertificate() {
    this.certificateAlert = false;
    const inspectorSettings = {
      name: this.inspectorsSettingsForm.get('certificate').value
    }
    this.inspector.addCertificate(inspectorSettings).subscribe( data => {
      this.showAlertBox();
      this.certificates.unshift(data);
    }, (error) => {
      try {
        this.certificateAlert = true;
        this.errorMessage = error.error.name[0];
      } catch {}
    });
  }

  updateCertificate(event, index) {
    this.promptDialogRef = this.dialog.open(PromptBoxComponent, {
      data: {
        label: 'تعديل الشهادة',
        value: event.name,
        id: event.id
      }
    });
    this.promptDialogRef.afterClosed().subscribe( result => {
      if(result?.id) {
        const certificate = {
          id: result.id,
          name: result.value
        }
        this.inspector.updateCertificate(result.id, certificate).subscribe( data => {
          this.certificates[index].name = data.name;
        }); 
      }           
    });
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: true,
        message: 'تم حفظ التعديلات بنجاح',
        button: 'تم'
      },
    });
  }

  closeError() {
    this.certificateAlert = false;
  }
}
