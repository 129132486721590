<div class="row">
  <div class="col-md-3 basic-data">
    <h4>إجمالي الإيراد</h4>
    <p> {{financialInfo?.amount ? financialInfo?.amount : '-'}} </p>
  </div>
  <div class="col-md-3 basic-data">
    <h4>قيمة الضريبة المضافة</h4>
    <p> {{financialInfo?.vat_amount ? financialInfo?.vat_amount : '-'}}</p>
  </div>
  <div class="col-md-3 basic-data">
    <h4>صافي الإيراد </h4>
    <p>{{financialInfo?.net_amount ? financialInfo?.net_amount :'-'}} </p>
  </div>
  <div class="col-md-3 basic-data">
    <h4>حالة الدفع</h4>
    <div class="double-action">
      <p> {{financialInfo?.payment_status ? financialInfo.payment_status : '-'}}
        @if (financialInfo?.payment_status === 'مستلم') {
          <img src="assets/images/svg/ellipse-green.svg" alt="">
        }
      </p>
      @if (financialInfo?.payment_status === 'غير مستحقة') {
        <button
          (click)="payOrder()"
          class="btn btn-green"
          [ngClass]="{
          disabled: !financialInfo?.vat_amount
          }"
          >
          تم الإستلام
        </button>
      }
    </div>
  </div>
  <div class="col-md-3 basic-data">
    <h4> تاريخ الدفع</h4>
    <p> {{dateFormate(financialInfo?.payment_date)}}  </p>
  </div>
  <div class="col-md-3 basic-data">
    <h4>طريقة الدفع</h4>
    <p> {{financialInfo?.payment_method ? financialInfo?.payment_method : '-'}}  </p>
  </div>
  <div class="col-md-3 basic-data">
    <h4>رقم المعاملة</h4>
    <p> {{financialInfo?.fort_id ? financialInfo?.fort_id : '-'}}  </p>
  </div>
</div>