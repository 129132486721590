<div class="main-info">
  <div class="order-info">
    <h3>معلومات الطلب</h3>
  </div>
  <div class="info-content">
    <!-- basic order - tabs -->
    <ul class="nav nav-tabs horizontal-tab" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="basicInfo-tab"
          data-toggle="tab"
          href="#basicInfo"
          role="tab"
          aria-controls="basicInfo"
          aria-selected="true"
          >التفاصيل الأساسية</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="inspectorInfo-tab"
            data-toggle="tab"
            href="#inspectorInfo"
            role="tab"
            aria-controls="inspectorInfo"
            aria-selected="false"
            >معلومات الفاحص</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="financialDetails-tab"
              data-toggle="tab"
              href="#financialDetails"
              role="tab"
              aria-controls="financialDetails"
              aria-selected="false"
              >التفاصيل المالية</a
              >
            </li>
          </ul>
          <div class="tab-content horizontal-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="basicInfo"
              role="tabpanel"
              aria-labelledby="basicInfo-tab"
              >
              @if (orderData) {
                <app-main-info
                  (errorMsg)="showModal($event)"
                ></app-main-info>
              }
            </div>
            <div
              class="tab-pane fade"
              id="inspectorInfo"
              role="tabpanel"
              aria-labelledby="inspectorInfo-tab"
              >
              <app-inspector-info></app-inspector-info>
            </div>
            <div
              class="tab-pane fade"
              id="financialDetails"
              role="tabpanel"
              aria-labelledby="financialDetails-tab"
              >
              <app-financial-info></app-financial-info>
            </div>
          </div>
        </div>
      </div>
