import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgSelectComponent } from '@ng-select/ng-select';
import { InspectorsService } from 'src/app/core/services/inspectors/inspectors.service';
@Component({
  selector: 'app-assign-inspector',
  templateUrl: './assign-inspector.component.html',
  styleUrls: ['./assign-inspector.component.scss']
})
export class AssignInspectorComponent implements OnInit {
  inspectors: any;
  page_count: number = 0;
  params: any;
  page: number = 1;
  searchInput: any;
  timeout: any = null;
  order_inspector: any;
  @ViewChild('selectInsp') selectInsp: NgSelectComponent;

  constructor(
    private _mdr: MatDialogRef<AssignInspectorComponent>,
    private Inspectors: InspectorsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  CloseDialog() {
    this._mdr.close(false);
  }
  ngOnInit(): void {
  }
  getInspectors(page) {
    this.Inspectors.getAvailableInspectors(page).subscribe(data => {
      this.inspectors.push(...data.results)
      this.page_count = data.pages_count;
    },
      (error) => {

      });
  }
  inspectorData(inspector) {
    if (inspector) {
      let inspectorData = this.inspectors.find(item => item.id == inspector);
      this.data = inspectorData;
    }
  }
  searchtext() {
    this.searchInput = this.selectInsp.searchTerm;
    this.params = { full_name: this.searchInput, ordering: 'full_name' };
    this.inspectors = [];
    clearTimeout(this.timeout);
    var $this = this;
    $this.timeout = setTimeout(() => {
      if ($this.searchInput != '') {
        $this.getInspectors(this.params);
      }
      else {
        this.params = { page: '1' }
        $this.getInspectors(this.params);
      }
    }, 600);
  }

  resetSearch() {
    this.inspectors = [];
    this.params = { page: '1' }
    this.getInspectors(this.params);
  }
  onScrollPagination() {
    if (this.page < this.page_count) {
      this.page++;
      this.params = { page: this.page };
      this.getInspectors(this.params);
    }
  }
}
