import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'security-error',
  templateUrl: './security-error.component.html',
})
export class SecurityErrorComponent {
  constructor(
    public dialogRef: MatDialogRef<SecurityErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  @Input() message: string = '';

  ngOnInit() {
    this.message = this.data.message;
  }
}
