import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
/**
 * declare hostName to ignore localhost on sentry
 */
const hostName = window.location.hostname;
if (hostName != "localhost" && !hostName.includes('test'))
  Sentry.init({
    dsn: "https://131f358d98cc4e1fa29991f0c71fc36f@o688178.ingest.sentry.io/5773232",
    environment: environment.envName,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    integrations: [new Sentry.Replay()],
  });

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
