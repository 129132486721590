import { Component, OnInit, Inject} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AyenServices } from 'src/app/core/services/ayen-services/ayen-services.service';
@Component({
  selector: 'add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss'],
})
export class AddServiceComponent implements OnInit {
  addServiceFormGroup: UntypedFormGroup;
  services: any;
  disable: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ayenServices: AyenServices,
    public dialogRef: MatDialogRef<AddServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.getCouponServices();
  }
  
  ngDoCheck(): void {
    if (this.addServiceFormGroup.get('services').value) {
      this.disable = false;
    } else {
      this.disable = true;
    }
  }

  createFormControls() {
    return this.addServiceFormGroup.controls;
  }

  createForm() {
    this.addServiceFormGroup = this.formBuilder.group({
      services: [null, Validators.required],
    });
  }

  addService() {
    let service = this.addServiceFormGroup.get('services').value;
    this.dialogRef.close({ service: service });
  }

  getCouponServices() {
    this.ayenServices.getAyenServices().subscribe((data) => {
      this.services = data;
    });
  }
}
