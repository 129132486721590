<div class="inner-page-header"><h3>إنشاء خدمة إضافية</h3></div>
<!-- stepper -->
<div class="stepper">
  <div class="main-card">
    <div class="setup-panel">
      <div class="step-action">
        <a
          href="#step-1"
          class="step-status"
          [ngClass]="{ 'step-active': selectedView === 'details' }"
          >
          <div class="step-circle">
            <p>1</p>
            <img src="../../assets/images/svg/check-icon-white.svg" alt="" />
          </div>
          <p class="step-title">تفاصيل الخدمة</p>
        </a>
      </div>

      <div class="step-action">
        <a
          href="#step-2"
          class="step-status"
          [ngClass]="{ 'step-active': selectedView === 'summary' }"
          >
          <div class="step-circle">
            <p>2</p>
            <img src="../../assets/images/svg/check-icon-white.svg" alt="" />
          </div>
          <p class="step-title">ملخص الطلب</p>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- end stepper -->
<div>
  @switch (selectedView) {
    @default {
      <app-amount-details
        (amount_details_view)="getView($event)"
      ></app-amount-details>
    }
    @case ('summary') {
      <app-amount-summary
        [summaryData]="summaryData"
        (summary_view)="getView($event)"
      ></app-amount-summary>
    }
  }
</div>
