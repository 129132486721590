import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AYENServiceBase } from '../../../../core/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class CouponsService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getCouponClassifications(): Observable<any> {
    return this.http.get(this.baseURL + '/coupon-classifications/').pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getDiscountTypes(): Observable<any> {
    return this.http.get(this.baseURL + '/coupon-discount-types/').pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  addNewCoupon(coupon): Observable<any> {
    return this.http.post(this.baseURL + '/coupons/', coupon).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getCouponById(id): Observable<any> {
    return this.http.get<any>(this.baseURL + '/coupons/' + id + '/').pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getCoupons(): Observable<any> {
    return this.http.get(this.baseURL + '/coupons/').pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateCoupon(id, coupon): Observable<any> {
    return this.http
      .put<any>(this.baseURL + '/coupons/' + id + '/', coupon)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  deleteCoupon(id): Observable<any> {
    return this.http
      .request('delete', this.baseURL + '/coupons/delete/', { body: id })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getCouponsByPage(query): Observable<any> {
    return this.http.get(this.baseURL + '/coupons/', { params: query }).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
