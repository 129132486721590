import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getOrderReasons(): Observable<any> {
    return this.http.get<any>(this.baseURL + '/sub-order-reasons/').pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  calcSubOrderAmount(data): Observable<any> {
    return this.http
      .post<any>(this.baseURL + '/calculate-sub-order-amount/', data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  creatAdditionalAmount(data): Observable<any> {
    return this.http.post<any>(this.baseURL + '/create-sub-order/', data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  createPurchaseProcess(data, id): Observable<any> {
    const payload = {
      amount: data.paymentAmount,
      invoice_id: data.purchaseInvoiceNumber,
      payment_method: data.bankName,
      qoyod_customer_id: data.clientNumInQoud,
      receipt_id: data.bondNumber,
      received_on_date: moment(data.paymentDate).format('YYYY-MM-DD'),
    };
    return this.http
      .post<any>(this.baseURL + `/admin/orders/${id}/purchase/`, payload)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPaymentMethods(): Observable<any> {
    return this.http.get<any>(this.baseURL + '/payment-methods/');
  }

  getBanksNames() {
    return this.http.get<any>(this.baseURL + '/payment-methods/').pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
