<div class="dialog-container addCategories">
  <h5 mat-dialog-title>اشترطات تقديم الخدمة</h5>
  <form class="needs-validation" [formGroup]="credentialsFormGroup">
    <div mat-dialog-content>
      <div class="row credentials-wrapper">
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">التخصص </label>
            <app-select [optionItems]="academicMajors" [placeholder]="'يرجى تحديد التخصصات'" 
              [initValues]="selectedAcademicMajors" [maxLength]="1"
              [id]="'academic_major'" (selectedOptions)="setMultiSelectValue($event, 'academic_major')">
            </app-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">الشهادات</label>
            <app-select [optionItems]="certificates" [placeholder]="'يرجى تحديد الشهادات'" 
              [initValues]="selectedCertificates" [maxLength]="1"
              [id]="'certificates'" (selectedOptions)="setMultiSelectValue($event, 'certificates')">
            </app-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">سنوات الخبرة </label>
            <app-select [optionItems]="yearsOfExperience" [placeholder]="'يرجى تحديد سنوات الخبرة'" 
              [initValues]="selectedYearsOfExperience" [maxLength]="1"
              [id]="'years_of_experience'" (selectedOptions)="setMultiSelectValue($event, 'years_of_experience')">
            </app-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">التصنيف</label>
            <app-select [optionItems]="categories" [placeholder]="'يرجى تحديد التصنيف'" 
              [initValues]="selectedCategories" [maxLength]="1" [bindValue]="'pk'"
              [id]="'category'" (selectedOptions)="setMultiSelectValue($event, 'category')">
            </app-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">التأهيل</label>
            <app-select [optionItems]="qualifications" [placeholder]="'يرجى تحديد التأهيل'" 
              [initValues]="selectedQualifications" [maxLength]="1"
              [id]="'qualification_choice'" (selectedOptions)="setMultiSelectValue($event, 'qualification_choice')">
            </app-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">التأهيل المخصص </label>
            <app-select [optionItems]="specialQualifications" [placeholder]="'يرجى تحديد التأهيل المخصص'" 
              [initValues]="selectedSpecialQualifications" [maxLength]="1"
              [id]="'special_qualification_tags'" (selectedOptions)="setMultiSelectValue($event, 'special_qualification_tags')">
            </app-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">صفة العمل</label>
            <app-select [optionItems]="inspectorTypes" [placeholder]="'يرجى تحديد صفة العمل'" 
              [initValues]="selectedInspectorTypes" [maxLength]="1"
              [id]="'inspector_type'" (selectedOptions)="setMultiSelectValue($event, 'inspector_type')">
            </app-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">مجال تركيز الخبرات</label>
            <app-select [optionItems]="expertiseAreas" [placeholder]="'يرجى تحديد مجال تركيز الخبرات'" 
              [initValues]="selectedExpertiseAreas" [maxLength]="1" [bindValue]="'pk'"
              [id]="'expertise_areas'" (selectedOptions)="setMultiSelectValue($event, 'expertise_areas')">
            </app-select>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="actions-double">
      <a class="btn btn-cancel center-btn" mat-dialog-close>إلغاء</a>
      <button
        class="btn btn-green center-btn"
        type="submit"
        (click)="save()">
        حفظ
      </button>
    </div>
  </form>
</div>
