import { Component, Input, OnInit } from '@angular/core';
import { Charts } from 'src/app/core/services/uitls/charts';

@Component({
  selector: 'chart-column',
  templateUrl: './basic-column.component.html',
  styleUrl: './basic-column.component.scss'
})
export class BasicColumnComponent implements OnInit {

  @Input() data;
  @Input() xlabel;
  xAxisData = [];
  yAxisData = [];

  ngOnInit(): void {
    this.setAxisData();
    this.createChartColumn();
  }

  setAxisData() {
    this.data.forEach(item => {
      this.xAxisData.push(item[this.xlabel] ?? 'لا يوجد');
      this.yAxisData.push(item.count);
    });
  }

  createChartColumn(): void {
    // Charts.createBasicColumnChart(this.xAxisData, this.yAxisData, 'عدد الفاحصين', 'سنوات الخبرة');
  }
}
