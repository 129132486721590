import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'select-paginated',
  templateUrl: './select-paginated.component.html',
  styleUrls: ['./select-paginated.component.scss'],
})
export class SelectPaginatedComponent implements OnInit {
  @Input() label;
  @Input() placeholder;
  @Input() items;
  @Input() pageCount: number;
  @Input() selectedContract: number;
  @Input() readonly: boolean;
  @Output() params = new EventEmitter();
  @Output() itemData = new EventEmitter();
  @ViewChild('select') select: NgSelectComponent;
  searchInput: string;
  timeout: any = null;
  page: any;

  private onScroll = (event: any) => {
    if (this.select && this.select.isOpen) {
      const isScrollingInScrollHost =
        (event.target.className as string).indexOf('ng-dropdown-panel-items') >
        -1;
      if (isScrollingInScrollHost) {
        return;
      }
      if (this.select && this.select.isOpen) {
        this.select.close();
      }
    }
  };

  ngOnInit(): void {
    window.addEventListener('scroll', this.onScroll, true);
  }

  searchtext() {
    this.searchInput = this.select.searchTerm;
    this.items = [];
    clearTimeout(this.timeout);
    const $this = this;
    $this.timeout = setTimeout(() => {
      if ($this.searchInput != '') {
        this.params.emit({
          params: { query: this.searchInput },
          clearList: true,
        });
      } else {
        this.page = 1;
        this.params.emit({ params: { page: this.page }, clearList: true });
      }
    }, 600);
  }

  changeItemData(item) {
    if (item) {
      let data = this.items.find(
        (element) => (element.pk || element.id) == item
      );
      this.itemData.emit(data);
    }
  }

  resetSearch() {
    this.items = [];
    this.page = '1';
    this.params.emit({ params: { page: this.page }, clearList: true });
  }

  onScrollPagination() {
    if (this.page < this.pageCount) {
      this.page++;
      this.params.emit({ params: { page: this.page }, clearList: false });
    }
  }
}
