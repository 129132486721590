<div class="dialog-container" id="security_error">
  <div mat-dialog-content>
    <div class="p-3 text-center">
      <img src="../../../../assets/images/svg/refusal-icon.svg" alt="" />
      <h2 mat-dialog-title >{{ message }}</h2>
    </div>
    <div mat-dialog-actions class="actions-single">
      <button class="btn btn-grey-2" mat-dialog-close>تم</button>
    </div>
  </div>
</div>
