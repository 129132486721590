import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AYENServiceBase } from "src/app/core/services/base/base.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends AYENServiceBase {

  constructor(private http: HttpClient) {
    super();
  }

  getNotificationListHeader(params?): Observable<any> {
    let param = params || '?';
    return this.http.get(this.baseURL + `/admin/list-notification${param}`);
  }

  toggleNotification(id): Observable<any> {
    return this.http.get(this.baseURL + `/admin/toggle-read-notification/${id}`);
  }

  getFilterIcons(): Observable<any> {
    return this.http.get(this.baseURL + `/admin/notification-type-list`);
  }
}
