<div class="dialog-container">
  <h2 mat-dialog-title>حفظ التصفية</h2>
  <div mat-dialog-content>
    <form [formGroup]="saveFilterFormGroup">
      <div class="form-group">
        <label for="filterName"> الاسم الدلالي</label>
        <input type="text" id="filterName" formControlName="filterName" name="filterName" class="form-control" />
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="actions-double">
    <button class="btn btn-cancel center-btn" mat-dialog-close>رجوع</button>
    <button class="btn btn-green center-btn" (click)="saveFilter()">حفظ</button>
  </div>
</div>
