import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ServicesOrdersService } from 'src/app/core/services/servicesOrders/services-orders.service';

@Component({
  selector: 'app-service-financial-info',
  templateUrl: './service-financial-info.component.html',
  styleUrls: ['./service-financial-info.component.scss']
})
export class ServiceFinancialInfoComponent implements OnInit {
 @Input() financialInfo;
 orderId: any;
  constructor(private servicesOrdersService : ServicesOrdersService,
    private activatedRoute : ActivatedRoute) { }

  ngOnInit(): void {
    this.orderId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  dateFormate(date){
    if(date)
      return moment(date).format('YYYY-MM-DD');
    else
      return '-';
  }

  payOrder(){
    this.servicesOrdersService.changePaymentStatus(this.orderId).subscribe(
      data =>{
        this.financialInfo.payment_status = 'مستلم';
      },
      error =>{
        try{
          console.log(error);
        }
        catch{

        }
      }
    )
  }

}
