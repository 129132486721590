import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { OrderState } from '../../state/order.state';
import { Observable } from 'rxjs';
import { OrderFilesService } from '../../services/orderFiles/order-files.service';
import { HttpEventType } from '@angular/common/http';
import { OrderDetails } from '../../state/order-action';

@Component({
  selector: 'order-files',
  styleUrls: ['./order-files.component.scss'],
  templateUrl: './order-files.component.html',
})
export class OrderFilesComponent implements OnInit {
  orderFilesList: any = [];
  @Output() errorMsg = new EventEmitter();
  index;
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;
  orderID: any;

  constructor(
    private orderFilesService: OrderFilesService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.orderDetails$.subscribe((data) => {
      this.orderID = data.id;
      this.orderFilesList = data.order_documents;
    });
  }
  
  deleteFile(event) {
    const orderUpdated = {
      file: null,
      id: event.fileId
    };
    this.updateFile(orderUpdated, event.index, null, 'تم حذف الملف بنجاح.');
  }

  save(event) {
    const orderUpdated: FormData = new FormData();
    orderUpdated.append('file', event.files[0]);
    orderUpdated.append('id', event.fileId);
    this.updateFile(orderUpdated, event.index,event.files[0], 'تم رفع الملف بنجاح.');
  }

  updateFile(orderUpdated, index, file, msg) {
    
    this.orderFilesService.updateOrderDocuments(this.orderID, orderUpdated).subscribe((event) => {
      switch (event.type) {
        case HttpEventType.Sent:
          this.orderFilesList[index].progress = false;
          break;
        case HttpEventType.ResponseHeader:
          this.orderFilesList[index].progress = false;
          this.store.dispatch(new OrderDetails({ orderId: +this.orderID }))
            .subscribe((res) => {});
          break;
        case HttpEventType.UploadProgress:
          this.orderFilesList[index].progressVal = Math.round(
            (event.loaded / event.total) * 100
          );
          this.orderFilesList[index].progress = true;
          break;
        case HttpEventType.Response:
          this.orderFilesList[index].progress = false;
          this.orderFilesList[index].file = file;
          this.showConfirmationModal(msg);
          setTimeout(() => {
            this.orderFilesList[index].progressVal = 0;
          }, 0);
      }
    });
    // .subscribe(() => {
    //   this.orderFilesList[index].file = file;
    //   this.showConfirmationModal(msg);
    // });
  }

  showConfirmationModal(msg) {
    this.errorMsg.emit({
      message: msg,
      imgSuccess: true,
    });
  }
}
