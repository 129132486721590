import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesOrdersService } from 'src/app/core/services/servicesOrders/services-orders.service';
import { Element } from 'src/app/models/interfaces/serviceEngine/element.model';
import * as moment from 'moment';
import { ServiceGlobalValueService } from 'src/app/core/services/service-engine/service-global-value/service-global-value.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './service-order-details.component.html',
  styleUrls: ['./service-order-details.component.scss'],
})
export class ServiceOrderDetailsComponent implements OnInit {
  panelOpenState = false;
  orderID;
  formName: UntypedFormGroup;
  elem: Element;
  totalElements: any = [];
  dynamicElements: any;
  basicInfo: any;
  financialInfo: any;
  orderDetails: any;
  ServiceProvider: any;
  servicePaymentProvider;
  orderStatus: string;
  confirmMsg: string = 'لقد تم حفظ التعديلات بنجاح.';
  modelImg: boolean;
  button: string = 'تم';
  alertmsg: boolean = false;
  errorMessage: string = '';
  saveDialogRef: MatDialogRef<AlertBoxComponent>;

  constructor(
    private serviceOrders: ServicesOrdersService,
    private router: Router,
    private globalValueService: ServiceGlobalValueService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((param) => {
      this.orderID = param['id'];
    });
    this.getOrderDetails();
    this.globalValueService.currentServiceQuatation.subscribe((data) => {
      this.getOrderDetails();
    });
  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
  getOrderDetails() {
    this.serviceOrders.getServicesOrder(this.orderID).subscribe(
      (data) => {
        this.orderDetails = data;
        this.orderStatus = this.orderDetails.essential_details.status;
        this.servicePaymentProvider = this.orderDetails.essential_details;
        this.basicInfo = this.orderDetails.order_info_basics;
        this.financialInfo = this.orderDetails.order_info_payment_details;
        this.ServiceProvider = this.orderDetails.order_info_service_provider;
        this.dynamicElements = this.orderDetails.dynamic_sections;
        this.dynamicElements.forEach((element) => {
          this.totalElements.push(...element.elements);
        });
        this.initForm();
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  initForm() {
    this.createForm();
    this.createFormControls();
    this.appendControls(this.orderDetails.essential_details, false);
    this.orderDetails.dynamic_sections.forEach((section) => {
      section.elements.forEach((field) => {
        if (field.field_value) {
          this.formName.get(field.field_key_name).setValue(field.field_value);
          if (field.choices?.length > 0) {
            field.choices.forEach((choice) => {
              if (choice.sub_elements?.length > 0) {
                this.appendControls(choice.sub_elements, true);
                choice.sub_elements.forEach((sub_elem) => {
                  this.formName
                    .get(sub_elem.field_key_name)
                    .setValue(sub_elem?.field_value);
                });
              }
            });
          }
        }
      });
    });
  }

  createFormControls() {
    return this.formName.controls;
  }

  createForm() {
    this.formName = this.formBuilder.group({});
    for (let formModule of this.totalElements) {
      this.formName.addControl(
        formModule.field_key_name,
        new UntypedFormControl(formModule?.validation?.default)
      );
      this.setValidation(formModule.field_key_name, formModule.validation);
    }
  }

  setValidation(formControl, validation) {
    let validators = [];
    let defaultValidators = [
      'required',
      'maxLength',
      'minLength',
      'pattern',
      'min',
      'max',
    ];
    defaultValidators.forEach((validator) => {
      if (validation[validator]) {
        if (validator == 'required') validators.push(Validators.required);
        else validators.push(Validators[validator](validation[validator]));
      }
    });
    this.formName.controls[formControl].setValidators(
      Validators.compose(validators)
    );
    this.formName.controls[formControl].updateValueAndValidity();
  }

  getComponentName(elem) {
    return this.serviceOrders.getComponentByType(
      elem.field_category,
      elem.field_type
    );
  }

  enableSaveChanges(event) {
    return event;
  }

  appendControls(controls, isElement) {
    if (isElement) {
      for (let control of controls) {
        this.formName.addControl(
          control.field_key_name,
          new UntypedFormControl(control.field_value)
        );
      }
    } else {
      let essentialControls = Object.keys(controls);
      for (let control of essentialControls) {
        this.formName.addControl(
          control,
          new UntypedFormControl(controls[control])
        );
      }
    }
  }

  save() {
    this.alertmsg = false;
    let keys = Object.keys(this.orderDetails.essential_details);
    keys.forEach((key) => {
      if (key.includes('date')) {
        this.orderDetails.essential_details[key] = this.formName.get(key).value
          ? moment(this.formName.get(key).value).format('YYYY-MM-DD').toString()
          : null;
      } else
        this.orderDetails.essential_details[key] = this.formName.get(key).value;
    });
    this.serviceOrders
      .updateServicesOrder(this.orderID, this.orderDetails)
      .subscribe(
        (data) => {
          this.modelImg = true;
          this.showAlertBox();
        },
        (error) => {
          try {
            this.alertmsg = true;
            this.errorMessage = Object.values(error.error)[0].toString();
          } catch {}
        }
      );
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.confirmMsg,
        backUrl: '/pages/orders/services-orders',
        button: this.button,
      },
    });
  }

  goList() {
    this.router.navigateByUrl('/pages/orders/services-orders');
  }

  updateData() {
    this.serviceOrders.updateZap(this.orderID).subscribe(
      (data) => {
        this.confirmMsg = 'تم تحديث البيانات بنجاح.';
        this.modelImg = true;
        this.showAlertBox();
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  handleBackendError(error) {
    try {
    } catch {}
  }
}
