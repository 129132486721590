import { Component } from '@angular/core';
import { ScriptService } from './core/services/script/script.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent {
  title = 'admin-panel';

  constructor(loadService: ScriptService) {
    loadService.checkScriptLoaded();
  }
}
