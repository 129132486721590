export class OrderDetails {
  static readonly type = '[OrderState] OrderDetails';
  constructor(public payload: { orderId: number }) {}
}

export class UpdateOrderDetails {
  static readonly type = '[OrderState] UpdateOrderDetails';
  constructor(public payload: { orderId: number; order: {} }) {}
}
export class GetOrderMsg {
  static readonly type = '[OrderState] GetOrderMsg';
  constructor(public payload: { orderId: number }) {}
}

export class PostPropertyLocation {
  static readonly type = '[OrderState] PostPropertyLocation';
  constructor(public payload: { orderId: number, propertyLocation: {}}) {}
}

export class GetPropertylocation {
  static readonly type = '[OrderState] GetPropertylocation';
  constructor(public payload: {orderId: number}) {} 
}
