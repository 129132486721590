@if (refundData.length == 0) {
  <div>
    <p class="no-refund">لم يتم طلب أي مرتجع حتى الآن</p>
  </div>
}
@if (refundData.length > 0 ) {
  <div>
    @for (item of refundData; track item) {
      <div class="sub-content">
        <div class="row">
          <div class="col">
            <h4>نوع الإرجاع</h4>
            <p> {{item.type}} </p>
          </div>
          <div class="col">
            <h4>مبلغ الإرجاع</h4>
            <p> {{item.amount}} <span> ر.س </span></p>
          </div>
          <div class="col">
            <h4>إجمالي الإيراد بعد الإرجاع</h4>
            <p> {{item.total_cost_after_refund}} </p>
          </div>
          <div class="col">
            <h4>الضريبة بعد الإرجاع</h4>
            <p> {{item.tax_after_refund}} </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h4>حصة الفاحص بعد الإرجاع</h4>
            <p> {{item.inspector_share_after_refund}} </p>
          </div>
          <div class="col">
            <h4>تاريخ الإرجاع</h4>
            <p> {{item.created_at}} </p>
          </div>
          <div class="col">
            <h4>سبب الإرجاع</h4>
            <p> {{item.reason}} </p>
          </div>
          <div class="col">
            <h4>حالة الإرجاع</h4>
            <p>
              {{item.status}}
              @if (item.status != 'معلق') {
                <img src="assets/images/svg/ellipse-green.svg" alt="">
              }
            </p>
          </div>
        </div>
        @if (item.status == 'معلق') {
          <div class="refund-btn">
            <button class="btn btn-green" (click)="deliveredOrder(item.pk, paid)">تم الإسترداد</button>
          </div>
        }
      </div>
    }
  </div>
}
<div class="border-line mt-1"></div>
@if (refundData.length == 0) {
  <div class="refund-btns">
    <button class="btn btn-delete" (click)="openRefundModal()">
      ارجاع
    </button>
    <button class="btn btn-delete" [routerLink]="['refund']">
      ارجاع يدوى
    </button>
  </div>
}
