import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Images } from 'src/app/shared/global/image-constants';

export interface DialogData {
  message: string;
  button?: string;
  imgSuccess?: boolean;
  imgPath?: string;
  backUrl?: string;
}

/**
 confirmation message component
 *  
*/
@Component({
  selector: 'alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit{

  constructor(private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.data['button'] = this.data['button'] ? this.data['button'] : 'رجوع';
    this.data['imgPath'] = this.data['imgPath'] ? this.data['imgPath'] : Images.REFUSAL_ICON;
  }

 closeModel(){
  if(this.data.backUrl){
    this.router.navigateByUrl(this.data.backUrl);
  }
 }
}
