@if (propertyLocationData) {
  <div>
    <div class="row" [formGroup]="propertyLocationForm">
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">رقم المبنى</label>
          <input type="text" formControlName="building_number" class="form-control disabled" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">الحي</label>
          <input type="text" formControlName="district_ar" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">الشارع</label>
          <input type="text" formControlName="street_ar" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">المدينة</label>
          <ng-select bindLabel="name" #selectStatus appendTo="body"
            formControlName="_city" [searchable]="true" [clearable]="true">
            @for (city of cities; track city) {
              <ng-option [value]="city.pk">
                {{city.name}}
              </ng-option>
            }
          </ng-select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">الرمز البريدي</label>
          <input type="text" formControlName="zip_code" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">الرقم الإضافي</label>
          <input type="text" formControlName="additional_number" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">رقم الوحدة<span class="optional">(اختياري)</span></label>
          <input type="text" formControlName="unit_number" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label"> إحداثيات الموقع</label>
          <input type="text" formControlName="location" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">العنوان المختصر</label>
          <input type="text" formControlName="short_address" class="form-control" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        @if (alertmsg) {
          <div class="alert-box">
            <img
              alt=""
              src="assets/images/svg/error-icon.svg"
              class="close-alert"
              (click)="hideErrorMSG()"
              alt=""
              />
              <p class="alert-msg">برجاء ادخال جميع البيانات المطلوبة</p>
            </div>
          }
        </div>
      </div>
    <div class="row">
      <div class="col-md-12 save-btn-wrapper">
        <button class="btn btn-green" (click)="save()">
          @if (showSpinner) {
            <img class="spin-img" src="assets/images/svg/Spinner-s.svg" alt="" />
          }
          حفظ
        </button>
      </div>
    </div>
  </div>
}
