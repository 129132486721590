import { Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdminOrdersService } from 'src/app/features/orders/services/orders/admin-orders.service';
import { OrderState } from 'src/app/features/orders/state/order.state';
import { Order } from 'src/app/models/interfaces/order';

@Component({
  selector: 'app-financial-info',
  templateUrl: './financial-info.component.html',
  styleUrls: ['./financial-info.component.scss'],
})
export class FinancialInfoComponent {
  orders: Order;
  flag: boolean = false;
  received = {
    is_received: true,
  };
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;

  constructor(private order: AdminOrdersService) {}

  ngOnInit(): void {
    this.orderDetails$.subscribe((data) => {
      this.orders = data;
    });
  }

  deliveredOrder(id, received) {
    this.flag = true;
    this.order.changePaymentStatus(id, received).subscribe(
      (data) => {
        this.orders.payment_data.payment_status = 'مستلم';
      },
      (error) => {
        try {
          console.log(error);
        } catch {
          console.log(error);
        }
      }
    );
  }
}
