import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';

@Component({
  selector: 'delete-selection',
  templateUrl: './delete-selection.component.html',
  styleUrls: ['./delete-selection.component.scss']
})
export class DeleteSelectionComponent implements OnInit {

  @Input() disabledDelete;
  @Input() id;
  @Input() index;
  @Input() deleteMessage;
  @Output() deleteResult = new EventEmitter;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {}

  deleteSelectRows() {
    this.showDeleteConfirmationBox();
  }

  showDeleteConfirmationBox() {
    this.deleteDialogRef = this.dialog.open(ConfirmationBoxComponent, {
      data: {
        id: this.id,
        index: this.index,
        message: this.deleteMessage
      }
    });
    this.deleteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteResult.emit(result);
      }
    });
  }

}
