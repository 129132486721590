import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class QualificationService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }
  getQualificationStatus(): Observable<any> {
    return this.http.get(this.baseURL + '/qualification-status/').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  listQualifiersActions(): Observable<any> {
    return this.http.get(this.baseURL + '/inspector-commands/').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  ApplyQualifiersActions(query, inspectors): Observable<any> {
    return this.http
      .post(
        this.baseURL + '/inspector-execute-command/?command=' + query,
        inspectors
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  handleError(error) {
    let errorMessage;
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
