import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { OrderAdditionsService } from '../../services/order-additions/order-additions.service';
import * as moment from 'moment';

@Component({
  selector: 'order-additions',
  templateUrl: './order-additions.component.html',
  styleUrl: './order-additions.component.scss',
})
export class OrderAdditionsComponent implements OnInit {
  @Input() additions = [];
  @Input() orderId;
  additionForm: UntypedFormGroup;
  showSpinner: boolean = false;
  @Output() errorMsg = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private orderAdditions: OrderAdditionsService
  ) {}
  ngOnInit() {
    this.createForm();
    this.createFormControls();
  }
  createFormControls() {
    return this.additionForm.controls;
  }

  createForm() {
    this.additionForm = this.formBuilder.group({});
    for (let formModule of this.additions) {
      this.additionForm.addControl(
        formModule.type+'_'+formModule.id,
        new FormControl(formModule.field_value)
      );
    }
  }

  deleteFile(event) {
    let formData = new FormData;
    formData.append('id', event.fileId);
    formData.append('file', "");
    this.updateOrderAdditions(formData, true, event.index);
  }

  saveFile(event) {
    let formData = new FormData;
    formData.append('id', event.fileId);
    formData.append('file', event.files[0]);
    this.updateOrderAdditions(formData, true, event.index);
  }

  showConfirmationModal() {
    this.errorMsg.emit({
      message: 'تم تحديث البيانات بنجاح.',
      imgSuccess: true,
    });
  }

  updateOrderAdditions(data, isFile?, fileIndex?) {
    this.showSpinner = true;
    this.orderAdditions.updateOrderAdditions(this.orderId, data).subscribe(
      data => {
        if(isFile) {
          this.additions[fileIndex] = data;
        }
        this.showSpinner = false;
        this.showConfirmationModal();
      }
    )
  }

 save() {
  let additionObj= [];
  Object.keys(this.additionForm.value).forEach(key => {
    console.log(key)
    let type = key.substring(0 , key.indexOf('_'));
    let id = key.substring(key.indexOf('_')+1);
    let value = this.additionForm.get(key).value;
    if(type === 'date') {
      let formatedDate: any = moment(
        this.additionForm.get(key).value
      );
      formatedDate = formatedDate.format('YYYY-MM-DD');
      value = formatedDate;
    }
    additionObj.push({'id':+id,'field_value': value});
  });
  this.updateOrderAdditions(additionObj);
 }
}
