import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RedirectionUrls } from 'src/app/shared/global/redirectionsUrls';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {

  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedRole = route.data.expectedRole;
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && [...expectedRole].includes(currentUser.user.profile.user_type)) {
      return true;
    }
    this.router.navigate([RedirectionUrls.redirectRoot[currentUser.user.profile.user_type].url]);

    return false;
  }
}
