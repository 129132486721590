<form class="needs-validation"  [formGroup]="addAmountFormGroup"
  (ngSubmit)="save()">
  <div class="main-card">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="" class="form-label">
            رقم الطلب الأساسي<span class="red">*</span>
          </label>
          <input type="number" formControlName="reference_number" placeholder="ادخل رقم الطلب"
            class="form-control">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label">
              السبب<span class="red">*</span>
            </label>
            <ng-select bindLabel="name" class="single-select"
              placeholder="يرجى تحديد السبب"
              appendTo="body"
              formControlName="reason_pk"
              [searchable]="true"
              [clearable]="true"
              (change)="checkType($event)">
              @for (reason of reasons; track reason) {
                <ng-option [value]="reason.pk">
                  {{reason.title}}
                </ng-option>
              }
            </ng-select>
          </div>
        </div>
        @if (reasonType=== 'المبلغ') {
          <div class="col-md-6">
            <div class="form-group">
              <label for="" class="form-label">
                المبلغ المطلوب<span class="red">*</span>
                <small>(غير شامل الضريبة)</small>
              </label>
              <input
                type="number"  step="0.01"
                numbersOnly
                min='1' formControlName="sub_order_value"
                class="form-control" placeholder="ادخل المبلغ المطلوب"
                oninput="validity.valid||(value='');">
                <span class="static-code">ر.س</span>
              </div>
            </div>
          }

          @if (reasonType=== 'المساحة') {
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="form-label">
                  المساحة<span class="red">*</span>
                </label>
                <input
                  type="number"   step="0.01"
                  min="1" formControlName="sub_order_value" class="form-control"
                  placeholder="ادخل المساحة الإضافية" oninput="validity.valid||(value='');">
                  <span class="static-code">م<sup>2</sup></span>
                </div>
              </div>
            }
          </div>
          @if (alertmsg) {
            <div class="alert-box">
              <img
                src="assets/images/svg/error-icon.svg"
                class="close-alert"
                alt=""
                (click)="hideErrorMsg()"
                />
                <p class="alert-msg">{{errorrMsg}}</p>
              </div>
            }
          </div>
          <div class="row">
            <div class="col-md-12 confirm-next-step">
              <button class="btn btn-green" type="submit" >
                التالي
              </button>
            </div>
          </div>
        </form>
