import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-rating-stars-render',
  templateUrl: './rating-stars-render.component.html',
  styleUrls: ['./rating-stars-render.component.scss'],
})
export class RatingStarsRenderComponent implements ICellRendererAngularComp {
  rating: number = 0;
  starCount: number = 5;
  flag: boolean = false;
  params;
  public ratingArr = [];
  count: number = 0;

  ratingData: any = {};
  rating_existing: boolean = true;

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params.value == 0) {
      this.rating_existing = false;
    }
  }
}
