<div class="sms-wrapper">
  <div class="inner-page-header">
    <h3>تحميل الرسائل النصية</h3>
  </div>
  <div class="main-card">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group download-wrapper">
          <label for="" class="form-label"> نموذج الرسائل النصية</label>
          <a
            class="btn btn-yellow"
            href="assets/files/sms-template.csv"
            target="_self"
            download
            >
            <span> تحميل نموذج الملف </span>
            <img alt="" src="assets/images/svg/download-icon.svg" />
          </a>
        </div>
      </div>
      <div class="col-md-12">
        <span class="upload-note">برجاء مراعاة ألا يتعدى الملف عدد ١٠٠ رسالة في المرة الواحدة</span>
        @if (file.exist) {
          <div class="existance-wrapper">
            <div class="existance">
              <img src="assets/images/svg/file-name-icon.svg" alt="" />
              <span>{{ sliceFileName(file.name) }}</span>
            </div>
          </div>
        }
        @if (file.progress) {
          <div class="file-progress">
            <div class="file-progress-boxs col-md-12">
              <div class="progress-item">
                <img src="assets/images/svg/file-progress.svg" alt="" />
              </div>
              <div class="progress-item upload-progress">
                <span class="file-name">{{ sliceFileName(file.name) }}</span>
                <progress
                  id="bar"
                  value="{{ file.progressVal }}"
                  max="100"
                ></progress>
              </div>
            </div>
          </div>
        }
        @if (!(file.progress || file.exist)) {
          <div class="drag-btn">
            <button type="button" class="btn btn-outline-secondary add-new-file">
              <img src="assets/images/svg/upload-img.svg" alt="" />
              رفع ملف
            </button>
            <input
              type="file"
              name=""
              accept=".csv"
              class="file-new form-control"
              (change)="handleFileInput($event)"
              />
            </div>
          }
          @if (file.error) {
            <div class="alert-box">
              <img
                src="assets/images/svg/error-icon.svg"
                class="close-alert"
                alt=""
                (click)="closeError()"
                />
                <p class="alert-msg">{{ errorMSG }}</p>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="single-action">
        <button class="btn btn-green" [disabled]="disableBtn" (click)="uploadFile()">ارسال</button>
      </div>
    </div>
