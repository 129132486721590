import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocationsService } from '../../services/locations/locations.service';
import { AyenServices } from '../../services/ayen-services/ayen-services.service';
import { InspectorGridService } from 'src/app/features/inspectors/services/inspectors-grid/inspectors-grid.service';
import { InspectorsService } from '../../services/inspectors/inspectors.service';
import { QualificationService } from 'src/app/features/inspectors/services/qualification/qualification.service';
import { InspectionService } from '../../services/inspection/inspection.service';

@Injectable()
export class QualificationsResolver  {
  constructor(
    private qualificationService: QualificationService
  ) {}

  resolve(): Observable<any> {
    return this.qualificationService.getQualificationStatus().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class AcadamicMajorResolver  {
  constructor(
    private inspectorService: InspectorsService
  ) {}

  resolve(): Observable<any> {
    return this.inspectorService.getAcadamicMajor().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class ExperinceYearsResolver  {
  constructor(private inspectorGridService: InspectorGridService) {}
  resolve(): Observable<any> {
    return this.inspectorGridService.getExperinceYears().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class RegistrationAsResolver  {
  constructor(private inspectorGridService: InspectorGridService) {}

  resolve(): Observable<any> {
    return this.inspectorGridService.getRegistrationAs().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class ExpertiseFocusResolver  {
  constructor(private inspectorGridService: InspectorGridService) {}

  resolve(): Observable<any> {
    return this.inspectorGridService.getExpertiseFocus().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class SpecialQualificationsResolver  {
  constructor(private inspectorsService: InspectorsService) {}

  resolve(): Observable<any> {
    return this.inspectorsService.getSpecialQualifications().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}
@Injectable()
export class ServicesResolver  {
  constructor(private ayenServices: AyenServices) {}

  resolve(): Observable<any> {
    return this.ayenServices.getAyenServices().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class AccountInfoResolver  {
  constructor(private inspectorGridService: InspectorGridService) {}

  resolve(): Observable<any> {
    return this.inspectorGridService.getAccountInfo().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class CountryResolver  {
  constructor(private locationsService: LocationsService) {}

  resolve(): Observable<any> {
    return this.locationsService.getCountries().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class RegionResolver  {
  constructor(private locationsService: LocationsService) {}

  resolve(): Observable<any> {
    return this.locationsService.getAllRegions().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}
@Injectable()
export class CitiesResolver  {
  constructor(private locationsService: LocationsService) {}

  resolve(): Observable<any> {
    return this.locationsService.getCoveredCities().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class CertificatesResolver  {
  constructor(private inspectorGridService: InspectorGridService) {}

  resolve(): Observable<any> {
    return this.inspectorGridService.getCertificates().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class AccountCategoryResolver  {
  constructor(private inspectorService: InspectorsService) {}

  resolve(): Observable<any> {
    return this.inspectorService.getCategories().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class OrderStatusResolver  {
  constructor(
    private inspectionService: InspectionService
  ) {}

  resolve(): Observable<any> {
    return this.inspectionService.getInspectionStatus().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}

@Injectable()
export class FacilitiesResolver  {
  constructor(private inspectorGridService: InspectorGridService) {}

  resolve(): Observable<any> {
    return this.inspectorGridService.getFacilities().pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }
}
