import { Component } from '@angular/core';
/**
 * The bank parent component
*/
@Component({
  selector: 'app-bank-cards',
  templateUrl: './bank-cards.component.html',
  styleUrls: []
})
export class BankCardsComponent {

}
