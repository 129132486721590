import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-inspector-avaliablity-render',
  templateUrl: './inspector-avaliablity-render.component.html',
  styleUrls: ['./inspector-avaliablity-render.component.scss']
})
export class InspectorAvaliablityRenderComponent implements ICellRendererAngularComp {
  params: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any) {
    this.params = params;
    return true;
  }
}
