<div class="error-page">
    <div class="container">
        <div class="row">
          <div class="col-md-6 text-center">
           <div class="error-comp">
             <h2>404</h2>
             <p>لم يتم العثور على الصفحة.‎</p>
             <div>
               <a class="back-page" (click)="goBack()">العودة للصفحة السابقة</a>
               <a  (click)="goHome()">  الصفحة الرئيسية</a>
             </div>
           </div>
          </div>
          <div class="col-md-6 text-center">
            <img src="./assets/images/svg/notFound.svg" alt=""/>
           </div>
       </div>
      </div>
</div>
