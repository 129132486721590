<div class="info-content">
  <ul class="nav nav-tabs horizontal-tab" id="myTab" role="tablist">
    <li class="nav-item">
      <a
        class="nav-link active"
        id="personal-tab"
        data-toggle="tab"
        href="#personal_notifications"
        role="tab"
        aria-controls="personal_notifications"
        aria-selected="true"
        >الخاصة
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        id="general-tab"
        data-toggle="tab"
        href="#general_notifications"
        role="tab"
        aria-controls="general_notifications"
        aria-selected="false"
      >
        العامة</a
      >
    </li>
  </ul>
  <div class="tab-content horizontal-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="personal_notifications"
      role="tabpanel"
      aria-labelledby="personal-tab"
    >
      <div class="table-responsive">
        <table class="table" aria-label="">
          <thead>
            <tr>
              <th class="width-70" id="">نص الاشعار</th>
              <th class="width-15" id="">الرابط</th>
              <th class="width-15" id="">تاريخ الإرسال</th>
            </tr>
          </thead>
          <tbody>
            @for (privateNotification of privateNotifications | paginate:
            privateConfig; track privateNotification) {
            <tr>
              <td>{{ convertHtml(privateNotification.body) }}</td>
              <td>{{ privateNotification.url }}</td>
              <td>{{ privateNotification.date_added }}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>

      @if (privateNotifications && privateNotifications.length > 0) {
      <ayen-pagination
        (pageData)="privatePageChanged($event)"
        (pageSize)="privatePageSizeChanged($event)"
        [totalCount]="privateConfig.totalItems"
        [id]="'privatePagination'"
      >
      </ayen-pagination>
      }
    </div>
    <div
      class="tab-pane fade"
      id="general_notifications"
      role="tabpanel"
      aria-labelledby="general-tab"
    >
      <div class="table-responsive">
        <table class="table" aria-label="">
          <thead>
            <tr>
              <th class="width-70" id="">نص الاشعار</th>
              <th class="width-15" id="">الرابط</th>
              <th class="width-15" id="">تاريخ الإرسال</th>
            </tr>
          </thead>
          <tbody>
            @for (generalNotification of generalNotifications | paginate:
            generalConfig; track generalNotification) {
            <tr>
              <td>{{ generalNotification.body }}</td>
              <td>{{ generalNotification.url }}</td>
              <td>{{ generalNotification.date_added }}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>

      @if (generalNotifications && generalNotifications.length > 0) {
      <ayen-pagination
        (pageData)="generalPageChanged($event)"
        (pageSize)="generalPageSizeChanged($event)"
        [totalCount]="generalConfig.totalItems"
        [id]="'generalPagination'"
      >
      </ayen-pagination>
      }
    </div>
  </div>
</div>
