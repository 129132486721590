import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { BankCardsService } from '../../../../services/bank-cards/bank-cards.service';
import { SelectUtil } from 'src/app/core/services/uitls/selectUtil';

@Component({
  selector: 'app-bank-list',
  templateUrl: './bank-list.component.html',
  styleUrls: ['./bank-list.component.scss'],
})
export class BankListComponent implements OnInit {
  searchValue: string = '';
  bankdata: any[] = [];
  config: any;
  page: number = 1;
  totalRecords: number = 0;
  selectedRowsIndex: any[] = [];
  selectedRowsPK: any[] = [];
  confirmMsg: string;
  modelImg: boolean;
  deletedBankIndex: any[];
  deletedBankID: any[];
  disabledDelete: boolean = true;
  saveDialogRef: MatDialogRef<AlertBoxComponent>;
  deleteDialogRef: MatDialogRef<ConfirmationBoxComponent>;
  @ViewChildren('checkBoxList') checkBoxList: ElementRef<HTMLInputElement>[];
  @ViewChild('selectAllElm') selectAllElm: ElementRef<HTMLInputElement>;
  pageTitle: string = 'البطاقات البنكية';
  totalRowsSelected: boolean = false;
  params: any= {};
  currentItemsPerPage: number;

  constructor(
    private bankService: BankCardsService,
    private titleService: Title,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.config = {
      id: 'myPagination',
      itemsPerPage: 20,
      currentPage: this.page,
      totalItems: this.totalRecords,
    };
  }

  ngOnInit(): void {
    this.getBanksByPage();
  }

  addBank() {
    this.router.navigateByUrl('/pages/coupons/add-bank/0');
    this.titleService.setTitle('إضافة بطاقة بنكية | عاين');
  }

  openBank(id) {
    this.router.navigateByUrl('/pages/coupons/add-bank/' + id);
    this.titleService.setTitle('تعديل بطاقة بنكية #' + id + '  | عاين');
  }

  selectAll(event) {
    this.disabledDelete = true;
    SelectUtil.selectAll(event, this);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  selectsSingleRow(event, rowPk, index) {
    this.disabledDelete = true;
    this.totalRowsSelected = false;
    SelectUtil.selectsSingleRow(event, rowPk, index, this);
    if (this.selectedRowsPK.length > 0) {
      this.disabledDelete = false;
    }
  }

  selectTotalRows(event) {
    this.totalRowsSelected = event;
  }

  deleteSelectedRows() {
    this.deletedBankID = this.selectedRowsPK;
    this.deletedBankIndex = this.selectedRowsIndex;
    this.showDeleteConfirmationBox();
  }

  showDeleteConfirmationBox() {
    this.deleteDialogRef = this.dialog.open(ConfirmationBoxComponent, {
      data: {
        id: this.deletedBankID,
        index: this.deletedBankIndex,
        message: 'هل أنت متأكد من حذف رقم / أرقام البطاقة البنكية؟',
      },
    });
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete(result);
      }
    });
  }

  delete(event) {
    let index = event.index;
    let pk = event.id;
    this.bankService.deletBank(pk).subscribe(
      () => {
        for (let item of index) {
          let bankInd = item;
          this.bankdata.splice(bankInd, 1);
        }
        this.selectedRowsPK = [];
        this.selectedRowsIndex = [];
      },
      (error) => {
        this.modelImg = false;
        this.confirmMsg =
          '    عذراً لا يمكن حذف رقم / أرقام البطاقة البنكية كونه مستخدم. ‎';
        this.showAlertBox();
        this.selectedRowsPK = [];
        this.selectedRowsIndex = [];
        this.handleBackendError(error);
      }
    );
  }

  showAlertBox() {
    this.saveDialogRef = this.dialog.open(AlertBoxComponent, {
      data: {
        imgSuccess: this.modelImg,
        message: this.confirmMsg,
        button: 'تم',
      },
    });
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.totalRowsSelected = false;
    this.getBanksByPage();
  }
  pageSizeChanged(event) {
    this.params['page_size'] = event;
    this.getBanksByPage();

  }
  quickSearch(event) {
    this.searchValue = event;
    this.config.currentPage = 1;
    this.getBanksByPage();
  }

  getBanksByPage() {
    this.params['page'] = this.config.currentPage;
    this.params['query'] = this.searchValue;
    this.bankService
      .getBanksByPage(this.params)
      .subscribe(
        (data) => {
          this.bankdata = data.results;
          this.config['totalItems'] = data.count;
          this.currentItemsPerPage = data.results.length;
        },
        (error) => {
          this.handleBackendError(error);
        }
      );
  }

  handleBackendError(error) {
    try {
      console.log(error);
    } catch {
      console.log(error);
    }
  }
}
