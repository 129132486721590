@if (infrastructure$ | async; as infrastructure) {
  <div>
    <div class="items-wrapper">
      @for (item of infrastructure; track item; let i = $index) {
        <div class="item" >
          <div class="checkBox-wrapper">
            <label class="checkbox-container" >
              <input type="checkbox" [attr.checked]="item.value ? true : null" [id]="item.id" (click)="itemValueChanged(infrastructure , $event , i )">
              <span class="checkmark"> </span>
            </label>
            <span class="checkbox-label">{{item.label_ar}}</span>
          </div>
        </div>
      }
    </div>
    <div  class="row">
      <div class="col-md-12 save-btn-wrapper">
        <button class="btn btn-green" (click)="save(infrastructure)">
          @if (showSpinner) {
            <img class="spin-img" src="assets/images/svg/Spinner-s.svg" alt=""/>
          }
          حفظ
        </button>
      </div>
    </div>
  </div>
}

