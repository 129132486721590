<div class="rating-content" >
  @if (customerReviewExist) {
    <div>
      <div class="row">
        <div class="col">
          <h4 class="title"> العميل</h4>
        </div>
      </div>
      <div class="row  rating-type">
        <div class="col-md-4 col">
          <h4> مستوى تقديم الخدمة </h4>
          <div class="stars" dir="rtl">
            @for (ratingId of ratingArr; track ratingId; let i = $index) {
              <button mat-icon-button   [id]="'star_'+i" >
                <img dir="rtl" alt="" [src]="showIcon(i , customerReview?.service_rating)" height="19px" width="18px"/>
              </button>
            }
          </div>
          <div class="probability">
            <h4> ما احتمالية أن توصي بخدمات عاين؟</h4>
            <p class="reco-box " > {{customerReview?.service_recommendation}} </p>
          </div>
        </div>
        <div class="col-md-6 col">
          <h4>الملاحظات</h4>
          <p class="review-note"> {{customerReview?.customer_notes}}</p>
        </div>
        <div class="col-md-2 col">
          <div class="switch-view">
            <span>إخفاء التقييم</span>
            <app-switch-input
              #switchInputCustomerReview
              [label]=""
              [labelEn] = "'customerReview'"
              [value]="!customerReview?.active"
              [index]="1"
              (valueChanged) = "hideValueChanged($event, switchInputCustomerReview)">
            </app-switch-input>
          </div>
        </div>
      </div>
    </div>
  }
  @if (qcReviewExist && customerReviewExist) {
    <div class="border-line"></div>
  }

  @if (qcReviewExist) {
    <div>
      <div class="row">
        <div class="col next-view">
          <h4 class="title">قسم الجودة </h4>
        </div>
      </div>
      <div class="row rating-type">
        <div class="col-md-4 col">
          <h4> مستوى تقديم الخدمة </h4>
          <div class="stars" dir="rtl">
            @for (ratingId of ratingArr; track ratingId; let i = $index) {
              <button mat-icon-button   [id]="'star_'+i" >
                <img dir="rtl" [src]="showIcon(i , qcReview?.inspector_rating)" alt="" height="19px" width="18px"/>
              </button>
            }
          </div>
        </div>
        <div class="col-md-6 col">
          <h4>الملاحظات</h4>
          <p class="review-note"> {{qcReview?.qc_notes}}</p>
        </div>
        <div class="col-md-2 col">
          <div class="switch-view">
            <span>إخفاء التقييم</span>
            <app-switch-input
              #switchInputQcReview
              [label]=""
              [labelEn] = "'qcReview'"
              [value]="!qcReview?.active"
              [index]="2"
              (valueChanged) = "hideValueChanged($event, switchInputQcReview)">
            </app-switch-input>
          </div>
        </div>
      </div>
    </div>
  }

</div>
