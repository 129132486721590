import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
/**
  declare  reset password component
 *  
*/
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
/**
  declare alertmsg variable
 *  
*/
  alertmsg: boolean = false;
/**
  declare error message
 *  
*/
  errorMsg :string;
/**
  declare reset formGroup variable
 *  
*/
  resetFormGroup: UntypedFormGroup;
/**
  declare constructor
 *  
*/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private resetPassword: AuthenticationService,
    private titleService: Title,
  ) {}
/**
  declare ngAfterViewInit function
 *  
*/
  ngAfterViewInit() {
    document.querySelector('body').classList.add('login');
  }
/**
  declare ngonDestory function
 *  
*/
  ngOnDestroy() {
   document.querySelector('body').classList.remove('login');
  }
/**
  declare ngonint
 *  
*/
  ngOnInit(): void {
    this.createForm();
    this.createFormControls();
    this.titleService.setTitle(' استعادة كلمة المرور | عاين');
  }
/**
  declare creatFormControl function
 *  
*/
  createFormControls() {
    // Initilize controlers for reset-password form
    return this.resetFormGroup.controls;
  }
/**
  declare create form function
 *  
*/
  createForm() {
    // Create reset-password form
    this.resetFormGroup = this.formBuilder.group({
      // verification_code: [null, Validators.required],
      new_password1: [null, Validators.required],
      new_password2: [null, Validators.required],
    });
  }
/**
  declare save function for rest password
 *  
*/
  // submit for rest password
  save() {
    console.log('clicked');
    if (
      this.resetFormGroup.controls['new_password1'].value ===
      this.resetFormGroup.controls['new_password2'].value
    ) {
      const sendedData = this.resetFormGroup.value;
      const uid = this.activatedRoute.snapshot.params.uid;
      console.log(uid); // OUTPUT 1534
      const token = this.activatedRoute.snapshot.params.token;
      console.log(token); // OUTPUT red
      sendedData['uid'] = uid;
      sendedData['token'] = token;
      console.log(sendedData);
      this.resetPassword.resetPassword(sendedData).subscribe((resp) => {
        console.log(resp);
        this.router.navigateByUrl('/auth/login');
      },
      (error)=>{
        console.log(error);
        this.alertmsg = true;
        this.errorMsg =  Object.values(error.error)[0].toString() ;

      });
    } else {
      this.errorMsg = 'كلمة المرور الجديدة غير متطابقتان'
      this.alertmsg = true;
    }
  }

  closeError(){
    this.alertmsg = false;
  }
}
