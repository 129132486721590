import { Component,HostListener, OnInit } from '@angular/core';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  isReadable: boolean = false;
  readTooltip: string = "جعله مقروء";
  activeItems: string[] = [];
  noitify_Filter: any[] = [];
  allNotification: any[] = [];
  ReadStatus: boolean = false;
  params='?page=';
  page:number=1;
  page_count: number=0;
   @HostListener('scroll', ['$event'])

   onScroll(event: any ) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight 
      && this.page < this.page_count) {
     this.page++;
     this.params=this.params+this.page;
      this.GetAllNotification(this.params);
      this.params="?page=";
    }
   }

  constructor(private Notifications: NotificationsService) { }

  ngOnInit(): void {
    this.getFilters();
    this.GetAllNotification(this.params+'1');
  }

  radioBtnToggle(isReadable, id, index) {
    this.ReadStatus = isReadable;
    this.ReadStatus = !this.ReadStatus;
    this.Notifications.toggleNotification(id).subscribe(data => {
      this.ReadStatus = data['is_read'];
      this.allNotification[index]['is_read'] = data['is_read'];
      console.log(data)
    }, error => {
      this.handleBackendError(error);
    });
  }

  ToggleImg(item) {
    let paramtype = '';
    let params = '';
    if (this.activeItems.includes(item)) {
      this.activeItems.splice(this.activeItems.indexOf(item), 1);
    }
    else {
      this.activeItems.push(item);
    }
    if (this.activeItems.length > 1) {
      this.activeItems.forEach(elem => {
        paramtype += elem + ',';
      });
      params = '?notification_type__name=' + paramtype;
    }
    else if (this.activeItems.length == 0) {
      params = '?'
    }
    else {
      paramtype = this.activeItems[0];
      params = '?notification_type__name=' + paramtype;
    }
    this.Notifications.getNotificationListHeader(params).subscribe(filterData => {
      this.allNotification = filterData.results;
    }, error => {
      this.handleBackendError(error);
    })
  }

  checkActive(active) {
    return this.activeItems.includes(active) ? true : false;
  }

  getFilters() {
    this.Notifications.getFilterIcons().subscribe(data => {
      this.noitify_Filter = data;
    },error=>{
      this.handleBackendError(error);
    })
  }

  handleBackendError(err){
    try {
      console.log(err);
    } catch {
      
    }
  }
  
  GetAllNotification(params) {
    this.Notifications.getNotificationListHeader(params).subscribe(data => {
      this.page_count=data.pages_count;
      this.allNotification.push(...data.results) ;
    });
  }
}
