<div class="inner-page-header">
  <h3>بروفايل العميل</h3>
</div>
@if (customerData) {
  <div class="main-content">
    <form class="needs-validation" [formGroup]="customerProfileForm">
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngClass]="{
          'closed-panel': panelOpenState == false,
          'open-panel': panelOpenState == true
          }" class="custom-panel">
          <mat-expansion-panel-header>
            <mat-panel-title> البيانات الأساسية</mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="panel-body">
            @if (customerData) {
              <user-main-info
                [subForm]="customerProfileForm"
                [userData]="customerData">
              </user-main-info>
            }
            <div class="row">
              <div class="col-md-6">
                <div class="form-group custom-form-panel">
                  <label for="" class="form-label"> حالة الحساب </label>
                  <ng-select bindLabel="name" class="single-select"
                    #select
                    placeholder="  يرجى اختيار حالة الحساب  "
                    appendTo="body"
                    formControlName="account_status"
                    [searchable]="true"
                    [clearable]="false">
                    @for (vrification of verifications; track vrification) {
                      <ng-option [value]="vrification.id">
                        {{vrification.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group custom-form-panel">
                  <label for="" class="form-label">حالة البريد</label>
                  <ng-select bindLabel="name" class="single-select"
                    #select
                    placeholder="يرجى اختيار حالة البريد  "
                    appendTo="body"
                    formControlName="email_status"
                    [searchable]="true"
                    [clearable]="false">
                    @for (vrification of verifications; track vrification) {
                      <ng-option [value]="vrification.id">
                        {{vrification.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngClass]="{
          'closed-panel': panelOpenState == false,
          'open-panel': panelOpenState == true
          }" class="custom-panel">
          <mat-expansion-panel-header>
            <mat-panel-title> الاشعارات</mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="panel-body">
            <activat-notifications
              [is_SMS]="is_SMS"
              [is_Email]="is_Email"
              (switchEvent)="switchBtnChanged($event)">
            </activat-notifications>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngClass]="{
          'closed-panel': panelOpenState == false,
          'open-panel': panelOpenState == true
          }">
          <mat-expansion-panel-header>
            <mat-panel-title>سجل الاشعارات</mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="panel-body">
            <notifications-log userType="CLT" [userID]="customerID"></notifications-log>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </form>
    <div class="row">
      <div class="col-md-12">
        @if (alertmsg) {
          <div class="alert-box">
            <img
              src="assets/images/svg/error-icon.svg"
              class="close-alert"
              alt=""
              (click)="closeError()"
              />
              <p class="alert-msg">{{errorMessage}}</p>
            </div>
          }
        </div>
      </div>
      <div  class="row">
        <div class="col-md-12 double-action">
          <button class="btn btn-cancel" type="button" (click) = "back()">
            رجوع
          </button>
          <button class="btn btn-green" id='saveBtn' type="submit" (click) = "save()">
            حفظ
          </button>
        </div>
      </div>
    </div>
  }
