<button
  class="btn btn-custom-delete"
  #t="matMenuTrigger"
  mat-button
  [matMenuTriggerFor]="menu"
  (menuOpened)="menuOpened()">
  <img [src]="t.menuOpen ? 'assets/images/svg/close-filter.svg' : 'assets/images/svg/new-filter.svg'" alt="" />
  @if (columnsFormArray.length > 1 && !t.menuOpen) {
    <span >{{columnsFormArray.length - 1}}</span>
  }
</button>
<mat-menu #menu="matMenu" backdropClass="custom-menu">
  <div class="filter-content" (click)="$event.stopPropagation()">
    <div class="title">
      <h2>فلتر سريع</h2>
    </div>
    <form [formGroup]="filtersForm" class="needs-validation">
      @for (columnData of columnsObject.controls; track columnData; let i = $index) {
        <div
          class="row"
          >
          <div [formGroup]="columnData" class="form-row">
            <div class="col-md-6">
              <ng-select
                class="single-select"
                bindLabel="name"
                formControlName="columnName"
                placeholder="المعيار"
                appendTo="body"
                (change)="selectedType($event, i)"
                >
                @for (column of columns; track column) {
                  <ng-option [value]="column.value">
                    {{ column.display_name }}
                  </ng-option>
                }
              </ng-select>
            </div>
            <div class="col-md-6">
              <div class="input-style">
                @switch (columnType[i]) {
                  @default {
                    <input
                      type="text"
                      placeholder="القيمة"
                      formControlName="value"
                      class="form-control"
                      (input)="addRow()"
                      />
                  }
                  @case ('number') {
                    <input
                      type="number"
                      placeholder="القيمة"
                      class="form-control"
                      formControlName="value"
                      (input)="addRow()"
                      />
                  }
                  @case ('date') {
                    <mat-form-field
                      (click)="resultPicker.open()"
                      placeholder="القيمة"
                      appearance="outline"
                      >
                      <mat-date-range-input [rangePicker]="resultPicker">
                        <input matStartDate formControlName="startDate" />
                        <input
                          matEndDate
                          formControlName="endDate"
                          (dateChange)="changeDate(i)"
                          />
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="resultPicker">
                          <mat-icon matDatepickerToggleIcon class="calender-icon">
                            <img
                              src="assets/images/svg/calendar-admin-icon.svg"
                              alt=""
                              />
                            </mat-icon>
                          </mat-datepicker-toggle>
                          <mat-date-range-picker #resultPicker></mat-date-range-picker>
                        </mat-form-field>
                      }
                      @case ('multi_select') {
                        <app-select
                          class="app-select"
                          [optionItems]="selectOptions[i]"
                          [id]="'multi_' + i"
                          [maxLength]="1"
                          [initValues]="selectedVals[i]"
                          [placeholder]="placeholder"
                          (selectedOptions)="setSelectedOptions($event, i)"
                        ></app-select>
                      }
                      @case ('select') {
                        <ng-select
                          class="single-select"
                          bindLabel="name"
                          [id]="'single_' + i"
                          formControlName="value"
                          placeholder="القيمة"
                          (change)="addRow()"
                          appendTo="body"
                          >
                          @for (column of selectOptions[i]; track column) {
                            <ng-option
                              [value]="column.id"
                              >
                              {{ column.name }}
                            </ng-option>
                          }
                        </ng-select>
                      }
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </form>

        <div class="footer-btns">
          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-cancel" (click)="clearFilters(true)">
                إعادة تعيين
              </button>
              @if (enableSave) {
                <button
                class="btn btn-cancel"
                (click)="saveFilter(); trigger.closeMenu()"
                >
                حفظ التصفية
              </button>
              }
            </div>
            <div class="col-md-6">
              <button
                class="btn btn-green"
                (click)="search(); trigger.closeMenu()"
                [disabled]="
                this.filtersForm.value.columnData[0].value === '' &&
                this.filtersForm.value.columnData[0].startDate === null
                "
                >
                بحث
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-menu>
