<div class="autocompletePanel" #container>
  <form class="example-form">
    <mat-form-field class="example-full-width">
      <input type="text"
        matInput
        #autocomplete
        [formControl]="myControl"
        [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          @for (option of filteredOptions | async; track option) {
            <mat-option
              (onSelectionChange)="_autoCompleteChanged(option)" [value]="option.name">
              {{option.name}}
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>