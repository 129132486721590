<div class="report-log">
  <div class="table-responsive">
    <table id="inspection-log-table" class="table table-striped " aria-label="">
      <thead>
        <tr>
          <th scope="col"> رقم التقرير</th>
          <th scope="col"> تاريخ إصدار التقرير العملية</th>
          <th scope="col">مصدر التقرير  </th>
        </tr>
      </thead>
      <tbody>
        @for (order of orders?.order_delivery; track order) {
          <tr>
            <td>{{order?.report_number}}</td>
            <td>{{order?.delivery_date}}</td>
            @if(order?.inspector) {
            <td>{{order?.inspector?.name}}</td>
            }
          </tr>
        }

      </tbody>
    </table>
  </div>
</div>
