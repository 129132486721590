<mat-form-field class="example-full-width" (click)="picker.open()" appearance="outline">
    <input matInput 
    [matDatepicker]="picker" 
    [value]="'1/2/2020'" 
    (dateChange)="onDateChanged($event)"  disabled/>
    <mat-datepicker-toggle
      matSuffix
      [for]="picker">
      <mat-icon matDatepickerToggleIcon class="calender-icon">
          <img src="assets/images/svg/calendar-admin-icon.svg" alt="">
      </mat-icon>
  </mat-datepicker-toggle>
    <mat-datepicker #picker disabled="false" ></mat-datepicker>
  </mat-form-field>
