<div class="row">
  <div class="col-md-3 basic-data">
    <h4> رقم الطلب</h4>
    <p> {{basicInfo.reference_number ? basicInfo.reference_number : '-'}} </p>
  </div>
  <div class="col-md-3 basic-data">
    <h4>اسم العميل</h4>
    <div class="double-tooltip">
      <p>  {{basicInfo.order_client ? basicInfo.order_client :'-'}}
        <span class="custom-tooltip">
          <img src="assets/images/svg/phone-tooltip.svg" alt="" />
          <span class="tooltip-text">{{basicInfo.mobile ? basicInfo.mobile :'-'}}</span>
        </span>
        <span class="custom-tooltip">
          <img src="assets/images/svg/email-tooltip.svg" alt="" />
          <span class="tooltip-text">{{basicInfo.email ? basicInfo.email :'-'}}</span>
        </span>
      </p>
    </div>
  </div>
  <div class="col-md-3 basic-data">
    <h4> تاريخ الإنشاء</h4>
    <p> {{dateFormate(basicInfo.date_added)}}  </p>
  </div>
  <div class="col-md-3 basic-data">
    <h4> تاريخ تسليم التقرير </h4>
    <p>{{dateFormate(basicInfo.order_close_date)}} </p>
  </div>
  <div class="col-md-3 basic-data">
    <h4> حالة الطلب </h4>
    <p>{{basicInfo.status ? basicInfo.status :'-'}}  </p>
  </div>
  <div class="col-md-3 basic-data">
    <h4>  الخدمة</h4>
    <p>{{basicInfo.service ? basicInfo.service : '-'}} </p>
  </div>
  <div class="col-md-3 basic-data">
    <h4>  نوع الوحدة </h4>
    <p> {{basicInfo.building_type ? basicInfo.building_type : '-'}}  </p>
  </div>
  <div class="col-md-3 basic-data">
    <h4> عنوان الوحدة </h4>
    <div class="double-tooltip">
      <p>
        {{getAddressDescription(basicInfo.address)}}
        @if (basicInfo.address) {
          <span class="custom-tooltip">
            <img (click)="copyAddress()" src="assets/images/svg/copy.svg" alt="" />
            <span class="tooltip-text">نسخ العنوان</span>
          </span>
        }
        @if (basicInfo.address) {
          <span class="custom-tooltip">
            <img (click)="openLocation()" src="assets/images/svg/view-address.svg" alt="" />
            <span class="tooltip-text">عرض العنوان على الخريطة</span>
          </span>
        }
      </p>
    </div>
  </div>