import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AYENServiceBase } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class InspectorsService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getAvailableInspectors(params): Observable<any> {
    return this.http
      .get(this.baseURL + `/available-inspectors/`, { params: params })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  deleteInspector(inspectorId): Observable<any> {
    return this.http.delete(this.baseURL + '/inspectors/' + inspectorId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  deleteInspectorFile(inspectorId, fileName): Observable<any> {
    return this.http
      .post(
        this.baseURL + '/delete-inspector-document/' + inspectorId + '/',
        fileName
      )
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getPrivateNotifications(id, params): Observable<any> {
    return this.http
      .get(
        this.baseURL + `/get-private-notification-for-user/${id}`,{
          params: params
        }
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getGeneralNotifications(params): Observable<any> {
    return this.http
      .get(
        this.baseURL +
          `/admin/list-general-notifications`,{
            params: params
          }
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getCategories(): Observable<any> {
    return this.http.get(this.baseURL + `/inspector-categories/`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getInspectorByID(id): Observable<any> {
    return this.http.get(this.baseURL + `/inspectors/${id}`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getAcadamicMajor(): Observable<any> {
    return this.http.get(this.baseURL + '/academic-majors/').pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  assignInspector(inspectorId, data): Observable<any> {
    return this.http
      .post(this.baseURL + '/admin/inspectors/' + inspectorId + '/orders', data)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  changePaymentInspectorsStatus(orderIDs, isPaid): Observable<any> {
    let data = {
      is_paid: isPaid,
      orders_ids: orderIDs,
    };
    return this.http.put<any>(this.baseURL + `/inspector-share-is-paid/`, data);
  }

  getSpecialQualifications(): Observable<any> {
    return this.http.get(this.baseURL + `/special-qualification-tags/`);
  }

  addSpectialQualification(specialQualification: string): Observable<any> {
    const data = {
      name: specialQualification
    }
    return this.http.post(this.baseURL + `/special-qualification-tags/`, data);
  }

  updateSpecialQualification(specialQualification: string, id): Observable<any> {
    const data = {
      name: specialQualification
    }
    return this.http.put(this.baseURL + `/special-qualification-tags/${id}`, data);
  }

  handleError(error) {
    let errorMessage;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
