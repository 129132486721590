import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SpinnerComponent } from './components/spinner/spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { AddClientComponent } from './components/add-client/add-client.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileModalComponent } from './components/file-modal/file-modal.component';
import { ReportModalComponent } from './components/report-modal/report-modal.component';
import { AutoCompleteComponent } from './components/ag-grid/auto-complete/auto-complete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CountryRenderComponent } from './components/ag-grid/country-render/country-render.component';
import { InspectorMultiselectComponent } from './components/ag-grid/inspector-multiselect/inspector-multiselect.component';
import { InspectorMultiselectRenderComponent } from './components/ag-grid/inspector-multiselect-render/inspector-multiselect-render.component';
import { StatusComponent } from './components/ag-grid/status/status.component';
import { StatusEditorComponent } from './components/ag-grid/status-editor/status-editor.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RatingStarsRenderComponent } from './components/ag-grid/rating-stars-render/rating-stars-render.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { SelectEditorComponent } from './components/ag-grid/select-editor/select-editor.component';
import { SelectRenderComponent } from './components/ag-grid/select-render/select-render.component';
import { RegisterAsComponent } from './components/ag-grid/register-as/register-as.component';
import { DatepickerComponent } from './components/ag-grid/datepicker/datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { AvatarModelComponent } from './components/avatar-model/avatar-model.component';
import { SwitchInputComponent } from './components/switch-input/switch-input.component';
import { PhoneNumberDirective } from './directive/phone-number.directive';
import { SelectComponent } from './components/select/select.component';
import { NumberDirective } from './directive/numbers.directive';
import { FilterComponent } from './components/filter/filter.component';
import { InspectorRatingModalComponent } from './components/ag-grid/inspector-rating-modal/inspector-rating-modal.component';
import { RatingStarEditorComponent } from './components/ag-grid/rating-star-editor/rating-star-editor.component';
import { MultiselectComponent } from './components/dynamicElments/multiselect/multiselect.component';
import { DateComponent } from './components/dynamicElments/date/date.component';
import { InputsComponent } from './components/dynamicElments/inputs/inputs.component';
import { DateRangeComponent } from './components/dynamicElments/date-range/date-range.component';
import { FilesComponent } from './components/dynamicElments/files/files.component';
import { RadioBtnsComponent } from './components/dynamicElments/radio-btns/radio-btns.component';
import { CheckboxBtnComponent } from './components/dynamicElments/checkbox-btn/checkbox-btn.component';
import { TextareaComponent } from './components/dynamicElments/textarea/textarea.component';
import { ModalDynamicComponent } from './components/dynamicElments/modal-dynamic/modal-dynamic.component';
import { ComponentHostComponent } from './components/dynamicElments/component-host/component-host.component';
import { SelectDynamicComponent } from './components/dynamicElments/select/select.component';
import { ImgViewerComponent } from './components/img-viewer/img-viewer.component';
import { SendNotificationComponent } from './components/send-notification/send-notification.component';
import { SecurityErrorComponent } from './components/security-error/security-error.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NotificationsLogComponent } from './components/notifications-log/notifications-log.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ActivatNotificationsComponent } from './components/activat-notifications/activat-notifications.component';
import { UserMainInfoComponent } from './components/user-main-info/user-main-info.component';
import { AssignInspectorComponent } from './components/assign-inspector/assign-inspector.component';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AssignOrdersProjectComponent } from './components/assign-orders-project/assign-orders-project.component';
import { QuickSearchComponent } from './components/quick-search/quick-search.component';
import { SaveFilterComponent } from './components/save-filter/save-filter.component';
import { SavedFiltersComponent } from './components/saved-filters/saved-filters.component';
import { OrderTableComponent } from './components/order-table/order-table.component';
import { DeleteSelectionComponent } from './components/delete-selection/delete-selection.component';
import { CustomHeaderComponent } from './components/ag-grid/custom-header/custom-header.component';
import { NotificationMenuComponent } from './components/notification-menu/notification-menu.component';
import { AvatarRenderComponent } from './components/ag-grid/avatar-render/avatar-render.component';
import { FileUploadRenderComponent } from './components/ag-grid/file-upload-render/file-upload-render.component';
import { InspectorAvaliablityRenderComponent } from './components/ag-grid/inspector-avaliablity-render/inspector-avaliablity-render.component';
import { TagElementRenderComponent } from './components/ag-grid/tag-element-render/tag-element-render.component';
import { OrdersManagementComponent } from './components/orders-management/orders-management.component';
import { AddOrderComponent } from './components/add-order/add-order.component';
import { SelectPaginatedComponent } from './components/fields/select-paginated/select-paginated.component';
import { RouterModule } from '@angular/router';
import { PromptBoxComponent } from './components/prompt-box/prompt-box.component';
import { WhatsNewModalComponent } from './components/whats-new-modal/whats-new-modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SelectedCountComponent } from './components/selected-count/selected-count.component';
import { CustomerRatingEditorComponent } from './components/ag-grid/customer-rating-editor/customer-rating-editor.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { provideHttpClient, withJsonpSupport } from '@angular/common/http';
import { RegionMapComponent } from './components/statistics/region-map/region-map.component';
import { BasicColumnComponent } from './components/statistics/basic-column/basic-column.component';
import { CardFilterComponent } from './components/statistics/card-filter/card-filter.component';
import { CardStatsComponent } from './components/statistics/card-stats/card-stats.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { DynamicMapComponent } from './components/dynamicElements/dynamic-map/dynamic-map.component';
import {AyenMapModule } from 'ayen-google-map';
import { PromptColorBoxComponent } from './components/prompt-color-box/prompt-color-box.component';
import { CompletionStatusComponent } from './components/completion-status/completion-status.component';
import { LinkedOrderComponent } from './components/linked-order/linked-order.component';

class CustomDateAdapter extends MomentDateAdapter {
  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return [
      'الأحد',
      'الإثنين',
      'الثلاثاء',
      'الأربعاء',
      'الخميس',
      'الجمعة',
      'السبت',
    ];
  }
}

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MMM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [
    SpinnerComponent,
    AlertBoxComponent,
    ConfirmationBoxComponent,
    AddClientComponent,
    FileModalComponent,
    ReportModalComponent,
    AutoCompleteComponent,
    CountryRenderComponent,
    InspectorMultiselectComponent,
    InspectorMultiselectRenderComponent,
    StatusComponent,
    StatusEditorComponent,
    RatingStarsRenderComponent,
    SelectEditorComponent,
    SelectRenderComponent,
    RegisterAsComponent,
    DatepickerComponent,
    AvatarModelComponent,
    SwitchInputComponent,
    PhoneNumberDirective,
    SelectComponent,
    NumberDirective,
    FilterComponent,
    InspectorRatingModalComponent,
    RatingStarEditorComponent,
    MultiselectComponent,
    DateComponent,
    InputsComponent,
    DateRangeComponent,
    FilesComponent,
    RadioBtnsComponent,
    CheckboxBtnComponent,
    TextareaComponent,
    ModalDynamicComponent,
    ComponentHostComponent,
    SelectDynamicComponent,
    ImgViewerComponent,
    RegionMapComponent,
    SendNotificationComponent,
    SecurityErrorComponent,
    NotificationsLogComponent,
    ActivatNotificationsComponent,
    UserMainInfoComponent,
    AssignInspectorComponent,
    AssignOrdersProjectComponent,
    QuickSearchComponent,
    SaveFilterComponent,
    SavedFiltersComponent,
    OrderTableComponent,
    DeleteSelectionComponent,
    CustomHeaderComponent,
    NotificationMenuComponent,
    AvatarRenderComponent,
    FileUploadRenderComponent,
    InspectorAvaliablityRenderComponent,
    TagElementRenderComponent,
    OrdersManagementComponent,
    AddOrderComponent,
    SelectPaginatedComponent,
    PromptBoxComponent,
    WhatsNewModalComponent,
    PaginationComponent,
    SelectedCountComponent,
    CustomerRatingEditorComponent,
    BasicColumnComponent,
    CardFilterComponent,
    CardStatsComponent,
    FileInputComponent,
    DynamicMapComponent,
    PromptColorBoxComponent,
    CompletionStatusComponent,
    LinkedOrderComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatTooltipModule,
    MatAutocompleteModule,
    NgSelectModule,
    MatSnackBarModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatNativeDateModule,
    MatToolbarModule,
    NgxSummernoteModule,
    NgxPaginationModule,
    MatDialogModule,
    MatInputModule,
    GoogleMapsModule,
    AyenMapModule
  ],
  exports: [
    SpinnerComponent,
    AlertBoxComponent,
    ConfirmationBoxComponent,
    AddClientComponent,
    FileModalComponent,
    ReportModalComponent,
    MatAutocompleteModule,
    AvatarModelComponent,
    SwitchInputComponent,
    SelectComponent,
    FilterComponent,
    InspectorRatingModalComponent,
    MultiselectComponent,
    DateComponent,
    InputsComponent,
    DateRangeComponent,
    FilesComponent,
    RadioBtnsComponent,
    CheckboxBtnComponent,
    TextareaComponent,
    ModalDynamicComponent,
    ComponentHostComponent,
    SelectDynamicComponent,
    ImgViewerComponent,
    RegionMapComponent,
    SendNotificationComponent,
    SecurityErrorComponent,
    NotificationsLogComponent,
    ActivatNotificationsComponent,
    UserMainInfoComponent,
    QuickSearchComponent,
    SaveFilterComponent,
    SavedFiltersComponent,
    OrderTableComponent,
    DeleteSelectionComponent,
    NotificationMenuComponent,
    OrdersManagementComponent,
    AddOrderComponent,
    SelectPaginatedComponent,
    WhatsNewModalComponent,
    PaginationComponent,
    SelectedCountComponent,
    BasicColumnComponent,
    CardFilterComponent,
    CardStatsComponent,
    FileInputComponent,
    DynamicMapComponent,
    PromptColorBoxComponent,
    CompletionStatusComponent
  ],
  providers: [
provideHttpClient(withJsonpSupport()),

    DatePipe,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-eg' },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
})
export class SharedModule {}
