<div class="card-stats-wrapper">
  <div class="card-content">
    <h3>
      {{ card?.value }}
      @if(valueDescription) {
      <span>{{ valueDescription }} ر.س</span>
      }
    </h3>
    <p>{{ card?.label }}</p>
  </div>
</div>
