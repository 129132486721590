@if (Loader) {
  <app-spinner></app-spinner>
}
@if (!Loader) {
  <form class="needs-validation" id="inspect-form" [formGroup]="inspectionDetailsFormGroup"
    (ngSubmit)="updateOrder()">
    <div class="main-card">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="" class="form-label"> {{dateLabel}}</label>
            <mat-form-field (click)="picker.open()" appearance="outline">
              <input matInput [min]="minDate" [placeholder]="datePlaceholder" formControlName="orderDate"
                [matDatepicker]="picker" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon class="calender-icon">
                    <img src="assets/images/svg/calendar-admin-icon.svg" alt="">
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="disableDate"></mat-datepicker>
              </mat-form-field>
              <div class="checkBox-wrapper">
                <label class="checkbox-container">
                  <input type="checkbox" formControlName="is_inspection_later" (click)="toggleDisableDate($event)">
                  <span class="checkmark"> </span>
                </label>
                <span class="checkbox-label"> موعد مفتوح
                  <small>(تحديد الموعد لاحقا)</small> </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="form-label"> الفترة </label>
                <app-select [optionItems]="inspectionPeriods" [placeholder]="'يرجى تحديد الفتره الفحص'"
                  [initValues]="selectedPeriods" [disableSelect]="disableSelect" (selectedOptions)="setOrderPeriods($event)">
                </app-select>
              </div>
            </div>
            @if (orders.order_type === orderType.Visit) {
              @if (orders.building_type != buildingType.Land && orders.building_type != buildingType.Other) {
                <div class="col-md-6"
                  >
                  <div class="form-group">
                    <label for="" class="form-label">حالة العقار</label>
                    <ng-select bindLabel="name" class="single-select" placeholder="يرجى اختيار حالة العقار" appendTo="body"
                      formControlName="building_status" [searchable]="true" [clearable]="true">
                      @for (type of buildingStatus; track type) {
                        <ng-option [value]="type.id">
                          {{type.name}}
                        </ng-option>
                      }
                    </ng-select>
                  </div>
                </div>
              }
              <div class="col-md-6">
                <div class="form-group">
                  <label for="" class="form-label"> تخصص مزود الخدمة</label>
                  <ng-select bindLabel="name" class="single-select" #selectStatus placeholder="يرجى تحديد تخصص مزود الخدمة"
                    appendTo="body" formControlName="inspector_academic_major" [searchable]="true" [clearable]="true">
                    @for (item of acadamicMajor; track item) {
                      <ng-option [value]="item.id">
                        {{item.name}}
                      </ng-option>
                    }
                  </ng-select>
                </div>
              </div>
            }
            <div class="col-md-12">
              <div class="notes-section form-group">
                <label for="" class="form-label">ملاحظات إضافية</label>
                <textarea class="form-control" name="notes" formControlName="notes" maxlength="500"></textarea>
              </div>
            </div>
          </div>
          @if (alertmsg) {
            <div class="alert-box">
              <img src="assets/images/svg/error-icon.svg" class="close-alert" alt="" (click)="hideErrorMsg()" />
              <p class="alert-msg">برجاء ادخال الحقول المطلوبة</p>
            </div>
          }
        </div>
        <div class="row">
          <div class="col-md-12 confirm-next-step double-action">
            <button class="btn btn-grey" type="button" (click)="prevStep()">
              السابق
            </button>
            <button class="btn btn-green" type="submit">
              التالي
            </button>
          </div>
        </div>
      </form>
    }
