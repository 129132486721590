<div class="dialog-container">
  <div mat-dialog-content>
    <div class="prompt-wrapper">
      <div class="form-group">
        <label for="" class="form-label">{{data.textLabel}}</label>
        <input type="text" [(ngModel)]="data.textValue" class="form-control" [placeholder]=data.placeholder />
      </div>
      <div class="form-group">
        <label for="" class="form-label">{{data.colorLabel}}</label>
        <ng-select bindLabel="name" class="single-select" placeholder="" appendTo="body" [(ngModel)]="data.colorValue"
          [searchable]="true" [clearable]="true">
          @for (color of data.options; track color) {
          <ng-option [value]="color">
            <span class="color-option" [ngStyle]="{'background-color': color}"></span>
          </ng-option>
          }
        </ng-select>
      </div>
    </div>

  </div>
  <div mat-dialog-actions class="setup-footer actions-double">
    <button class="btn btn-cancel" type="button" mat-dialog-close>إلغاء</button>
    <button class="btn btn-green" [mat-dialog-close]="data" type="submit">
      موافق
    </button>
  </div>
</div>
