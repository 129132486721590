import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-switch-input',
  templateUrl: './switch-input.component.html',
  styleUrls: ['./switch-input.component.scss'],
})
export class SwitchInputComponent {
  @Input() label: string = '';
  @Input() labelEn: string = '';
  @Input() labelDesc: string = '';
  @Input() value: boolean = false;
  @Input() index;
  @Input() disableBtn = false;
  @Output() valueChanged = new EventEmitter();
  @ViewChild('switchBtn') switchBtn: ElementRef<HTMLInputElement>;

  changeValue(event) {
    let value = event.checked;
    this.valueChanged.emit({
      label: this.label,
      labelEn: this.labelEn,
      value: value,
    });
  }
}
