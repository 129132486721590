export class Images {
  public static DEFAULT_SORT = '/assets/images/svg/sort-default.svg';
  public static ASC_SORT = '/assets/images/svg/sort-up.svg';
  public static DESC_SORT = '/assets/images/svg/sort-down.svg';
  public static REFUSAL_ICON = 'assets/images/svg/refusal-icon-admin.svg';
  public static GRIDE_DELETE = 'assets/images/svg/grid-delete-icon.svg';
  public static FILE_ICON = 'assets/images/svg/file-icon.svg';
  public static UPLOAD_ICON = 'assets/images/svg/upload-icon-admin.svg';
  public static CANCEL_ICON = 'assets/images/svg/cancel-icon-admin.svg';
  public static DOWNLOAD_ICON = 'assets/images/svg/download-insp.svg';
  public static DELETE_ICON = 'assets/images/svg/delete-icon-ins.svg';
  public static WARNING_ICON = 'assets/images/svg/warning-icon.svg';
}
