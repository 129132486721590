import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ProjectsService } from 'src/app/features/projects/services/projects.service';
@Component({
  selector: 'app-assign-orders-project',
  templateUrl: './assign-orders-project.component.html',
  styleUrls: ['./assign-orders-project.component.scss'],
})
export class AssignOrdersProjectComponent implements OnInit {
  projects: any;
  page_count: number = 0;
  params: any;
  page: number = 1;
  searchInput: any;
  timeout: any = null;
  order_inspector: any;
  @ViewChild('selectInsp') selectInsp: NgSelectComponent;

  constructor(
    private Projects: ProjectsService,
    private _mdr: MatDialogRef<AssignOrdersProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  getProjects(params) {
    this.Projects.getProjects(params).subscribe(
      (data) => {
        this.projects.push(...data.results);
        this.page_count = data.pages_count;
      },
      (error) => {
        try {
        } catch {}
      }
    );
  }

  projectsData(project) {
    if (project) {
      let projectData = this.projects.find((item) => item.pk == project);
      this.data = projectData;
    }
  }
  searchtext() {
    this.searchInput = this.selectInsp.searchTerm;
    this.params = { query: this.searchInput };
    this.projects = [];
    clearTimeout(this.timeout);
    var $this = this;
    $this.timeout = setTimeout(() => {
      if ($this.searchInput != '') {
        $this.getProjects(this.params);
      } else {
        this.params = { page: '1' };
        $this.getProjects(this.params);
      }
    }, 600);
  }

  resetSearch() {
    this.projects = [];
    this.params = { page: '1' };
    this.getProjects(this.params);
  }

  onScrollPagination() {
    if (this.page < this.page_count) {
      this.page++;
      this.params = { page: this.page };
      this.getProjects(this.params);
    }
  }
}
