import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { OrderType } from 'src/app/models/enums/order-type.enum';
import { RefundService } from '../../services/refund/refund.service';
import { RefundModalComponent } from '../refund-modal/refund-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-refund-view',
  templateUrl: './refund-view.component.html',
  styleUrls: ['./refund-view.component.scss'],
})
export class RefundViewComponent implements OnInit {
  orderID: number = 0;
  refundData: any = [];
  modelImg: boolean;
  confirmMsg: string;
  @Input() final_cost;
  @Input() isPaid;
  flag: boolean = false;
  paid = {
    is_paid: true,
  };

  @Input() status;
  orderStatus = OrderStatus;
  @Input() type;
  orderType = OrderType;
  refundDialogRef: MatDialogRef<RefundModalComponent>;

  constructor(
    private refundService: RefundService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.orderID = params['id'];
      this.getOrderCostRefund();
    });
  }

  getOrderCostRefund() {
    this.refundService.getOrderCostRefund(this.orderID).subscribe(
      (data) => {
        this.refundData = data;
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  handleBackendError(error) {
    try {
      console.log(error);
    } catch {
      console.log(error);
    }
  }

  deliveredOrder(id, paid) {
    this.flag = true;
    this.refundService.changeRefundStatus(id, paid).subscribe(
      (data) => {
        this.refundData[0].status = 'مسترد';
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  applyNewRefund(data) {
    this.getOrderCostRefund();
  }

  openRefundModal() {
    this.refundDialogRef = this.dialog.open(RefundModalComponent, {
      data: {
        final_cost: this.final_cost,
        orderID: this.orderID,
      },
    });
    this.refundDialogRef.afterClosed().subscribe((result) => {
      if (result?.type) {
        this.refundData.push(result);
      }
    });
  }
}
