import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-add-categories',
  templateUrl: './add-categories.component.html',
  styleUrls: ['./add-categories.component.scss'],
})
export class AddCategoriesComponent implements OnInit {
  addCategoryFormGroup: UntypedFormGroup;
  errorMsg: string;
  alertmsg: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddCategoriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.patchCategoiesValues();
  }
  createForm() {
    this.addCategoryFormGroup = this.formBuilder.group({
      categories: this.formBuilder.array([]),
    });
  }
  patchCategoiesValues() {
    if(this.data.length > 0) {
      this.data.forEach((element, index) => {
        this.addCategories();
        this.categories.at(index).get('name').setValue(element.name);
        this.categories.at(index).get('id').setValue(element.id);
        this.categories
          .at(index)
          .get('ratio')
          .setValue(element.ratio ?? 0);
      });
    } else {
      this.addCategories();
    }
  }

  addCategories() {
    const categoryForm = this.formBuilder.group({
      name: ['', Validators.required],
      id: [null, Validators.required],
      ratio: [0, Validators.required],
    });
    this.categories.push(categoryForm);
  }

  get categories(): FormArray {
    return this.addCategoryFormGroup.controls['categories'] as FormArray;
  }

  deleteItem(index) {
    this.categories.removeAt(index);
  }

  saveCategories() {
    let count = 0;
    this.addCategoryFormGroup.get('categories').value.forEach((item) => {
      count += item.ratio;
    });
    if (count !== 100) {
      this.alertmsg = true;
      this.errorMsg =
        'يجب ان تكون نسب الانجاز 100% ولكن  النسب المدخله ' + count + '%';
    } else {
      this.dialogRef.close(this.addCategoryFormGroup.value);
    }
  }
  closeError() {
    this.alertmsg = false;
  }
}
