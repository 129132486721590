<!-- <app-spinner *ngIf="spinner"></app-spinner> -->
<div class="inner-page-header">
  <h3>تقيمات العملاء</h3>
</div>
@if (rowData) {
<div class="request-control">
  <div class="filter-sec">
    @if (filterData) {
    <app-filter
      [filterData]="filterData"
      [filterDataInit]="filterDataInit"
      [filterPage]="filterPage"
      (formValus)="filterFormValus($event)"
    >
    </app-filter>
    }
    <quick-search (searchResults)="quickSearch($event)"></quick-search>
  </div>
  <button
    class="btn btn-green mr-2"
    (click)="handelExportExcel()"
    [disabled]="download"
  >
    @if (download) {
    <img class="spin-img" src="assets/images/svg/spinner-download.svg" alt="" />
    } تحميل كملف اكسيل
  </button>
</div>
}
<div class="saved-filters">
  <saved-filters></saved-filters>
</div>
<div class="grid-section">
  <ag-grid-angular
    #agGrid
    style="width: 100%; height: 100%"
    id="myGrid"
    enableRtl="true"
    class="ag-theme-alpine"
    [rowData]="rowData | paginate : config"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [components]="frameworkComponents"
    (gridReady)="onGridReady($event); excelGridReady($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    [paginationPageSize]="paginationPageSize"
  >
  </ag-grid-angular>
</div>
@if (rowData && rowData.length > 0) {
<ayen-pagination
  (pageData)="pageChanged($event)"
  (pageSize)="pageSizeChanged($event)"
  [totalCount]="config.totalItems"
  [pageTitle]="pageTitle"
>
</ayen-pagination>
}
<ag-grid-angular
  #agGrid
  id="myGrid2"
  enableRtl="true"
  class="ag-theme-alpine"
  [columnDefs]="columnDefs"
  [components]="frameworkComponents"
  [defaultColDef]="defaultColDef"
  (gridReady)="excelGridReady($event)"
>
</ag-grid-angular>
