import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BuildingTypeService } from 'src/app/core/services/buildingType/building-type.service';
import { OrderStatus } from 'src/app/models/enums/order-status.enum';
import { OrderDetailsService } from '../../../services/order-details/order-details.service';

@Component({
  selector: 'building-specifications',
  templateUrl: './building-specifications.component.html',
  styleUrls: ['./building-specifications.component.scss']
})
export class BuildingSpecificationsComponent implements OnInit {

  buildingSpecificationForm: UntypedFormGroup;
  @Input() orderData: any;
  @Input() orderID;
  @Input() order_type;
  @Output() errorMsg = new EventEmitter;
  selectedNeighbour_buildings: any;
  selectedNeighbour_streets: any;
  directions;
  errMsg = '';
  alertmsg: boolean = false;
  errorMessage: string;
  landFlag: boolean = false;
  buildingType: any;
  showSpinner: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private builgingTypes: BuildingTypeService,
    private orderDetails: OrderDetailsService) { }

  ngOnInit(): void {
    this.selectedNeighbour_buildings = this.orderData?.neighbour_buildings;
    this.selectedNeighbour_streets = this.orderData?.neighbour_streets;
    this.getDirections();
    this.createForm();
    this.createFormControls();
    this.patchValues();
    this.builgingTypes.currentBuildingType.subscribe(data => {
      this.buildingType = data;
      this.landFlag = false;
      if (this.buildingType == 7) {
        this.landFlag = true;
        this.clearFormControlValidators('age');
        this.clearFormControlValidators('floors_number');
        this.clearFormControlValidators('surfaces_space');
      }
      else if (this.buildingType != 7 && this.buildingType != 10) {
        this.setRequiredValidatorVal('surfaces_space');
      }
      else {
        this.setRequiredValidatorVal('floors_number');
        this.setRequiredValidatorVal('age');
        this.clearFormControlValidators('surfaces_space');

      }
    })
  }

  createForm() {
    this.buildingSpecificationForm = this.formBuilder.group({
      age: [null],
      floors_number: [null],
      land_area: [null],
      neighbour_buildings: [null, Validators.required],
      neighbour_streets: [null, Validators.required],
      surfaces_space: [null],
    })
  }

  createFormControls() {
    return this.buildingSpecificationForm.controls;
  }
  /**
    *  implement setRequiredValidatorVal
    */
  setRequiredValidatorVal(formControl) {
    this.buildingSpecificationForm.controls[formControl].setValidators(Validators.required);
    this.buildingSpecificationForm.controls[formControl].updateValueAndValidity();
  }

  clearFormControlValidators(formControl) {
    this.buildingSpecificationForm.controls[formControl].setValue(null);
    this.buildingSpecificationForm.controls[formControl].clearValidators();
    this.buildingSpecificationForm.controls[formControl].updateValueAndValidity();
  }
  /**
  *  implement patchValues to set form values on controls
  */
  patchValues() {
    this.buildingSpecificationForm.patchValue({
      age: this.orderData?.age,
      floors_number: this.orderData?.floors_number,
      land_area: this.orderData?.land_area,
      neighbour_buildings: this.orderData?.neighbour_buildings,
      neighbour_streets: this.orderData?.neighbour_streets,
      surfaces_space: this.orderData?.surfaces_space,
    });
  }

  getDirections() {
    this.orderDetails.getDirections().subscribe(data => {
      this.directions = data;
    },
      error => {
        try {
          this.errMsg = error;
        } catch {
          this.errMsg = error;
        }
      })
  }

  /**
  *  implement setNeighbour_buildings to set orderPeriods value on form control
  */
  setNeighbour(event, formControl) {
    this.buildingSpecificationForm.controls[formControl]
      .patchValue([
        ...event
      ]);
  }

  save() {
    this.alertmsg = false;
    if (this.buildingSpecificationForm.valid) {
      this.showSpinner = true;
      this.orderDetails.updateBuildingSpecifications(this.orderID, this.buildingSpecificationForm.value).subscribe(
        data => {
          this.showSpinner = false;
          this.showConfirmationModal();
        },
        error => {
          this.showSpinner = false;
          this.alertmsg = true;
          this.errorMessage = Object.values(error.error)[0].toString();
        }
      )
    }
    else {
      this.alertmsg = true;
      this.errorMessage = 'يوجد خطأ في بعض البيانات';
    }
  }

  showConfirmationModal() {
    this.errorMsg.emit({
      message: "تم تحديث البيانات بنجاح.",
      imgSuccess: true
    });
  }

  hideErrorMSG() {
    this.alertmsg = false;
  }
}
