<div class="dialog-container">
  <h2 mat-dialog-title class="modal-head">
    <p>ما الجديد؟ </p>
    <button type="button" class="close" mat-dialog-close>
      <img src="assets/images/svg/close.svg" alt="close-modal" />
    </button>
  </h2>
  <div mat-dialog-content>
    @if (whatsNewList.length > 0) {
      <div class="modal-body">
        @for (item of whatsNewList; track item; let i = $index) {
          <div class="event-date">
            <span class="cyrcle-icon" [ngClass]="{'readed': item.is_read}" (click)="toggleRead(item.id , i)"></span>
            <p>{{item.publish_date}}</p>
          </div>
          <h3 class="event-header">
            <span class="cyrcle-icon" [ngClass]="{'readed': item.is_read}" (click)="toggleRead(item.id , i )"></span>
            {{item.title}}
          </h3>
          <div class='img-container' (mouseenter)="mouseEnter(i)" (mouseleave)="mouseLeave(i)">
            @if (item.hover) {
              <div class='overlay-img'></div>
            }
            <img src="{{item.cover}}" alt="" />
          </div>
          <div class="summernote-container" [ngxSummernoteView]="item.body"></div>
          @if ( i < whatsNewList.length-1) {
            <div class="border-line"></div>
          }
        }
      </div>
    } @else {
      <div class="modal-body no-data">لا يوجد تحديثات جديدة</div>
    }
    <div class="modal-footer">
      <a class="mark-as-read" (click)="makeAllReaded()">تحديد الكل كمقروء</a>
      <div class="ayen-social-icons">
        <p>لمتابعة التحديثات أولاً بأول تابعنا على</p>
        <a href="https://twitter.com/AyenPlatform" target="_blank" rel=noopener>
          <img src="assets/images/svg/twitter-grey.svg" alt="" />
        </a>
        <a href="https://www.instagram.com/AyenPlatform/" target="_blank" rel=noopener>
          <img src="assets/images/svg/instagram-grey.svg" alt="" />
        </a>
        <a href="https://www.linkedin.com/company/ayenplatform/" target="_blank" rel=noopener>
          <img src="assets/images/svg/linkedin-grey.svg" alt="" />
        </a>
      </div>
    </div>
  </div>
</div>
