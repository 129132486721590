<div class="row"  [formGroup]="subForm" >
    <div class="col-md-6">
        <div class="form-group">
            <label class="form-label">الاسم الأول</label>
            <input formControlName="first_name" class="form-control" type="text">
            <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group">
            <label class="form-label">الاسم الأخير</label>
            <input formControlName="last_name" class="form-control" type="text">
            <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group">
            <label class="form-label">البريد الإلكتروني</label>
            <input formControlName="email" class="form-control" type="text">
            <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group">
            <label class="form-label">رقم الجوال</label>
            <input formControlName="mobile" class="form-control" type="text">
            <img class="form-control-feedback" src="assets/images/svg/error-icon1.svg" alt="">
        </div>
    </div>
</div>