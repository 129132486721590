<div class="dialog-container">
  <div mat-dialog-content>
    <div class="text-center">
      @if (data.imgSuccess) {
        <img src="../../../../assets/images/svg/done-icon-green.svg" alt=''>
      }
      @if (!data.imgSuccess) {
        <img [src]="data.imgPath" alt="">
      }
      <h2 class="dialog-message">{{data.message}}</h2>
    </div>
  </div>
  <div mat-dialog-actions class="actions-single">
    <a class="btn btn-cancel center-btn" (click)="closeModel()" [mat-dialog-close]="data">{{data.button}}</a>
  </div>
</div>
