<div class="dialog-container">
  <div mat-dialog-content>
    <div class="text-center">
      <img src="../../../../assets/images/svg/warning-icon.svg" alt=''>
      <h2 class="dialog-message">{{data.message}}</h2>
    </div>
  </div>
  <div mat-dialog-actions class="setup-footer actions-double">
    <button class="btn btn-cancel" type="button" mat-dialog-close>إلغاء</button>
    <button class="btn"
      [ngClass]="{'btn-delete': data.isDelete , 'btn-green' : !data.isDelete}"
      [mat-dialog-close]="data"
      type="submit">
      {{data.confirmBtn}}
    </button>
  </div>
</div>
