<div class="row">
  <div class="col-3 basic-data">
    <h4> الفاحص</h4>
    <a href="pages/inspectors/inspector-profile/{{orders?.order_details?.order_inspector?.pk}}">{{orders?.order_details?.order_inspector? orders.order_details?.order_inspector?.full_name : '-'}} </a>
  </div>
  <div class="col-3 basic-data">
    <h4> بدء عملية الفحص </h4>
    <p> {{orders?.started_at?orders.started_at : '-'}}  </p>
  </div>
  <div class="col-3 basic-data">
    <h4> وقت تسليم التقرير </h4>
    <p> {{orders?.ended_at?orders.ended_at : '-'}} </p>
  </div>
  <div class="col-3 basic-data">
    <h4> حصة الفاحص </h4>
    <p> {{orders?.cost?.cost_details?.inspector_share_sends_via_email
      ?orders?.cost?.cost_details?.inspector_share_sends_via_email: '-'}} @if (orders?.cost?.cost_details?.inspector_share_sends_via_email!='-') {
      <span> ر.س </span>
    } </p>
  </div>
</div>
<div class="row">
  <div class="col-3 basic-data">
    <h4>حالة الدفع (للفاحص)</h4>
    <div class="double-action">
      <p> {{orders?.cost?.inspector_payment_status ?orders?.cost?.inspector_payment_status  : '-'}}
        @if (orders?.cost?.inspector_payment_status  === 'مدفوعة') {
          <img src="assets/images/svg/ellipse-green.svg" alt="">
        }
      </p>
      @if (orders?.cost?.inspector_payment_status  === 'مستحقة') {
        <button (click)="deliveredOrder(orders.id, paid)" class="btn btn-green">
          تم الدفع
        </button>
      }
    </div>
  </div>
  <div class="col-3 basic-data">
    <h4>تاريخ الدفع (للفاحص)</h4>
    <p>{{orders?.cost?.inspector_payment_date?orders?.cost.inspector_payment_date : '-'}}</p>
  </div>
</div>
