<div class="row">
  <div class="col-md-12">
    <div class="notes-section">
      <label for="" class="form-label">ملخص الزيارة</label>
      <textarea
        class="form-control"
        readonly
        name="notes"
        maxlength="500"
      >{{visitText$ | async}}</textarea>
    </div>
  </div>
  @if (visitPhotos$ | async; as visitPhotos) {
    <div class="col-12 img-container">
      <label class="form-label">صور العقار</label>
      <div class="photos">
        @for (photo of visitPhotos; track photo) {
          <div class="img-wrapper"
            [ngStyle]="{'background-image': 'url(' + photo.photo + ')'}">
          </div>
        }
      </div>
    </div>
  }
</div>