
<!-- stepper -->
<div class="stepper">
    <div class="main-card">
      <div class="setup-panel">
        <div class="step-action">
          <a href="#step-1" class="step-status " [ngClass]="{'step-active' : selectedView === 'building'}">
            <div class="step-circle">
                <p>1</p>
              <img src="../../assets/images/svg/check-icon-white.svg" alt=""/>
            </div>
            <p class="step-title">تفاصيل العقار</p>
          </a>
        </div>
        <div class="step-action">
          <a href="#step-2" class="step-status" [ngClass]="{'step-active' : selectedView === 'inspectoring'}">
            <div class="step-circle">
                <p>2</p>
              <img src="../../assets/images/svg/check-icon-white.svg" alt=""/>
            </div>
            <p class="step-title">تفاصيل الفحص</p>
          </a>
        </div>
        <div class="step-action">
          <a href="#step-3" class="step-status" [ngClass]="{'step-active' : selectedView === 'summary'}">
            <div class="step-circle">
                <p>3</p>
              <img src="../../assets/images/svg/check-icon-white.svg" alt=""/>
            </div>
            <p class="step-title">ملخص الطلب</p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- end stepper -->
