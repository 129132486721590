@if (spinner) {
  <app-spinner></app-spinner>
}

<div class="inner-page-header">
  <h3>
    ارجاع مبلغ
    @if (order) {
      <a class="header-link" (click)="goOrder()">طلب #{{order?.reference_number}}</a>
    }
  </h3>
</div>

@if (order) {
  <form [formGroup]="refundFormGroup" (ngSubmit)="save()">
    <div class="main-card">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">رقم الطلب الاساسي </label>
            <input type="text" class="form-control" readonly formControlName="orderNumber" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">
              نوع الإرجاع
            </label>
            <ng-select bindLabel="name" class="single-select" placeholder="يرجى اختيار نوع الإرجاع " appendTo="body"
              [searchable]="true" formControlName="refundType" (change)="TypeChanged($event)" [clearable]="true">
              @for (type of refundTypes; track type) {
                <ng-option [value]="type.id">
                  {{type.name}}
                </ng-option>
              }
            </ng-select>
          </div>
        </div>
        @if (showValueField) {
          <div class="col-6">
            <div class="form-group">
              <label for="" class="form-label">ادخل مبلغ محدد</label>
              <span class="static-code">{{valueMark}}</span>
              <input type="number" class="form-control" formControlName="value" />
            </div>
          </div>
        }
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">
              طريقة الدفع
            </label>
            <ng-select bindLabel="name" class="single-select" placeholder="يرجى اختيار طريقة الدفع " appendTo="body"
              [searchable]="true" formControlName="paymentMethod" [clearable]="true">
              @for (method of paymentMethods; track method) {
                <ng-option [value]="method.id">
                  {{method.name}}
                </ng-option>
              }
            </ng-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">تاريخ الارجاع</label>
            <mat-form-field (click)="picker.open()" appearance="outline">
              <input matInput formControlName="refundDate" [matDatepicker]="picker" disabled />
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon class="calender-icon">
                  <img src="assets/images/svg/calendar-admin-icon.svg" alt="">
                </mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">رقم اشعار الدائن في منصة قيود</label>
            <input type="text" class="form-control" formControlName="qoudNotificationNumber" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">رقم السند</label>
            <input type="text" class="form-control" formControlName="bondNumber" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">سبب الارجاع</label>
            <ng-select bindLabel="name" class="single-select" placeholder=" اختر من القائمة " appendTo="body"
              formControlName="refundReason" [clearable]="true">
              @for (reason of refundReasons; track reason) {
                <ng-option [value]="reason.id">
                  {{reason.name}}
                </ng-option>
              }
            </ng-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="" class="form-label">رقم العميل في منصة قيود</label>
            <input type="number" class="form-control" formControlName="qoudClinetNumber" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <app-switch-input
            [label]="'تحويل حالة الطلب الى ملغي'"
            [labelEn] = "'changeOrderStatusToCancel'"
            [value]="changeOrderStatus"
            (valueChanged) = "changeOrderStatusHandler($event)">
          </app-switch-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          @if (alertmsg) {
            <div class="alert-box">
              <img alt=""
                src="assets/images/svg/error-icon.svg"
                class="close-alert"
                (click)="hideErrorMSG()"
                alt=""
                />
                <p class="alert-msg">{{errorMessage}}</p>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-12 double-action">
          <button class="btn btn-cancel" (click)="goOrder()" type="button">
            رجوع
          </button>
          <button class="btn btn-green" id='saveBtn' type="submit">
            حفظ
          </button>
        </div>
      </div>
    </form>
  }
