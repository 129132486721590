import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AvatarModelComponent } from '../../avatar-model/avatar-model.component';
import { FileModalComponent } from '../../file-modal/file-modal.component';
@Component({
  selector: 'app-avatar-render',
  templateUrl: './avatar-render.component.html',
  styleUrls: ['./avatar-render.component.scss'],
})
export class AvatarRenderComponent implements ICellRendererAngularComp {
  id: any;
  field: any;
  inputId: string;
  imgId: string;
  params: any;
  avatarDialogRef: MatDialogRef<AvatarModelComponent>;
  fileDialogRef: MatDialogRef<FileModalComponent>;

  constructor(private dialog: MatDialog) {}

  agInit(params: any): void {
    this.params = params;
    this.id = params?.data?.id;
    this.field = params?.column?.colId;
    this.inputId = `${this.field}_${this.id}`;
    this.imgId = `img_${this.id}`;
  }

  refresh(params: any) {
    return true;
  }

  showAvatarModal() {
    this.avatarDialogRef = this.dialog.open(AvatarModelComponent, {
      data: {
        inspectorName: this.params?.data?.full_name,
        inspectorID: this.id,
        imgPath: this.params?.data?.avatar || this.params?.value,
      },
    });
    this.avatarDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.params.value = null;
      }
    });
  }
  uploadFile(event) {
    this.fileDialogRef = this.dialog.open(FileModalComponent, {
      data: {
        files: event.target['files'],
        orderlabel: this.field,
        url: '/inspectors/' + this.id + '/',
      },
    });
    this.fileDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.params.value = result.value;
      }
    });
  }
}
