import { Component, ElementRef, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';

@Component({
  selector: 'quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent {

  searchSubject = new Subject();
  
  @ViewChild('searchInput', { static: true }) input: ElementRef;
  
  @Output() searchResults = this.searchSubject.pipe(
    debounceTime(600),
    distinctUntilChanged()
  );

  doSearch() {
    this.searchSubject.next(this.query);
  }

  private get query() { 
    return this.input.nativeElement.value; 
  }

}
