import { Component } from '@angular/core';
/**
  setting parent component
 *  
*/
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent {

}
