import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';
@Injectable({
  providedIn: 'root',
})
export class CompanyServicesService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getCompaniesList(params) {
    return this.http
      .get(this.baseURL + '/admin/companies/', { params: params })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  addCompany(data) {
    return this.http.post(this.baseURL + '/admin/companies/', data).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  UpdateCompany(companyId, data) {
    return this.http
      .patch(this.baseURL + '/admin/companies/' + companyId + '/', data)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getCompanyDetails(companyId) {
    return this.http
      .get(this.baseURL + '/admin/companies/' + companyId + '/')
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  deleteCompany(companyId) {
    return this.http
      .request('DELETE', this.baseURL + '/admin/companies/', {
        body: companyId,
      })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }
}
