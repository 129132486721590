import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'user-main-info',
  templateUrl: './user-main-info.component.html',
  styleUrls: []
})
export class UserMainInfoComponent implements OnInit {
  
  @Input() subForm: UntypedFormGroup;
  @Input() userData: any;
  
  ngOnInit(): void {
    this.patchValues();
  }

  patchValues(){
    this.subForm.patchValue({
      first_name: this.userData.first_name,
      last_name: this.userData.last_name,
      email: this.userData.email,
      mobile : this.userData.mobile
    });
  }

}
