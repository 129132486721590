import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AYENServiceBase } from 'src/app/core/services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class SettingServicesService extends AYENServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getUnitOfMeasurements(params): Observable<any> {
    return this.http.get(`${this.baseURL}/admin/unit-of-measurements/`,{params: params});
  }

  getRooms(params): Observable<any> {
    return this.http.get(`${this.baseURL}/admin/rooms/`,{params: params});
  }

  getFloors(params): Observable<any> {
    return this.http.get(`${this.baseURL}/admin/floors/`,{params:params});
  }

  getRecommendations(params): Observable<any> {
    return this.http.get(`${this.baseURL}/admin/note-recommendations/`,{params:params});
  }

  getTechnicalProblems(): Observable<any> {
    return this.http.get(`${this.baseURL}/admin/technical-problems-types/`);
  }
  
  addRoom(room): Observable<any> {
    return this.http.post(`${this.baseURL}/admin/rooms/`, room);
  }

  updateRoom(id, room): Observable<any> {
    return this.http.put(`${this.baseURL}/admin/rooms/${id}/`, room);
  }

  addFloor(floor): Observable<any> {
    return this.http.post(`${this.baseURL}/admin/floors/`, floor);
  }

  updateFloor(id, floor) {
    return this.http.put(`${this.baseURL}/admin/floors/${id}/`, floor);
  }

  addUnitOfMeasurements(unit): Observable<any> {
    return this.http.post(`${this.baseURL}/admin/unit-of-measurements/`, unit);
  }

  updateUnitOfMeasurements(id, unit): Observable<any> {
    return this.http.put(`${this.baseURL}/admin/unit-of-measurements/${id}/`, unit);
  }
  addStatus(status): Observable<any> {
    return this.http.post(`${this.baseURL}/admin/completion-statuses/`, status);
  }
  
  addRecommendation(data): Observable<any> {
    return this.http.post(`${this.baseURL}/admin/note-recommendations/`, data)
  }
  updateRecommendation(id, data): Observable<any> {
    return this.http.put(`${this.baseURL}/admin/note-recommendations/${id}/`, data);
  }
}
