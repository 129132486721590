import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/app/features/notifications/notifications.service';
import { WhatsNewService } from 'src/app/features/whats-new/services/whats-new/whats-new.service';
import { UserType } from 'src/app/models/enums/user-type.enum';
import { WhatsNewModalComponent } from 'src/app/shared/components/whats-new-modal/whats-new-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userData;
  userName;
  avatar;
  readTooltip: string = 'جعله مقروء';
  notificationList: any[] = [];
  ReadStatus: boolean = false;
  unread_count: number = 0;
  whatsNewDialogRef: MatDialogRef<WhatsNewModalComponent>;
  @Output() showOverlay = new EventEmitter();

  constructor(
    private router: Router,
    private whatsNewService: WhatsNewService,
    private titleService: Title,
    private Notifications: NotificationsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('currentUser')).user;
    this.userName = this.userData.first_name + ' ' + this.userData.last_name;
    this.avatar = this.userData.profile.avatar
      ? this.userData.profile.avatar
      : 'assets/images/avatar.svg';
    if (this.userData.profile.user_type === UserType.Admin) {
      this.notifyList();
    }
  }

  setting() {
    this.router.navigateByUrl('/pages/setting');
    this.titleService.setTitle('الإعدادات | عاين');
  }

  notifications() {
    this.router.navigateByUrl('/pages/notifications');
    this.titleService.setTitle('الاشعارات | عاين');
  }

  notifyList() {
    let params = '?page_size=8';
    this.Notifications.getNotificationListHeader(params).subscribe(
      (data) => {
        this.notificationList = data.results;
        this.unread_count = data.unread_count;
        if (this.unread_count > 99) {
          this.unread_count = 99;
        }
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }
  menuOpened() {
    this.showOverlay.emit(true);
  }

  menuClosed() {
    this.showOverlay.emit(false);
  }
  radioBtnToggle(isReadable, id, index) {
    this.ReadStatus = isReadable;
    this.ReadStatus = !this.ReadStatus;
    this.Notifications.toggleNotification(id).subscribe(
      (data) => {
        this.ReadStatus = data['is_read'];
        this.notificationList[index]['is_read'] = data['is_read'];
      },
      (error) => {
        this.handleBackendError(error);
      }
    );
  }

  handleBackendError(error) {
    try {
      console.log(error);
    } catch {}
  }
  stopclose($event: any) {
    $event.stopPropagation();
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/auth/login');
  }

  openWhatsNew() {
    this.whatsNewService.updateWhatsNewList(true);
    this.whatsNewDialogRef = this.dialog.open(WhatsNewModalComponent, {
      panelClass: 'whatsnew-dialog-container',
    });
  }
}
