@if (customerStatus[0]) {
  <span class="tag">{{ customerStatus[0] }}</span>
}
@if (customerStatus[1]) {
  <span class="tag">{{ customerStatus[1] }}</span>
}

@if (customerStatus.length > 2) {
  <span
    [matTooltip]="getTooltipMsg()"
    matTooltipClass="tip"
    class="data-tooltip"
    [attr.data-content]="getTooltipMsg()"
    >
    {{ customerStatus.length - 2 }}+</span
    >
  }
